import { ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';

/**
 * 本日時点で、無料期間最終日になるcreateTimeの日付。有料期間にあるProvider Accountを取ってくる場合、このday > x とする
 * @returns
 */
export const getLastFreeTermsDay = (): Date =>
  dayjs().startOf('d').subtract(30, 'd').add(1, 'd').toDate();

const getTraialDateDataSet = (providerAccount: ProviderAccount) => {
  const createTime = dayjs(providerAccount.createTime);
  if (!createTime.isValid()) {
    throw new Error('createTime is invalid');
  }

  const paymentStartDate = createTime.add(30, 'd').startOf('d');
  const freeLastDateString = paymentStartDate
    .subtract(1, 'd')
    .format('YYYY年M月D日');
  const today = dayjs().startOf('d');
  // TODO: テスト書きたい
  // diffの左辺の方が未来だとプラス、過去だとマイナスになる
  // paymentStartDateがtodayより未来。つまり、まだ無料期間
  const remain = paymentStartDate.diff(today, 'd');

  // 本当はAPIでやりたいけど時間ないので一旦ここで
  // todayがpaymentStartよりも未来なら > 0になる。paymentStartと同じだったらfinished
  const isFinishedTrial = today.diff(paymentStartDate, 'd') >= 0;

  return {
    freeLastDateString,
    remain,
    paymentStartDate,
    today,
    isFinishedTrial,
  };
};

export const needPayment = (providerAccount: ProviderAccount): boolean => {
  const { isFinishedTrial } = getTraialDateDataSet(providerAccount);
  return isFinishedTrial && providerAccount.paymentStatus === 'unregistered';
};

export const getRemainDates = (providerAccount: ProviderAccount) => {
  const { freeLastDateString, remain } = getTraialDateDataSet(providerAccount);

  return {
    freeLastDateString,
    remain,
  };
};
