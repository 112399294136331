import {
  CollectionReference,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
} from 'firebase/firestore';

import { range } from '@pochico/shared';
import CONSTANTS from '../../commons/constants';
import { Pagination } from './type';

export const getOne = async <T>(
  collectionRef: CollectionReference<T>,
  id: string
): Promise<T | undefined> => {
  return getDoc(doc(collectionRef, id)).then((snap) => {
    if (snap.exists()) {
      return snap.data();
    } else {
      return Promise.reject(`データが見つかりません。id: ${id}`);
    }
  });
};

export const getList = async <T extends { id: string }>(
  queryRef: Query<T>,
  pagination: Pagination<T>
): Promise<{ data: T[] }> => {
  // 内部的にループを回して必要な件数をFirestoreの上限を考慮しつつ、perPage or 上限数まで繰り返し取得する
  const maxResultCount = Math.min(
    pagination.perPage,
    CONSTANTS.PAGINATION_MAX_LIMIT
  );
  const loopCount = Math.ceil(maxResultCount / CONSTANTS.FIRESTORE_MAX_LIMIT);
  const data: T[] = [];
  let lastCursor = pagination.lastCursor;
  for (const i of range(0, loopCount)) {
    const { data: _data } = await _getList(queryRef, {
      perPage: Math.min(
        pagination.perPage - CONSTANTS.FIRESTORE_MAX_LIMIT * i,
        CONSTANTS.FIRESTORE_MAX_LIMIT
      ),
      sort: pagination.sort,
      lastCursor: lastCursor ?? undefined,
    });
    if (_data.length === 0) {
      break;
    } else {
      data.push(..._data);
      if (data.length >= maxResultCount) {
        break;
      }
      const lastData = data[data.length - 1];
      lastCursor = {
        cursor: lastData[pagination.sort.field],
        id: lastData.id,
      };
    }
  }
  // console.log({
  //   queryRef,
  //   maxResultCount,
  //   loopCount,
  //   dataLength: data.length,
  // });
  return { data };
};

const _getList = async <T extends { id: string }>(
  queryRef: Query<T>,
  pagination: Pagination<T>
): Promise<{ data: T[] }> => {
  const base = (() => {
    if (pagination.sort.field === 'id') {
      return query(
        queryRef,
        orderBy(String(pagination.sort.field), pagination.sort.direction)
      );
    } else {
      return query(
        queryRef,
        orderBy(String(pagination.sort.field), pagination.sort.direction),
        orderBy('id', 'asc')
      );
    }
  })();
  const q = query(
    pagination.lastCursor
      ? query(
          base,
          startAfter(
            ...(pagination.sort.field === 'id'
              ? [pagination.lastCursor.id]
              : [pagination.lastCursor.cursor, pagination.lastCursor.id])
          )
          // pagination.sort.direction === 'asc'
          //   ? startAfter(pagination.lastCursor)
          //   : endBefore(pagination.lastCursor)
        )
      : base,
    limit(Math.min(pagination.perPage, CONSTANTS.FIRESTORE_MAX_LIMIT))
  );
  return getDocs(q).then((snap) => {
    const data = snap.docs.map((doc) => {
      return doc.data();
    });
    return { data };
  });
};

export const getCount = async <T>(queryRef: Query<T>): Promise<number> => {
  return getCountFromServer(queryRef).then((s) => s.data().count);
};
