import { Spinner, Text } from '@chakra-ui/react';
import { AttachErrorCode, attachErrorCodes } from '@pochico/shared';
import React from 'react';

import { useAuthState } from '../../context/providerAccount';
import { fetchCustomerPortalURL } from '../../dataStore/paymentRepository';
import { WebLink } from './WebLink';

export const ErrorCode = ({
  errorCode,
  error,
}: {
  errorCode: AttachErrorCode;
  error?: string;
}) => {
  const { providerAccount, firebaseUser } = useAuthState();
  const [errorElement, setErrorElement] = React.useState<JSX.Element>();

  React.useEffect(() => {
    const f = async () => {
      switch (errorCode as AttachErrorCode) {
        case attachErrorCodes.timeout:
          return (
            <Text>
              タイムアウトが発生しました。時間を空けてもう一度ログインしてからお試しください。
            </Text>
          );

        case attachErrorCodes.unauthenticated:
          return (
            <Text>
              認証処理に失敗しました。時間を空けてもう一度ログインしてからお試しください。
            </Text>
          );

        case attachErrorCodes.already_attached:
          return (
            <Text>
              LINE公式アカウントがすでにポチコと連携済みです。ログインページから再度ログインしてお試しください。
            </Text>
          );

        case attachErrorCodes.not_allowed:
          return <Text>許可されていない操作です。</Text>;

        case attachErrorCodes.already_connected:
          return (
            <Text>
              複数のLINE公式アカウントを同じメールアドレスで登録することはできません。新規登録ページから別のメールアドレスで新しくアカウントを作成してください。
            </Text>
          );

        case attachErrorCodes.user_cancelled_authorize:
          return (
            <Text>
              操作が中断されました。再度ログインしてからLINE連携をやり直してください
            </Text>
          );

        case attachErrorCodes.invalid_request:
          return <Text>リクエストが不正です</Text>;

        case attachErrorCodes.invalid_client:
          return (
            <Text>
              入力された情報が正しくありません。確認して再度お試しください。
            </Text>
          );

        case attachErrorCodes.unpaid_invoice:
          const portalUrl = await (async () => {
            if (firebaseUser && providerAccount) {
              return fetchCustomerPortalURL(firebaseUser, providerAccount);
            }
            return;
          })();
          const text =
            '支払い期日が過ぎている請求があります。先に支払いを済ませてからLINE連携をおこなってください。';
          return (
            <>
              {text}
              <br />
              {portalUrl ? (
                <>
                  <WebLink href={portalUrl} target={'_blank'} rel={'noopener'}>
                    支払いポータル
                  </WebLink>
                  を確認してください
                </>
              ) : (
                <Spinner />
              )}
            </>
          );

        default:
          const _error = errorCode + (error || '');
          return (
            <Text>
              予期しないエラーが発生しました。時間を空けてもう一度ログインしてからお試しください。エラー:{' '}
              {_error}
            </Text>
          );
      }
    };

    f().then(setErrorElement);
  }, [error, errorCode, firebaseUser, providerAccount]);

  return errorElement || <></>;
};
