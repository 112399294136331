import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { MailNotificationConfigurationPage } from '../components/features/configuration/MailNotificationConfiguration';

export const MailNotificationConfiguration: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  return (
    <MailNotificationConfigurationPage providerAccount={providerAccount} />
  );
};
