import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

export const Card: React.FC<BoxProps & { children: React.ReactNode }> = (
  props
) => {
  return (
    <Box boxShadow={'lg'} {...props}>
      {props.children}
    </Box>
  );
};
