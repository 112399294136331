import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useMutation } from '@tanstack/react-query';
import { SpotCreateParams } from '../../../firebase/types';
import { useCreateSpot } from '../../../hooks/spots';
import { useDialogDispatcher } from '../../ui/Dialog';

export const useSpotCreateDialog = ({
  providerAccount,
  bookingMenuList,
}: {
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}) => {
  const { openDialog } = useDialogDispatcher();
  const open = React.useCallback(
    (
      bookingMenu: BookingMenu,
      input: { date: string; startTime: string } | undefined
    ) => {
      openDialog({
        size: '2xl',
        body: (
          <DialogBody
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
            bookingMenuList={bookingMenuList}
            input={input}
          />
        ),
      });
    },
    [bookingMenuList, openDialog, providerAccount]
  );

  return {
    open,
  };
};

const DialogBody: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
  input: { date: string; startTime: string } | undefined;
}> = ({ providerAccount, bookingMenu, bookingMenuList, input }) => {
  const { closeDialog } = useDialogDispatcher();
  const createSpot = useCreateSpot(providerAccount);
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: createSpot.mutateAsync,
    onSuccess: (result) => {
      toast({
        title: '予約枠を作成しました',
        status: 'success',
      });
      closeDialog();
    },
    onError: (error) => {
      toast.closeAll();
      toast({
        title: `予約枠の作成に失敗しました ${error}`,
        status: 'error',
      });
    },
  });
  const inputStartTime = React.useMemo(() => {
    if (!input) {
      return undefined;
    }
    const [hour, minute] = String(input.startTime).split(':').map(Number);
    return { hour, minute };
  }, [input]);

  const form = useForm<SpotCreateParams & { startTime: string }>({
    mode: 'onChange',
    defaultValues: {
      date: input?.date,
      startTime: input?.startTime,
      startTimeHour: inputStartTime?.hour,
      startTimeMinute: inputStartTime?.minute,
      bookingMenuId: bookingMenu.id,
    },
  });

  return (
    <AlertDialogContent width={'fit-content'} overflowY={'auto'}>
      <AlertDialogHeader>
        <Text fontSize={'lg'} fontWeight="bold">
          予約枠の作成
        </Text>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((params) => {
            return mutation.mutateAsync(params);
          })}
          style={{
            width: '100%',
          }}
        >
          <AlertDialogBody w={'400px'}>
            <VStack
              w={'full'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              py={'8px'}
              spacing={'10px'}
            >
              <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
                <FormControl
                  isRequired={false}
                  isInvalid={!!form.formState.errors.date}
                >
                  <FormLabel>予約日</FormLabel>
                  <Input
                    w={'full'}
                    type={'date'}
                    {...form.register('date', {
                      required: true,
                    })}
                    bg={'white'}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.date?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.startTime}
                >
                  <FormLabel>開始時間</FormLabel>
                  <Input
                    w={'full'}
                    {...form.register('startTime', {
                      required: true,
                      validate: (value) => {
                        if (!value) {
                          return '入力してください';
                        }
                        return undefined;
                      },
                      onChange: (e) => {
                        const [hour, minute] = String(e.target.value).split(
                          ':'
                        );
                        form.setValue('startTimeHour', Number(hour));
                        form.setValue('startTimeMinute', Number(minute));
                      },
                    })}
                    type={'time'}
                    bg={'white'}
                  />
                  <FormErrorMessage>
                    {form.formState.errors?.startTime?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.bookingMenuId}
                >
                  <FormLabel>予約メニュー</FormLabel>
                  <Select
                    {...form.register('bookingMenuId', {
                      required: true,
                    })}
                    bg={'white'}
                    w={'full'}
                  >
                    {bookingMenuList.map((menu) => (
                      <option key={menu.id} value={menu.id}>
                        {menu.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {form.formState.errors?.bookingMenuId?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.maxBookings}
                >
                  <FormLabel>予約枠数</FormLabel>
                  <Input
                    type={'number'}
                    w={'full'}
                    placeholder={'この時間に予約できる枠数'}
                    bg={'white'}
                    {...form.register('maxBookings', {
                      valueAsNumber: true,
                      required: true,
                      validate: (value) => {
                        if (value < 0) {
                          return '0以上の数値を入力してください';
                        }
                        return undefined;
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {form.formState.errors?.maxBookings?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter justifyContent={'center'}>
            <VStack w={'full'} spacing={6} alignItems={'flex-end'}>
              <Button
                // w={'full'}
                variant={'blue-fill'}
                type={'submit'}
                isLoading={mutation.isPending}
                paddingRight={4}
                isDisabled={!form.formState.isValid}
              >
                保存する
              </Button>
            </VStack>
          </AlertDialogFooter>
        </form>
      </FormProvider>
    </AlertDialogContent>
  );
};
