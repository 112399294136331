import { HStack, Input, Switch, Text, VStack } from '@chakra-ui/react';
import { BookingMenu } from '@pochico/shared';
import dayjs from 'dayjs';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CONSTANTS from '../../commons/constants';
import { useIsPC } from '../../hooks/useIsPC';

// 受付開始/終了、キャンセル期限の入力
type LimitDateTimeInputProps = {
  bookingMenu?: BookingMenu;
  source: 'bookingStart' | 'bookingEnd' | 'cancelEnd';
  label: string; // フォームのタイトル
  sentence: string; // フォーム内の文章('X日前のHH:MM'のあとに続く文章)
  showExample: (input: { dayBefore: number; time?: string }) => string; // フォーム内の例示する文章
};

export const LimitDateTimeInput: React.FC<{
  bookingMenu?: LimitDateTimeInputProps['bookingMenu'];
  source: LimitDateTimeInputProps['source'];
}> = ({ bookingMenu, source }) => {
  switch (source) {
    case 'bookingStart':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="予約受付の『開始』時間を設定する"
          source="bookingStart"
          sentence="から予約受付を開始する"
          showExample={({ dayBefore, time }) => {
            const now = dayjs('2023-03-31 15:00:00+09:00');
            return `この設定では、3月31日 15:00の枠は${now
              .add(-dayBefore, 'day')
              .format(`M月D日`)} ${time || '15:00'}から予約が可能になります。`;
          }}
        />
      );
    case 'bookingEnd':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="予約受付の『終了』時間を設定する"
          source="bookingEnd"
          sentence="で予約の受付を終了する"
          showExample={({ dayBefore, time }) => {
            const now = dayjs('2023-03-31 15:00:00+09:00');
            return `この設定では、3月31日 15:00の枠は${now
              .add(-dayBefore, 'day')
              .format(`M月D日`)} ${time || '15:00'}より後は予約ができません。`;
          }}
        />
      );
    case 'cancelEnd':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="『キャンセル』受付の終了時間を設定する"
          source="cancelEnd"
          sentence="にキャンセル受付を終了する"
          showExample={({ dayBefore, time }) => {
            const now = dayjs('2023-03-31 15:00:00+09:00');
            return `この設定では、3月31日 15:00の枠は${now
              .add(-dayBefore, 'day')
              .format(`M月D日`)} ${
              time || '15:00'
            }より後は予約をキャンセルできません。`;
          }}
        />
      );
  }
};

// daysBefore/timeの入力フォームを共通化するためのcomponent
const LimitDateTimeInputShared = (props: LimitDateTimeInputProps) => {
  const isPC = useIsPC();
  const { source, label, bookingMenu, sentence, showExample } = props;
  const [visible, setVisible] = React.useState<boolean>(() => {
    return !!bookingMenu?.[source];
  });
  const { register, formState, control, setValue } = useFormContext();

  const dayBefore = useWatch({ control, name: `${source}.dayBefore` });
  const time = useWatch({ control, name: `${source}.time` });

  const onChange = React.useCallback(() => {
    const newVisible = !visible;
    // チェックボックスがtrueの時だけ入力欄を有効化する
    if (newVisible) {
      // editの際はすでにある値を使う
      const dayBefore = bookingMenu?.[source]?.dayBefore
        ? bookingMenu[source]?.dayBefore
        : CONSTANTS.DEFAULT_BOOKING_LIMIT_DAY_BEFORE;
      const time = bookingMenu?.[source]?.time
        ? bookingMenu[source]?.time
        : CONSTANTS.DEFAULT_BOOKING_LIMIT_TIME;
      setValue(`${source}.dayBefore`, dayBefore, { shouldDirty: true });
      setValue(`${source}.time`, time, { shouldDirty: true });
    } else {
      setValue(`${source}`, undefined, { shouldDirty: true });
    }
    setVisible(newVisible);
  }, [bookingMenu, setValue, source, visible]);

  return (
    <VStack w={'full'} spacing={'12px'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          {/* <Icon as={Schedule} color={'black'} /> */}
          <Text fontWeight={'bold'}>{label}</Text>
          {/* <Icon as={HelpOutline} color={'red'} /> */}
        </HStack>
        <HStack spacing={2}>
          <Text>{visible ? 'ON' : 'OFF'}</Text>
          <Switch
            defaultChecked={visible}
            checked={visible}
            onChange={onChange}
          />
        </HStack>
      </HStack>
      {visible && (
        <VStack
          mt={'8px'}
          w={'full'}
          spacing={'12px'}
          alignItems={'flex-start'}
        >
          {isPC ? (
            <HStack
              justifyContent={'center'}
              alignItems={'baseline'}
              spacing={'4px'}
            >
              <Input
                width={'4rem'}
                type={'number'}
                placeholder={'5'}
                paddingRight={0}
                min={0}
                max={180}
                {...register(`${source}.dayBefore`, {
                  required: visible,
                  // valueAsNumber: true,
                })}
              />
              <Text>日前の</Text>
              <Input
                width={'8rem'}
                type={'time'}
                placeholder={'12:00'}
                paddingLeft={'8px'}
                paddingRight={0}
                min={0}
                max={180}
                {...register(`${source}.time`, {
                  required: false,
                })}
              />
              <Text>{sentence}</Text>
            </HStack>
          ) : (
            <HStack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={'4px'}
            >
              <VStack alignItems={'flex-start'}>
                <HStack>
                  <Input
                    width={'4rem'}
                    type={'number'}
                    placeholder={'5'}
                    paddingRight={0}
                    min={0}
                    max={180}
                    {...register(`${source}.dayBefore`, {
                      required: visible,
                    })}
                  />
                  <Text>日前の</Text>
                </HStack>
                <Input
                  width={'8rem'}
                  type={'time'}
                  placeholder={'12:00'}
                  // paddingLeft={'8px'}
                  // paddingRight={0}
                  min={0}
                  max={180}
                  {...register(`${source}.time`, {
                    required: false,
                  })}
                />
              </VStack>
              <Text>{sentence}</Text>
            </HStack>
          )}
          <VStack
            w={'full'}
            borderRadius={'4px'}
            backgroundColor={'gray.50'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            paddingX={'16px'}
            paddingY={'8px'}
            alignItems={'flex-start'}
            spacing={'4px'}
          >
            {/* <Icon as={MdOutlinePushPin} color={'black'} /> */}
            <Text fontWeight={'bold'} color={'blue.500'}>
              現在の設定
            </Text>
            <Text>{showExample({ dayBefore, time })}</Text>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
