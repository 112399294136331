import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Switch,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { deleteField } from 'firebase/firestore';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useAuthState } from '../../../context/providerAccount';
import { useIsPC } from '../../../hooks/useIsPC';
import { updateProviderAccount } from '../../../providers/dataProvider/providerAccount';
import { Path } from '../../../routers/Path';
import { Layout } from '../../ui/Layout';

type InputProps = NonNullable<Pick<ProviderAccount, 'notification'>>;
export const MailNotificationConfigurationPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { refetch } = useAuthState();
  const form = useForm<InputProps>({
    defaultValues: {
      notification: providerAccount.notification || {
        mail: {
          to: providerAccount.invoiceEmail,
          onBooking: false,
          onCancel: false,
        },
      },
    },
  });
  const {
    register,
    setFocus,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = form;
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (input: InputProps) => {
        return updateProviderAccount(providerAccount, {
          notification: input.notification
            ? {
                mail: {
                  to: input.notification.mail.to,
                  onBooking: input.notification.mail.onBooking,
                  onCancel: input.notification.mail.onCancel,
                },
              }
            : deleteField(),
        });
      },
      [providerAccount]
    ),
    onSuccess: async () => {
      toast.closeAll();
      toast({
        title: '保存しました',
        status: 'success',
      });
      await refetch();
    },
    onError: (error) => {
      toast({
        title: `エラーが発生しました ${error}`,
        description: error.message,
        status: 'error',
      });
    },
  });

  const isPC = useIsPC();
  const [onBooking, onCancel] = useWatch({
    control: form.control,
    name: ['notification.mail.onBooking', 'notification.mail.onCancel'],
  });

  return (
    <Layout
      pageTitle={'予約/キャンセル時のメール通知'}
      hasBackButton={Path.configuration}
    >
      <FormProvider {...form}>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit((bookingLimitation) =>
            mutation.mutateAsync(bookingLimitation)
          )}
        >
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            maxW={'600px'}
            spacing={{ base: '12px', md: '20px' }}
          >
            <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                spacing={'16px'}
                px={{ base: 0, md: '16px' }}
                py={{ base: '32px', md: '16px' }}
                borderRadius={'4px'}
                borderColor={'gray.300'}
                borderWidth={'1px'}
              >
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text fontSize={'16px'} fontWeight={'bold'}>
                    予約時にメール通知を受け取る
                  </Text>
                  <HStack>
                    <Text fontSize={'14px'}>{onBooking ? 'ON' : 'OFF'}</Text>
                    <Switch
                      {...register('notification.mail.onBooking')}
                      colorScheme={'blue'}
                      size={'md'}
                    />
                  </HStack>
                </HStack>
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text fontSize={'16px'} fontWeight={'bold'}>
                    キャンセル時にメール通知を受け取る
                  </Text>
                  <HStack>
                    <Text fontSize={'14px'}>{onCancel ? 'ON' : 'OFF'}</Text>
                    <Switch
                      {...register('notification.mail.onCancel')}
                      colorScheme={'blue'}
                      size={'md'}
                    />
                  </HStack>
                </HStack>
                <Text fontSize={'14px'} color={'gray.500'}>
                  ※メールの月間送付メール数は1,000件が上限です。
                  <br />
                  　（例：1日平均10予約・5キャンセル、営業日20日 = 300件）
                </Text>
              </VStack>
              <FormControl
                w={'full'}
                isInvalid={!!errors.notification?.mail?.message}
                isRequired={onBooking || onCancel}
              >
                <FormLabel>通知先メールアドレス</FormLabel>
                <Input
                  {...register('notification.mail.to')}
                  type={'email'}
                  autoComplete="email"
                />
              </FormControl>
            </VStack>
            <Button
              type={'submit'}
              isLoading={mutation.isPending}
              w={{ base: 'full', md: 'fit-content' }}
              alignSelf={'flex-end'}
              variant={'blue-fill'}
              isDisabled={!isValid || !isDirty}
              size={'md'}
              h={'44px'}
            >
              保存する
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  );
};
