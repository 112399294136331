import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BookingFormElement,
  ProviderAccount,
  getRandomString,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React, { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  MdAdd,
  MdCallMade,
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import {
  BookingFormCreateParams,
  BookingFormUpdateParams,
} from '../../../providers/dataProvider/bookingForm';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { useLoadingOverlayContext } from '../../ui/LoadingOverlay';
import { TextWithLinks } from '../../ui/TextWithLink';
import { WebLink } from '../../ui/WebLink';
import { useBookingFormElementEditor } from './BookingFormElementEditorDialog';
import { BookingFormElementView } from './BookingFormElementView';

// 親側でkeyを指定して編集されるたびに強制的に再レンダリングされるようにしているのでuseStateを使わない
export const BookingFormElementsEditor: React.FC<{
  providerAccount: ProviderAccount;
  onChange: () => Promise<void>;
}> = ({ providerAccount, onChange }) => {
  const formContext = useFormContext<
    BookingFormCreateParams | BookingFormUpdateParams
  >();

  const { swap, fields, insert, remove } = useFieldArray({
    control: formContext.control,
    name: `formElements`,
    keyName: '_id',
  });
  const { onClickToEdit, renderEditor } = useBookingFormElementEditor(
    onChange,
    formContext
  );

  const loadingOverlay = useLoadingOverlayContext();
  const mutationOnSwap = useMutation({
    mutationFn: async () => {
      loadingOverlay.onOpen();
      return onChange().finally(loadingOverlay.onClose);
    },
  });

  const confirmationDialog = useConfirmationDialog();
  const onClickRemove = React.useCallback(
    (formElement: BookingFormElement, idx: number) => {
      confirmationDialog.openConfirmationDialog({
        title: '削除しますか？',
        body: (
          <VStack w={'full'} alignItems={'flex-start'}>
            <HStack>
              <Text whiteSpace={'nowrap'}>質問：</Text>
              <TextWithLinks>{formElement.title}</TextWithLinks>
            </HStack>
            <br />
            <Text>この操作は取り消せません。</Text>
          </VStack>
        ),
        submitText: '削除',
        // submitButtonDisabledDurationSec: 1,
        cancelText: 'キャンセル',
        onSubmit: () => {
          remove(idx);
          loadingOverlay.onOpen();
          return onChange().finally(loadingOverlay.onClose);
        },
      });
    },
    [confirmationDialog, loadingOverlay, onChange, remove]
  );
  const onClickToAdd = React.useCallback(
    async (index: number) => {
      const now = new Date();
      const id = getRandomString(10);
      const formElement: BookingFormElement = {
        id,
        title: '',
        description: '',
        required: false,
        type: 'string',
        createTime: now,
        updateTime: now,
      };
      insert(index, formElement);
      onClickToEdit(formElement, index);
    },
    [insert, onClickToEdit]
  );
  const buttonToAdd = React.useCallback(
    (index: number) => {
      return (
        <Button
          variant={'transparent-clickable'}
          w={'full'}
          onClick={() => onClickToAdd(index)}
        >
          <HStack w={'full'} gap={'24px'} p={0} m={0} whiteSpace={'nowrap'}>
            <Divider borderColor={'gray.500'} />
            <HStack color={'gray.500'} p={0} m={0}>
              <Icon as={MdAdd} m={0} />
              <Text>追加</Text>
            </HStack>
            <Divider borderColor={'gray.500'} />
          </HStack>
        </Button>
      );
    },
    [onClickToAdd]
  );

  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
      <VStack w={'full'} py={'20px'} alignItems={'flex-start'}>
        <HStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          <Text fontSize={'lg'} fontWeight={'bold'}>
            その他の質問
          </Text>

          <WebLink
            href={'https://help.pochico.app/11aeddbd00d780ddae88c12549f4f364'}
            cursor={'pointer'}
            textDecoration={'underline'}
            target={'_blank'}
          >
            <HStack>
              <Text>質問の編集について</Text>
              <Icon as={MdCallMade} />
            </HStack>
          </WebLink>
        </HStack>
      </VStack>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        spacing={'16px'}
        // divider={ButtonToAdd}
        // divider={<StackDivider borderColor={'gray.500'} />}
      >
        {fields.map((formElement, idx) => {
          const isInvalid = !formElement.title;
          return (
            <Fragment key={`${formElement.id}-${formElement.title}`}>
              <FormControl isInvalid={isInvalid}>
                <VStack
                  bgColor={'white'}
                  w={'full'}
                  alignItems={'flex-start'}
                  flexGrow={1}
                  position={'relative'}
                  p={0}
                  spacing={0}
                  gap={0}
                  borderRadius={'12px'}
                  boxShadow={'base'}
                  borderWidth={isInvalid ? '2px' : '1px'}
                  borderColor={isInvalid ? 'red.300' : 'gray.100'}
                  divider={
                    <StackDivider m={0} p={0} borderColor={'gray.200'} />
                  }
                >
                  <HStack
                    w={'full'}
                    justifyContent={'space-between'}
                    px={'20px'}
                    py={'16px'}
                  >
                    <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
                      質問{idx + 1}
                    </Text>
                    <HStack gap={'6px'}>
                      <IconButton
                        icon={<MdKeyboardArrowUp />}
                        aria-label="Move up"
                        size={'16px'}
                        onClick={() => {
                          swap(idx, idx - 1);
                          return mutationOnSwap.mutateAsync();
                        }}
                        isDisabled={idx === 0 || fields.length === 1}
                      />
                      <IconButton
                        icon={<MdKeyboardArrowDown />}
                        aria-label="Move down"
                        size={'16px'}
                        onClick={() => {
                          swap(idx, idx + 1);
                          return mutationOnSwap.mutateAsync();
                        }}
                        isDisabled={
                          idx === fields.length - 1 || fields.length === 1
                        }
                      />
                    </HStack>
                  </HStack>
                  <BookingFormElementView element={formElement} />

                  {/* <BookingFormEditElement idx={idx} /> */}
                  <HStack
                    w={'full'}
                    px={'20px'}
                    py={'16px'}
                    spacing={'12px'}
                    justifyContent={'flex-end'}
                  >
                    <Button
                      variant={'white-red'}
                      onClick={() => {
                        onClickRemove(formElement, idx);
                      }}
                    >
                      <HStack>
                        <MdDelete />
                        <Text>削除</Text>
                      </HStack>
                    </Button>
                    <Button
                      variant={'blue-fill'}
                      onClick={() => {
                        onClickToEdit(formElement, idx);
                      }}
                    >
                      <HStack>
                        <MdEdit />
                        <Text>編集</Text>
                      </HStack>
                    </Button>
                  </HStack>
                </VStack>
                <FormErrorMessage>
                  {isInvalid ? '編集してください' : ''}
                </FormErrorMessage>
              </FormControl>
              {buttonToAdd(idx + 1)}
            </Fragment>
          );
        })}
      </VStack>
      {renderEditor()}
      {fields.length > 0 ? null : ( // ButtonToAdd
        <Button
          py={'20px'}
          h={'256px'}
          variant={'transparent-clickable'}
          w={'full'}
          onClick={() => onClickToAdd(0)}
          borderRadius={'4px'}
          borderWidth={'1px'}
          borderStyle={'dashed'}
          borderColor={'gray.500'}
        >
          <VStack color={'gray.500'} p={0} m={0} fontSize={'18px'}>
            <Icon as={MdAdd} m={0} />
            <Text>質問を追加</Text>
          </VStack>
        </Button>
      )}
    </VStack>
  );
};
