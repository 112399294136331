import { useToast } from '@chakra-ui/react';
import React from 'react';
import CONSTANTS from '../commons/constants';

export const useShowTooManyResultWarning = (count: number | undefined) => {
  const toast = useToast();

  React.useEffect(() => {
    if (count && count > CONSTANTS.PAGINATION_MAX_LIMIT) {
      if (!toast.isActive('too-many-result')) {
        toast({
          id: 'too-many-result',
          title: '該当するデータ件数が多すぎるため、全件は表示できません',
          status: 'warning',
          isClosable: true,
          duration: 2000,
        });
      }
    }
  }, [count, toast]);
};
