import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import React from 'react';

import { useTimer } from '../../helpers/timer';
import { useDialogDispatcher } from './Dialog';

export type OpenConfirmationDialogParams = {
  title: string;
  body: React.ReactNode;
  size?: string;
  cancelText: string;
  submitText: string;
  submitButtonColor?: ButtonProps['colorScheme'];
  submitButtonDisabledDurationSec?: number;
  onSubmit: () => void;
  onCancel?: () => void;
};
export const useConfirmationDialog = () => {
  const state = useDialogDispatcher();
  const openConfirmationDialog = React.useCallback(
    (params: OpenConfirmationDialogParams) => {
      return state.openDialog({
        size: params.size,
        body: <ConfirmationDialogBody state={state} params={params} />,
      });
    },
    [state]
  );
  return {
    isOpen: state.isOpen,
    openConfirmationDialog,
    closeConfirmationDialog: state.closeDialog,
  };
};

const ConfirmationDialogBody: React.FC<{
  state: ReturnType<typeof useDialogDispatcher>;
  params: OpenConfirmationDialogParams;
}> = ({ state, params }) => {
  const timer = useTimer({
    durationSec: params.submitButtonDisabledDurationSec || 0,
  });
  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {params?.title}
      </AlertDialogHeader>

      <AlertDialogBody>{params.body}</AlertDialogBody>

      <AlertDialogFooter>
        <Button
          // ref={cancelRef}
          onClick={() => {
            params?.onCancel?.();
            state.closeDialog();
          }}
        >
          {params.cancelText}
        </Button>
        <Button
          colorScheme={params.submitButtonColor || 'red'}
          onClick={() => {
            params.onSubmit();
            state.closeDialog();
          }}
          isLoading={!timer.done}
          ml={3}
        >
          {params.submitText}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
