import { useCallback, useEffect, useState } from 'react';

import { useAuthState } from '../context/providerAccount';
import { FirebaseUser } from '../firebase/firebaseInit';
import { deleteBotApi, getBotApi, postBotApi } from './bot';

const getRichMenu = async (
  firebaseUser: FirebaseUser,
  providerAccountId: string
): Promise<
  { richMenuId: string; hasOriginalRichMenu: boolean } | undefined
> => {
  return getBotApi<{ richMenuId: string; hasOriginalRichMenu: boolean }>(
    `/rich-menu/provider-account/${providerAccountId}`,
    firebaseUser
  ).then((response) => {
    if (response.ok) {
      return response.body;
    } else {
      console.warn(`failed to get rich menu: ${response.error}`);
      return undefined;
    }
  });
};

const setDefaultRichMenu = async (
  firebaseUser: FirebaseUser,

  providerAccountId: string
): Promise<{ richMenuId: string } | undefined> => {
  return postBotApi<{ richMenuId: string }>(
    `/rich-menu/provider-account/${providerAccountId}`,
    firebaseUser,
    {}
  ).then((response) => {
    if (response.ok) {
      return response.body;
    } else {
      console.error(`failed to set default rich menu: ${response.error}`);
      throw response.error;
      // return undefined;
    }
  });
};

const deleteDefaultRichMenu = async (
  firebaseUser: FirebaseUser,
  providerAccountId: string
): Promise<void> => {
  await deleteBotApi(
    `/rich-menu/provider-account/${providerAccountId}`,
    firebaseUser
  );

  return;
};

export const useRichMenu = () => {
  const { firebaseUser, providerAccount } = useAuthState();
  const [richMenuId, setRichMenuId] = useState<string | undefined>();
  const [isEnabledPochicoRichMenu, setIsEnabledPochicoRichMenu] = useState<
    boolean | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!firebaseUser || !providerAccount) {
      return;
    }

    setLoading(true);
    getRichMenu(firebaseUser, providerAccount.id)
      .then((result) => {
        if (result) {
          const _richMenuId = result.richMenuId;
          setRichMenuId(_richMenuId);
          setIsEnabledPochicoRichMenu(isRichMenuEnabled(_richMenuId));
        }
      })
      .finally(() => setLoading(false));
  }, [firebaseUser, providerAccount]);

  const _setDefaultRichMenu = useCallback(async () => {
    if (!firebaseUser || !providerAccount) {
      return;
    }
    setLoading(true);
    return setDefaultRichMenu(firebaseUser, providerAccount.id)
      .then((result) => {
        const _richMenuId = result?.richMenuId;
        setRichMenuId(_richMenuId);
        setIsEnabledPochicoRichMenu(isRichMenuEnabled(_richMenuId));
      })
      .finally(() => setLoading(false));
  }, [firebaseUser, providerAccount]);

  const _deleteDefaultRichMenu = useCallback(async () => {
    if (!firebaseUser || !providerAccount) {
      return;
    }

    setLoading(true);
    return deleteDefaultRichMenu(firebaseUser, providerAccount.id)
      .then(() => {
        setRichMenuId(undefined);
        setIsEnabledPochicoRichMenu(false);
      })
      .finally(() => setLoading(false));
  }, [firebaseUser, providerAccount]);

  return {
    isEnabledPochicoRichMenu,
    richMenuId,
    loading,
    setDefaultRichMenu: _setDefaultRichMenu,
    deleteDefaultRichMenu: _deleteDefaultRichMenu,
  };
};

const isRichMenuEnabled = (richMenuId: string | undefined) =>
  typeof richMenuId === 'string' && richMenuId !== '' ? true : false;
