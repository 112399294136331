import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { LineUserList } from '../../components/features/lineUsers/list';

export const LineUserListPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('LINEユーザーを管理');
  return <LineUserList providerAccount={providerAccount} />;
};
