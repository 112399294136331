import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingList } from '../../components/features/bookings/list';

export const BookingListPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約を管理');
  return <BookingList providerAccount={providerAccount} />;
};
