import { ProviderAccount } from '@pochico/shared';

import { FirebaseUser } from '../firebase/firebaseInit';
import { postBotApi } from './bot';

// botと共通で揃える型
type CreateCustomerPortalURLRequest = {
  providerAccountId: string;
};

export const fetchCustomerPortalURL = async (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
) => {
  const request: CreateCustomerPortalURLRequest = {
    providerAccountId: providerAccount.id,
  };
  return postBotApi<{ url: string }>(
    '/payment/customer-portal',
    firebaseUser,
    request
  ).then(async (response) => {
    if (response.ok) {
      return response.body.url;
    } else {
      return Promise.reject(response.error);
    }
  });
};

type CheckUnpaidInvoiceRequest = {
  providerAccountId: string;
};

type CheckUnpaidInvoiceResponse = {
  status: 'ok' | 'ng' | 'delayed';
  error: string;
};

export const checkUnpaid = async (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
) => {
  const request: CheckUnpaidInvoiceRequest = {
    providerAccountId: providerAccount.id,
  };
  return postBotApi<CheckUnpaidInvoiceResponse>(
    '/payment/check',
    firebaseUser,
    request
  ).then(async (response) => {
    if (response.ok) {
      return response.body.status;
    } else {
      console.log({ error: response.error });
      return { error: response.error };
    }
  });
};
