import { Box, BoxProps, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { inputLikeBaseStyle } from '../../theme/input';

export type StyledSelectOption = {
  id: string;
  element: React.ReactElement;
};
export type StyledSelectProps = Omit<BoxProps, 'onSelect'> & {
  type: 'select' | 'mustSelect';
  onSelect: (value: StyledSelectOption['id'] | null) => void;
  options: StyledSelectOption[];
  placeholder: string;
  defaultValue: StyledSelectOption['id'] | undefined;
  isDisabled?: boolean;
};

// Select時は入力で候補を絞り込み、選択するとInput欄に選択済みのものが表示される
export const StyledSelect: React.FC<StyledSelectProps> = ({
  type,
  onSelect,
  placeholder,
  defaultValue,
  options,
  isDisabled,
  ...rest
}) => {
  const stateAtom = React.useMemo(
    () =>
      atom<{
        selectedOption: StyledSelectOption | undefined;
        isFocus: boolean;
      }>({
        // ページをまたいだらおかしくなる
        selectedOption: defaultValue
          ? options.find((opt) => opt.id === defaultValue)
          : undefined,
        isFocus: false,
      }),
    [defaultValue, options]
  );
  const [state, setState] = useAtom(stateAtom);
  const onMouseDown = useMutation({
    mutationFn: async (choice: StyledSelectOption | null) => {
      if (isDisabled) {
        return;
      }
      if (choice?.id === state.selectedOption?.id) {
        setState((prev) => ({ ...prev, isFocus: false }));
        return;
      }
      setState((prev) => ({
        ...prev,
        isFocus: false,
        selectedOption: choice || undefined,
      }));
      onSelect(choice ? choice.id : null);
    },
  });
  const ref = React.useRef<HTMLInputElement>(null);

  const Choices = React.useMemo(() => {
    const props: BoxProps = {
      bgColor: 'white',
      _hover: { bgColor: 'gray.100' },
      borderRadius: '4px',
      textAlign: 'left',
      w: 'full',
      px: '8px',
      py: '4px',
      cursor: 'pointer',
    };
    const choiceElements = options
      .filter((opt) => opt.id !== state.selectedOption?.id)
      .map((option, i) => (
        <Box
          key={`choice-${i}`}
          {...props}
          onMouseDown={() => {
            onMouseDown.mutate(option);
          }}
        >
          {option.element}
        </Box>
      ));
    if (type === 'select') {
      return [
        <Box
          key={'select-placeholder'}
          {...props}
          onMouseDown={() => {
            onMouseDown.mutate(null);
          }}
        >
          {placeholder}
        </Box>,
      ].concat(choiceElements);
    } else {
      return choiceElements;
    }
  }, [onMouseDown, options, placeholder, state.selectedOption?.id, type]);
  const changeFocus = React.useCallback(
    (focus: boolean) => {
      setState((prev) => ({ ...prev, isFocus: focus }));
      if (focus) {
        ref.current?.focus();
      } else {
        ref.current?.blur();
      }
    },
    [setState]
  );

  return (
    <Box
      // w="full"
      // flexShrink={0}
      ref={ref}
      onBlur={() => changeFocus(false)}
      position={'relative'}
      px={'8px'}
      py={'4px'}
      borderRadius={'4px'}
      {...inputLikeBaseStyle}
      bgColor={isDisabled ? 'gray.200' : 'white'}
      {...rest}
    >
      {/* // Selectで何かしら選択済みの状態 */}
      <HStack
        justifyContent={'space-between'}
        // bgColor={'white'}
        cursor={'pointer'}
        minW={'fit-content'}
        w={'max-content'}
        alignItems={'center'}
        // w={'300px'}
        onClick={() => changeFocus(true)}
      >
        {state.selectedOption ? (
          <Box>{state.selectedOption.element}</Box>
        ) : (
          <Text
            w={'full'}
            borderRadius={'8px'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            px={'12px'}
            py={'8px'}
          >
            {placeholder}
          </Text>
        )}
        <Box
          color={'gray.500'}
          w={'16px'}
          h={'16px'}
          style={{ rotate: '90deg' }}
        >
          <MdArrowForwardIos size={'16px'} />
        </Box>
      </HStack>

      {!isDisabled && state.isFocus && (
        <>
          <Box
            onClick={() => changeFocus(false)}
            bgColor={'transparent'}
            position={'fixed'}
            top={0}
            left={0}
            w={'full'}
            h={'100vh'}
            zIndex={99}
          />
          <VStack
            zIndex={100}
            position={'absolute'}
            w="full"
            minW={'max-content'}
            whiteSpace={'nowrap'}
            left={0}
            right={0}
            boxShadow="base"
            // bg="white"
            mt="4px"
            py={'8px'}
            px={'8px'}
            gap={0}
            justifyContent={'flex-start'}
            borderBottomRadius={'md'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            bgColor={'white'}
            divider={<Divider margin={0} borderColor={'gray.120'} />}
            maxH={'400px'}
            overflowY={'auto'}
          >
            {Choices}
          </VStack>
        </>
      )}
    </Box>
  );
};
