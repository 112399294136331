import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { HintTooltip } from './HintTooltip';

export const HelpSpot = () => {
  return (
    <HintTooltip
      color={'gray.500'}
      text={
        <Box w={'fit-content'}>
          <Text>受付できる予約数の上限設定を表します。</Text>
          <Text>予約枠は、予約メニューごとに設定することができます。</Text>
          <Text>予約枠を設定しないと、予約の受付ができません。</Text>
        </Box>
      }
    >
      <Text>予約枠とは？</Text>
    </HintTooltip>
  );
};
