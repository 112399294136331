import { Box, Button, HStack, Link, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const PricingWarningMessage: React.FC = () => {
  return (
    <Box w={'full'} borderWidth={'1px'} borderColor={'gray.200'} p={'16px'}>
      <VStack w={'full'} spacing={'8px'} alignItems={'flex-start'}>
        <Box color={'red.500'}>
          ※
          リマインダーを登録すると、メッセージの通数によってはLINEの無料メッセージ枠を超えて有料となる場合があります。
          <br />
          詳しくは以下をご確認ください。
          をご確認ください。（ポチコではなく、LINE社への課金となります。）
        </Box>
        <Button
          as={Link}
          target={'_blank'}
          // textDecoration={'underline'}
          href="https://www.linebiz.com/jp/service/line-official-account/plan/"
          colorScheme="gray"
        >
          <HStack>
            <Box>LINE公式アカウント料金プラン</Box>
            <FaExternalLinkAlt />
          </HStack>
        </Button>
      </VStack>
    </Box>
  );
};
