import {
  chunk,
  isNullOrUndefinedArgs,
  LineUser,
  undefinedToNull,
  waitUntil,
} from '@pochico/shared';
import {
  collection,
  doc,
  endAt,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  runTransaction,
  startAt,
  Timestamp,
  UpdateData,
  where,
} from 'firebase/firestore';

import { QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import CONSTANTS from '../../commons/constants';
import { LineUserFilter } from '../../components/features/LineUsers';
import { db } from '../../firebase/firebaseInit';
import {
  DisplayLineUser,
  FromFSLineUser,
  LineUserUpdateParams,
  ToFSLineUser,
} from '../../firebase/types';
import { lineUserQueryKey } from '../../hooks/lineUser';
import { getCount, getList, getOne } from './helper';
import { ListResult, Pagination, Sort } from './type';

export const getLineUsersRef = (providerId: string) => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    CONSTANTS.COLLECTION.SUB_LINE_USERS
  ).withConverter({
    toFirestore(doc: DisplayLineUser): ToFSLineUser {
      const { pictureUrlLarge, pictureUrlSmall, ...rest } = doc;
      return {
        ...rest,
        status: doc.status || 'active',
        pictureUrl: doc.pictureUrl ?? null,
      };
    },
    fromFirestore(
      snap: QueryDocumentSnapshot<FromFSLineUser>
    ): DisplayLineUser {
      const nullableDoc = snap.exists() ? snap.data() : undefined;
      if (
        !nullableDoc ||
        isNullOrUndefinedArgs(
          nullableDoc.botId,
          nullableDoc.id,
          nullableDoc.displayName,
          nullableDoc.displayNameByProvider,
          nullableDoc.pictureUrl,
          nullableDoc.updateTime
        )
      ) {
        throw new Error(
          'invalid args from firestore spot: ' + JSON.stringify(nullableDoc)
        );
      }
      const lastBookedAt = nullableDoc.lastBookedAt?.toDate();
      const updateTime: Timestamp = nullableDoc.updateTime as Timestamp;
      return {
        ...nullableDoc,
        pictureUrl: nullableDoc.pictureUrl || null,
        pictureUrlSmall: nullableDoc.pictureUrl
          ? `${nullableDoc.pictureUrl}/small`
          : undefined,
        pictureUrlLarge: nullableDoc.pictureUrl
          ? `${nullableDoc.pictureUrl}/large`
          : undefined, // /largeをつけるとサイズが200x200になる https://developers.line.biz/ja/reference/line-login/#get-user-profile
        status: nullableDoc.status ?? 'active',
        lastBookedAt,
        updateTime: updateTime.toDate(),
      } as DisplayLineUser;
    },
  });
};

const filteredLineUserRef = (
  providerAccountId: string,
  filter?: LineUserFilter
): Query<DisplayLineUser>[] => {
  const whereBase = [
    filter?.archived !== undefined
      ? [where('archived', '==', filter.archived)]
      : [],
    filter?.status ? [where('status', '==', filter.status)] : [],
    filter?.lastBookedAt?.start
      ? where(
          'lastBookedAt',
          '>=',
          dayjs(filter.lastBookedAt.start).startOf('day').toDate()
        )
      : [],
    // filter?.sharedFormInputs
    //   ? Object.entries(filter.sharedFormInputs)
    //       .filter(([, value]) => Boolean(value))
    //       .map(([type, value]) => {
    //         return where(`sharedFormInputs.${type}.input`, '==', value); // ネストしたobjectのinputに==でクエリかける
    //       })
    //   : [],

    filter?.lastBookedAt?.end
      ? where(
          'lastBookedAt',
          '<=',
          dayjs(filter.lastBookedAt.end).endOf('day').toDate()
        )
      : [],
  ].flat();

  const wheres = [
    filter?.ids && filter.ids.length > 0
      ? chunk(filter.ids, 25).map((ids) => {
          return where('id', 'in', ids);
        })
      : [],
    // firestoreの制限 https://firebase.google.com/docs/firestore/query-data/queries?hl=ja#query_limitations
    // ↓のフィルタをfirestore側でかけるのは難しい
    // filter?.displayName
    //   ? [
    //       where('displayName', '>=', filter.displayName),
    //       where('displayName', '<', `${filter.displayName}\uf8ff`),
    //     ]
    //   : [],
    // filter?.displayNameByProvider
    //   ? [
    //       where('displayNameByProvider', '>=', filter.displayNameByProvider),
    //       where(
    //         'displayNameByProvider',
    //         '<',
    //         `${filter.displayNameByProvider}\uf8ff`
    //       ),
    //     ]
    //   : [],
  ].flat();

  return wheres.length > 0
    ? wheres.map((_where) => {
        return query(
          getLineUsersRef(providerAccountId),
          ...whereBase.concat(_where)
        );
      })
    : [query(getLineUsersRef(providerAccountId), ...whereBase)];
};

export const updateLineUser = async (
  providerAccountId: string,
  params: LineUserUpdateParams
) => {
  // status, providerMemoとdisplayNameByProviderだけ変更可能にする
  const documentId = params.id.toString();
  const userRef = getLineUsersRef(providerAccountId);
  const afterDisplayNameByProvider = params.displayNameByProvider;
  const afterMemo = params.providerMemo;

  return runTransaction(db, async (t) => {
    return t.get(doc(userRef, documentId)).then((fetchedUserDoc) => {
      const fetchedUser = fetchedUserDoc?.exists()
        ? fetchedUserDoc.data()
        : undefined;
      if (typeof fetchedUser === 'undefined') {
        return Promise.reject(new Error('cannot get lineUser.'));
      }
      const updateData: Partial<LineUser> = {
        status: params.status || 'active',
        // sharedFormResponse:
        //   params.sharedFormResponse || fetchedUser.sharedFormResponse, // 管理画面からLINEユーザーの入力内容を編集することはできない
        archived: params.archived,
        updateTime: new Date(),
      };
      if (afterMemo || fetchedUser.providerMemo) {
        updateData.providerMemo = afterMemo;
      }
      if (afterDisplayNameByProvider || fetchedUser.displayNameByProvider) {
        updateData.displayNameByProvider = afterDisplayNameByProvider;
      }
      t.update(fetchedUserDoc.ref, undefinedToNull(updateData));
      return updateData;
    });
  }).then((updateData) => {
    return { data: { id: documentId, ...updateData } } as any; // `update`のtype checkを通すため
  });
};

export const getLineUser = async (
  providerAccountId: string,
  id: string
): Promise<DisplayLineUser | undefined> => {
  return getOne(getLineUsersRef(providerAccountId), id);
};

const defaultSort: Sort<LineUser> = {
  field: 'lastBookedAt',
  direction: 'desc',
};
export const getLineUserList = async (
  queryClient: QueryClient, // キャッシュが必要なためqueryClientを受け取るようにする
  providerAccountId: string,
  options: {
    filter: LineUserFilter;
    pagination: Pagination<DisplayLineUser>;
  }
): Promise<ListResult<DisplayLineUser>> => {
  const { filter, pagination } = options;
  const sort = pagination?.sort || defaultSort;
  if (options.filter.ids?.length === 0) {
    return { data: [] };
  }
  if (filter.ids && filter.ids.length > 0 && pagination.lastCursor) {
    return Promise.reject(
      'in句とページネーションを同時に指定することはできません'
    );
  }

  // formの回答で検索するときは直接フィールドの==で検索して結果を返してしまう
  if (filter.sharedFormInputs) {
    const data = await fetchFilteredLineUserByFormInput(
      providerAccountId,
      filter.sharedFormInputs,
      pagination
    );
    if (data !== undefined) {
      return { data };
    }
  }

  // 名前のincludeでのフィルタがfirestoreではできないので、全件取得してクライアントでfilterする
  if (filter.displayName || filter.displayNameByProvider) {
    const allLineUserCache = await fetchAllLineUserUsingCache(
      queryClient,
      providerAccountId
    );
    const filteredLineUsers = allLineUserCache
      .filter(lineUserFilterOutsideFirestore(filter))
      .sort(lineUserSorter(sort));
    if (pagination.lastCursor) {
      const lastCursorIndex = filteredLineUsers.findIndex((user) => {
        if (
          pagination.sort.field === 'lastBookedAt' ||
          pagination.sort.field === 'updateTime'
        ) {
          return (
            user.id === pagination.lastCursor?.id &&
            user[pagination.sort.field]?.getTime() ===
              pagination.lastCursor?.cursor.getTime()
          );
        } else {
          return (
            user.id === pagination.lastCursor?.id &&
            user[pagination.sort.field] === pagination.lastCursor?.cursor
          );
        }
      });
      if (lastCursorIndex >= 0) {
        return {
          data: filteredLineUsers.slice(
            lastCursorIndex + 1,
            lastCursorIndex + 1 + pagination.perPage
          ),
        };
      }
    }
    return {
      data: filteredLineUsers.slice(
        ((pagination.page || 1) - 1) * pagination.perPage,
        (pagination.page || 1) * pagination.perPage
      ),
    };
  } else {
    const { ids, archived, lastBookedAt, status, sharedFormInputs } = filter;
    const refs = filteredLineUserRef(providerAccountId, {
      ids,
      archived,
      lastBookedAt,
      status,
      sharedFormInputs,
    });

    const procs = refs.map(async (ref, i) => {
      return getList(ref, {
        perPage: pagination.perPage,
        sort,
        ...(pagination.page
          ? { page: pagination.page, lastCursor: undefined }
          : {
              page: undefined,
              lastCursor: pagination.lastCursor,
            }),
      }).then(({ data: lineUsers }) => {
        const data = lineUsers
          // .filter(lineUserFilterOutsideFirestore(filter))
          .sort(lineUserSorter(sort));
        return data;
      });
    });
    const results = await Promise.all(procs);
    return {
      data: results.flat(),
    };
  }
};

// formの回答での検索は単一フィールドでのみ行う(他の条件を加味しない)
const fetchFilteredLineUserByFormInput = async (
  providerAccountId: string,
  filter: NonNullable<LineUserFilter['sharedFormInputs']>,
  pagination: Pagination<DisplayLineUser>
): Promise<DisplayLineUser[] | undefined> => {
  if (filter) {
    const _filter = Object.entries(filter).find(([, value]) => Boolean(value));
    if (_filter) {
      const fieldName = `sharedFormInputs.${_filter[0]}.input`;
      const userRef = getLineUsersRef(providerAccountId);
      return getDocs(
        query(
          userRef,
          // where(fieldName, '>=', _filter[1]), // ネストしたobjectのinputに==でクエリかける // startAt + endAtの方が正しく前方一致検索できる
          orderBy(fieldName, 'asc'),
          orderBy(pagination.sort.field, pagination.sort.direction),
          startAt(_filter[1]),
          endAt(`${_filter[1]}\uf8ff`),
          // startAfter(pagination.lastCursor?.cursor),
          limit(pagination.perPage)
        )
      ).then((snap) => snap.docs.map((doc) => doc.data()));
      // return getList(query(userRef, wheres), pagination).then(({ data }) => {
      //   return data
      // })
    }
  }
  return undefined;
};

export const lineUserSorter =
  <T extends LineUser>(sort: Sort<T>) =>
  (a: T, b: T) => {
    const { field, direction } = sort;
    const aField = a[field];
    const bField = b[field];
    if (typeof aField === 'number' && typeof bField === 'number') {
      return direction === 'asc' ? aField - bField : bField - aField;
    }
    if (aField instanceof Date && bField instanceof Date) {
      return direction === 'asc'
        ? aField.getTime() - bField.getTime()
        : bField.getTime() - aField.getTime();
    }

    return direction === 'asc'
      ? String(aField).localeCompare(String(bField))
      : String(bField).localeCompare(String(aField));
    // if (aField > bField) {
    //   return direction === 'asc' ? 1 : -1;
    // } else if (aField < bField) {
    //   return direction === 'asc' ? -1 : 1;
    // } else {
    //   return 0;
    // }
  };

export const lineUserFilterOutsideFirestore =
  (filter: LineUserFilter) => (lineUser: DisplayLineUser) => {
    const {
      displayName,
      displayNameByProvider,
      status,
      lastBookedAt,
      archived,
    } = filter;
    const result =
      (displayName ? lineUser.displayName.includes(displayName) : true) &&
      (displayNameByProvider
        ? Boolean(
            lineUser.displayNameByProvider &&
              lineUser.displayNameByProvider.includes(displayNameByProvider)
          )
        : true) &&
      (status
        ? (lineUser.status || 'active') === status // 互換性のためactiveをデフォルトにしておく
        : true) &&
      (lastBookedAt?.start && lineUser.lastBookedAt
        ? lineUser.lastBookedAt >=
          dayjs(lastBookedAt.start).startOf('day').toDate()
        : true) &&
      (lastBookedAt?.end && lineUser.lastBookedAt
        ? lineUser.lastBookedAt <= dayjs(lastBookedAt.end).endOf('day').toDate()
        : true) &&
      (archived === undefined ? true : lineUser.archived === archived);
    return result;
  };

// paginationのために件数取得に使う
export const getLineUserCount = async (
  queryClient: QueryClient,
  providerAccountId: string,
  filter?: LineUserFilter
): Promise<number> => {
  if (filter?.displayName || filter?.displayNameByProvider) {
    return getLineUserList(queryClient, providerAccountId, {
      filter: filter || {},
      pagination: {
        perPage: CONSTANTS.PAGINATION_MAX_LIMIT,
        page: 1,
        sort: {
          field: 'lastBookedAt',
          direction: 'desc',
        },
      },
    }).then(({ data }) => data.length);
  } else {
    const refs = filteredLineUserRef(providerAccountId, filter);
    return Promise.all(
      refs
        .map((ref) =>
          query(ref, orderBy('lastBookedAt', 'desc'), orderBy('id', 'asc'))
        )
        .map(getCount)
    ).then((p) => p.reduce((a, b) => a + b, 0));
  }
};

let isFetchingAllUser = false;
export const fetchAllLineUserUsingCache = async (
  queryClient: QueryClient,
  providerAccountId: string
) => {
  // 雑な排他制御
  if (isFetchingAllUser) {
    await waitUntil(() => isFetchingAllUser === false, 10000).catch((e) => {
      console.log(`timeout happened`);
    });
  }
  const key = lineUserQueryKey.all(providerAccountId).queryKey;
  const state = queryClient.getQueryState(key);
  if (state && !state.isInvalidated) {
    const allLineUserCache =
      queryClient.getQueryData<DisplayLineUser[]>(key) || [];
    if (allLineUserCache.length > 0) {
      return allLineUserCache;
    }
  }
  isFetchingAllUser = true;

  try {
    const refs = filteredLineUserRef(providerAccountId, {
      archived: false,
    });
    if (refs.length > 1) {
      return Promise.reject(
        'idsとdisplayName, displayNameByProviderのフィルタは同時に指定することはできません'
      );
    }
    const ref = refs[0];
    const { data: lineUsers } = await getList(ref, {
      perPage: CONSTANTS.PAGINATION_MAX_LIMIT,
      sort: defaultSort,
      page: 1,
    });
    // const allCount = await getCount(ref);
    // for (
    //   let i = 1;
    //   i <= Math.ceil(allCount / CONSTANTS.PAGINATION_MAX_LIMIT);
    //   i++
    // ) {
    //   const paging =
    //     lineUsers.length > 0
    //       ? {
    //           page: undefined,
    //           lastCursor: {
    //             cursor: lineUsers[lineUsers.length - 1]?.updateTime,
    //             id: lineUsers[lineUsers.length - 1]?.id,
    //           },
    //         }
    //       : {
    //           page: 1,
    //           lastCursor: undefined,
    //         };
    //   await getList(ref, {
    //     perPage: CONSTANTS.PAGINATION_MAX_LIMIT,
    //     sort: defaultSort,
    //     ...paging,
    //   }).then((result) => {
    //     lineUsers.push(...result.data);
    //   });
    // }
    queryClient.setQueryData(key, lineUsers);
    return lineUsers;
  } finally {
    isFetchingAllUser = false;
  }
};

export const bulkUpdateLineUsers = async (
  providerAccountId: string,
  params: (UpdateData<LineUser> & { id: string })[]
) => {
  const userRef = getLineUsersRef(providerAccountId);

  const chunked = params.chunk(500);
  for (const params of chunked) {
    await runTransaction(db, async (t) => {
      return params.map((updateData) => {
        return t.update(
          doc(userRef, updateData.id),
          undefinedToNull(updateData)
        );
      });
    });
  }
};
