import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { MACRO } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { LineBackground } from '../../ui/LineBackground';
import { TextWithMacro } from '../../ui/TextWithMacro';
import { ReminderConfiguration } from './type';

const MESSAGE_SUFFIX = `【予約日時】\n・${MACRO.fullDate}`;

export const ReminderPreview: React.FC<{
  configuration: ReminderConfiguration;
}> = ({ configuration }) => {
  const locationDefined =
    configuration.defaultLocation &&
    // configuration.defaultLocation.title &&
    // configuration.defaultLocation.address &&
    configuration.defaultLocation.latitude &&
    configuration.defaultLocation.longitude;

  const latLng = `${configuration.defaultLocation?.latitude},${configuration.defaultLocation?.longitude}`;

  return (
    <LineBackground>
      <VStack
        padding={'6'}
        backgroundColor={'line.talk'}
        spacing={'2'}
        borderRadius={'base'}
        width={'400px'}
      >
        <Box
          padding={'4'}
          borderWidth="1px"
          borderRadius={'3xl'}
          backgroundColor={'white'}
          boxShadow={'lg'}
          width={'full'}
        >
          <TextWithMacro
            text={configuration.remindText}
            suffix={MESSAGE_SUFFIX}
            date={dayjs().hour(9).minute(30)} // 09:30をデフォルトにしておく
          />
        </Box>
        {locationDefined && (
          <VStack
            borderWidth="1px"
            borderRadius={'3xl'}
            backgroundColor={'white'}
            // padding={'2'}
            width={'full'}
            spacing={'3'}
            boxShadow={'lg'}
          >
            <Image
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${latLng}&markers=color:red|${latLng}&zoom=15&size=288x200&scale=2&key=${GLOBAL_CONFIG.GOOGLE?.MAP.API_KEY}`}
              alt="周辺の地図画像"
              borderWidth="1px"
              borderTopLeftRadius={'3xl'}
              borderTopRightRadius={'3xl'}
              width={'full'}
              // padding={'2'}
              // height={280}
              cursor="pointer"
            />
            <VStack
              alignItems={'flex-start'}
              width={'full'}
              paddingLeft={3}
              paddingRight={3}
              paddingBottom={3}
              spacing={1}
            >
              <Text fontWeight={'bold'} align={'left'} wordBreak={'break-word'}>
                {configuration.defaultLocation?.title || '施設名'}
              </Text>
              <Text wordBreak={'break-word'}>
                {configuration.defaultLocation?.address || '住所'}
              </Text>
            </VStack>
          </VStack>
        )}
      </VStack>
    </LineBackground>
  );
};
