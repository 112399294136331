import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { inputLikeBaseStyle } from './input';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const outline = definePartsStyle({
  // define the part you're going to style
  field: inputLikeBaseStyle,
});

export const Select = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    variant: 'outline',
  },
});
