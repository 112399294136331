import React from 'react';

export const useTimer = ({ durationSec }: { durationSec: number }) => {
  const [done, setDone] = React.useState(false);
  const clear = React.useCallback(() => setDone(false), []);

  React.useEffect(() => {
    if (done) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setDone(true);
    }, durationSec * 1000);
    // こういう処理を書く
    return () => {
      clearTimeout(timeoutId);
    };
  }, [done, durationSec]);

  return {
    done,
    clear,
  };
};
