import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import { useSetDocumentTitle } from '../../../commons/components/setDocumentTitle';
import { CheckoutForm } from '../../../components/features/payment/CheckoutForm';

const STRIPE_API_KEY = GLOBAL_CONFIG.STRIPE.STRIPE_API_KEY;

const stripePromise = loadStripe(STRIPE_API_KEY);

const PaymentRegistrationPage = () => {
  useSetDocumentTitle('支払い登録');
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentRegistrationPage;
