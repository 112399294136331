import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useAuthState } from '../../../context/providerAccount';
import { useAddSavedFilterDialog } from './useAddSavedFilterDialog';

export type SavedFilter<T> = {
  // id: string;
  name: string;
  filter: T;
};
export type SavedFilterKey = 'spot' | 'booking' | 'lineUser';
export type SavedFilterContext<T> = ReturnType<typeof useSavedFilterContext<T>>;

// 保存した検索条件についての操作および状態をとりまとめる
export const useSavedFilterContext = <T,>(
  filterKey: SavedFilterKey | undefined, // undefinedの場合は保存機能を無効化
  toComponent: ((filter: T) => React.ReactElement) | undefined, // PC向け表示で保存した検索条件を一覧表示するための関数
  onSavedFilterSelected: (newFilter: T) => void
) => {
  const toast = useToast();
  const { providerAccount } = useAuthState();
  const buildKey = React.useCallback(() => {
    if (!providerAccount) {
      return `saved-filters.${filterKey}`;
    }
    return `saved-filters.${filterKey}.${providerAccount.id}`;
  }, [filterKey, providerAccount]);

  const readAll = React.useCallback(async () => {
    if (!providerAccount) {
      return [];
    }
    const key = buildKey();
    const filters = localStorage.getItem(key);
    if (filters === null) {
      // ReactAdminで保存した検索条件を移行する
      const keyForBackwardCompatibility = `RaStore.${providerAccount.id}/${providerAccount.botId}/${filterKey}.savedQueries`;
      console.log({ keyForBackwardCompatibility });
      const storedFilterStr = localStorage.getItem(keyForBackwardCompatibility);
      if (storedFilterStr) {
        const storedFilter = JSON.parse(storedFilterStr) as {
          label: string;
          value: {
            filter: T;
          };
        }[];
        const migrated = storedFilter.map(({ label, value: { filter } }) => ({
          id: label,
          name: label,
          filter,
        }));
        localStorage.setItem(key, JSON.stringify(migrated));
        console.log({
          message: `Migrated saved filters from ${keyForBackwardCompatibility}`,
          migrated,
        });
        return migrated;
      }
    }
    const result = filters ? (JSON.parse(filters) as SavedFilter<T>[]) : [];

    return result;
  }, [buildKey, filterKey, providerAccount]);

  const queryClient = useQueryClient();
  const queryClientKey = React.useMemo(() => [buildKey()], [buildKey]);
  const readAllQuery = useQuery({
    queryKey: queryClientKey,
    queryFn: readAll,
    enabled: Boolean(filterKey && providerAccount),
  });

  const upsert = React.useCallback(
    async (newFilter: Omit<SavedFilter<T>, 'id'>) => {
      const key = buildKey();
      const items = await readAll();
      localStorage.setItem(
        key,
        JSON.stringify([
          ...items.filter((item) => item.name != newFilter.name),
          { ...newFilter } as SavedFilter<T>,
        ])
      );
      await queryClient.refetchQueries({ queryKey: queryClientKey });
      toast({
        title: '保存しました',
        status: 'success',
      });
    },
    [buildKey, queryClient, queryClientKey, readAll, toast]
  );
  const remove = React.useCallback(
    async (filterName: string) => {
      const key = buildKey();
      const items = await readAll();
      const newItems = items.filter((item) => item.name !== filterName);
      localStorage.setItem(key, JSON.stringify(newItems));
      await queryClient.invalidateQueries({ queryKey: queryClientKey });
      toast({
        title: '削除しました',
        status: 'success',
      });
    },
    [buildKey, queryClient, queryClientKey, readAll, toast]
  );

  const [selectedFilter, _setSelectedFilter] =
    React.useState<SavedFilter<T> | null>(null);
  const setSelectedFilter = React.useCallback(
    (filter: SavedFilter<T> | null) => {
      _setSelectedFilter(filter);
      if (filter) {
        onSavedFilterSelected(filter.filter);
      }
    },
    [onSavedFilterSelected]
  );
  const clearSelection = React.useCallback(() => {
    _setSelectedFilter(null);
  }, []);
  const { open: openAddDialog } = useAddSavedFilterDialog({
    upsert,
    toComponent: toComponent,
  });
  // const component = React.useMemo(() => {
  //   return ({ onSelect }: { onSelect: (filter: SavedFilter<T>) => void }) => (
  //     <SavedFilters<T>
  //       filterKey={filterKey}
  //       onSelect={(filter) => {
  //         // 選択中フィルタのstate更新
  //         setSelectedFilterName(filter.name);
  //         // コールバックの実行
  //         onSelect(filter);
  //       }}
  //       data={readAllQuery.data || []}
  //       selectedFilterName={selectedFilterName}
  //       toComponent={toComponent}
  //       remove={remove}
  //     />
  //   );
  // }, [filterKey, readAllQuery.data, remove, selectedFilterName, toComponent]);
  if (!filterKey || !toComponent) {
    return undefined;
  }
  return {
    dispatcher: {
      upsert,
      remove,
      openAddDialog,
      clearSelection,
      toComponent,
      setSelectedFilter,
    },
    state: {
      data: readAllQuery.data || [],
      selectedFilter,

      // SavedFiltersComponent: component,
    },
  };
};
