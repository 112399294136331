import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React from 'react';

import { waitAtLeast } from '@pochico/shared';
import { Pagination } from '../providers/dataProvider/type';

export const usePaginationQuery = <T extends { id: string }>({
  queryKey,
  queryFn,
  pagination,
  page,
  enabled,
}: {
  queryKey: readonly any[];
  queryFn: (pagination: Pagination<T>) => Promise<T[]>;
  pagination: Omit<Pagination<T>, 'lastCursor'>;
  page: number;
  enabled?: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();
  const queryKeyPerPage = React.useCallback(
    (page: number) => {
      return [...queryKey, page];
    },
    [queryKey]
  );

  // 求めるページの前のページの最後の要素
  const getLastCursor = React.useCallback(
    (page: number) => {
      if (page === 1) {
        return undefined;
      }
      const lastCache = queryClient.getQueryData<T[]>(
        queryKeyPerPage(page - 1)
      );
      if (lastCache) {
        const obj = lastCache[lastCache.length - 1];
        if (obj) {
          return {
            cursor: obj[pagination.sort.field],
            id: obj.id,
          };
        }
      }
      return undefined;
    },
    [pagination.sort.field, queryClient, queryKeyPerPage]
  );

  // 指定されたページのデータを取得する
  const doFetch = React.useCallback(
    async (page: number) => {
      // 求めるページの前のページの最後の要素
      const lastCursor = getLastCursor(page);
      // console.log(`[usePagination] doFetch page: ${page}`, { lastCursor });

      if (page > 1 && !lastCursor) {
        return Promise.reject(
          `invalid request. page=${page} but lastCursor not found`
        );
      }
      const _pagination: Pagination<T> = {
        ...pagination,
        lastCursor: lastCursor,
        page: undefined,
      };
      // console.log(`[usePagination] cache miss: ${page}`, {
      //   _pagination,
      //   // data: cache,
      // });

      return queryFn(_pagination).catch((e) => {
        const error = `[usePagination] failed due to ${e}`;
        console.error({ message: error, error: e, queryKey });
        return Promise.reject(error);
      });
    },
    [getLastCursor, pagination, queryFn, queryKey]
  );

  // 1ページ目から順番にfetchしていく
  const doFetchRecursively = React.useCallback(async () => {
    setLoading(true);
    const p = async () => {
      // QueryClientのキャッシュをpagination用のストレージとして使う
      for (let i = 1; i <= page; i++) {
        const key = queryKeyPerPage(i);
        const cache = (() => {
          const state = queryClient.getQueryState(key);
          if (!state || state.isInvalidated) {
            return undefined;
          } else {
            return queryClient.getQueryData<T[]>(key);
          }
        })();
        // console.log(
        //   `[usePagination] fetch page: ${i}, cache found?: ${!!cache}`,
        //   { key, cache }
        // );
        if (!cache) {
          const result = await doFetch(i).catch((e) => {
            console.error(`error: ${e}`, { e });
            throw e;
          });

          queryClient.setQueryData(key, result);
        }
      }
      return queryClient.getQueryData<T[]>(queryKeyPerPage(page));
    };
    return waitAtLeast(p(), 300).finally(() => {
      setLoading(false);
    });
  }, [doFetch, page, queryClient, queryKeyPerPage]);

  const query = useQuery({
    queryKey: queryKeyPerPage(page),
    queryFn: doFetchRecursively,
    placeholderData: keepPreviousData,
    enabled: enabled ?? true,
  });

  return { ...query, isFetchingPage: loading };
};
