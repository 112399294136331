import * as React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useAuthState } from '../context/providerAccount';
import {
  checkUnpaid,
  fetchCustomerPortalURL,
} from '../dataStore/paymentRepository';

export const useCheckUnpaid = () => {
  const { firebaseUser, providerAccount, initialized } = useAuthState();
  const query = useQuery({
    queryKey: ['checkUnpaid', providerAccount?.id],
    queryFn: React.useCallback(async (): Promise<
      | undefined
      | {
          status: 'ok';
          customerPortalUrl?: never;
        }
      | {
          status: 'delayed' | 'ng';
          customerPortalUrl?: string;
        }
      | {
          status: 'error';
          customerPortalUrl?: never;
        }
    > => {
      if (!firebaseUser || !providerAccount) {
        return;
      }
      return checkUnpaid(firebaseUser, providerAccount).then(async (result) => {
        if (typeof result === 'object' && 'error' in result) {
          // 何かしらでエラーなのでここではスルー
          // navigate(Path.logout);
          return { status: 'error' };
        }

        if (result !== 'ok') {
          return fetchCustomerPortalURL(firebaseUser, providerAccount).then(
            (customerPortalUrl) => {
              return {
                status: result,
                customerPortalUrl,
              };
            }
          );
        }
        return {
          status: result,
          customerPortalUrl: undefined,
        };
      });
    }, [firebaseUser, providerAccount]),
    enabled: Boolean(initialized && firebaseUser && providerAccount),
  });

  return {
    loading: query.isLoading,
    status: query.data?.status,
    customerPortalUrl: query.data?.customerPortalUrl,
  };
};
