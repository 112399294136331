import { Heading, HeadingProps } from '@chakra-ui/react';
import React from 'react';

export const PageTitle = (
  props: HeadingProps & { children: React.ReactNode }
) => {
  return (
    <Heading
      as="h1"
      fontSize={{ base: 'xl', md: '2xl' }}
      fontWeight={'bold'}
      my={3}
      {...props}
    >
      {props.children}
    </Heading>
  );
};
