import { Box } from '@chakra-ui/react';
import React from 'react';

export const RadioButtonIcon: React.FC = () => {
  return (
    <Box
      w={'16px'}
      h={'16px'}
      bgColor={'gray.500'}
      borderWidth={'5px'}
      borderColor={'white'}
      borderRadius={'full'}
    />
  );
};
