import {
  Button,
  Divider,
  HStack,
  Icon,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  PredefinedSharedBookingFormElement,
  ProviderAccount,
  SharedBookingFormElement,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { MdCheckCircle, MdDoNotDisturb, MdEdit } from 'react-icons/md';
import {
  useFetchSharedBookingFormElements,
  useUpsertSharedBookingFormElement,
} from '../../../../hooks/sharedBookingForms';
import { useIsPC } from '../../../../hooks/useIsPC';
import { sharedBookingFormElementsWithPredefined } from '../../../../models/predefinedSharedBookingFormElements';
import { SharedBookingFormElementUpsertParams } from '../../../../providers/dataProvider/sharedBookingForm';
import { HintTooltip } from '../../../ui/HintTooltip';
import { Layout, LayoutBodyWithQuery } from '../../../ui/Layout';
import { StyledSelect } from '../../../ui/StyledSelect';
import { WebLink } from '../../../ui/WebLink';
import { SharedBookingFormElementView } from '../../bookingForms/BookingFormElementView';
import { useSharedBookingFormElementEditor } from './SharedBookingFormElementEditorDialog';

type FormElement =
  | SharedBookingFormElement
  | PredefinedSharedBookingFormElement;
export const SharedBookingFormElementList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const query = useFetchSharedBookingFormElements(providerAccount);
  const isPC = useIsPC();

  return (
    <Layout
      pageTitle={'個人情報質問のカスタマイズ'}
      hasBackButton={{
        providerAccount,
        resourceName: 'bookingForm',
        action: 'list',
      }}
    >
      <VStack
        w={{ base: 'full', md: '600px' }}
        alignItems={'flex-start'}
        gap={'16px'}
      >
        <VStack w={'full'} alignItems={'flex-start'} gap={'0'}>
          <Text fontSize={'lg'}>
            質問フォームで使いたい項目を有効にしましょう。
          </Text>
          <HintTooltip
            text={
              <>
                ・利用者の個人情報について、予約時に回答してもらいたい内容を設定することができます。
                <br />
                ・回答内容はLINEユーザーに紐づく情報として登録され、LINEユーザー詳細画面等で確認できます。
                <br />
                ・なお、入力された内容を後から管理者が変更することはできません。
              </>
            }
            color={'gray.500'}
          >
            <Text fontSize={'xs'}>個人情報質問とは？</Text>
          </HintTooltip>

          <VStack
            w={'full'}
            alignItems={'flex-start'}
            px={'16px'}
            py={'12px'}
            gap={'12px'}
            bgColor={'blue.50'}
            color={'gray.700'}
          >
            <Text fontSize={'sm'} fontWeight={'bold'}>
              使い方のヒント
            </Text>
            <UnorderedList fontSize={'sm'} listStyleType={'none'}>
              <ListItem>
                ・質問フォームごとに表示/非表示を選ぶことができます。（設定方法は
                <WebLink
                  target="_blank"
                  href={
                    'https://help.pochico.app/11aeddbd00d780808d28c648e356f646'
                  }
                >
                  こちら
                </WebLink>
                ）
              </ListItem>
              <ListItem>
                ・個人情報以外に聞きたい項目は、質問フォーム編集画面の「その他の質問」から追加ができます。
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>

        <LayoutBodyWithQuery<SharedBookingFormElement[]> query={query}>
          {(elements) => (
            <SharedBookingFormElementListView
              providerAccount={providerAccount}
              elements={elements}
            />
          )}
        </LayoutBodyWithQuery>
      </VStack>
    </Layout>
  );
};

const SharedBookingFormElementListView: React.FC<{
  providerAccount: ProviderAccount;
  elements: SharedBookingFormElement[];
}> = ({ providerAccount, elements }) => {
  const elementsWithPredefined = React.useMemo(
    () => sharedBookingFormElementsWithPredefined(elements),
    [elements]
  );
  const upsertMutation = useUpsertSharedBookingFormElement(providerAccount);
  const toast = useToast();
  const callback = React.useMemo(
    () => ({
      onSuccess: () => {
        toast.closeAll();
        toast({
          title: '保存しました',
          status: 'success',
        });
      },
      onError: (e: Error) => {
        toast.closeAll();
        toast({
          title: `保存に失敗しました: ${e}`,
          status: 'error',
        });
      },
    }),
    [toast]
  );
  const onChange = React.useCallback(
    async (element: SharedBookingFormElementUpsertParams) => {
      await upsertMutation.mutateAsync(element, callback);
    },
    [callback, upsertMutation]
  );
  const onChangeEnabled = React.useCallback(
    async (
      element: SharedBookingFormElement | PredefinedSharedBookingFormElement,
      newValue: boolean
    ) => {
      if ('enabled' in element && element.enabled === newValue) {
        return;
      }
      await upsertMutation.mutateAsync(
        {
          ...element,
          enabled: newValue,
          createTime: new Date(),
          updateTime: new Date(),
        },
        callback
      );
    },
    [callback, upsertMutation]
  );

  return (
    <VStack
      w={{ base: 'full', md: '600px' }}
      alignItems={'flex-start'}
      gap={'16px'}
    >
      {elementsWithPredefined.map((element) => (
        <SharedBookingFormElementListItem
          key={`shared-${element.id}`}
          element={element}
          onChangeEnabled={onChangeEnabled}
          onChange={onChange}
        />
      ))}
    </VStack>
  );
};

const SharedBookingFormElementListItem: React.FC<{
  element: FormElement;
  onChangeEnabled: (
    formElement: FormElement,
    newValue: boolean
  ) => Promise<void>;
  onChange: (element: SharedBookingFormElementUpsertParams) => Promise<void>;
}> = ({ element, onChangeEnabled, onChange }) => {
  const enabled = 'enabled' in element ? element.enabled : false;
  const { renderEditor, onClickToEdit } = useSharedBookingFormElementEditor(
    element,
    onChange
  );
  return (
    <VStack
      key={`shared-${element.id}-${element.title}`}
      bgColor={enabled ? 'white' : 'gray.100'}
      w={'full'}
      alignItems={'flex-start'}
      flexGrow={1}
      position={'relative'}
      p={0}
      spacing={0}
      gap={0}
      borderRadius={'12px'}
      boxShadow={'base'}
      borderWidth={'1px'}
      borderColor={'gray.100'}
      // divider={<StackDivider m={0} p={0} borderColor={'gray.200'} />}
    >
      <HStack
        w={'full'}
        justifyContent={'space-between'}
        px={'20px'}
        py={'16px'}
      >
        <HStack spacing={0}>
          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
            {element.name}
          </Text>
          {element.id === 'memberId' && (
            <HintTooltip
              text={
                'サービスで個人を特定するためのIDにあたるものを設定してください。(例: 会員証番号、診察券番号など)'
              }
              color={'gray.500'}
            ></HintTooltip>
          )}
        </HStack>
        <EnabledSwitcher
          element={element}
          enabled={enabled}
          onChangeEnabled={onChangeEnabled}
        />
      </HStack>
      <Divider m={0} p={0} borderColor={'gray.300'} />
      <SharedBookingFormElementView element={element} />
      <Divider m={0} p={0} borderColor={'gray.300'} />

      <HStack
        w={'full'}
        px={'20px'}
        py={'16px'}
        spacing={'12px'}
        justifyContent={'flex-end'}
      >
        <Button
          variant={'blue-fill'}
          onClick={() => {
            onClickToEdit(element);
          }}
        >
          <HStack>
            <MdEdit />
            <Text>編集</Text>
          </HStack>
        </Button>
      </HStack>
      {renderEditor()}
    </VStack>
  );
};

const EnabledSwitcher: React.FC<{
  element: SharedBookingFormElement | PredefinedSharedBookingFormElement;
  enabled: boolean;
  onChangeEnabled: (
    element: SharedBookingFormElement | PredefinedSharedBookingFormElement,
    enabled: boolean
  ) => Promise<void>;
}> = ({ element, enabled, onChangeEnabled }) => {
  const mutation = useMutation({
    mutationFn: async (value: string | null) =>
      onChangeEnabled(element, value === 'true'),
  });
  return (
    <HStack>
      <StyledSelect
        type={'mustSelect'}
        w={'fit-content'}
        defaultValue={String(enabled)}
        placeholder="選択してください"
        options={[
          {
            id: 'true',
            element: (
              <HStack>
                <Icon color={'green.500'} as={MdCheckCircle} />
                <Text>有効</Text>
              </HStack>
            ),
          },
          {
            id: 'false',
            element: (
              <HStack>
                <Icon color={'red.500'} as={MdDoNotDisturb} />
                <Text>無効</Text>
              </HStack>
            ),
          },
        ]}
        onSelect={(value) => mutation.mutateAsync(value)}
      />
      {mutation.isPending && <Spinner />}
    </HStack>
  );
};
