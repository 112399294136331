import React from 'react';

import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { ReminderConfigurationEditPage } from '../../components/features/reminder/edit';

const ReminderConfigurationEdit = () => {
  setDocumentTitle('リマインダー設定の編集');
  return <ReminderConfigurationEditPage />;
};

export default ReminderConfigurationEdit;
