import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { BookingMenu, dateStringWithWeekDay } from '@pochico/shared';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import React from 'react';

import {
  MdOutlineAccessTime,
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
} from 'react-icons/md';
import { dayOfWeekMap } from '../../../helpers/dayOfWeeks';
import { useIsPC } from '../../../hooks/useIsPC';
import { CalendarCell, CalendarView } from '../../ui/CalendarView';
import { BulkAddSpotsInput, SpotSlot, dateFormat } from './types';

export const BulkAddSpotsPreview: React.FC<{
  // TODO: bulkSpotDataProviderとあわせて、inputから生成されたspot[]を表示するようにするべき
  input: BulkAddSpotsInput;
  bookingMenu: BookingMenu;
  isSubmitting: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({ input, bookingMenu, isSubmitting, onSubmit, onCancel }) => {
  const now = React.useMemo(() => dayjs(), []);
  const startDate = React.useMemo(
    () => dayjs(input.duration.startDate),
    [input.duration.startDate]
  );
  const endDate = React.useMemo(
    () => dayjs(input.duration.endDate),
    [input.duration.endDate]
  );
  const isPC = useIsPC();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedCell, setClickedCell] = React.useState<{
    date: dayjs.Dayjs;
    startTimes: SpotSlot[];
  } | null>(null);
  const onClickCell = React.useCallback(
    (date: dayjs.Dayjs, startTimes: SpotSlot[]) => {
      setClickedCell({ date, startTimes });
      onOpen();
    },
    [onOpen]
  );

  const dateComponent = React.useCallback(
    (date: dayjs.Dayjs) => {
      const excludeDates = new Set(input.excludeDays.map((d) => d.date));
      return (
        <CalendarElement
          key={`dayElements-${date.format(dateFormat)}`}
          now={now}
          startDate={startDate}
          endDate={endDate}
          date={date}
          allStartTimes={input.slots}
          excludeDates={excludeDates}
          onClickCell={onClickCell}
        />
      );
    },
    [input.excludeDays, input.slots, now, startDate, endDate, onClickCell]
  );

  console.log('preview', { input });
  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <VStack
        bgColor={'white'}
        borderWidth={isPC ? '1px' : 0}
        borderColor={'gray.200'}
        justifyContent={'center'}
        w={'full'}
        paddingBottom={'24px'}
        spacing={'0'}
        borderRadius={'3px'}
        divider={
          isPC ? <Divider borderColor={'gray.200'} w={'full'} /> : undefined
        }
      >
        <Text
          w={'full'}
          textAlign={'left'}
          px={'14px'}
          py={'18px'}
          color={'red.500'}
        >
          以下の内容で一括登録します。誤って作成しないよう、ご確認をお願いします。
        </Text>
        <Flex
          w={'full'}
          direction={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          borderWidth={isPC ? 0 : '1px'}
          px={'12px'}
          py={'8px'}
          borderRadius={'8px'}
        >
          <Text marginBottom={2} fontSize={'14px'}>
            登録先の予約メニュー
          </Text>
          <Text fontWeight={'bold'} fontSize={'16px'}>
            {bookingMenu.name}
          </Text>
        </Flex>
        <HStack
          flexWrap={'wrap'}
          // columns={{ base: 1, lg: 2 }}
          gap={'72px'}
          pt={'24px'}
          w={'full'}
          p={'16px'}
        >
          {Array.from(Array(1 + endDate.diff(startDate, 'M')).keys()).map(
            (index) => {
              return (
                <Flex
                  key={`calendar-${index}`}
                  direction={'column'}
                  alignItems={'flex-start'}
                  textAlign={'center'}
                >
                  <Box w={'full'} marginBottom={'18px'}>
                    <Text fontSize={'16px'} fontWeight={'bold'}>
                      {dayjs(input.duration.startDate)
                        .add(index, 'month')
                        .format('YYYY年M月')}
                    </Text>
                  </Box>
                  <Spacer height={4} />
                  <CalendarView
                    dateComponent={dateComponent}
                    yearMonth={{
                      year: dayjs(input.duration.startDate)
                        .add(index, 'month')
                        .year(),
                      month:
                        dayjs(input.duration.startDate)
                          .add(index, 'month')
                          .month() + 1,
                    }}
                  />
                </Flex>
              );
            }
          )}
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent paddingY={6} justifyContent={'center'}>
              <ModalHeader justifyContent={'center'}>
                {clickedCell && dateStringWithWeekDay(clickedCell.date)}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <SimpleGrid
                  columns={2}
                  borderRadius={'lg'}
                  backgroundColor={'gray.100'}
                  padding={4}
                  spacing={4}
                >
                  {(clickedCell?.startTimes || [])
                    .sort((a, b) => a.startTime.localeCompare(b.startTime))
                    .map((startTime: SpotSlot, index: number) => {
                      return (
                        <HStack
                          key={`${clickedCell?.date || ''}-${index}`}
                          w={'full'}
                          spacing={4}
                        >
                          <MdOutlineAccessTime />
                          <Text>
                            {startTime.startTime} / {startTime.maxBookings} 枠
                          </Text>
                        </HStack>
                      );
                    })}
                </SimpleGrid>
              </ModalBody>
            </ModalContent>
          </Modal>
        </HStack>
        <Text fontSize={'sm'} pt={'12px'} px={'12px'}>
          ※各日付をクリック / タップするとその日付の予約枠の一覧を確認できます。
        </Text>
      </VStack>
      <Stack
        w={'full'}
        direction={['column-reverse', 'row']}
        // spacing={[8, 12]}
        alignItems={'center'}
        paddingY={'24px'}
        justifyContent={['center', 'flex-end']}
      >
        <Button
          type={'button'}
          variant={'white-blue'}
          onClick={onCancel}
          disabled={isSubmitting}
        >
          <MdOutlineNavigateBefore />
          戻る
        </Button>
        <Button
          type={'submit'}
          variant={'blue-fill'}
          onClick={onSubmit}
          isLoading={isSubmitting}
        >
          この内容で一括登録する
          <MdOutlineNavigateNext />
        </Button>
      </Stack>
    </VStack>
  );
};

const CalendarElement: React.FC<{
  now: dayjs.Dayjs;
  date: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  allStartTimes: BulkAddSpotsInput['slots'];
  excludeDates: Set<string>;
  onClickCell: (date: dayjs.Dayjs, startTimes: SpotSlot[]) => void;
}> = ({
  now,
  date,
  startDate,
  endDate,
  allStartTimes,
  excludeDates,
  onClickCell,
}) => {
  const dayOfWeek = dayOfWeekMap.get(
    date.day() as 0 | 1 | 2 | 3 | 4 | 5 | 6
  )?.id;
  const startTimes = allStartTimes[dayOfWeek!];
  const today = now.startOf('day');
  if (
    startTimes.length === 0 || // 枠を作らない
    excludeDates.has(date.format(dateFormat)) || // 除外日に指定されている
    date < today || // 今日以前は作成できない
    startDate > date ||
    endDate < date // durationからはみ出ている
  ) {
    return <CalendarCell date={date} count={0} />;
  } else {
    const nowTime = now.format('HH:mm');
    const availableStartTimes = startTimes.filter((time) => {
      return date > today || time.startTime >= nowTime;
    });
    return (
      <CalendarCell
        date={date}
        onClick={() => onClickCell(date, availableStartTimes)}
        count={availableStartTimes.length}
      />
    );
  }
};
