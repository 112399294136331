import { Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { scrollToTop } from '../../helpers/scrollToTop';
import { LocationFrom } from '../../hooks/locationState';

type LinkProps = React.ComponentProps<typeof ChakraLink> &
  React.ComponentProps<typeof ReactRouterLink> & {
    locationFrom?: LocationFrom;
  };

export const Link: React.FC<LinkProps> = React.forwardRef(
  ({ locationFrom, ...props }, ref) => {
    return (
      <ChakraLink
        as={ReactRouterLink}
        onClick={scrollToTop}
        state={locationFrom?.path}
        {...props}
        ref={ref}
      />
    );
  }
);
