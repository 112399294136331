import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

export type OpenDialogParams = {
  body: React.ReactElement;
  size?: AlertDialogProps['size'];
  closeOnOverlayClick?: boolean;
};
type DialogState = {
  isOpen: boolean;
  openDialog: (params: OpenDialogParams) => void;
  closeDialog: () => void;
  params?: OpenDialogParams;
};
const initialState: DialogState = {
  isOpen: false,
  openDialog: (params) => {
    throw 'openDialog is not implemented';
  },
  closeDialog: () => {
    throw 'closeDialog is not implemented';
  },
};

const StateContext = React.createContext<DialogState>({ ...initialState });
export const useDialogDispatcher = () => React.useContext(StateContext);

const useDialogDispatcherInternal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = React.useState<DialogState>({
    ...initialState,
    isOpen,
  });
  const openDialog = React.useCallback(
    (params: OpenDialogParams) => {
      onClose();
      setState({
        ...state,
        isOpen: true,
        params,
      });
      onOpen();
    },
    [onClose, onOpen, state]
  );
  const closeDialog = React.useCallback(() => {
    setState({
      ...state,
      isOpen: false,
      params: undefined,
    });
    onClose();
  }, [onClose, state]);
  return {
    state: {
      ...state,
      isOpen,
      openDialog,
      closeDialog,
    },
  };
};

type FocusableObject = Parameters<typeof AlertDialog>[0]['leastDestructiveRef'];
export const DialogContainer: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { isOpen } = useDisclosure();
  const { state } = useDialogDispatcherInternal();
  const cancelRef: FocusableObject = React.useRef(null);

  return (
    <StateContext.Provider value={{ ...state }}>
      {props.children}

      <AlertDialog
        size={state.params?.size || 'md'}
        isOpen={state.isOpen}
        onClose={state.closeDialog}
        leastDestructiveRef={cancelRef}
        onCloseComplete={() => {
          state.closeDialog();
        }}
        isCentered={true}
        autoFocus={false}
        closeOnEsc={state.params?.closeOnOverlayClick || false}
        closeOnOverlayClick={state.params?.closeOnOverlayClick || false}
      >
        <AlertDialogOverlay>{state.params?.body}</AlertDialogOverlay>
      </AlertDialog>
    </StateContext.Provider>
  );
};
