import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loading } from '../components/ui/Loading';
import { useAuthState } from '../context/providerAccount';
import authProvider from '../providers/authProvider';

export const LogOut: FC = () => {
  const navigate = useNavigate();
  const { clear } = useAuthState();
  const { initialized, providerAccount } = useAuthState();

  React.useEffect(() => {
    console.log('LogOut', { providerAccount, initialized, clear });
    if (!clear) {
      return;
    }

    clear().then(() => {
      if (providerAccount) {
        authProvider
          .logout({})
          .catch((e) => {
            console.error(`failed to logout. ${e}`, {
              providerAccount,
              error: e,
            });
          })
          .finally(() => navigate('/login'));
      } else {
        navigate('/login');
      }
    });
  }, [clear, initialized, navigate, providerAccount]);
  if (!initialized) {
    return <Loading />;
  }

  return <></>;
};
