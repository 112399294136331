import {
  Badge,
  Box,
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BookingFormElement,
  PredefinedSharedBookingFormElement,
  SharedBookingFormElement,
} from '@pochico/shared';
import React from 'react';
import { BookingFormElementType } from '../../ui/BookingFormElementType';
import { TextWithLinks } from '../../ui/TextWithLink';

const Row: React.FC<{ header: React.ReactNode; children: React.ReactNode }> = ({
  header,
  children,
}) => {
  return (
    <Grid w={'full'} gap={'24px'} templateColumns={'120px 1fr'}>
      <GridItem>
        <Text
          color={'gray.900'}
          fontSize={'14px'}
          minW={'120px'}
          // whiteSpace={'nowrap'}
        >
          {header}
        </Text>
      </GridItem>

      <GridItem>{children}</GridItem>
    </Grid>
  );
};
export const BookingFormElementView: React.FC<{
  element: BookingFormElement;
}> = ({ element }) => {
  const type = element.type;
  return (
    <VStack w={'full'} px={'20px'} py={'12px'} gap={'16px'}>
      <Row header={type === 'agreement' ? 'タイトル' : '質問文'}>
        <TextWithLinks
          w={'full'}
          p={0}
          color={'gray.900'}
          fontSize={'14px'}
          fontWeight={'bold'}
        >
          {element.title}
        </TextWithLinks>
      </Row>
      {type === 'agreement' ? (
        <Row header={'内容'}>
          <Box w={'full'} p={'12px'} bgColor={'gray.50'} borderRadius={'4px'}>
            <TextWithLinks
              color={'gray.900'}
              fontSize={'14px'}
              w={'full'}
              maxH={'10em'}
              overflowY={'auto'}
              bgColor={'white'}
              borderWidth={'1px'}
              borderColor={'gray.200'}
              borderRadius={'6px'}
              px={'8px'}
              py={'12px'}
            >
              {element.description}
            </TextWithLinks>
          </Box>
        </Row>
      ) : (
        <Row header={'説明文'}>
          <TextWithLinks
            color={'gray.900'}
            fontSize={'14px'}
            w={'full'}
            maxH={'10em'}
            overflowY={'auto'}
          >
            {element.description}
          </TextWithLinks>
        </Row>
      )}

      {type === 'agreement' && (
        <Row header={'確認チェックボックスのテキスト'}>
          <HStack
            flexGrow={1}
            spacing={'8px'}
            color={'gray.800'}
            p={'12px'}
            bgColor={'gray.50'}
            w={'full'}
          >
            <Checkbox isReadOnly isChecked={false} />
            <TextWithLinks color={'gray.800'} fontSize={'14px'}>
              {element.label}
            </TextWithLinks>
          </HStack>
        </Row>
      )}

      {'required' in element && (
        <Row header={'回答'}>
          <Badge
            variant={'subtle'}
            colorScheme={element.required ? 'red' : 'gray'}
            fontSize={'sm'}
          >
            {element.required ? '必須' : '任意'}
          </Badge>
        </Row>
      )}
      <Row header={'タイプ'}>
        <Box w={'full'}>
          <BookingFormElementType formElement={element} />
        </Box>
      </Row>
      {(element.type === 'single-select' ||
        element.type === 'multi-select') && (
        <Row header={'選択肢'}>
          <VStack
            w={'full'}
            p={'12px'}
            borderRadius={'4px'}
            bgColor={'gray.50'}
            gap={'16px'}
          >
            {element.candidates.map((candidate, i) => (
              <HStack
                w={'full'}
                gap={'8px'}
                key={`${element.id}-candidates-${i}`}
                color={'gray.800'}
              >
                {element.type === 'single-select' ? (
                  <Radio isChecked={false} />
                ) : (
                  <Checkbox readOnly isChecked={false} />
                )}
                <HStack w={'full'} gap={'8px'}>
                  <Text color={'gray.900'} fontSize={'14px'}>
                    {candidate}
                  </Text>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Row>
      )}
    </VStack>
  );
};

export const SharedBookingFormElementView: React.FC<{
  element: SharedBookingFormElement | PredefinedSharedBookingFormElement;
}> = ({ element }) => {
  return (
    <VStack w={'full'} px={'20px'} py={'12px'} gap={'16px'}>
      <Row header={'質問文'}>
        <TextWithLinks
          w={'full'}
          color={'gray.900'}
          fontSize={'14px'}
          fontWeight={'bold'}
        >
          {element.title}
        </TextWithLinks>
      </Row>
      <Row header={'説明文'}>
        <TextWithLinks color={'gray.900'} fontSize={'14px'} w={'full'}>
          {element.description}
        </TextWithLinks>
      </Row>
      <Row header={'タイプ'}>
        <Box w={'full'}>
          <BookingFormElementType formElement={element} />
        </Box>
      </Row>
      {element.type === 'sex' && (
        <Row header={'選択肢'}>
          <VStack
            w={'full'}
            p={'12px'}
            borderRadius={'4px'}
            bgColor={'gray.50'}
            gap={'16px'}
            spacing={'16px'}
          >
            {element.candidates.map((candidate, i) => (
              <HStack
                w={'full'}
                gap={'8px'}
                key={`${element.id}-candidates-${i}`}
                color={'gray.800'}
              >
                <Radio readOnly isChecked={false} bgColor={'white'} />
                <Text color={'gray.900'} fontSize={'14px'}>
                  {candidate}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Row>
      )}
    </VStack>
  );
};
