import { Button, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useSetDocumentTitle } from '../../../commons/components/setDocumentTitle';
import Spacer from '../../../components/fragments/Spacer';
import Footer from '../../../components/ui/Footer';
import { Path } from '../../../routers/Path';

const PaymentRegistrationCompletePage: FC = () => {
  useSetDocumentTitle('支払い登録の完了');
  return (
    <VStack>
      <VStack>
        <Spacer height={'40px'} />
        <Text fontSize={'xl'} fontWeight={'bold'}>
          支払い方法の登録が完了しました。
        </Text>
        <Spacer height={'60px'} />
        <Link to={Path.home}>
          <Button colorScheme={'green'}>トップへ戻る</Button>
        </Link>
      </VStack>
      <Spacer height={'60px'} />
      <Footer />
    </VStack>
  );
};
export default PaymentRegistrationCompletePage;
