import { Button, useToast } from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingMenuUpdateParams } from '../../../firebase/types';
import { useUpdateBookingMenu } from '../../../hooks/bookingMenus';
import { resourcePath } from '../../../hooks/useUrlPath';
import { Layout } from '../../ui/Layout';
import {
  BookingMenuEditForm,
  BookingMenuEditFormInputs,
} from './BookingMenuEditForm';

export const BookingMenuEdit: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  const toast = useToast();

  const defaultValues = React.useMemo(() => {
    return {
      ...bookingMenu,
      customText: {
        bookingSuccess:
          bookingMenu.customText?.bookingSuccess ||
          providerAccount?.customText?.bookingSuccess, // providerAccount単位で設定されているものがあればそちらを表示する
      },
      useImage: Boolean(bookingMenu.imageUrl),
      bookingSuccessEnabled:
        !bookingMenu.bookingForm?.enabled ||
        bookingMenu.bookingForm.sendBookingSuccess,
    };
  }, [bookingMenu, providerAccount?.customText?.bookingSuccess]);
  const form = useForm<BookingMenuUpdateParams & { useImage: boolean }>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    formState: { isDirty, isValid },
  } = form;

  const navigate = useNavigate();

  const updateMutation = useUpdateBookingMenu(providerAccount);
  const onSubmit = React.useCallback(
    async (data: BookingMenuEditFormInputs) => {
      if (!('id' in data)) {
        return;
      }
      return updateMutation
        .mutateAsync({
          data,
        })
        .then(async (res) => {
          toast({
            title: '予約メニューを更新しました。',
            status: 'success',
          });
          const path = providerAccount.needBookingMenu
            ? resourcePath({
                providerAccount,
                resourceName: 'bookingMenu',
                resourceId: bookingMenu.id,
                action: 'show',
              })
            : resourcePath({
                providerAccount,
                resourceName: 'bookingMenu',
                action: 'list',
              });
          navigate(path);
        })
        .catch((e) => {
          toast({
            title: `予約メニューの更新に失敗しました error: ${e}`,
            status: 'error',
          });
        });
    },
    [bookingMenu, updateMutation, toast, providerAccount, navigate]
  );

  return (
    <Layout
      pageTitle="予約メニュー編集"
      headerAction={
        <Button
          type={'submit'}
          variant={'blue-fill'}
          form={'booking-menu-edit-form'}
          isLoading={updateMutation.isPending}
          isDisabled={!isValid || !isDirty}
        >
          保存する
        </Button>
      }
      hasBackButton={{
        providerAccount,
        resourceName: 'bookingMenu',
        action: 'show',
        resourceId: bookingMenu.id,
      }}
    >
      <FormProvider {...form}>
        <BookingMenuEditForm
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </Layout>
  );
};
