import {
  PredefinedSharedBookingFormElement,
  SharedBookingFormElement,
} from '@pochico/shared';

export const predefinedSharedBookingFormElements: {
  [id in PredefinedSharedBookingFormElement['id']]: PredefinedSharedBookingFormElement;
} = {
  name: {
    id: 'name',
    type: 'name',
    name: '氏名',
    title: 'お名前を入力してください',
    description: '',
    order: 1,
  },
  furigana: {
    id: 'furigana',
    type: 'furigana',
    name: 'フリガナ',
    title: 'フリガナを入力してください（全角カナ）',
    description: '',
    order: 2,
  },
  birthday: {
    id: 'birthday',
    type: 'birthday',
    name: '生年月日',
    title: '生年月日を入力してください',
    description: '',
    order: 3,
  },
  email: {
    id: 'email',
    type: 'email',
    name: 'メールアドレス',
    title: 'メールアドレスを入力してください',
    description: '',
    order: 4,
  },
  tel: {
    id: 'tel',
    type: 'tel',
    name: '電話番号',
    title: '電話番号を入力してください（ハイフンなし）',
    description: '',
    order: 5,
  },
  memberId: {
    id: 'memberId',
    type: 'memberId',
    name: '会員番号',
    title: '会員番号を入力してください',
    description: '',
    order: 6,
  },
  sex: {
    id: 'sex',
    type: 'sex',
    name: '性別',
    title: '性別を入力してください',
    description: '',
    candidates: ['女性', '男性', 'その他'],
    order: 7,
  },
  address: {
    id: 'address',
    type: 'address',
    name: '住所',
    title: '住所を入力してください',
    description: '',
    order: 8,
  },
};

// pre-definedなフォーム要素と、すでに設定済みのフォーム要素をわけて扱えるようにする
// pre-definedな要素をあとから追加したときに、すでに設定済みのフォーム要素との差分を取るために使う
// 並び順はpre-definedの要素の順番に従う
export const sharedBookingFormElementsWithPredefined = (
  configuredElements: SharedBookingFormElement[]
) => {
  const predefinedElements = Object.values(predefinedSharedBookingFormElements);
  return predefinedElements.reduce((acc, predefined) => {
    const configuredElement = configuredElements.find(
      (e) => e.id === predefined.id
    );
    if (configuredElement) {
      acc.push(configuredElement);
    } else {
      acc.push(predefined);
    }
    return acc;
  }, [] as (SharedBookingFormElement | PredefinedSharedBookingFormElement)[]);
};
