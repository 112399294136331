import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Radio,
  Select,
  StackDivider,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingFormElement, getObjectKeys, sleep } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';
import React from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useIsPC } from '../../../hooks/useIsPC';
import {
  BookingFormCreateParams,
  BookingFormUpdateParams,
} from '../../../providers/dataProvider/bookingForm';
import { typeLabel } from './typeLabel';

const targetAtom = atom<
  | { isOpen: true; element: BookingFormElement; idx: number }
  | { isOpen: false; element?: never }
>({ isOpen: false });
targetAtom.onMount = (setAtom) => {
  setAtom({ isOpen: false });
};

export const useBookingFormElementEditor = (
  onChange: () => Promise<void>,
  formContext: ReturnType<
    typeof useFormContext<BookingFormCreateParams | BookingFormUpdateParams>
  >
) => {
  const isPC = useIsPC();
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;

  const { fields, update } = useFieldArray({
    control: formContext.control,
    name: `formElements`,
    keyName: '_id',
  });

  const [target, setTarget] = useAtom(targetAtom);
  const onClose = React.useCallback(async () => {
    disclosure.onClose();
    await sleep(100);
    setTarget({ isOpen: false });
  }, [disclosure, setTarget]);

  const _onChange = React.useCallback(
    async (element: BookingFormElement) => {
      if (!target.isOpen) {
        return;
      }
      const { idx } = target;
      // すでに空のformElementがinsertされた後に実行されるコードになるためinsertはせずにupdateだけする
      update(idx, element);
      await onChange().finally(onClose);
    },
    [onChange, onClose, target, update]
  );

  const onClickToEdit = React.useCallback(
    async (formElement: BookingFormElement, idx: number) => {
      setTarget({ isOpen: true, element: formElement, idx });
      onOpen();
    },
    [onOpen, setTarget]
  );

  const renderEditor = React.useCallback(() => {
    if (!target.isOpen) {
      return <></>;
    }
    const _disclosure = {
      ...disclosure,
      // isOpen: target.isOpen,
      onClose: onClose,
    };
    return isPC ? (
      <BookingFormElementEditorDialog
        // key={now}
        key={target.element.id}
        disclosure={_disclosure}
        onChange={_onChange}
      />
    ) : (
      <BookingFormElementEditorDrawer
        // key={now}
        key={target.element.id}
        disclosure={_disclosure}
        onChange={_onChange}
      />
    );
  }, [_onChange, disclosure, isPC, onClose, target]);
  return {
    renderEditor,
    onClickToEdit,
  };
};

const BookingFormElementEditorDrawer: React.FC<{
  disclosure: ReturnType<typeof useDisclosure>;
  onChange: (formElement: BookingFormElement) => Promise<void>;
}> = ({ onChange, disclosure: { onClose, isOpen } }) => {
  const formElement = useAtomValue(targetAtom).element;
  const form = useForm<BookingFormCreateParams['formElements'][number]>({
    defaultValues: formElement,
  });
  const {
    formState: { isValid },
    getValues,
    trigger,
  } = form;

  const toast = useToast();
  const onSubmit = useMutation({
    mutationFn: React.useCallback(async () => {
      const ok = await trigger();
      if (ok) {
        const formElement = getValues();
        return onChange(formElement);
      }
    }, [getValues, onChange, trigger]),
    onSuccess: onClose,
    onError: (e) => {
      toast({
        title: `エラーが発生しました ${e}`,
        status: 'error',
      });
    },
  });

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      size={'xl'}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isFullHeight={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Text w={'full'}>質問内容の編集</Text>
        </DrawerHeader>

        <DrawerBody
          maxH={'60vh'}
          borderTopWidth={'1px'}
          borderTopColor={'gray.200'}
          borderBottomWidth={'1px'}
          borderBottomColor={'gray.200'}
        >
          <FormProvider {...form}>
            <BookingFormElementEditorBody />
          </FormProvider>
        </DrawerBody>

        <DrawerFooter>
          <HStack w={'full'} justifyContent={'flex-end'} gap={'12px'}>
            <Button colorScheme="gray" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              isDisabled={!isValid}
              isLoading={onSubmit.isPending}
              variant={'blue-fill'}
              onClick={() => onSubmit.mutateAsync()}
            >
              保存
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const BookingFormElementEditorDialog: React.FC<{
  disclosure: ReturnType<typeof useDisclosure>;
  onChange: (formElement: BookingFormElement) => Promise<void>;
}> = ({ onChange, disclosure: { isOpen, onClose } }) => {
  const formElement = useAtomValue(targetAtom).element;
  const form = useForm<BookingFormCreateParams['formElements'][number]>({
    defaultValues: formElement,
  });
  const {
    formState: { isValid },
    getValues,
    trigger,
  } = form;

  const toast = useToast();
  const onSubmit = useMutation({
    mutationFn: React.useCallback(async () => {
      const ok = await trigger();
      if (ok) {
        const formElement = getValues();
        return onChange(formElement);
      }
    }, [getValues, onChange, trigger]),
    onSuccess: onClose,
    onError: (e) => {
      toast({
        title: `エラーが発生しました ${e}`,
        status: 'error',
      });
    },
  });
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      size={'2xl'}
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      autoFocus={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          marginX={'16px'}
          // marginBottom={{ base: 0, md: 'auto' }}
          // px={{ base: 0, md: 'auto' }}
        >
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            alignItems={'center'}
          >
            <Text w={'full'}>質問内容の編集</Text>
            <AlertDialogCloseButton />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormProvider {...form}>
              <BookingFormElementEditorBody />
            </FormProvider>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack w={'full'} justifyContent={'flex-end'} gap={'12px'}>
              <Button colorScheme="gray" onClick={onClose}>
                キャンセル
              </Button>
              <Button
                isDisabled={!isValid}
                isLoading={onSubmit.isPending}
                variant={'blue-fill'}
                onClick={() => onSubmit.mutateAsync()}
              >
                保存
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const BookingFormElementEditorBody: React.FC = () => {
  const form =
    useFormContext<BookingFormCreateParams['formElements'][number]>();
  const {
    control,
    formState: { errors },
    register,
    setValue,
    trigger,
  } = form;
  const [type, candidates] = useWatch({
    control: control,
    name: [`type`, `candidates`],
  });
  const handleTextareaChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
      // setValue('remindText', e.target.value);
    },
    []
  );
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      gap={'20px'}
      p={0}
      divider={<StackDivider borderColor={'gray.200'} />}
    >
      <VStack w={'full'} alignItems={'flex-start'} gap={'20px'}>
        <FormControl isRequired isInvalid={!!errors.type}>
          <FormLabel color={'gray.900'} fontSize={'14px'} fontWeight={500}>
            タイプ
          </FormLabel>
          <Box w={'full'}>
            <Select
              {...register(`type`, {
                onChange: (e) => {
                  if (e.target.value === 'agreement') {
                    setValue(`label`, '確認しました');
                  }
                },
              })}
            >
              {getObjectKeys(typeLabel).map((key) => (
                <option key={`type-${key}`} id={`type-${key}`} value={key}>
                  {typeLabel[key]}
                </option>
              ))}
            </Select>
          </Box>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.title}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <FormLabel color={'gray.900'} fontSize={'14px'} fontWeight={500}>
              {type === 'agreement' ? 'タイトル' : '質問文'}
            </FormLabel>
            <FormHelperText color={'gray.500'}>100文字まで</FormHelperText>
          </HStack>
          <Box w={'full'} h={'fit-content'}>
            <Textarea
              minW={'full'}
              maxW={'full'}
              // boxSizing={'content-box'}
              minH={'1em'}
              h={'fit-content'}
              pb={0}
              maxH={'10em'}
              fontSize={'md'}
              bgColor={'white'}
              placeholder={'質問文'}
              {...register(`title`, {
                onChange: handleTextareaChange,
                required: true,
                maxLength: 100,
              })}
            />
          </Box>
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.description}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <FormLabel color={'gray.900'} fontSize={'14px'} fontWeight={500}>
              {type === 'agreement' ? '内容' : '説明文'}
            </FormLabel>
            <FormHelperText color={'gray.500'}>
              {type === 'agreement' ? '10万文字' : '400文字'}まで
            </FormHelperText>
          </HStack>
          <Box w={'full'} h={'fit-content'}>
            <Textarea
              minW={'full'}
              maxW={'full'}
              // boxSizing={'content-box'}
              minH={'3em'}
              h={'fit-content'}
              maxH={'10em'}
              overflowY={'auto'}
              fontSize={'md'}
              bgColor={'white'}
              placeholder={
                type === 'agreement'
                  ? '注意事項などの内容をここに記載してください'
                  : '内容をここに記載してください'
              }
              {...register(`description`, {
                onChange: handleTextareaChange,
                required: false,
                maxLength: type === 'agreement' ? 100000 : 400,
              })}
            />
          </Box>
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>
        {type === 'agreement' && (
          <FormControl>
            <HStack w={'full'} justifyContent={'space-between'}>
              <FormLabel color={'gray.900'} fontSize={'14px'} fontWeight={500}>
                確認チェックボックスのテキスト
              </FormLabel>
              <FormHelperText color={'gray.500'}>100文字まで</FormHelperText>
            </HStack>
            <Box w={'full'} h={'fit-content'}>
              <Input
                w={'full'}
                // boxSizing={'content-box'}
                minH={'3em'}
                fontSize={'md'}
                bgColor={'white'}
                placeholder={'確認しました'}
                {...register(`label`, {
                  onChange: handleTextareaChange,
                  required: true,
                  maxLength: 100,
                })}
              />
            </Box>
          </FormControl>
        )}

        <FormControl>
          <HStack
            spacing={'12px'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Checkbox p={0} m={0} {...register(`required`)} />
            <FormLabel
              color={'gray.900'}
              fontSize={'14px'}
              fontWeight={500}
              whiteSpace={'nowrap'}
              p={0}
              m={0}
            >
              回答必須にする
            </FormLabel>
          </HStack>
        </FormControl>
      </VStack>

      {(type === 'single-select' || type === 'multi-select') && (
        <VStack w={'full'} gap={'8px'} alignItems={'flex-start'}>
          <Text color={'gray.900'} fontSize={'16px'} fontWeight={'bold'}>
            選択肢設定
          </Text>
          <Text color={'gray.500'} fontSize={'14px'}>
            最大20選択肢 / 各選択肢100文字まで
          </Text>
          {errors && 'candidates' in errors && (
            <Text color={'red.500'}>入力内容を確認してください</Text>
          )}
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            spacing={'8px'}
            mt={'12px'}
            pl={'12px'}
            borderLeftWidth={'2px'}
            borderLeftColor={'gray.500'}
          >
            {(candidates || ['']).map((option, _idx) => {
              const error =
                errors && 'candidates' in errors
                  ? (
                      errors.candidates as {
                        message: string;
                      }[]
                    )?.[_idx]?.message
                  : undefined;
              return (
                <HStack w={'full'} m={0} key={`option-${_idx}`}>
                  {type === 'single-select' ? (
                    <Radio readOnly isChecked={false} />
                  ) : (
                    <Checkbox readOnly isChecked={false} />
                  )}
                  <FormControl isRequired isInvalid={Boolean(error)}>
                    <Input
                      {...register(`candidates.${_idx}`, {
                        required: '入力してください',
                        maxLength: 100,
                      })}
                      autoFocus={
                        candidates && _idx === candidates.length - 1 && !option
                      }
                    />
                  </FormControl>
                  <IconButton
                    aria-label="Delete"
                    icon={<MdDelete />}
                    color={'red'}
                    variant={'transparent-clickable'}
                    size={'20px'}
                    // boxSize={'20px'}
                    w={'20px'}
                    h={'20px'}
                    m={0}
                    onClick={() => {
                      const newOptions = (candidates || []).filter(
                        (_, i) => i !== _idx
                      );
                      setValue(`candidates`, newOptions);
                      trigger();
                    }}
                  />
                </HStack>
              );
            })}
            <Button
              colorScheme="gray"
              w={'full'}
              size={'md'}
              isDisabled={!candidates || candidates.length >= 20}
              onClick={() => {
                setValue(`candidates`, [...(candidates || []), '']);
              }}
            >
              <HStack>
                <MdAdd />
                <Text>選択肢を追加</Text>
              </HStack>
            </Button>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
