import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  ChurnSurveyResponse,
  ProviderAccount,
  getRandomString,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../context/providerAccount';
import { postBotApi } from '../../dataStore/bot';
import { Path } from '../../routers/Path';

export const ChurnSurveyForm: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const form = useForm<ChurnSurveyResponse>({
    mode: 'onBlur',
    defaultValues: {
      id: getRandomString(15),
      providerAccountId: providerAccount.id,
      type: 'churnSurvey',
    },
  });
  const navigate = useNavigate();
  const { clear, initialized, firebaseUser } = useAuthState();
  // const { status, customerPortalUrl, loading } = useCheckUnpaid();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: async (input: ChurnSurveyResponse) => {
      if (!providerAccount || !firebaseUser) {
        return;
      }
      const ok = confirm('退会します。よろしいですか？');
      if (!ok) {
        return;
      }
      const request = {
        providerAccountId: providerAccount.id,
        surveyInput: input,
      };
      console.log({
        message: 'confirmed to churn',
        request,
      });
      return postBotApi<{ ok: boolean }>(
        `/provider-accounts/${providerAccount.id}/churn`,
        firebaseUser,
        request
      ).then(async (response) => {
        if (response.ok) {
          return response.body;
        } else {
          throw response.error;
        }
      });
    },
    onSuccess: (result) => {
      if (!result) {
        // skipされた
        return;
      }
      toast({
        title: '退会が完了しました。',
        status: 'success',
      });
      clear().then(() => {
        navigate(Path.signup);
      });
    },
    onError: (error) => {
      toast({
        title: `エラーが発生しました ${error}`,
        status: 'error',
      });
    },
  });
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit((d) => mutation.mutateAsync(d))}
        style={{
          width: '100%',
        }}
      >
        <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
          <SurveyForm />
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              type={'submit'}
              variant={'red-fill'}
              isDisabled={!form.formState.isDirty || !form.formState.isValid}
              isLoading={mutation.isPending}
            >
              退会する
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};
const questions = {
  reason: {
    他の予約受付方法を検討する: '他の予約受付方法を検討する',
    事業の撤退: '事業の撤退、テストアカウントなど',
    予約システム自体を使わないことにした:
      '予約システム自体を使わないことにした',
    期間限定の利用: '期間限定の利用だったから（いずれ再開予定）',
    時間を置いて検討する: '時間を置いて検討する',
  },
  reasonDetail: {
    使い方がわからなかった: '使い方がわからなかった/難しかった',
    機能が足りなかった: '機能が足りなかった',
    価格が高かった: '価格が高かった',
    他のサービス: '他のサービス/ツールの方が良かった',
    LINE公式アカウントの運用を終了する: 'LINE公式アカウントの運用を終了する',
    その他: 'その他',
  },
};

const SurveyForm: React.FC = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ChurnSurveyResponse>();
  const [reason, reasonDetail] = useWatch({
    name: ['reason', 'reasonDetail'],
    control,
  });
  return (
    <VStack
      w={'full'}
      p={'16px'}
      bg={'white'}
      borderRadius={8}
      borderWidth={'1px'}
      borderColor={'gray.200'}
      alignItems={'flex-start'}
    >
      <Text fontSize={'xl'} fontWeight={'bold'}>
        アンケートにご協力ください
      </Text>
      <FormControl isRequired isInvalid={!!errors.reason?.message}>
        <Box bgColor={'white'} padding={'8px'}>
          <FormLabel>退会理由を選択してください。</FormLabel>
          <Select
            {...register('reason', {
              required: '選択してください',
            })}
          >
            <option></option>
            {Object.values(questions.reason).map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
        </Box>
      </FormControl>
      {reason === questions.reason['他の予約受付方法を検討する'] && (
        <>
          <FormControl isRequired isInvalid={!!errors.reasonDetail?.message}>
            <Box bgColor={'white'} padding={'8px'}>
              <FormLabel>詳しい理由をお教えください。</FormLabel>
              <Select
                {...register('reasonDetail', {
                  required: '選択してください',
                })}
              >
                <option></option>
                {Object.values(questions.reasonDetail).map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.reasonDetail?.message}
              </FormErrorMessage>
            </Box>
          </FormControl>
          {reasonDetail === questions.reasonDetail['他のサービス'] && (
            <FormControl isInvalid={!!errors.reasonDetailDescription?.message}>
              <Box bgColor={'white'} padding={'8px'}>
                <FormLabel>
                  差し支えなければサービス/ツール名を教えて下さい。
                </FormLabel>
                <Input {...register('reasonDetailDescription')} />
              </Box>
            </FormControl>
          )}
        </>
      )}
      <FormControl isRequired isInvalid={!!errors.reasonDescription?.message}>
        <Box bgColor={'white'} padding={'8px'}>
          <FormLabel>簡単に理由をお聞かせください。</FormLabel>
          <Textarea
            {...register('reasonDescription', { required: '記入してください' })}
          />
          <FormErrorMessage>
            {errors.reasonDescription?.message}
          </FormErrorMessage>
        </Box>
      </FormControl>
      <FormControl isInvalid={!!errors.comment?.message}>
        <Box bgColor={'white'} padding={'8px'}>
          <FormLabel>ご意見・ご要望などご自由にお書きください。</FormLabel>
          <Textarea {...register('comment')} />
          <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
        </Box>
      </FormControl>
    </VStack>
  );
};
