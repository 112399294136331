import {
  Box,
  Button,
  HStack,
  Icon,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../context/providerAccount';
import { useLocationFrom } from '../../hooks/locationState';
import { useIsPC } from '../../hooks/useIsPC';
import { ResourceProp, resourcePath } from '../../hooks/useUrlPath';
import { Path } from '../../routers/Path';
import { useConsoleSidebarContext } from './ConsoleSidebar';
import { Link } from './Link';
import { PageTitle } from './PageTitle';

export const Layout: React.FC<{
  hasBackButton?: boolean | Path | ResourceProp | (() => void);
  pageTitle: React.ReactNode | string | undefined;
  headerAction?: React.ReactNode;
  header?: React.ReactNode;
  children: React.ReactNode;
}> = ({ hasBackButton, pageTitle, header, headerAction, children }) => {
  const { isOpen: isSidebarOpen } = useConsoleSidebarContext();
  return (
    <Box w={'full'}>
      {header}
      <VStack
        // width={{ base: '342px', lg: '891px' }}
        // w={isSidebarOpen ? 'calc(100% - 64px)' : 'calc(100% - 64px)'}
        w={'full'}
        // h={'full'}
        paddingX={{ base: 0, md: '16px' }}
        paddingTop={{ base: 0, md: '16px' }}
        paddingBottom={'64px'}
        // alignSelf={{ base: 'center', lg: 'normal' }}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={0}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          // p={'16px'}
          alignItems={{ base: 'center', md: 'flex-start' }}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          spacing={{ base: '16px', md: '16px' }}
          w={'full'}
          pt={'16px'}
          px={'16px'}
        >
          {hasBackButton && (
            <Box>
              <BackButtonInHeader
                resource={hasBackButton === true ? undefined : hasBackButton}
              />
            </Box>
          )}
          <HStack p={0} m={0} w={'full'} justifyContent={'space-between'}>
            {typeof pageTitle === 'string' ? (
              <PageTitle>{pageTitle}</PageTitle>
            ) : (
              pageTitle
            )}
            {headerAction}
          </HStack>
        </Stack>
        <React.Suspense
          fallback={
            <HStack>
              <Spinner />
              loading...
            </HStack>
          }
        >
          <Box w={'full'} p={'16px'}>
            {children}
            <NavigationList />
          </Box>
        </React.Suspense>
      </VStack>
    </Box>
  );
};

export function LayoutBodyWithQuery<T>({
  query,
  children,
}: {
  query: UseQueryResult<T>;
  children: (t: T) => React.ReactNode;
}) {
  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
      {query.isLoading && <Spinner />}
      {query.isError && <Text>エラーが発生しました {String(query.error)}</Text>}
      {query.data && children(query.data)}
    </VStack>
  );
}

const NavigationItem: React.FC<{
  to: string;
  label: string;
  target?: React.HTMLAttributeAnchorTarget;
}> = ({ to, label, target }) => {
  return (
    <Link to={to} target={target} w={'full'} px={'20px'} py={'16px'}>
      <HStack w={'full'} justifyContent={'space-between'} spacing={'16px'}>
        <Text color={'gray.800'}>{label}</Text>
        <Icon color={'gray.500'} as={MdKeyboardArrowRight} boxSize={'24px'} />
      </HStack>
    </Link>
  );
};
const NavigationList: React.FC = () => {
  const isPC = useIsPC();
  const { providerAccount } = useAuthState();
  const prefix = React.useMemo(
    () => `/${providerAccount?.id}/${providerAccount?.botId}`,
    [providerAccount]
  );
  const inquiryFormUrl = React.useMemo(() => {
    return `https://docs.google.com/forms/d/e/1FAIpQLSeUYrhdLoGeQWYBw-vDqEcsNRKuL25LghcX12EG-rouMYOHWg/viewform?usp=pp_url&entry.101049160=${providerAccount?.id}`;
  }, [providerAccount]);
  if (isPC) {
    return null;
  }

  return (
    <VStack
      w={'full'}
      spacing={0}
      mt={'96px'}
      borderTop={'1px solid gray'}
      divider={<StackDivider borderColor={'gray.200'} />}
    >
      <NavigationItem to={`/`} label={'ホーム'} />
      <NavigationItem to={`${prefix}/bulkSpots`} label={'予約枠の一括登録'} />
      <NavigationItem to={`${prefix}/spot`} label={'予約枠を管理'} />
      <NavigationItem to={`${prefix}/booking`} label={'予約の管理'} />
      ,
      <NavigationItem to={`${prefix}/lineUser`} label={'ユーザー一覧'} />
      <NavigationItem to={`${prefix}/bookingMenu`} label={'予約メニュー'} />
      <NavigationItem to={`${prefix}/bookingForm`} label={'質問フォーム'} />
      <NavigationItem to="/configuration" label="各種設定" />
      <NavigationItem
        to="https://help.pochico.app/"
        label="ヘルプ"
        target={'_blank'}
      />
      <NavigationItem
        to={inquiryFormUrl}
        label="お問い合わせ"
        target={'_blank'}
      />
    </VStack>
  );
};

const BackButtonInHeader: React.FC<{
  resource: Path | ResourceProp | (() => void) | undefined;
}> = ({ resource }) => {
  const locationFrom = useLocationFrom({
    fallbackPath: undefined,
  });
  const navigate = useNavigate();
  const to = React.useMemo(() => {
    if (locationFrom) {
      return locationFrom.path;
    }
    if (typeof resource === 'function') {
      return undefined;
    }
    if (typeof resource === 'string') {
      return resource;
    }
    if (resource) {
      return resourcePath(resource);
    }
    return undefined;
  }, [locationFrom, resource]);
  if (typeof resource === 'function') {
    return (
      <Button onClick={resource}>
        <MdOutlineKeyboardArrowLeft />
      </Button>
    );
  }
  return to ? (
    <Link to={to}>
      <Button>
        <MdOutlineKeyboardArrowLeft />
      </Button>
    </Link>
  ) : (
    <Button onClick={() => navigate(-1)}>
      <MdOutlineKeyboardArrowLeft />
    </Button>
  );
};
