import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Select,
  SimpleGrid,
  Spinner,
  StyleProps,
  SystemProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount, range } from '@pochico/shared';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { atom, useAtom } from 'jotai';
import React from 'react';
import {
  MdClose,
  MdOutlineCircle,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { DisplayBooking, DisplaySpot } from '../../../firebase/types';
import { useFetchBookings } from '../../../hooks/booking';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useFetchSpots } from '../../../hooks/spots';
import { useIsPC } from '../../../hooks/useIsPC';
import { TextEllipsis } from '../../ui/TextEllipsis';
import { useSpotCellDialog } from './useSpotCellDialog';
dayjs.locale('ja');

const selectedBookingMenuIdAtom = atom<BookingMenu['id'] | undefined>(
  undefined
);

export const SpotCalendar: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const { cellSize } = useCalendarCellStyle();
  const [selectedBookingMenuId, setSelectedBookingMenuId] = useAtom(
    selectedBookingMenuIdAtom
  );
  const bookingMenusQuery = useFetchBookingMenus({ providerAccount });
  const bookingMenus = React.useMemo(() => {
    return (bookingMenusQuery.data || []).filter((b) => b.status === 'active');
  }, [bookingMenusQuery.data]);

  const bookingMenu = React.useMemo(
    () =>
      selectedBookingMenuId
        ? bookingMenus.find((b) => b.id === selectedBookingMenuId)
        : undefined,
    [bookingMenus, selectedBookingMenuId]
  );

  React.useEffect(() => {
    if (bookingMenus && bookingMenus.length > 0 && !selectedBookingMenuId) {
      setSelectedBookingMenuId(bookingMenus[0].id);
    }
  }, [bookingMenus, selectedBookingMenuId, setSelectedBookingMenuId]);

  if (bookingMenusQuery.isLoading) {
    return (
      <HStack>
        <Spinner />
        <Text>Loading...</Text>
      </HStack>
    );
  }

  return (
    <VStack
      // minW={`${cellSize.width * 8}px`}
      w={'full'}
      borderColor={'gray.200'}
      borderWidth={{ base: 0, md: '1px' }}
      borderRadius={4}
      p={{ base: 0, md: '16px' }}
      spacing={4}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <VStack
        w={'fit-content'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={'20px'}
      >
        <BookingMenuList
          providerAccount={providerAccount}
          bookingMenus={bookingMenus}
        />

        {bookingMenu ? (
          <Calendar
            providerAccount={providerAccount}
            selectedBookingMenu={bookingMenu}
            bookingMenuList={bookingMenus}
          />
        ) : bookingMenus.length > 0 ? (
          <Text>予約メニューを選択してください</Text>
        ) : (
          <Text>公開中の予約メニューがありません</Text>
        )}
      </VStack>
    </VStack>
  );
};

const BookingMenuList: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const [selectedBookingMenuId, setSelectedBookingMenuId] = useAtom(
    selectedBookingMenuIdAtom
  );
  const isPC = useIsPC();
  return (
    <Flex
      w={'full'}
      maxW={isPC ? '50vw' : 'full'}
      justifyContent={'flex-start'}
    >
      {isPC ? (
        <SimpleGrid
          columns={5}
          w={'full'}
          flexWrap={'wrap'}
          gap={'16px'}
          spacing={0}
        >
          {bookingMenus.map((menu) => {
            return (
              <Button
                key={`booking-menu-button-${menu.id}`}
                size={'md'}
                onClick={() => setSelectedBookingMenuId(menu.id)}
                fontSize={'xs'}
                variant={
                  menu.id === selectedBookingMenuId ? 'blue-fill' : 'white-blue'
                }
              >
                <TextEllipsis w={'full'} whiteSpace={'pre-wrap'} line={2}>
                  {menu.name}
                </TextEllipsis>
              </Button>
            );
          })}
        </SimpleGrid>
      ) : (
        <Select
          value={selectedBookingMenuId}
          onChange={(e) => setSelectedBookingMenuId(e.target.value)}
        >
          {bookingMenus.map((menu) => {
            return (
              <option key={`booking-menu-option-${menu.id}`} value={menu.id}>
                {menu.name}
              </option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
};

const Calendar: React.FC<{
  providerAccount: ProviderAccount;
  selectedBookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, selectedBookingMenu, bookingMenuList }) => {
  const [weekOffset, setWeekOffset] = React.useState(0);
  const today = React.useMemo(() => dayjs(), []);
  const duration = React.useMemo(() => {
    const start = today.add(weekOffset, 'week');
    const end = today.add(weekOffset + 1, 'week');
    return { start, end };
  }, [today, weekOffset]);

  const spotsQuery = useFetchSpots({
    providerAccount,
    filter: {
      bookingMenuId: selectedBookingMenu.id,
      displayDate: {
        start: duration.start.format('YYYY-MM-DD'),
        end: duration.end.format('YYYY-MM-DD'),
      },
    },
    page: 1,
    perPage: 1000,
    direction: 'asc',
  });

  const bookingsQuery = useFetchBookings({
    providerAccount,
    filter: {
      bookingMenuId: selectedBookingMenu.id,
      displayDate: {
        start: duration.start.format('YYYY-MM-DD'),
        end: duration.end.format('YYYY-MM-DD'),
      },
    },
    page: 1,
    perPage: 1000,
    sort: {
      field: 'dateTimeForSort',
      direction: 'asc',
    },
  });
  const isLoading = spotsQuery.isLoading || bookingsQuery.isLoading;
  const spotMap = React.useMemo(() => {
    const spots = spotsQuery.data || [];
    const bookings = bookingsQuery.data || [];
    return spots.reduce<SpotMap>((acc, spot) => {
      const date = spot.date;
      const hour = spot.startTime.split(':')[0];
      if (!hour) {
        return acc;
      }
      if (!acc[date]) {
        acc[date] = {};
      }
      if (!acc[date][hour]) {
        acc[date][hour] = { spots: [], bookings: [] };
      }
      acc[date][hour].spots.push(spot);
      const _bookings = bookings.filter((b) => b.spotId === spot.id);
      acc[date][hour].bookings.push(..._bookings);
      return acc;
    }, {} as SpotMap);
  }, [bookingsQuery.data, spotsQuery.data]);

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'center'}>
      <HStack w={'full'} justifyContent={'flex-start'} alignItems={'center'}>
        <HStack w={'fit-content'} alignItems={'center'} spacing={'6px'}>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            onClick={() => setWeekOffset((prev) => prev - 1)}
            px={0}
          >
            <MdOutlineKeyboardArrowLeft size={'24px'} />
          </Button>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            px={0}
            onClick={() => setWeekOffset((prev) => prev + 1)}
          >
            <MdOutlineKeyboardArrowRight size={'24px'} />
          </Button>
        </HStack>
        <Text fontWeight={'bold'} fontSize={'lg'} textAlign={'center'}>
          {duration.start.format('YYYY年M月')}
        </Text>
      </HStack>
      {isLoading ? (
        <Spinner />
      ) : (
        <SpotsCalendarBody
          providerAccount={providerAccount}
          bookingMenu={selectedBookingMenu}
          bookingMenuList={bookingMenuList}
          spotMap={spotMap}
          duration={duration}
        />
      )}
    </VStack>
  );
};

export type SpotCellData = {
  spots: DisplaySpot[];
  bookings: DisplayBooking[];
};
type SpotMap = {
  [date: string]: {
    [hour: string]: SpotCellData;
  };
};
const scrollbarWidth = '16px';
const calendarCellSize = {
  browser: {
    width: 128,
    height: 45,
  },
  mobile: {
    width: 48,
    height: 32,
  },
};
const calendarRowHeaderWidth = {
  browser: '64px',
  mobile: '52px',
};
const useCalendarCellStyle = (): {
  calendarCellStyle: StyleProps;
  cellSize: { width: number; height: number };
} => {
  const isPC = useIsPC();
  const key = isPC ? 'browser' : 'mobile';

  const cellSize = calendarCellSize[key];
  const calendarCellStyle: SystemProps = {
    width: `${cellSize.width}px`,
    height: `${cellSize.height}px`,
    fontSize: isPC ? 'sm' : 'xs',
    p: 0,
    borderRadius: 0,
    borderWidth: '1px',
    borderColor: 'gray.200',
    boxSizing: 'border-box',
  };
  return {
    calendarCellStyle,
    cellSize,
  };
};

const CalendarRowHeader: React.FC = () => {
  const { calendarCellStyle } = useCalendarCellStyle();
  const isPC = useIsPC();
  return (
    <VStack
      w={calendarRowHeaderWidth[isPC ? 'browser' : 'mobile']}
      spacing={0}
      fontSize={calendarCellStyle.fontSize}
      paddingBottom={'0.4em'}
    >
      {range(0, 24).map((hour) => {
        return (
          <Box
            key={`calendar-column-header-${hour}`}
            h={calendarCellStyle.height}
            w={calendarCellStyle.width}
            textAlign={'center'}
            marginTop={'-0.4em'}
          >
            {hour}:00
          </Box>
        );
      })}
    </VStack>
  );
};

const SpotsCalendarBody: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
  spotMap: SpotMap;
  duration: { start: dayjs.Dayjs; end: dayjs.Dayjs };
}> = ({ providerAccount, bookingMenu, bookingMenuList, spotMap, duration }) => {
  const { calendarCellStyle, cellSize } = useCalendarCellStyle();
  const onClickCell = useSpotCellDialog({
    providerAccount,
    bookingMenu,
    bookingMenuList,
  });
  const ref = React.useRef<HTMLDivElement>(null);
  const days = React.useMemo(() => {
    const days: dayjs.Dayjs[] = [];
    let current = duration.start;
    while (current.isBefore(duration.end)) {
      days.push(current);
      current = current.add(1, 'day');
    }
    return days;
  }, [duration]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.scrollTo({
      top: (dayjs().hour() - 1) * cellSize.height,
    });
  }, [cellSize.height]);
  const todayString = React.useMemo(() => dayjs().format('MM/DD'), []);
  const isPC = useIsPC();

  return (
    <VStack
      w={'full'}
      spacing={0}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
    >
      <HStack w={'full'} spacing={0}>
        <Box w={calendarRowHeaderWidth[isPC ? 'browser' : 'mobile']} />
        {days.map((day, i) => {
          const dateString = day.format('MM/DD');
          const dayString = day.format('ddd');
          return (
            <Cell
              key={`calendar-header-${i}`}
              w={calendarCellStyle.width}
              bgColor={dateString === todayString ? 'blue.100' : undefined}
            >
              <VStack alignItems={'center'} spacing={0}>
                <Text>{dateString}</Text>
                <Text
                  color={
                    dayString === '土'
                      ? 'blue.400'
                      : dayString === '日'
                      ? 'red.400'
                      : 'black'
                  }
                >
                  {dayString}
                </Text>
              </VStack>
            </Cell>
          );
        })}
        <Flex flexGrow={1} />
        {/* {isPC && <Box w={scrollbarWidth} />} */}
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        spacing={0}
        h={`${cellSize.height * 12}px`}
        ref={ref}
        overflowY={'auto'}
        // boxSizing={'content-box'}
        // border={'1px solid red'}
        sx={{
          scrollbarColor: 'gray white',
          scrollbarWidth: scrollbarWidth,
          '&::-webkit-scrollbar': {
            width: scrollbarWidth,
          },
        }}
      >
        <CalendarRowHeader />

        {days.map((day, i) => {
          const key = `calendar-day-${i}`;
          return (
            <VStack
              key={key}
              w={calendarCellStyle.width}
              spacing={0}
              // flexGrow={1}
              borderWidth={'1px'}
              borderColor={'gray.100'}
              // boxSizing={'content-box'}
            >
              {range(0, 24).map((hour) => {
                const data: SpotCellData | undefined =
                  spotMap[day.format('YYYY-MM-DD')]?.[
                    String(hour).padStart(2, '0')
                  ];
                const cellKey = `calendar-cell-${i}-${hour}`;
                const spots = data?.spots || [];
                const available = spots.some(
                  (s) => s.bookingIds.length !== s.maxBookings
                );
                return (
                  <Cell w={'full'} key={cellKey}>
                    <Button
                      {...calendarCellStyle}
                      // w={calendarCellStyle.width}
                      flexGrow={1}
                      // w={'full'}
                      marginX={'-2px'}
                      variant={
                        spots.length > 0
                          ? available
                            ? 'blue-fill'
                            : 'gray-fill'
                          : 'white-selectable'
                      }
                      p={0}
                      borderRadius={0}
                      onClick={() => {
                        onClickCell({ date: day, hour, data });
                      }}
                    >
                      {spots.length > 0 &&
                        (available ? (
                          <MdOutlineCircle size={'20px'} />
                        ) : (
                          <MdClose size={'20px'} />
                        ))}
                    </Button>
                  </Cell>
                );
              })}
            </VStack>
          );
        })}
      </HStack>
    </VStack>
  );
};

const Cell: React.FC<BoxProps> = ({ children, ...rest }) => {
  const { calendarCellStyle } = useCalendarCellStyle();
  return (
    <Flex
      justifyContent={'center'}
      boxSizing={'border-box'}
      alignItems={'center'}
      // flexGrow={1}
      {...calendarCellStyle}
      {...rest}
    >
      {children}
    </Flex>
  );
};
