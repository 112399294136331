import React from 'react';
import {
  OpenConfirmationDialogParams,
  useConfirmationDialog,
} from '../components/ui/ConfirmationDialog';

// 面倒なのでcontextにdispatcherを持たせている
// 一括削除や更新が必要なリストのページでcontextを使う
type BulkActionContextType = {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  bulkAction: (f: (ids: string[]) => OpenConfirmationDialogParams) => void;
  clearSelectedIds: () => void;
};

const stub = () => {
  throw new Error();
};
const BulkActionContext = React.createContext<BulkActionContextType>({
  selectedIds: [],

  setSelectedIds: stub,
  bulkAction: stub,
  clearSelectedIds: stub,
});
export const useBulkActionContext = () => React.useContext(BulkActionContext);

export const BulkActionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const clearSelectedIds = React.useCallback(() => {
    setSelectedIds([]);
  }, []);
  const { openConfirmationDialog } = useConfirmationDialog();
  const bulkAction = React.useCallback(
    (f: (ids: string[]) => OpenConfirmationDialogParams) => {
      if (selectedIds.length === 0) {
        return;
      }
      const params = f(selectedIds);
      console.log(`[bulkAction.action]`, { ids: selectedIds });

      openConfirmationDialog(params);
    },
    [selectedIds, openConfirmationDialog]
  );

  return (
    <BulkActionContext.Provider
      value={{
        selectedIds,
        setSelectedIds,
        clearSelectedIds,
        bulkAction,
      }}
    >
      {children}
    </BulkActionContext.Provider>
  );
};
