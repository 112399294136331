import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { useIsPC } from '../../../../hooks/useIsPC';
import { BookingMenuListPC } from './BookingMenuListPC';
import { BookingMenuListSP } from './BookingMenuListSP';

export const BookingMenuList: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const isPC = useIsPC();
  if (isPC) {
    return (
      <BookingMenuListPC
        providerAccount={providerAccount}
        bookingMenus={bookingMenus}
      />
    );
  } else {
    return (
      <BookingMenuListSP
        providerAccount={providerAccount}
        bookingMenus={bookingMenus}
      />
    );
  }
};
