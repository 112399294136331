import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {},
  control: {
    borderWidth: '1px',
    borderColor: 'gray.300',
    bgColor: 'white',
  },
});

export const Checkbox = defineMultiStyleConfig({ baseStyle });
