import {
  PredefinedSharedBookingFormElement,
  ProviderAccount,
  SharedBookingFormElement,
} from '@pochico/shared';
import {
  QueryDocumentSnapshot,
  collection,
  doc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';

const sharedBookingFormElementRef = (providerAccountId: string) =>
  collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerAccountId,
    CONSTANTS.COLLECTION.SUB_SHARED_BOOKING_FORMS
  ).withConverter({
    toFirestore: (doc: SharedBookingFormElement) => {
      return {
        ...doc,
      };
    },
    fromFirestore: (
      snap: QueryDocumentSnapshot<SharedBookingFormElement>
    ): SharedBookingFormElement => {
      const doc = snap.data();
      return doc;
    },
  });

export const fetchSharedBookingFormElements = async (
  providerAccountId: ProviderAccount['id']
): Promise<SharedBookingFormElement[]> => {
  return getDocs(sharedBookingFormElementRef(providerAccountId)).then(
    (snap) => {
      return snap.docs
        .map((doc) => doc.data())
        .sort((a, b) => a.order - b.order);
    }
  );
};

export type SharedBookingFormElementUpsertParams =
  PredefinedSharedBookingFormElement & { enabled: boolean } & (
      | {
          createTime: Date; // not-nullならすでに設定済み
          updateTime: Date; // not-nullならすでに設定済み
        }
      | {
          enabled: boolean; // 未設定から有効/向こうを切り替える場合
          createTime: null; // nullなら未設定
          updateTime: null; // nullなら未設定
        }
    );

export const upsertSharedBookingFormElement = async (
  providerAccount: ProviderAccount,
  param: SharedBookingFormElementUpsertParams
): Promise<SharedBookingFormElement> => {
  const now = new Date();
  const sharedBookingFormElement: SharedBookingFormElement = {
    ...param,
    enabled: param.enabled ?? false, // defaultはfalse
    createTime: now,
    updateTime: param.updateTime ?? now,
  };
  return setDoc(
    doc(
      sharedBookingFormElementRef(providerAccount.id),
      sharedBookingFormElement.id
    ),
    sharedBookingFormElement,
    { merge: true } // upsert
  ).then(() => sharedBookingFormElement);
};
