import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  BookingMenu,
  dateTimeStringWithWeekDay,
  LineUser,
  ProviderAccount,
  waitAtLeast,
} from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  BookingUpdateParams,
  DisplayBooking,
  DisplaySpot,
} from '../../../firebase/types';
import {
  useDeleteBookings,
  useFetchBooking,
  useUpdateBooking,
} from '../../../hooks/booking';
import { useFetchLineUser } from '../../../hooks/lineUser';
import {
  createLocationFrom,
  useLocationFrom,
} from '../../../hooks/locationState';
import { useFetchSpot } from '../../../hooks/spots';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookedUserBadge } from '../../ui/BookingUser';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { Layout } from '../../ui/Layout';
import { Loading } from '../../ui/Loading';

export const BookingEdit: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const bookingQuery = useFetchBooking({ providerAccount, id });
  const spotQuery = useFetchSpot({
    providerAccount,
    id: bookingQuery.data?.spotId,
  });
  const lineUserQuery = useFetchLineUser({
    providerAccount,
    id: bookingQuery.data?.lineUserId,
  });
  if (
    bookingQuery.isLoading ||
    spotQuery.isLoading ||
    lineUserQuery.isLoading
  ) {
    return (
      <Layout pageTitle={'予約の編集'} hasBackButton>
        <Loading />
      </Layout>
    );
  }

  if (bookingQuery.data && !spotQuery.isLoading && !lineUserQuery.isLoading) {
    return (
      <BookingEditInner
        providerAccount={providerAccount}
        booking={bookingQuery.data}
        spot={spotQuery.data}
        lineUser={lineUserQuery.data}
      />
    );
  } else {
    return (
      <Layout pageTitle={'予約の編集'} hasBackButton>
        <Text>データが見つかりません</Text>
      </Layout>
    );
  }
};

const BookingEditInner: React.FC<{
  providerAccount: ProviderAccount;
  booking: DisplayBooking;
  spot: DisplaySpot | undefined; // 削除されている可能性がある
  lineUser: LineUser | undefined; // 名前を直接入力された可能性がある
}> = ({ providerAccount, booking, spot, lineUser }) => {
  const navigate = useNavigate();
  const locationFrom = useLocationFrom({
    fallbackPath: undefined,
  });
  const toast = useToast();
  const { openConfirmationDialog } = useConfirmationDialog();
  const deleteMutation = useDeleteBookings(providerAccount);
  const form = useForm<BookingUpdateParams>({
    mode: 'onChange',
    defaultValues: booking,
  });
  const onDelete = React.useCallback(async () => {
    openConfirmationDialog({
      title: '予約を削除しますか？',
      body: (
        <Text>
          元に戻すことはできません。
          <br />
          また、ユーザーにも通知されません。
        </Text>
      ),
      cancelText: 'キャンセル',
      submitText: '削除する',
      onSubmit: async () => {
        return deleteMutation
          .mutateAsync([booking.id])
          .then(() => {
            toast({
              title: `予約を削除しました`,
              status: 'success',
            });
            return navigate(
              locationFrom?.path ||
                resourcePath({
                  providerAccount,
                  resourceName: 'booking',
                  action: 'list',
                }),
              { state: locationFrom }
            );
          })
          .catch((e) => {
            toast({
              title: `予約の削除に失敗しました error: ${e}`,
              status: 'error',
            });
          });
      },
    });
  }, [
    booking.id,
    deleteMutation,
    locationFrom,
    navigate,
    openConfirmationDialog,
    providerAccount,
    toast,
  ]);

  const updateMutation = useUpdateBooking({ providerAccount });
  const onSubmit = React.useCallback(
    async (updateInput: BookingUpdateParams) => {
      return waitAtLeast(updateMutation.mutateAsync(updateInput), 1000).then(
        () => {
          navigate(
            resourcePath({
              providerAccount,
              resourceName: 'booking',
              action: 'show',
              resourceId: booking.id,
            }),
            { state: locationFrom }
          );
        }
      );
    },
    [booking.id, locationFrom, navigate, providerAccount, updateMutation]
  );

  return (
    <Layout hasBackButton pageTitle="予約の編集">
      <FormProvider {...form}>
        <form style={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}>
          <VStack w={'full'} spacing={'16px'}>
            <VStack
              alignItems={'flex-start'}
              width={'full'}
              gap={0}
              backgroundColor={'white'}
            >
              <BookingEditForm
                providerAccount={providerAccount}
                booking={booking}
                lineUser={lineUser}
                bookingMenu={booking.bookingMenu}
              />
            </VStack>
            <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
              <Button
                colorScheme={'red'}
                size={'sm'}
                onClick={onDelete}
                loadingText={'削除中'}
                isLoading={deleteMutation.isPending}
                isDisabled={
                  deleteMutation.isPending || updateMutation.isPending
                }
              >
                削除する
              </Button>
              <Button
                type={'submit'}
                variant={'blue-fill'}
                size={'sm'}
                isLoading={updateMutation.isPending}
                isDisabled={
                  deleteMutation.isPending || updateMutation.isPending
                }
              >
                保存する
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  );
};

const BookingEditForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  booking: DisplayBooking;
  lineUser: LineUser | undefined;
}> = ({ providerAccount, bookingMenu, booking, lineUser }) => {
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          providerAccount,
          resourceName: 'booking',
          action: 'edit',
          resourceId: booking.id,
        })
      ),
    [booking.id, providerAccount]
  );
  const form = useFormContext<BookingUpdateParams>();
  return (
    <VStack
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      py={'16px'}
      borderRadius={'4px'}
      spacing={'16px'}
      whiteSpace={'nowrap'}
    >
      <VStack w={'full'} alignItems={'flex-start'} px={'16px'}>
        <Text fontSize={'xl'} color={'gray.800'}>
          {booking.displayDate} {booking.startTime}~
        </Text>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          {bookingMenu.name}
        </Text>
        <HStack
          fontSize={'xl'}
          fontWeight={'bold'}
          color={'gray.600'}
          spacing={'8px'}
          alignItems={'baseline'}
        >
          {booking.userName ? (
            <>
              <Text>予約者名：</Text>
              <FormControl>
                <Input type={'text'} {...form.register('userName')} />
              </FormControl>
            </>
          ) : (
            <>
              <Text>予約ユーザー名：</Text>
              <BookedUserBadge
                booking={booking}
                providerAccount={providerAccount}
                lineUser={lineUser}
                locationFrom={locationFrom}
                to={'lineUser'}
              />
            </>
          )}
        </HStack>
        <Text fontSize={'sm'} color={'gray.500'}>
          予約作成日時：{dateTimeStringWithWeekDay(dayjs(booking.createTime))}
        </Text>
        <Text fontSize={'sm'} color={'gray.500'}>
          最終更新：
          {dateTimeStringWithWeekDay(
            dayjs(booking.updateTime || booking.createTime)
          )}
        </Text>
      </VStack>
      <Divider borderColor={'gray.300'} />

      <FormControl>
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          px={'16px'}
        >
          <FormLabel>予約メモ</FormLabel>
          <Textarea
            minHeight={'8em'}
            {...form.register('providerMemo')}
            bg={'white'}
          />
        </VStack>
      </FormControl>
    </VStack>
  );
};
