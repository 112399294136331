import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingMenuList } from '../../components/features/bookingMenus/list/BookingMenuList';
import { Loading } from '../../components/ui/Loading';
import { useFetchBookingMenus } from '../../hooks/bookingMenus';

const BookingMenuListPage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  useSetDocumentTitle('すべての予約メニュー');
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });

  if (bookingMenuQuery.isLoading) {
    return <Loading />;
  }
  // ConsoleのResourceとして登録するためにChakraPageをここで指定している
  return (
    <BookingMenuList
      providerAccount={providerAccount}
      bookingMenus={bookingMenuQuery.data || []}
    />
  );
};

export default BookingMenuListPage;
