import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Spinner,
  StackDivider,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingForm, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useCreateBookingForm,
  useUpdateBookingForm,
} from '../../../hooks/bookingForms';
import { resourcePath } from '../../../hooks/useUrlPath';
import {
  BookingFormCreateParams,
  BookingFormUpdateParams,
} from '../../../providers/dataProvider/bookingForm';
import { BookingFormElementsEditor } from './BookingFormElementsEditor';
import { SharedBookingFormElementConfigurations } from './SharedBookingFormElementConfigurations';

export const BookingFormCreateOrEditForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingForm: BookingForm | undefined;
}> = ({ providerAccount, bookingForm }) => {
  const type: 'create' | 'edit' = bookingForm ? 'edit' : 'create';
  const form = useForm<BookingFormCreateParams>({
    reValidateMode: 'onChange',
    defaultValues: {
      enabledSharedElements: [],
      formElements: [],
      ...bookingForm,
    },
  });
  const [updatingField, setUpdatingField] = React.useState<
    string | undefined
  >();
  const {
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
    control,
  } = form;
  const [formElements, enabledSharedElements] = useWatch({
    control: control,
    name: ['formElements', 'enabledSharedElements'],
  });
  const [key, setKey] = React.useState(dayjs().unix());
  const toast = useToast();
  const createMutation = useCreateBookingForm(providerAccount);
  const updateMutation = useUpdateBookingForm(providerAccount);
  const mutationCallbacks = React.useMemo(() => {
    return {
      onSuccess: () => {
        toast.closeAll();
        setKey(dayjs().unix()); // 強制再レンダリング
        toast({
          title: '保存しました',
          status: 'success',
        });
      },
      onError: (e: Error) => {
        toast.closeAll();
        toast({
          title: `保存に失敗しました: ${e}`,
          status: 'error',
        });
      },
      onSettled: () => {
        setUpdatingField(undefined);
      },
    };
  }, [toast]);
  const onChangeEnabledSharedElements = React.useCallback(
    async (
      enabledSharedElements: BookingFormCreateParams['enabledSharedElements']
    ) => {
      if (type === 'create') {
        // 値として入力するだけ
        // 名前が入力されてblurされたら保存されてeditモードになる
        setValue(`enabledSharedElements`, enabledSharedElements);
      } else {
        if (bookingForm) {
          setValue(`enabledSharedElements`, enabledSharedElements);
          const params = {
            ...bookingForm,
            enabledSharedElements,
          };
          return updateMutation.mutateAsync(params, mutationCallbacks);
        } else {
          toast({
            title: '質問フォームが見つかりません',
            status: 'error',
          });
        }
      }
      // setKey(dayjs().unix()); // 強制再レンダリング
    },
    [bookingForm, mutationCallbacks, setValue, toast, type, updateMutation]
  );
  const onChangeFormElements = React.useCallback(async () => {
    const _bookingForm = getValues();
    const formElements = _bookingForm.formElements.map((element) => {
      if ('_id' in element) {
        // useFieldArrayのkeyNameが_idなので削除しておく
        const { _id: _, ...rest } = element;
        return rest;
      } else {
        return element;
      }
    });
    if (type === 'create') {
      // 値として入力するだけ
      // 名前が入力されてblurされたら保存されてeditモードになる
      setValue(`formElements`, formElements);
    } else {
      if (bookingForm) {
        return updateMutation.mutateAsync(
          {
            id: bookingForm.id,
            ..._bookingForm,
            formElements,
          },
          mutationCallbacks
        );
      } else {
        toast({
          title: '質問フォームが見つかりません',
          status: 'error',
        });
      }
    }
    // setKey(dayjs().unix()); // 強制再レンダリング
  }, [
    bookingForm,
    getValues,
    mutationCallbacks,
    setValue,
    toast,
    type,
    updateMutation,
  ]);
  const formIsInvalid = React.useMemo(() => {
    if (!isValid) {
      return true;
    }
    if (enabledSharedElements.length === 0 && formElements.length === 0) {
      return true;
    }
    if (formElements.some((e) => !e.title)) {
      return true;
    }
    return false;
  }, [enabledSharedElements, formElements, isValid]);
  const navigate = useNavigate();
  const onSubmit = React.useCallback(
    (param: BookingFormCreateParams | BookingFormUpdateParams) => {
      // if (formIsInvalid) {
      //   return;
      // }
      console.log('onSubmit', { bookingForm, param });
      if (type === 'edit' && 'id' in param) {
        return updateMutation.mutateAsync(param, mutationCallbacks);
      } else {
        return createMutation.mutateAsync(param, {
          ...mutationCallbacks,
          onSuccess: async (bookingForm) => {
            mutationCallbacks.onSuccess();
            navigate(
              resourcePath({
                action: 'edit',
                resourceName: 'bookingForm',
                resourceId: bookingForm.id,
                providerAccount,
              }),
              {
                replace: true,
                state: { bookingForm: JSON.stringify(bookingForm) },
              }
            );
          },
        });
      }
    },
    [
      bookingForm,
      createMutation,
      mutationCallbacks,
      navigate,
      providerAccount,
      type,
      updateMutation,
    ]
  );
  return (
    <Box w={'full'}>
      <FormProvider {...form}>
        {/* {JSON.stringify(form.watch())} */}
        {/* <form style={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}> */}
        <form style={{ width: '100%' }}>
          <VStack
            w={{ base: 'full', md: '600px' }}
            alignItems={'flex-start'}
            spacing={'40px'}
          >
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              spacing={'40px'}
              divider={<StackDivider borderColor="gray.200" />}
            >
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel fontWeight={'bold'}>フォームのタイトル</FormLabel>
                <FormHelperText>
                  フォームのタイトルは予約者には表示されません。管理上わかりやすい名前をつけてください
                </FormHelperText>
                <HStack pt={'8px'}>
                  <Input
                    {...register('name', {
                      required: '入力してください',
                      onBlur: async () => {
                        const ok = await form.trigger();
                        if (ok) {
                          setUpdatingField('name');
                          form.handleSubmit(onSubmit)();
                        }
                      },
                    })}
                    isDisabled={
                      createMutation.isPending || updateMutation.isPending
                    }
                  />
                  {updatingField === 'name' && <Spinner />}
                </HStack>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <SharedBookingFormElementConfigurations
                // key={`sharedBookingFormElement-${key}`} // 何か編集するたびにstateがリセットされてしまう
                providerAccount={providerAccount}
                bookingFormId={bookingForm?.id}
                onChange={onChangeEnabledSharedElements}
              />
              <BookingFormElementsEditor
                key={`bookingFormElements-${key}`}
                providerAccount={providerAccount}
                onChange={onChangeFormElements}
              />
            </VStack>
            {/* {type === 'create' && (
              <HStack w={'full'} justifyContent={'flex-end'}>
                <Button
                  type={'submit'}
                  w={{ base: 'full', md: '280px' }}
                  variant={'blue-fill'}
                  isDisabled={formIsInvalid}
                  isLoading={createMutation.isPending}
                >
                  作成
                </Button>
              </HStack>
            )} */}
          </VStack>
        </form>
      </FormProvider>
    </Box>
  );
};
