import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PaymentDateCalculator,
  dateTimeStringWithWeekDay,
} from '@pochico/shared';
import dayjs from 'dayjs';
import Spacer from '../components/fragments/Spacer';
import { Card } from '../components/ui/Card';
import Footer from '../components/ui/Footer';
import { Link } from '../components/ui/Link';
import { Loading } from '../components/ui/Loading';
import { PageTitle } from '../components/ui/PageTitle';
import { StripeLinkButton } from '../components/ui/StripeButton';
import { useAuthState } from '../context/providerAccount';
import { useCheckUnpaid } from '../hooks/useCheckUnpaid';
import { Path } from '../routers/Path';

const Unpaid: FC = () => {
  const navigate = useNavigate();
  const { initialized, providerAccount } = useAuthState();
  const { status, customerPortalUrl, loading } = useCheckUnpaid();

  React.useEffect(() => {
    if (loading || !initialized) {
      return;
    }

    if (!providerAccount) {
      // alert('ログインしてください');
      // navigate('/login');
      return;
    }
    if (providerAccount?.paymentStatus === 'registered' && status === 'ok') {
      navigate('/');
      return;
    }
    const calc = new PaymentDateCalculator(providerAccount, dayjs());
    if (calc.remainFreeDays > 0) {
      navigate('/');
      return;
    }
  }, [initialized, loading, navigate, providerAccount, status]);

  if (!initialized || !providerAccount || loading) {
    return <Loading />;
  }

  return (
    <>
      <Flex alignItems={'center'} direction={'column'} paddingX={'16px'}>
        <Spacer height={'40px'} />
        <PageTitle>かんたん予約アプリ『ポチコ』の利用継続について</PageTitle>
        <Spacer height={'60px'} />
        {status === 'ok' ? (
          // 支払い登録がされていないだけの場合
          <>
            <Box>
              お使いのアカウント ではポチコの支払い登録がされていません。
              <VStack alignItems={'flex-start'} my={'20px'} fontWeight={'bold'}>
                <Text>アカウント名: {providerAccount.displayName}</Text>
                <Text>
                  登録日時:{' '}
                  {dateTimeStringWithWeekDay(dayjs(providerAccount.createTime))}
                </Text>
              </VStack>
              利用を継続する場合は、以下より支払い登録フォームへ進んでください。
              {providerAccount.status === 'attached' && (
                <>
                  <br />
                  もう使用されない場合は、退会フォームへの記入をお願いいたします。
                </>
              )}
            </Box>
            <Spacer height={'20px'} />
            <Card p={4}>
              <Flex direction={'row'} alignItems={'center'}>
                <Box display={'inline-block'} width={'10em'}>
                  利用継続をご希望
                </Box>
                <Button
                  as={Link}
                  m={4}
                  colorScheme={'green'}
                  _hover={{
                    color: 'white',
                  }}
                  to={Path.paymentRegistration}
                >
                  {'支払い登録フォーム'}
                </Button>
              </Flex>
              {providerAccount.status === 'attached' && (
                <Flex direction={'row'} alignItems={'center'}>
                  <Box display={'inline-block'} width={'10em'}>
                    利用終了をご希望
                  </Box>
                  <Button as={Link} to={Path.churn} m={4} colorScheme={'red'}>
                    {'退会ページ'}
                  </Button>
                </Flex>
              )}
              <Flex direction={'row'} alignItems={'center'}>
                <Box display={'inline-block'} width={'10em'}>
                  ログアウト
                </Box>
                <Button
                  m={4}
                  colorScheme={'gray'}
                  onClick={() => {
                    navigate('/logout');
                  }}
                >
                  {'ログアウト'}
                </Button>
              </Flex>
            </Card>
          </>
        ) : (
          // 支払っていない請求がある場合
          <>
            <VStack alignItems={'flex-start'}>
              <Text>
                お使いのアカウント({providerAccount.displayName}
                )で、<strong>ポチコ利用料のお支払いが遅延しています。</strong>
              </Text>
              <VStack alignItems={'flex-start'}>
                <Text>【支払い方法】</Text>
                <Text>①請求ポータル内の下部「インボイスの履歴」を確認</Text>
                <Text>
                  ②「期日超過」となっている列の「
                  <Box display={'inline'}>
                    <StripeLinkButton />
                  </Box>
                  」をクリック
                </Text>
                <Text>③請求内容をご確認の上お支払いをお願いします。</Text>
              </VStack>
              <br />
              <Text>
                なお、「支払い方法」を登録しても、過去の請求についてお支払いは完了しませんのでご注意ください。
              </Text>
              {/* <br />
              お支払いが完了し、継続してポチコをご利用の場合は再度お支払い情報をご登録してください */}
            </VStack>
            <Spacer height={'20px'} />
            <Link to={customerPortalUrl || '#'} isExternal>
              <Button
                m={4}
                isLoading={!customerPortalUrl}
                colorScheme={'green'}
              >
                {'請求ポータル'}
              </Button>
            </Link>
            {/* <Card p={4}>
              <Flex direction={'row'} alignItems={'center'}>
                <Box display={'inline-block'} width={'15em'}>
                  請求を確認する
                </Box>
                <Link href={customerPortalUrl} isExternal>
                  <Button
                    m={4}
                    isLoading={!customerPortalUrl}
                    colorScheme={'green'}
                  >
                    {'請求ポータル'}
                  </Button>
                </Link>
              </Flex>
              <Flex direction={'row'} alignItems={'center'}>
                <Box display={'inline-block'} width={'15em'}>
                  継続してポチコをご利用の場合
                </Box>
                <Link href={Path.paymentRegistration}>
                  <Button m={4} colorScheme={'gray'}>
                    {'支払い登録フォーム'}
                  </Button>
                </Link>
              </Flex>
            </Card> */}
          </>
        )}
      </Flex>
      <Spacer height={'60px'} />
      <Footer />
    </>
  );
};
export default Unpaid;
