import {
  getRandomString,
  isNotNullOrUndefined,
  isNullOrUndefinedArgs,
  ProviderAccount,
  Spot,
  undefinedToNull,
} from '@pochico/shared';
import dayjs from 'dayjs';
import {
  collection,
  CollectionReference,
  doc,
  getDocs,
  Query,
  query,
  QueryDocumentSnapshot,
  runTransaction,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';

import CONSTANTS from '../../commons/constants';
import { SpotFilter } from '../../components/features/Spots';
import { db } from '../../firebase/firebaseInit';
import {
  DisplaySpot,
  FromFSSpot,
  SpotCreateParams,
  ToFSSpot,
} from '../../firebase/types';
import { getCount, getList } from './helper';
import { ListResult, Pagination, Sort } from './type';

export const getFullSpotsRef = (
  providerId: string
): CollectionReference<Spot> => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    CONSTANTS.COLLECTION.SUB_SPOTS
  ).withConverter({
    toFirestore(doc: Spot): ToFSSpot {
      return undefinedToNull({
        id: doc.id,
        botId: doc.botId,
        date: doc.date,
        startTime: doc.startTime,
        dateForSort: doc.dateForSort,
        dateTimeForSort: doc.dateTimeForSort,
        bookingIds: doc.bookingIds,
        bookingMenuId: doc.bookingMenuId,
        maxBookings: doc.maxBookings,
        status: doc.status,
        bulkSpotAddHistoryId: doc.bulkSpotAddHistoryId,
        createTime: doc.createTime,
        updateTime: doc.updateTime,
      });
    },
    fromFirestore(snap: QueryDocumentSnapshot<FromFSSpot>): Spot {
      const nullableDoc = snap.exists() ? snap.data() : undefined;
      if (
        !nullableDoc ||
        isNullOrUndefinedArgs(
          nullableDoc.botId,
          nullableDoc.createTime,
          nullableDoc.dateForSort,
          nullableDoc.dateTimeForSort,
          nullableDoc.date,
          nullableDoc.bookingIds,
          nullableDoc.maxBookings,
          nullableDoc.startTime,
          nullableDoc.bulkSpotAddHistoryId,
          nullableDoc.updateTime
        )
      ) {
        throw new Error(
          'invalid args from firestore spot: ' + JSON.stringify(nullableDoc)
        );
      }
      const createTime: Timestamp = nullableDoc.createTime as Timestamp;
      const updateTime: Timestamp = nullableDoc.updateTime as Timestamp;
      return {
        ...nullableDoc,
        createTime: createTime.toDate(),
        updateTime: updateTime.toDate(),
      } as Spot;
    },
  });
};

export const filteredSpotRef = (
  providerAccountId: string,
  filter?: SpotFilter
): Query<Spot> => {
  const today = dayjs();
  const todayForSort = Number(today.format('YYYYMMDDHHmm'));
  const wheres = [
    where('status', '==', 'active'),
    filter?.displayDate?.start
      ? where(
          'dateTimeForSort',
          '>=',
          Number(dayjs(filter.displayDate.start).format('YYYYMMDD0000'))
        )
      : where('dateTimeForSort', '>=', todayForSort),
    filter?.displayDate?.end
      ? where(
          'dateTimeForSort',
          '<=',
          Number(
            dayjs(filter.displayDate.end).endOf('day').format('YYYYMMDDHHmm')
          )
        )
      : undefined,
    filter?.bookingMenuId
      ? where('bookingMenuId', '==', filter.bookingMenuId)
      : undefined,

    filter?.bulkSpotAddHistoryId
      ? where('bulkSpotAddHistoryId', '==', filter.bulkSpotAddHistoryId)
      : undefined,
  ].filter(isNotNullOrUndefined);

  return query(getFullSpotsRef(providerAccountId), ...wheres);
};

// paginationのために件数取得に使う
export const getSpotCount = async (
  providerAccountId: string,
  filter?: SpotFilter
): Promise<number> => {
  return getCount(filteredSpotRef(providerAccountId, filter));
};

// filter/paginationを考慮してspotのリストを取得する
export const getSpotList = async (
  providerAccountId: string,
  options: {
    filter: SpotFilter;
    pagination: Pagination<DisplaySpot>;
  }
): Promise<ListResult<DisplaySpot>> => {
  const { filter, pagination } = options;
  const sort = (pagination?.sort as Sort<Spot>) || {
    field: 'dateTimeForSort',
    direction: 'asc',
  };

  return getList<Spot>(filteredSpotRef(providerAccountId, filter), {
    ...pagination,
    sort,
  }).then(({ data }) => {
    const spots = data.map(convertSpotToDisplaySpot);
    return {
      data: spots,
    };
  });
};

export const convertSpotToDisplaySpot = (spot: Spot): DisplaySpot => {
  return {
    ...spot,
    displayDate: dayjs(spot.date).locale('ja').format('YYYY/MM/DD(ddd)'),
    displayAvailability: `${spot.bookingIds.length}/${spot.maxBookings}`,
  };
};

export const createSpot = async (
  providerAccount: ProviderAccount,
  spotData: SpotCreateParams
) => {
  if (!spotData.maxBookings) {
    return Promise.reject(new Error('枠数が入力されていません'));
  }

  if (!spotData.date) {
    return Promise.reject(new Error('日時が指定されていません'));
  }

  if (
    typeof spotData.startTimeHour !== 'number' ||
    typeof spotData.startTimeMinute !== 'number'
  ) {
    return Promise.reject(new Error('予約時刻が指定されていません'));
  }

  if (typeof spotData.bookingMenuId !== 'string') {
    return Promise.reject(new Error('予約メニューが設定されていません'));
  }

  const now = new Date();
  const bookingDateTime = dayjs(spotData.date)
    .hour(spotData.startTimeHour)
    .minute(spotData.startTimeMinute);

  const writeData: Spot = {
    id: getRandomString(20),
    botId: providerAccount.botId,
    date: spotData.date,
    startTime: bookingDateTime.format('HH:mm'),
    bookingMenuId: spotData.bookingMenuId,
    maxBookings: spotData.maxBookings,
    status: 'active',
    bookingIds: [] as string[],
    dateForSort: Number(bookingDateTime.format('YYYYMMDD')),
    dateTimeForSort: Number(bookingDateTime.format('YYYYMMDDHHmm')), // YYYYMMDDHHmmの整数値
    createTime: now,
    updateTime: now,
  };
  const existingSpot = await getDocs(
    query(
      getFullSpotsRef(providerAccount.id),
      where('bookingMenuId', '==', writeData.bookingMenuId),
      where('dateTimeForSort', '==', writeData.dateTimeForSort),
      where('status', '==', writeData.status)
    )
  ).then((spotSnapshot) => {
    const spotDoc = spotSnapshot.docs[0];
    return spotDoc?.exists() ? spotDoc.data() : undefined;
  });
  if (existingSpot) {
    return Promise.reject(new Error('すでに作成済みの予約枠が存在します'));
  }

  if (Object.values(writeData).some((d) => typeof d === 'undefined')) {
    return Promise.reject(
      new Error(
        '何らかの問題が発生しました。心当たりのない場合はお問い合わせください。'
      )
    );
  }

  return setDoc(
    doc(getFullSpotsRef(providerAccount.id), writeData.id),
    writeData,
    { merge: false }
  ).then(() => {
    return { data: writeData as Spot };
  });
};

export const updateSpot = async (providerAccountId: string, spot: Spot) => {
  const documentId = spot.id.toString();
  const spotRef = getFullSpotsRef(providerAccountId);
  const updateData: Spot = undefinedToNull({
    id: spot.id,
    botId: spot.botId,
    date: spot.date,
    startTime: spot.startTime,
    dateForSort: spot.dateForSort,
    dateTimeForSort: spot.dateTimeForSort,
    bookingIds: spot.bookingIds,
    bookingMenuId: spot.bookingMenuId,
    maxBookings: spot.maxBookings,
    status: spot.status,
    bulkSpotAddHistoryId: spot.bulkSpotAddHistoryId,
    createTime: spot.createTime,
    updateTime: new Date(),
  });

  return runTransaction(db, (t) => {
    return t.get(doc(spotRef, documentId)).then(async (fetchedSpotDoc) => {
      const fetchedSpot = fetchedSpotDoc?.exists()
        ? fetchedSpotDoc.data()
        : undefined;
      if (typeof fetchedSpot === 'undefined') {
        return Promise.reject(new Error('予約枠が見つかりません'));
      }

      if (
        updateData.maxBookings &&
        updateData.maxBookings < fetchedSpot.bookingIds.length
      ) {
        return Promise.reject(
          new Error(
            '既に予約されている件数より少ない枠数を設定することはできません'
          )
        );
      }

      await t.update(fetchedSpotDoc.ref, updateData);
      return updateData;
    });
  });
};

export const deleteSpot = async (providerId: string, spotId: string) => {
  const spotRef = getFullSpotsRef(providerId);

  return runTransaction(db, async (t) => {
    return t.get(doc(spotRef, spotId)).then(async (spotDoc) => {
      const spotData: Spot | undefined = spotDoc?.exists()
        ? spotDoc.data()
        : undefined;
      if (typeof spotData === 'undefined')
        return Promise.reject(new Error('予約枠が存在しません'));

      if (spotData.bookingIds.length > 0) {
        return Promise.reject(new Error('すでに予約が入っています'));
      }

      t.delete(doc(spotRef, spotId));
      return { data: { ...spotData, id: spotId } };
    });
  });
};

// 複数のSpotを削除する
export const deleteSpots = async (providerId: string, spotIds: string[]) => {
  const spotRef = getFullSpotsRef(providerId);

  return runTransaction(db, async (t) => {
    const getSpotPromises = spotIds.map((spotId) => {
      return t.get(doc(spotRef, spotId));
    });
    const spotDocs = await Promise.all(getSpotPromises);
    const spotsIsValid = spotDocs.every((spotDoc) => {
      const spot = spotDoc.exists() ? spotDoc.data() : undefined;

      if (!spot) return false;
      if (spot.bookingIds.length > 0) {
        // 予約が入ってしまっているSpotは削除できないようにする
        return false;
      }

      return true;
    });

    if (!spotsIsValid) {
      // ダメなspotを1つ以上ふくむ場合、1つも削除させない
      return Promise.reject(new Error('削除できない予約枠が含まれています'));
    }

    const deleteSpotPromises = spotDocs.map((spotDoc) => {
      return t.delete(spotDoc.ref);
    });

    return Promise.all(deleteSpotPromises).then(() => {
      return {
        data: spotIds,
      };
    });
  });
};
