import { Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { MdKeyboardArrowUp } from 'react-icons/md';
import CONSTANTS from '../../../../commons/constants';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { scrollToTop } from '../../../../helpers/scrollToTop';
import { Path } from '../../../../routers/Path';
import { Layout } from '../../../ui/Layout';
import { Link } from '../../../ui/Link';
import { NavigationButton } from '../../../ui/NavigationButton';
import { BookingMenuDraftListView } from './BookingMenuDraftListView';
import { DefaultBookingMenuShow } from './DefaultBookingMenuShow';
import {
  OrderingPublishedBookingMenusViewSP,
  PublishedBookingMenusViewSP,
} from './PublishedBookingMenusViewSP';

export const BookingMenuListSP: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const publishedBookingMenus = React.useMemo(
    () => bookingMenus.filter((menu) => menu.status === 'active'),
    [bookingMenus]
  );
  const draftBookingMenus = React.useMemo(
    () => bookingMenus.filter((menu) => menu.status === 'suspended'),
    [bookingMenus]
  );
  const [isOrdering, setIsOrdering] = React.useState(false);

  if (!providerAccount.needBookingMenu) {
    const defaultMenu = bookingMenus.find((menu) => menu.isDefault);
    if (defaultMenu) {
      // デフォルトメニューの詳細のみを表示する
      return (
        <Layout pageTitle={'予約メニュー'} headerAction={null}>
          <VStack
            spacing={{ base: '24px', lg: '48px' }}
            alignItems={'flex-start'}
          >
            {/* <NeedBookingMenuSwitcher providerAccount={providerAccount} /> */}
            <DefaultBookingMenuShow
              providerAccount={providerAccount}
              bookingMenu={defaultMenu}
            />
          </VStack>
        </Layout>
      );
    }
  }
  if (isOrdering) {
    return (
      <OrderingPublishedBookingMenusViewSP
        providerAccount={providerAccount}
        onCancel={() => setIsOrdering(false)}
        bookingMenus={publishedBookingMenus}
      />
    );
  }

  return (
    <Layout
      pageTitle={
        <VStack w={'full'} alignItems={'flex-start'}>
          <Heading
            as={'h1'}
            size={{ base: 'lg', md: 'lg' }}
            whiteSpace={'nowrap'}
          >
            予約メニュー
          </Heading>
          <Text fontSize={'sm'} color={'gray.500'}>
            予約メニュー機能をオフにしたい場合は
            <Link
              to={Path.configuration}
              textDecoration={'underline'}
              px={'4px'}
            >
              各種設定
            </Link>
            へ
          </Text>
        </VStack>
      }
      headerAction={null}
    >
      <VStack
        // marginX={'auto'}
        alignItems={'flex-start'}
        spacing={'24px'}
      >
        {/* <NeedBookingMenuSwitcher providerAccount={providerAccount} /> */}
        <HStack w={'full'} justifyContent={'space-between'}>
          <HStack>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              公開中 {publishedBookingMenus.length}件
            </Text>
            <Text fontSize={'xs'}>
              / 最大{CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS}件
            </Text>
          </HStack>
          <Button
            variant={'soft-fill'}
            colorScheme={'blue'}
            onClick={() => setIsOrdering(!isOrdering)}
          >
            並び替え
          </Button>
        </HStack>
        <PublishedBookingMenusViewSP
          providerAccount={providerAccount}
          bookingMenus={publishedBookingMenus}
        />
        <VStack w={'full'} alignItems={'flex-start'}>
          <HStack>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              下書き中 {draftBookingMenus.length}件
            </Text>
            <Text fontSize={'xs'}>
              / 最大{CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}件
            </Text>
          </HStack>
          <NavigationButton
            variant={'blue-fill'}
            isDisabled={
              draftBookingMenus.length >=
              CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS
            }
            to={{
              resourceName: 'bookingMenu',
              action: 'create',
              providerAccount,
            }}
          >
            予約メニューを作成する
          </NavigationButton>
          <BookingMenuDraftListView
            providerAccount={providerAccount}
            bookingMenus={draftBookingMenus}
          />
        </VStack>
        <Button variant={'blue-fill'} w={'full'} onClick={scrollToTop}>
          <HStack w={'full'} justifyContent={'center'} spacing={0}>
            <Text>上部へ戻る</Text>
            <MdKeyboardArrowUp size={'24px'} />
          </HStack>
        </Button>
      </VStack>
    </Layout>
  );
};
