import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DisplayLineUser, LineUserUpdateParams } from '../../../firebase/types';
import {
  useFetchLineUser,
  useUpdateLineUserMutation,
} from '../../../hooks/lineUser';
import { resourcePath } from '../../../hooks/useUrlPath';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { HintTooltip } from '../../ui/HintTooltip';
import { Layout } from '../../ui/Layout';
import { Loading } from '../../ui/Loading';

export const LineUserEdit: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const lineUserQuery = useFetchLineUser({ providerAccount, id });
  if (lineUserQuery.isFetching) {
    return <Loading />;
  }

  if (lineUserQuery.data) {
    return (
      <LineUserEditInner
        providerAccount={providerAccount}
        lineUser={lineUserQuery.data}
      />
    );
  } else {
    return <>データが見つかりません</>;
  }
};

const LineUserEditInner: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: DisplayLineUser;
}> = ({ providerAccount, lineUser }) => {
  const navigate = useNavigate();
  const form = useForm<LineUserUpdateParams>({
    mode: 'onChange',
    defaultValues: lineUser,
  });
  const { openConfirmationDialog } = useConfirmationDialog();
  const toast = useToast();
  const _updateMutation = useUpdateLineUserMutation({ providerAccount });
  const archiveMutation = useMutation({
    mutationFn: async () => {
      const archived = !Boolean(lineUser.archived);
      return _updateMutation
        .mutateAsync({
          id: lineUser.id,
          archived,
        })
        .then(() => archived);
    },
    onSuccess: async (archived: boolean) => {
      toast({
        title: archived ? '削除しました' : '削除を取り消しました',
        status: 'success',
      });
      navigate(
        resourcePath({
          providerAccount,
          resourceName: 'lineUser',
          action: 'show',
          resourceId: lineUser.id,
        })
      );
    },
    onError: (e) => {
      toast({
        title: `削除に失敗しました ${e}`,
        status: 'error',
      });
    },
  });
  const onClickArchive = React.useCallback(async () => {
    const newArchived = !Boolean(lineUser.archived);
    if (newArchived) {
      return openConfirmationDialog({
        title: '削除しますか？',
        size: '2xl',
        body: (
          <VStack w={'full'} alignItems={'flex-start'}>
            <Text>
              削除すると、LINEユーザーの一覧ページ等で表示されなくなります。
            </Text>
            <Text>・すでに取られている予約はそのまま残ります。</Text>
            <Text>・削除されていても新しく予約を取ることは可能です。</Text>
            <Text>
              ・新規で予約を取れないようにするためにはブロック機能をお使いください。
            </Text>
          </VStack>
        ),
        onSubmit: async () => {
          return archiveMutation.mutateAsync();
        },
        submitText: '削除する',
        cancelText: 'キャンセル',
      });
    } else {
      return archiveMutation.mutateAsync();
    }
  }, [archiveMutation, lineUser.archived, openConfirmationDialog]);

  const updateMutation = useMutation({
    mutationFn: async (updateInput: LineUserUpdateParams) => {
      return _updateMutation.mutateAsync(updateInput);
    },
    onSuccess: async () => {
      toast({
        title: '保存しました',
        status: 'success',
      });
      navigate(
        resourcePath({
          providerAccount,
          resourceName: 'lineUser',
          action: 'show',
          resourceId: lineUser.id,
        })
      );
    },
    onError: (e) => {
      console.error(e);
    },
  });

  return (
    <Layout hasBackButton pageTitle="LINEユーザーの編集">
      <FormProvider {...form}>
        <form
          style={{ width: '100%' }}
          onSubmit={form.handleSubmit((input) =>
            updateMutation.mutateAsync(input)
          )}
        >
          <VStack
            alignItems={'flex-start'}
            width={'full'}
            gap={0}
            bgColor={'white'}
          >
            {lineUser.archived && (
              <Box
                bg={'red.600'}
                color={'white'}
                w={'full'}
                textAlign={'center'}
                fontWeight={'bold'}
                borderRadius={'4px'}
                py={'4px'}
              >
                このユーザーは削除されています
              </Box>
            )}
            <LineUserEditForm
              providerAccount={providerAccount}
              lineUser={lineUser}
            />
          </VStack>
          <HStack w={'full'} justifyContent={'flex-end'} pt={'16px'}>
            <Button
              variant={lineUser.archived === true ? 'white-red' : 'red-fill'}
              size={'sm'}
              type={'button'}
              isLoading={archiveMutation.isPending}
              onClick={onClickArchive}
            >
              {lineUser.archived === true ? '削除を取り消す' : '削除する'}
            </Button>
            <Button
              variant={'blue-fill'}
              size={'sm'}
              type={'submit'}
              isLoading={updateMutation.isPending}
              isDisabled={!form.formState.isDirty}
            >
              保存する
            </Button>
          </HStack>
        </form>
      </FormProvider>
    </Layout>
  );
};

const LineUserEditForm: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: DisplayLineUser;
}> = ({ providerAccount, lineUser }) => {
  const form = useFormContext<LineUserUpdateParams>();
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={8}
      bg={'white'}
      borderWidth={'1px'}
      borderColor={'gray.100'}
      padding={'12px'}
      alignItems={{ base: 'center', md: 'stretch' }}
      w={'full'}
    >
      <LineUserCard lineUser={lineUser} />
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        gap={'12px'}
        whiteSpace={'nowrap'}
      >
        <FormControl isDisabled={lineUser.archived}>
          <FormLabel w={'7em'}>ポチコ上の別名</FormLabel>
          <Input {...form.register('displayNameByProvider')} />
        </FormControl>
        <FormControl isDisabled={lineUser.archived}>
          <FormLabel w={'8em'}>ユーザーメモ</FormLabel>
          <Textarea
            minHeight={'8em'}
            placeholder={
              'ユーザーメモはありません\n顧客対応に関する情報や無断キャンセルの回数などを記録することができます'
            }
            {...form.register('providerMemo')}
            bg={'white'}
          />
        </FormControl>
      </VStack>
    </Stack>
  );
};

const LineUserCard: React.FC<{ lineUser: DisplayLineUser }> = ({
  lineUser,
}) => {
  const { control, setValue } = useFormContext<LineUserUpdateParams>();
  return (
    <VStack
      // w={'fit-content'}
      w={'326px'}
      minH={'200px'}
      bgColor={'gray.50'}
      alignItems={'center'}
      borderRadius={'4px'}
      spacing={'16px'}
      px={'20px'}
      py={'16px'}
    >
      <Image
        alignSelf={'center'}
        src={lineUser.pictureUrlLarge}
        boxSize={'126px'}
        borderRadius={'full'}
      />
      <Text
        textAlign={'center'}
        w={'full'}
        fontWeight={'bold'}
        fontSize={'lg'}
        color={'gray.800'}
      >
        {lineUser.displayName}
      </Text>
      <Divider w={'full'} />
      <HStack w={'full'} justifyContent={'space-between'} whiteSpace={'nowrap'}>
        <HStack spacing={0}>
          <Text w={'fit-content'}>ブロック</Text>
          <HintTooltip
            text={'このLINEユーザーからの予約を受け付けないように設定します'}
          />
        </HStack>
        <Controller
          control={control}
          name={'status'}
          render={({ field: { onChange, value, ...rest } }) => (
            <Switch
              {...rest}
              defaultChecked={lineUser.status === 'blocked'}
              isDisabled={lineUser.archived}
              onChange={(e) => {
                const status = e.target.checked ? 'blocked' : 'active';
                // onChange(!value);
                setValue('status', status, {
                  shouldDirty: true,
                });
              }}
              isChecked={value === 'blocked'}
              // onChange={(e) => {
              //   const status = e.target.checked ? 'blocked' : 'active';
              //   setValue('status', status);
              // }}
              colorScheme={'red'}
            />
          )}
        />
      </HStack>
    </VStack>
  );
};
