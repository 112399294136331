import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    p: '4px',
    borderRadius: 'md',
    borderColor: 'gray.300',
  },
});
export const Menu = defineMultiStyleConfig({ baseStyle });
