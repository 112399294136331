import { Box, HStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { BookingMenuForConsole } from '../../../../firebase/types';
import { LocationFrom } from '../../../../hooks/locationState';
import { resourcePath } from '../../../../hooks/useUrlPath';
import { Link } from '../../../ui/Link';
import { BookingMenuStatusToggleButton } from './BookingMenuStatusToggleButton';

export const BookingMenuActionButtons: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenuForConsole;
  locationFrom: LocationFrom;
}> = ({ providerAccount, bookingMenu, locationFrom }) => {
  return (
    <Box
      w={'full'}
      p={'12px'}
      onPointerDown={(e) => {
        // D&Dで移動されないようにしておく
        e.preventDefault();
      }}
      cursor={'default'}
    >
      <HStack
        spacing={0}
        w={'full'}
        fontSize={'sm'}
        gap={0}
        justifyContent={'space-between'}
      >
        <BookingMenuStatusToggleButton
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
        />
        <HStack p={0} spacing={'8px'} textDecoration={'underline'}>
          <Link
            to={resourcePath({
              resourceName: 'bookingMenu',
              action: 'show',
              providerAccount,
              resourceId: bookingMenu.id,
            })}
            locationFrom={locationFrom}
          >
            詳細
          </Link>
          <Link
            to={resourcePath({
              resourceName: 'bookingMenu',
              action: 'edit',
              providerAccount,
              resourceId: bookingMenu.id,
            })}
            locationFrom={locationFrom}
          >
            編集
          </Link>
        </HStack>
      </HStack>
    </Box>
  );
};
