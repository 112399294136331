import {
  Button,
  ButtonProps,
  HStack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteBookingMenu } from '../../../hooks/bookingMenus';
import { useIsPC } from '../../../hooks/useIsPC';
import { resourcePath } from '../../../hooks/useUrlPath';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { BookingMenuPreviewWithoutBackground } from './BookingMenuPreview';

export const DeleteBookingMenuButton: React.FC<
  {
    providerAccount: ProviderAccount;
    bookingMenu: BookingMenu;
  } & ButtonProps
> = ({ providerAccount, bookingMenu, ...props }) => {
  const deleteMutation = useDeleteBookingMenu(providerAccount);
  const { openConfirmationDialog } = useConfirmationDialog();
  const toast = useToast();
  const navigate = useNavigate();
  const onClickDelete = React.useCallback(async () => {
    if (bookingMenu.isDefault) {
      return;
    }
    openConfirmationDialog({
      title: '予約メニューを削除しますか？',
      body: (
        <VStack w={'full'} alignItems={'flex-start'}>
          <HStack
            w={'full'}
            // m={'8px'}
            bgColor={'gray.100'}
            p={'20px'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <BookingMenuPreviewWithoutBackground
              bookingMenu={bookingMenu}
              showImage={Boolean(bookingMenu.imageUrl)}
            />
          </HStack>
          <Text fontWeight={'bold'}>メニューを削除しますか？</Text>
          <Text>
            削除した予約メニューは元に戻すことはできません。
            <br />
            また、予約メニューを削除しても以下は削除されません
            <br />
            ・予約枠
            <br />
            ・すでに取られた予約
          </Text>
        </VStack>
      ),
      cancelText: 'キャンセル',
      submitText: '削除する',
      onSubmit: async () => {
        return deleteMutation
          .mutateAsync(bookingMenu.id)
          .then(() => {
            toast({
              title: `${bookingMenu.name} を削除しました`,
              status: 'success',
            });
            navigate(
              resourcePath({
                providerAccount,
                resourceName: 'bookingMenu',
                action: 'list',
              })
            );
          })
          .catch((e) => {
            toast({
              title: `${bookingMenu.name} の削除に失敗しました error: ${e}`,
              status: 'error',
            });
          });
      },
    });
  }, [
    bookingMenu,
    deleteMutation,
    navigate,
    openConfirmationDialog,
    providerAccount,
    toast,
  ]);
  const isPC = useIsPC();
  if (!providerAccount.needBookingMenu && bookingMenu.isDefault) {
    return null;
  }

  return bookingMenu.isDefault ? (
    <Tooltip
      pointerEvents={'fill'}
      label={
        '予約メニューをOFFにしているときの設定を保存するための予約メニューなので削除できません'
      }
    >
      <span>
        <Button isDisabled={true} onClick={onClickDelete}>
          削除する
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button
      {...props}
      colorScheme="red"
      w={isPC ? 'fit-content' : 'full'}
      isLoading={deleteMutation.isPending}
      onClick={onClickDelete}
    >
      削除する
    </Button>
  );
};
