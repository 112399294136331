import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BulkAddSpots } from '../../components/features/bulkAddSpots';
import { Loading } from '../../components/ui/Loading';
import { useFetchBookingMenus } from '../../hooks/bookingMenus';

const BulkAddSpotsPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const bookingMenusQuery = useFetchBookingMenus({ providerAccount });

  useSetDocumentTitle('予約枠の一括登録');
  // ConsoleのResourceとして登録するためにChakraPageをここで指定している

  if (bookingMenusQuery.isLoading) {
    return <Loading />;
  }

  return (
    <BulkAddSpots
      providerAccount={providerAccount}
      bookingMenuList={bookingMenusQuery.data || []}
    />
  );
};

export default BulkAddSpotsPage;
