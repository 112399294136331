import { StyleFunctionProps, defineStyleConfig } from '@chakra-ui/react';

export const linkStyle = defineStyleConfig({
  variants: {
    subtle: (props: StyleFunctionProps) => ({
      bg: `${props.colorScheme}.100`,
      _hover: {
        bg: `${props.colorScheme}.200`,
      },
      _active: {
        bg: `${props.colorScheme}.300`,
      },
      color: `${props.colorScheme}.800`,
    }),
  },
});
