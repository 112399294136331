import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { useAuthState } from '../../../context/providerAccount';
import { postBotApi } from '../../../dataStore/bot';
import { useIsPC } from '../../../hooks/useIsPC';
import { Path } from '../../../routers/Path';
import { Layout } from '../../ui/Layout';
import { LineBackground } from '../../ui/LineBackground';
import { Link } from '../../ui/Link';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { ReminderPreview } from './preview';
import { PricingWarningMessage } from './PricingWarningMessage';

export const ReminderConfigurationPage: React.FC = () => {
  const { isOpen, onClose, onOpen: openConfirmationDialog } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { initialized, providerAccount, refetch } = useAuthState();
  const { firebaseUser } = useAuthState();
  const deleteReminder = React.useCallback(async () => {
    if (!firebaseUser || !providerAccount) {
      return;
    }

    return postBotApi<{ message: string } | { error: string }>(
      `/remind/provider-account/${providerAccount.id}`,
      firebaseUser,
      { needReminder: false }
    );
  }, [firebaseUser, providerAccount]);
  const mutation = useMutation({
    mutationFn: deleteReminder,
    onSuccess: async (response) => {
      if (!response) {
        return;
      }
      const error = (() => {
        if (!response.ok) {
          return response.error;
        }
        if (response.ok && 'error' in response.body) {
          return response.body.error;
        }
        return;
      })();
      if (error) {
        return Promise.reject(new Error(`エラー: ${error}が発生しました`));
      } else {
        toast({
          status: 'success',
          title: `リマインダーを削除しました`,
        });
        await refetch();
        // history.go(0); // reload
      }
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: `エラーが発生しました。error: ${error}`,
      });
      console.error(`failed to save reminder.`, error);
    },
  });
  const isPC = useIsPC();

  if (!initialized || !providerAccount) {
    return <Spinner />;
  }

  return (
    <Layout pageTitle={'リマインダー'} hasBackButton={Path.configuration}>
      <Text>リマインダーは予約がある日の前日17:00頃に送信されます</Text>
      <Spacer h={'16px'} />
      <Stack direction={{ base: 'column-reverse', md: 'row' }} spacing={'32px'}>
        <VStack spacing={'8px'} alignItems={'flex-start'} w={'full'}>
          <PricingWarningMessage />
          <HStack w={'full'} justifyContent={'flex-end'}>
            {providerAccount.reminder?.needReminder ? (
              <>
                <DeleteConfirmationDialog
                  isOpen={isOpen}
                  onClose={onClose}
                  onConfirm={async () => {
                    await mutation.mutateAsync();
                  }}
                />
                <Button
                  type={'button'}
                  variant={'red-fill'}
                  onClick={openConfirmationDialog}
                  w={isPC ? 'fit-content' : 'full'}
                  isLoading={mutation.isPending}
                >
                  削除する
                </Button>
                <Button
                  as={Link}
                  to={Path.reminderEdit}
                  type={'button'}
                  variant={'blue-fill'}
                  w={isPC ? 'fit-content' : 'full'}
                  isDisabled={mutation.isPending}
                >
                  編集する
                </Button>
              </>
            ) : (
              <Button
                as={Link}
                to={Path.reminderEdit}
                type={'button'}
                w={isPC ? 'fit-content' : 'full'}
                variant={'blue-fill'}
              >
                リマインダーを新規作成する
              </Button>
            )}
          </HStack>
        </VStack>

        {isPC ? (
          <VStack
            w={'full'}
            spacing={'8px'}
            alignItems={'flex-start'}
            p={'20px'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
          >
            <Text fontSize={'lg'} fontWeight={'bold'}>
              設定中のリマインダー
            </Text>
            {providerAccount.reminder?.needReminder ? (
              <ReminderPreview configuration={providerAccount.reminder} />
            ) : (
              <LineBackground>
                <Text color={'white'}>設定中のリマインダーはありません</Text>
              </LineBackground>
            )}
          </VStack>
        ) : (
          <PreviewContainer label={'設定中のリマインダー'}>
            {providerAccount.reminder?.needReminder ? (
              <ReminderPreview configuration={providerAccount.reminder} />
            ) : (
              <LineBackground>
                <Text color={'white'}>設定中のリマインダーはありません</Text>
              </LineBackground>
            )}
          </PreviewContainer>
        )}
      </Stack>
    </Layout>
  );
};

const DeleteConfirmationDialog: React.VFC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}> = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = React.useRef(null);
  const onClick = React.useCallback(async () => {
    try {
      await onConfirm();
      onClose();
    } catch (e: any) {
      console.error(e);
    } finally {
    }
  }, [onConfirm, onClose]);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>リマインダを削除しますか？</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>この操作は戻すことが出来ません。</AlertDialogBody>
        <AlertDialogFooter>
          <HStack spacing={'12px'}>
            <Button ref={cancelRef} onClick={onClose}>
              キャンセルする
            </Button>
            <Button variant="red-fill" onClick={onClick}>
              削除する
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
