import { Badge, HStack, Icon, Text } from '@chakra-ui/react';
import {
  BookingFormElement,
  PredefinedSharedBookingFormElement,
} from '@pochico/shared';
import React from 'react';
import { MdAssignment, MdEdit, MdOutlineCheckBox } from 'react-icons/md';
import { typeLabel } from '../features/bookingForms/typeLabel';
import { RadioButtonIcon } from './RadioButtonIcon';

export const BookingFormElementType: React.FC<{
  formElement: PredefinedSharedBookingFormElement | BookingFormElement;
}> = ({ formElement }) => {
  const { icon, text } =
    'name' in formElement
      ? sharedBookingFormElementTypes[formElement.type]
      : bookingFormElementTypes[formElement.type];
  return (
    <Badge
      whiteSpace={'nowrap'}
      px={'12px'}
      py={'6px'}
      m={0}
      borderRadius={'2px'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      bgColor={'gray.200'}
    >
      <HStack gap={'4px'}>
        {icon}
        <Text p={0} m={0}>
          {text}
        </Text>
      </HStack>
    </Badge>
  );
};

const sharedBookingFormElementTypes: {
  [type in PredefinedSharedBookingFormElement['type']]: {
    icon: React.ReactElement;
    text: string;
  };
} = {
  name: { icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />, text: '自由入力' },
  tel: { icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />, text: '数字入力' },
  email: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: 'メールアドレス入力',
  },
  furigana: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: 'カナ入力',
  },
  memberId: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: '自由入力',
  },
  sex: {
    icon: <Icon as={RadioButtonIcon} w={'16px'} h={'16px'} />,
    text: '性別',
  },
  address: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: '住所',
  },
  birthday: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: '生年月日',
  },
};

const bookingFormElementTypes: {
  [type in BookingFormElement['type']]: {
    icon: React.ReactElement;
    text: string;
  };
} = {
  string: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: typeLabel['string'],
  },
  number: {
    icon: <Icon as={MdEdit} w={'16px'} h={'16px'} />,
    text: typeLabel['number'],
  },
  agreement: {
    icon: <Icon as={MdAssignment} w={'16px'} h={'16px'} />,
    text: typeLabel['agreement'],
  },
  'multi-select': {
    icon: <Icon as={MdOutlineCheckBox} w={'16px'} h={'16px'} />,
    text: typeLabel['multi-select'],
  },
  'single-select': {
    // icon: <Icon as={MdSelectAll} w={'16px'} h={'16px'} />,
    icon: <Icon as={RadioButtonIcon} w={'16px'} h={'16px'} />,
    text: typeLabel['single-select'],
  },
};
