import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ProviderAccount, Spot, waitAtLeast } from '@pochico/shared';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React from 'react';

import { SpotFilter } from '../components/features/Spots';
import { DisplaySpot, SpotCreateParams } from '../firebase/types';
import { getOne } from '../providers/dataProvider/helper';
import {
  convertSpotToDisplaySpot,
  createSpot,
  deleteSpots,
  getFullSpotsRef,
  getSpotCount,
  getSpotList,
  updateSpot,
} from '../providers/dataProvider/spot';
import { Sort, SortDirection } from '../providers/dataProvider/type';
import { usePaginationQuery } from './usePaginationQuery';

export const spotQueryKey = createQueryKeys('spot', {
  count: (providerAccountId: string, filter: SpotFilter) => [
    providerAccountId,
    filter,
  ],
  list: (
    providerAccount: ProviderAccount,
    filter: SpotFilter,
    direction: SortDirection,
    perPage: number
    // page: number
  ) => [providerAccount.id, filter, direction, perPage],
  show: (providerAccount: ProviderAccount, id: Spot['id']) => [
    providerAccount.id,
    id,
  ],
});
export const useFetchSpot = ({
  providerAccount,
  id,
}: {
  providerAccount: ProviderAccount;
  id: string | undefined;
}) => {
  const fetch = React.useCallback(() => {
    return id
      ? getOne(getFullSpotsRef(providerAccount.id), id).then((s) =>
          s ? convertSpotToDisplaySpot(s) : undefined
        )
      : undefined;
  }, [providerAccount, id]);

  return useQuery({
    queryKey: spotQueryKey.show(providerAccount, id!).queryKey,
    queryFn: fetch,
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
};

export const useFetchSpots = ({
  providerAccount,
  filter,
  perPage,
  direction,
  page,
  enabled,
}: {
  providerAccount: ProviderAccount;
  filter: SpotFilter;
  perPage: number;
  direction: SortDirection;
  page: number;
  enabled?: boolean;
}) => {
  // spotのsort順はこれだけ
  const sort: Sort<DisplaySpot> = {
    field: 'dateTimeForSort',
    direction,
  };
  const query = usePaginationQuery<DisplaySpot>({
    queryKey: spotQueryKey.list(providerAccount, filter, direction, perPage)
      .queryKey,
    queryFn: async (pagination) => {
      return getSpotList(providerAccount.id, {
        filter,
        pagination,
      }).then((result) => {
        // return result.data.sort((a, b) => {
        //   return a[sort.field] > b[sort.field] ? 1 : -1;
        // });
        return result.data;
      });
    },
    pagination: {
      perPage,
      sort,
    },
    page,
    enabled: enabled ?? true,
  });

  return query;
};

export const useFetchSpotCount = ({
  providerAccountId,
  filter,
}: {
  providerAccountId: string;
  filter: SpotFilter;
}) => {
  return useQuery({
    queryKey: spotQueryKey.count(providerAccountId, filter).queryKey,
    queryFn: () => getSpotCount(providerAccountId, filter),
    placeholderData: keepPreviousData,
  });
};

export const useCreateSpot = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: SpotCreateParams) =>
      waitAtLeast(createSpot(providerAccount, data), 500),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: spotQueryKey._def });
    },
  });
};

export const useUpdateSpot = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updatedSpot: Spot) =>
      waitAtLeast(updateSpot(providerAccount.id, updatedSpot), 500),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: spotQueryKey._def });
    },
  });
};

export const useDeleteSpots = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: Spot['id'][]) =>
      waitAtLeast(deleteSpots(providerAccount.id, ids), 1000),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: spotQueryKey._def });
    },
  });
};
