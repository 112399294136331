import { Box, Text, VStack } from '@chakra-ui/react';
import { BookingMenu } from '@pochico/shared';
import React from 'react';
import { BookingMenuLineImage } from '../../ui/BookingMenuLineImage';
import { LineBackground } from '../../ui/LineBackground';

export const BookingMenuPreview: React.FC<{
  bookingMenu: Pick<BookingMenu, 'name' | 'description' | 'imageUrl'>;
  showImage: boolean;
}> = ({ bookingMenu, showImage }) => {
  return (
    <LineBackground>
      <BookingMenuPreviewWithoutBackground
        bookingMenu={bookingMenu}
        showImage={showImage}
      />
    </LineBackground>
  );
};

export const BookingMenuPreviewWithoutBackground: React.FC<{
  children?: React.ReactNode;
  bookingMenu: Pick<BookingMenu, 'name' | 'description' | 'imageUrl'>;
  showImage: boolean;
}> = ({ children, bookingMenu, showImage }) => {
  const description: React.ReactNode = (() => {
    return (bookingMenu.description || '').replace(
      new RegExp('\n', 'g'),
      '<br />'
    );
    // if (bookingMenu.description.length > 30) {
    //   return `${bookingMenu.description.slice(0, 30)}...`;
    // } else {
    //   return bookingMenu.description;
    // }
  })();
  const isEmpty =
    !bookingMenu.name && !bookingMenu.description && !bookingMenu.imageUrl;

  return (
    <VStack
      direction={'column'}
      boxShadow={'md'}
      w={'219px'}
      // minH={'326px'}
      h={'fit-content'}
      borderRadius={'17px'}
      borderWidth={'1px'}
      borderColor={'#DEDEDE'}
      backgroundColor={'white'}
      gap={0}
      spacing={0}
      justifyContent={'space-between'}
    >
      {isEmpty ? (
        <Box marginY={'auto'}>
          <Text>ここにプレビューが表示されます</Text>
        </Box>
      ) : (
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <BookingMenuLineImage
            imageUrl={bookingMenu.imageUrl}
            alt={bookingMenu.name}
            showImage={showImage}
          />
          <VStack
            w={'full'}
            paddingTop={'15px'}
            padding={'15px'}
            alignItems={'left'}
            // overflow={'scroll'}
          >
            <Text fontWeight={'bold'} fontSize={20} whiteSpace={'pre-wrap'}>
              {bookingMenu.name}
            </Text>
            {description && (
              <Text
                fontSize={13}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></Text>
            )}
          </VStack>
        </VStack>
      )}
      {children ? children : null}
    </VStack>
  );
};
