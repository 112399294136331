import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { SpotCreateParams } from '../../../firebase/types';
import { useTimer } from '../../../helpers/timer';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useCreateSpot } from '../../../hooks/spots';
import { resourcePath } from '../../../hooks/useUrlPath';
import { Layout } from '../../ui/Layout';
import { Link } from '../../ui/Link';

export const SpotCreate: React.FC<{
  providerAccount: ProviderAccount;
  input: { date: string; startTime: string; bookingMenuId: string } | undefined;
}> = ({ providerAccount, input }) => {
  // const navigate = useNavigate();
  // const locationFrom = useLocationFrom({
  //   fallbackPath: undefined,
  // });
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });

  const inputStartTime = React.useMemo(() => {
    if (!input) {
      return undefined;
    }
    const [hour, minute] = String(input.startTime).split(':').map(Number);
    return { hour, minute };
  }, [input]);
  const form = useForm<SpotCreateParams & { startTime: string }>({
    mode: 'onChange',
    defaultValues: {
      date: input?.date,
      startTime: input?.startTime,
      startTimeHour: inputStartTime?.hour,
      startTimeMinute: inputStartTime?.minute,
      bookingMenuId: input?.bookingMenuId,
    },
  });
  const timer = useTimer({ durationSec: 2 });
  const mutation = useCreateSpot(providerAccount);
  const toast = useToast();

  const onSubmit = React.useCallback(
    async (createSpotData: SpotCreateParams) => {
      if (createSpotData) {
        toast.closeAll();
        return mutation
          .mutateAsync(createSpotData)
          .then(async ({ data: spot }) => {
            const spotShowPath = resourcePath({
              providerAccount,
              resourceName: 'spot',
              action: 'show',
              resourceId: spot.id,
            });
            // ボタン連打で重複する予約枠が作成されないようにボタンをdisabledにしておくためtimerを使う
            timer.clear();

            const toastId = toast({
              status: 'success',
              title: (
                <VStack alignItems={'flex-start'}>
                  <Text>予約枠を作成しました</Text>
                  <Link
                    to={spotShowPath}
                    color={'white'}
                    _hover={{
                      textDecoration: 'underline',
                      color: 'gray.200',
                    }}
                    textDecoration={'underline'}
                    onClick={() => toast.close(toastId)}
                  >
                    作成した予約枠を確認する
                  </Link>
                </VStack>
              ),
              duration: 5000,
            });
            // navigate(
            //   locationFrom?.path || spotShowPath
            // );
          })
          .catch((e) => {
            toast({
              title: 'エラーが発生しました',
              description: e.message,
              status: 'error',
            });
          });
      }
    },
    [mutation, providerAccount, timer, toast]
  );

  return (
    <Layout hasBackButton pageTitle="予約枠の作成">
      <FormProvider {...form}>
        <form style={{ width: '100%' }} onSubmit={form.handleSubmit(onSubmit)}>
          <VStack w={'full'} spacing={'16px'}>
            <VStack
              alignItems={'flex-start'}
              width={'full'}
              padding={0}
              gap={0}
              marginTop={'30px'}
            >
              {bookingMenuQuery.isFetching ? (
                <Spinner />
              ) : !bookingMenuQuery.data ||
                bookingMenuQuery.data.length === 0 ? (
                <Text>予約メニューがありません</Text>
              ) : (
                <SpotCreateForm
                  providerAccount={providerAccount}
                  bookingMenuList={bookingMenuQuery.data}
                />
              )}
            </VStack>

            <HStack w={'full'} spacing={'16px'} justifyContent={'flex-end'}>
              <Button
                variant={'blue-fill'}
                type={'submit'}
                size={'sm'}
                isLoading={mutation.isPending}
                isDisabled={!timer.done || !form.formState.isValid}
              >
                保存する
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  );
};

const SpotCreateForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const form = useFormContext<SpotCreateParams & { startTime: string }>();
  // React.useEffect(() => {
  //   const { unsubscribe } = form.watch((values, { name }) => {
  //     console.log(`[SpotEditForm]`, { values });
  //     if (values && name && name in values && values[name as keyof Spot]) {
  //       onChange({ ...spot, [name]: values[name as keyof Spot], id: spot.id });
  //     }
  //   });
  //   return unsubscribe;
  // }, [form, onChange, spot]);

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      borderWidth={'1px'}
      borderColor={'gray.100'}
      fontSize={'xs'}
      p={'16px'}
    >
      <FormControl isRequired isInvalid={!!form.formState.errors?.date}>
        <FormLabel>予約日</FormLabel>
        <Input
          w={'fit-content'}
          {...form.register('date', {
            required: true,
            validate: (value) => {
              if (!value) {
                return '入力してください';
              }
              return undefined;
            },
          })}
          type={'date'}
          bg={'white'}
        />
        <FormErrorMessage>
          {form.formState.errors?.date?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors?.startTime}>
        <FormLabel>開始時間</FormLabel>
        <Input
          w={'fit-content'}
          {...form.register('startTime', {
            required: true,
            validate: (value) => {
              if (!value) {
                return '入力してください';
              }
              return undefined;
            },
            onChange: (e) => {
              const [hour, minute] = String(e.target.value).split(':');
              form.setValue('startTimeHour', Number(hour));
              form.setValue('startTimeMinute', Number(minute));
            },
          })}
          type={'time'}
          bg={'white'}
        />
        <FormErrorMessage>
          {form.formState.errors?.startTime?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={!!form.formState.errors?.bookingMenuId}
      >
        <FormLabel>予約メニュー</FormLabel>
        <Select
          {...form.register('bookingMenuId', {
            required: true,
          })}
          bg={'white'}
          w={'fit-content'}
        >
          {bookingMenuList.map((menu) => (
            <option key={menu.id} value={menu.id}>
              {menu.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {form.formState.errors?.bookingMenuId?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors.maxBookings}>
        <FormLabel htmlFor={'maxBookings'}>予約枠数</FormLabel>
        <HStack>
          <Input
            type={'number'}
            w={'fit-content'}
            placeholder={'この時間に予約できる枠数'}
            bg={'white'}
            {...form.register('maxBookings', {
              required: true,
              valueAsNumber: true,
              validate: (value) => {
                if (value < 1) {
                  return '1以上の数値を入力してください';
                }
                return undefined;
              },
            })}
          />
        </HStack>
        <FormErrorMessage>
          {form.formState.errors?.maxBookings?.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );
};
