import { Box } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Path } from '../../routers/Path';
import { Logo } from './Logo';

export const Header = () => {
  return (
    <Box w={'100vw'} h={'64px'} backgroundColor={'white'} paddingX={'24px'}>
      <Link to={Path.home}>
        <Logo width={'128px'} />
      </Link>
    </Box>
  );
};
