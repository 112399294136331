import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  borderWidth: '1px',
  borderColor: 'gray.300',
  bgColor: 'white',
  _focusVisible: {
    borderColor: 'blue.500',
  },
});

export const Textarea = defineStyleConfig({
  variants: { outline },
});
