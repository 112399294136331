import React, { FC } from 'react';

const Spacer: FC<{ width?: string; height?: string; className?: string }> = ({
  width = 0,
  height = 0,
  className = '',
}) => {
  return (
    <div
      className={['spacer', className].join(' ')}
      style={{
        paddingTop: height,
        paddingLeft: width,
      }}
    />
  );
};

export default Spacer;
