import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  MdOutlineKey,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { sleep } from '@pochico/shared';
import { Layout } from '../components/ui/Layout';
import authProvider from '../providers/authProvider';

type PasswordChangeProp = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};
export const PasswordChange: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<PasswordChangeProp>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (input: PasswordChangeProp) => {
      if (input.newPassword !== input.newPasswordConfirmation) {
        return Promise.reject('パスワードが一致しません');
      }

      const { currentPassword, newPassword } = input;
      return authProvider.changePassword({ currentPassword, newPassword });
    },
    onSuccess: async () => {
      toast({
        status: 'success',
        title: 'パスワードが更新されました',
        duration: 1500,
      });
      await sleep(1200);
      navigate('/configuration');
    },
    onError: (e) => {
      toast({
        status: 'error',
        title: `パスワードの変更に失敗しました エラー: ${e}`,
      });
    },
  });
  const navigate = useNavigate();

  return (
    <Layout pageTitle={'パスワード変更'} hasBackButton>
      <form onSubmit={handleSubmit((input) => mutation.mutateAsync(input))}>
        <VStack
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          spacing={'16px'}
          w={'full'}
        >
          <Box
            w={'full'}
            borderWidth={'1px'}
            borderColor={'gray.200'}
            p={'16px'}
          >
            <VStack
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              spacing={'16px'}
              w={'600px'}
            >
              <FormControl isRequired isInvalid={!!errors.currentPassword}>
                <FormLabel htmlFor="currentPassword">
                  現在のパスワード
                </FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon as={MdOutlineKey} w={6} h={6} color="blue.600" />
                    }
                  />
                  <Input
                    id={'currentPassword'}
                    type={passwordVisible ? 'text' : 'password'}
                    {...register('currentPassword', {
                      required: '入力は必須です',
                    })}
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Icon
                          as={MdOutlineVisibility}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      ) : (
                        <Icon
                          as={MdOutlineVisibilityOff}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.newPassword?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.newPassword}>
                <FormLabel htmlFor="newPassword">新しいパスワード</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon as={MdOutlineKey} w={6} h={6} color="blue.600" />
                    }
                  />
                  <Input
                    id={'newPassword'}
                    type={passwordVisible ? 'text' : 'password'}
                    {...register('newPassword', {
                      required: '入力は必須です',
                      minLength: {
                        message: '6文字以上で入力してください',
                        value: 6,
                      },
                    })}
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Icon
                          as={MdOutlineVisibility}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      ) : (
                        <Icon
                          as={MdOutlineVisibilityOff}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.newPassword?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={!!errors.newPasswordConfirmation}
              >
                <FormLabel htmlFor="newPasswordConfirmation">
                  新しいパスワード(確認)
                </FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon as={MdOutlineKey} w={6} h={6} color="blue.600" />
                    }
                  />
                  <Input
                    id={'newPasswordConfirmation'}
                    {...register('newPasswordConfirmation', {
                      required: '入力は必須です',
                      minLength: 6,
                      validate: (value, values) => {
                        if (value !== values.newPassword) {
                          return 'パスワードが一致しません';
                        }
                        return true;
                      },
                    })}
                    type={passwordVisible ? 'text' : 'password'}
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Icon
                          as={MdOutlineVisibility}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      ) : (
                        <Icon
                          as={MdOutlineVisibilityOff}
                          w={6}
                          h={6}
                          color="blue.600"
                        />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.newPasswordConfirmation?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </Box>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              type="submit"
              isLoading={mutation.isPending}
              // isDisabled={!isValid}
              variant={'blue-fill'}
            >
              パスワードを更新する
            </Button>
          </HStack>
        </VStack>
      </form>
    </Layout>
  );
};
