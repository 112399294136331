import { AspectRatio, Flex, Image } from '@chakra-ui/react';
import React from 'react';

export const BookingMenuLineImage: React.FC<{
  showImage: boolean;
  imageUrl: string | undefined;
  alt: string;
}> = ({ showImage, imageUrl, alt }) => {
  if (!showImage) {
    return null;
  }
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'flex-start'}
      borderRadius={'17px'}
      w={'full'}
      // maxH={'169px'}
    >
      <Flex
        alignItems={'flex-start'}
        w={'full'}
        h={'full'}
        overflow={'hidden'}
        borderTopRadius={'17px'}
      >
        {showImage ? (
          <AspectRatio w={'full'} ratio={2 / 1}>
            <Image
              // maxW={'full'}
              // maxH={'full'}
              src={imageUrl}
              alt={imageUrl ? alt : 'NO IMAGE'}
            />
          </AspectRatio>
        ) : (
          // <Flex
          //   backgroundColor={'gray.200'}
          //   w={'full'}
          //   h={'full'}
          //   justifyContent={'center'}
          //   alignItems={'center'}
          // >
          //   <Text color={'black'}>NO IMAGE</Text>
          // </Flex>
          <></>
        )}
      </Flex>
    </Flex>
  );
};
