import { ProviderAccount } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';

import { fetchBulkSpotAddHistories } from '../providers/dataProvider/spotBulkAddHistory';

export const useFetchSpotBulkAddHistories = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  return useQuery({
    queryKey: ['fetchSpotBulkAddHistories', providerAccount.id],
    queryFn: () => fetchBulkSpotAddHistories(providerAccount.id),
  });
};
