import { HStack, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

export const Loading = () => {
  return (
    <HStack padding={8}>
      <Spinner size={'lg'} />
      <Text>読み込み中です...</Text>
    </HStack>
  );
};
