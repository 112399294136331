import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingFormList } from '../../components/features/bookingForms/list';

const BookingFormListPage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  useSetDocumentTitle('すべての質問フォーム');
  return <BookingFormList providerAccount={providerAccount} />;
};

export default BookingFormListPage;
