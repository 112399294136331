import { Button, Center, Flex, Link, Text, VStack } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';

import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { Checkmark } from '../components/ui/Checkmark';
import { FixedRight } from '../components/ui/Fixed';
import { PageTitle } from '../components/ui/PageTitle';
import { RegistrationLayout } from '../components/ui/RegistrationLayout';
import { RegistrationStepAttachComplete } from '../components/ui/RegistrationSteps';
import { RightArrowWhite } from '../components/ui/RightArrow';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { postBotApi } from '../dataStore/bot';
import { analytics } from '../firebase/firebaseInit';

const AttachComplete: FC = () => {
  const { firebaseUser, providerAccount, initialized } = useAuthState();
  const registerMetadata = React.useCallback(async () => {
    if (!providerAccount || !firebaseUser) {
      return;
    }
    type Params = { [key: string]: string };
    const params = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split(' ')[0].split('=');
      // LPを見たときのURLパラメータを`param.`prefix付きでcookieに保存してあるので取り出してAPIに送りつける
      if (key.startsWith('param.')) {
        return { ...acc, [key]: decodeURIComponent(value) };
      } else {
        return acc;
      }
    }, {} as Params);

    if (params && Object.keys(params).length === 0) {
      return;
    }

    const payload = {
      metadata: {
        attachedFirebaseUserId: firebaseUser?.uid,
        ...params,
      },
    };

    return postBotApi(
      `/provider-accounts/${providerAccount.id}`,
      firebaseUser,
      payload
    )
      .then(async (response) => {
        if (response.ok) {
          console.log(
            `successfully wrote metadata. payload: ${JSON.stringify(payload)}`
          );
          return Promise.resolve();
        } else {
          return Promise.reject(response.error);
        }
      })
      .catch((error) => {
        console.error(
          `failed to write metadata. payload: ${JSON.stringify(
            payload
          )}, error: ${error}`
        );
      });
  }, [firebaseUser, providerAccount]);

  setDocumentTitle('LINE連携の完了');

  useEffect(() => {
    if (!providerAccount || !firebaseUser) {
      return;
    }
    const event = {
      firebaseUserId: firebaseUser.uid,
      displayName: firebaseUser.displayName,
      providerAccountId: providerAccount.id,
    };
    setUserId(analytics, firebaseUser.uid, { global: true });
    logEvent(analytics, 'sign_up', event);
    setUserProperties(analytics, {
      providerAccountId: providerAccount.id,
    });
    console.log(`completed sending data to analytics ${JSON.stringify(event)}`);

    // providerAccountのmetadataとして登録しておく
    registerMetadata();
  }, [firebaseUser, providerAccount, registerMetadata]);

  if (!initialized) {
    return <>Loading...</>;
  }

  if (!firebaseUser || !providerAccount) {
    return (
      <RegistrationLayout showLogoutButton={true}>
        <Center>
          <Text>登録されていません。</Text>
          <WebLink fontWeight={'bold'} href={`/signup`}>
            こちら
          </WebLink>
          から登録してください
        </Center>
      </RegistrationLayout>
    );
  }
  return (
    <RegistrationLayout showLogoutButton={true}>
      <VStack
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
        paddingY={8}
        paddingX={2}
      >
        <RegistrationStepAttachComplete />
        <PageTitle fontSize={'2xl'} textAlign={'center'}>
          LINE連携が完了しました。
        </PageTitle>
        <Checkmark />
        <Text fontSize={'md'} textAlign={'center'}>
          さあ、準備が整いました！
          <br />
          以下より管理者ツールに進んで、使い方を確認していきましょう！
        </Text>
        <OkButton />
        {/* <Text
          color={'#333333'}
          fontWeight={'bold'}
          textAlign={'center'}
          margin={'0 50px'}
          maxWidth={'500px'}
        >
          ※無料期間は1ヶ月までです。その後も使い続けるには、管理者ツールから決済手段を登録してください。
        </Text> */}
      </VStack>
    </RegistrationLayout>
  );
};
export default AttachComplete;

const OkButton = () => (
  <Flex alignItems={'flex-end'} h={'full'} w={['full', '280px']}>
    <Link width={'full'} height={'50px'} href={'/'}>
      <Button
        width={'full'}
        height={'full'}
        borderRadius={40}
        colorScheme={'green'}
      >
        管理者ツールへ進む
        <FixedRight>
          <RightArrowWhite />
        </FixedRight>
      </Button>
    </Link>
  </Flex>
);
