import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { SharedBookingFormElementList } from '../../components/features/sharedBookingForms/list';

const SharedBookingFormElementListPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('個人情報質問のカスタマイズ');
  return <SharedBookingFormElementList providerAccount={providerAccount} />;
};

export default SharedBookingFormElementListPage;
