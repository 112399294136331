import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import CONSTANTS from '../../../commons/constants';
import { BookingSuccessMessagePreview } from '../../ui/BookingSuccessPreview';
import { PreviewContainer } from '../../ui/PreviewContainer';

export const BookingSuccessPreview: React.FC<{
  providerAccount: ProviderAccount;
  bookingSuccessMessage: string | undefined;
}> = ({ providerAccount, bookingSuccessMessage }) => {
  const bookingSuccess =
    bookingSuccessMessage || providerAccount?.customText?.bookingSuccess; // providerAccount単位で設定されているものがあればそちらを表示する

  return (
    <PreviewContainer label={'予約完了時のメッセージ'}>
      <BookingSuccessMessagePreview
        bookingSuccess={bookingSuccess || CONSTANTS.DEFAULT_BOOKING_SUCCESS}
      />
    </PreviewContainer>
  );
};
