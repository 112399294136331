import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import React from 'react';

dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

import { MdInfo } from 'react-icons/md';
import { getRemainDates } from '../../../commons/paymentUtil';
import { useAuthState } from '../../../context/providerAccount';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { useSendEmailVerificationMutation } from '../../../hooks/useEmailVerification';
import { useIsPC } from '../../../hooks/useIsPC';
import { resourcePath } from '../../../hooks/useUrlPath';
import { HelpSpot } from '../../ui/HelpSpot';
import { Layout } from '../../ui/Layout';
import { Link } from '../../ui/Link';
import { OnboardingDialog } from '../onboarding/dialog/OnboardingDialog';
import { DashboardBookingList } from './DashboardBookingList';
import { Information } from './Information';
import { SpotCalendar } from './SpotCalendar';
import { WelcomeMessage } from './WelcomeMessage';

export const Dashboard: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { firebaseUser } = useAuthState();
  return (
    <Layout
      header={
        <>
          {firebaseUser && (
            <EmailVerificationWarning
              providerAccount={providerAccount}
              firebaseUser={firebaseUser}
            />
          )}
          <AttachNotification providerAccount={providerAccount} />
        </>
      }
      pageTitle={undefined}
    >
      <VStack spacing={'24px'} alignItems={'flex-start'} w={'full'}>
        <WelcomeMessage providerAccount={providerAccount} />
        <Information providerAccount={providerAccount} />

        <SpotCalendarSection providerAccount={providerAccount} />

        <BookingListSection providerAccount={providerAccount} />

        {/* <BookingsCalendar providerAccount={providerAccount} /> */}
        <OnboardingDialog providerAccount={providerAccount} />
      </VStack>
    </Layout>
  );
};

const AttachNotification: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  if (
    providerAccount.status === 'attached' &&
    providerAccount.paymentStatus === 'registered'
  ) {
    // 支払い登録済かつattachedは何も出さない
    return <></>;
  }

  return <AttachNotificationContent providerAccount={providerAccount} />;
};

const AttachNotificationContent: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { remain, freeLastDateString } = getRemainDates(providerAccount);

  const text = React.useMemo(() => {
    // TODO: いつlineからの請求が確定するかの返答がきたら、それに合うコメントにしたい
    if (remain === 3) {
      return (
        <Box w={'full'} bgColor={'#ffe9e9'}>
          {`無料期間は`}
          <Text color={'red'}>{`【明後日まで】`}</Text>
          {`です（${freeLastDateString}まで）。継続希望の場合[`}
          <a href={'/configuration'}>各種各種設定ページ</a>
          {
            'より、決済手段を登録してください。\n決済手段を登録されていない場合、ご利用できなくなります。'
          }
        </Box>
      );
    } else if (remain === 2) {
      return (
        <>
          無料期間は
          <Text color={'red'}>{`【明日まで】`}</Text>
          です（{freeLastDateString}まで）。
        </>
      );
    } else if (remain === 1) {
      return (
        <>
          無料期間は
          <Text color={'red'}>{`【今日まで】`}</Text>
          です（{freeLastDateString}まで）。
        </>
      );
    } else if (remain >= 4) {
      return (
        <>
          無料期間は【
          <b>{`残り${remain}日】です`}</b>（{freeLastDateString}まで）。
        </>
      );
    }
  }, [freeLastDateString, remain]);

  if (providerAccount.status === 'detached') {
    return (
      <>
        {providerAccount.paymentStatus === 'registered' ? (
          <span>
            現在このアカウントはポチコとの連携が切れています。再接続する場合は
            <a
              href="https://console.pochico.app/signup"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              https://console.pochico.app/signup
            </a>
            より、再度ログインとLINE公式アカウントとの連携をしてください。
          </span>
        ) : (
          <span>
            現在このアカウントはポチコとの連携が切れています。再接続する場合は、『各種設定』のメニューから決済情報を登録の上、
            <a
              href="https://console.pochico.app/signup"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              https://console.pochico.app/signup
            </a>
            より、再度ログインとLINE公式アカウントとの連携をしてください。
          </span>
        )}
      </>
    );
  }
  return (
    <Box
      bgColor={'#ffe9e9'}
      // display={'inline'}
      boxShadow={'base'}
      py={'12px'}
      px={'12px'}
    >
      {text}
      それまでに[
      <Link to={'/configuration'}>
        <Text display={'inline'} textDecoration={'underline'}>
          各種設定ページ
        </Text>
      </Link>
      ]より、決済手段を登録してください。
    </Box>
  );
};

const EmailVerificationWarning: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const toast = useToast();
  const mutation = useSendEmailVerificationMutation(
    providerAccount,
    firebaseUser,
    {
      onSuccess: (email) => {
        toast.closeAll();
        toast({
          title: `認証メールを「${email}」に送信しました`,
          status: 'info',
          duration: 5000,
        });
      },
      onError: (error) => {
        toast.closeAll();
        toast({
          title: `認証メールの送信に失敗しました ${error}`,
          status: 'error',
        });
      },
    }
  );

  if (
    !providerAccount ||
    Boolean(providerAccount.invoiceEmail) ||
    providerAccount.paymentStatus === 'unregistered'
  ) {
    return null;
  }
  return (
    <Stack
      w={'full'}
      direction={{ base: 'column', md: 'row' }}
      bgColor={'orange.100'}
      py={'12px'}
      px={'12px'}
      justifyContent={'space-between'}
      fontSize={'sm'}
    >
      <HStack>
        <Icon as={MdInfo} color={'orange.500'} boxSize={'24px'} />
        <Text fontWeight={'bold'}>
          請求先メールアドレスに届いているメールをご確認の上、本文内のURLリンクをクリックして認証を完了してください。
        </Text>
      </HStack>
      <Button
        // backgroundColor={'blue.200'}
        colorScheme="orange"
        // textDecoration={'underline'}
        onClick={() =>
          mutation.mutateAsync({
            action: 'emailVerification',
          })
        }
        minW={'fit-content'}
        isLoading={mutation.isPending}
      >
        認証メールを再送信する
      </Button>
    </Stack>
  );
};

const SpotCalendarSection: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const isPC = useIsPC();
  return (
    <>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <HStack alignItems={'center'}>
          <Heading size={'lg'}>空き状況カレンダー</Heading>
          <HelpSpot />
        </HStack>
        {isPC ? (
          <Text color={'gray.500'}>
            ◯：予約枠に空きがある時間帯　✕：予約枠に空きがない間帯　空白：予約枠が設置されていない時間帯
          </Text>
        ) : (
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            color={'gray.500'}
            spacing={0}
          >
            <Text>◯：予約枠に空きがある時間帯</Text>
            <Text>✕：予約枠に空きがない間帯</Text>
            <Text>空白：予約枠が設置されていない時間帯</Text>
          </VStack>
        )}
      </VStack>
      <SpotCalendar providerAccount={providerAccount} />
    </>
  );
};

const BookingListSection: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  return (
    <>
      <VStack
        pt={'56px'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pb={'12px'}
      >
        <Heading size={'lg'}>近日中の予約</Heading>
        <Text color={'gray.500'}>
          今日と明日に入っている、受付済みの予約を確認できます。
          <Link
            to={resourcePath({
              providerAccount,
              action: 'list',
              resourceName: 'booking',
            })}
            color={'blue.400'}
            textDecoration={'underline'}
            fontWeight={'bold'}
            px={'4px'}
          >
            すべての予約を確認する
          </Link>
        </Text>
      </VStack>
      <DashboardBookingList providerAccount={providerAccount} />
    </>
  );
};
