import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { useDialogDispatcher } from '../../ui/Dialog';
import { SavedFilter } from './SavedFilter';

export const useListSavedFilterDialog = <T,>({
  data,
  remove,
  toComponent,
}: {
  data: SavedFilter<T>[];
  remove: (name: string) => Promise<void>;
  toComponent: (filter: T) => React.ReactNode;
}) => {
  const { openDialog, closeDialog, isOpen } = useDialogDispatcher();
  const mutation = useMutation({
    mutationFn: async (name: string) => {
      return remove(name);
    },
    onSuccess: () => {
      closeDialog();
    },
  });
  const open = React.useCallback(async () => {
    if (isOpen) {
      return;
    }
    return openDialog({
      size: 'lg',
      closeOnOverlayClick: true,
      body: (
        <DialogBody<T>
          data={data}
          onCancel={closeDialog}
          onDelete={async (data) => {
            return mutation.mutate(data);
          }}
          isLoading={mutation.isPending}
          toComponent={toComponent}
        />
      ),
    });
  }, [toComponent, closeDialog, data, isOpen, mutation, openDialog]);
  return {
    open,
  };
};

const DialogBody = <T,>({
  data,
  onDelete,
  isLoading,
  toComponent,
}: {
  data: SavedFilter<T>[];
  onCancel: () => void;
  onDelete: (name: string) => Promise<void>;
  isLoading: boolean;
  toComponent: (filter: T) => React.ReactNode;
}) => {
  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader fontSize="lg" fontWeight="bold" alignItems={'center'}>
        保存した検索条件
        <AlertDialogCloseButton />
      </AlertDialogHeader>

      <AlertDialogBody>
        <VStack w={'full'} alignItems={'flex-start'}>
          {data.map((savedFilter) => (
            <HStack
              key={savedFilter.name}
              alignItems={'center'}
              justifyContent={'space-between'}
              w={'full'}
            >
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
              >
                <Text color={'gray.800'} fontWeight={'bold'} fontSize={'md'}>
                  {savedFilter.name}
                </Text>
                {toComponent(savedFilter.filter)}
              </VStack>
              <Button
                onClick={() => {
                  onDelete(savedFilter.name);
                }}
                size={'lg'}
                variant={'transparent-clickable'}
                color={'red'}
                isLoading={isLoading}
              >
                <MdOutlineDelete />
              </Button>
            </HStack>
          ))}
        </VStack>
      </AlertDialogBody>
      <AlertDialogFooter />
    </AlertDialogContent>
  );
};
