import { defineStyleConfig } from '@chakra-ui/react';

export const Box = defineStyleConfig({
  variants: {
    'circle.disabled': {
      bg: 'white',
      color: 'gray.300',
      textAlign: 'center',
      cursor: 'not-allowed',
      borderRadius: 'full',
      borderColor: 'gray.200',
      borderWidth: '1px',
      width: 'full',
      height: 'full',
    },
    'circle.selected': {
      bg: 'blue.500',
      color: 'white',
      textAlign: 'center',
      cursor: 'default',
      borderRadius: 'full',
      width: 'full',
      height: 'full',
    },
    'circle.default': {
      bg: 'white',
      _hover: {
        bg: 'gray.10',
      },
      _active: {
        bg: 'gray.50',
      },
      borderColor: 'gray.200',
      borderWidth: '1px',
      color: 'gray.600',
      textAlign: 'center',
      cursor: 'pointer',
      borderRadius: 'full',
      width: 'full',
      height: 'full',
    },
  },
});
