import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { Path } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import { NavigationButton } from '../../ui/NavigationButton';
import { SpotCellData } from './SpotCalendar';
import { useSpotCreateDialog } from './useSpotCreateDialog';

export const useSpotCellDialog = ({
  providerAccount,
  bookingMenu,
  bookingMenuList,
}: {
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
}) => {
  const { openDialog, closeDialog } = useDialogDispatcher();
  const spotCreateDialog = useSpotCreateDialog({
    providerAccount,
    bookingMenuList,
  });
  const f = React.useCallback(
    (params: {
      date: dayjs.Dayjs;
      hour: number;
      data: SpotCellData | undefined;
    }) => {
      const { date, hour, data } = params;
      return openDialog({
        size: '2xl',
        closeOnOverlayClick: true,
        body: (
          <AlertDialogContent marginX={'16px'}>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              alignItems={'center'}
            >
              <Text w={'full'}>{`${date.format('YYYY/MM/DD(ddd)')} ${hour}:00~${
                hour + 1
              }:00`}</Text>
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody>
              {data && data.spots.length > 0 ? (
                <TableContainer w={'full'}>
                  <Table variant="pochico-striped">
                    <Thead>
                      <Tr height={'3rem'} paddingY={'10px'}>
                        {/* <Th>予約日</Th> */}
                        <Th>開始時間</Th>
                        <Th>予約メニュー</Th>
                        <Th>予約状況</Th>
                        <Th>操作</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.spots.map((spot) => {
                        return (
                          <Tr justifyContent={'center'} alignSelf={'center'}>
                            {/* <Td verticalAlign={'middle'}>{spot.displayDate}</Td> */}
                            <Td verticalAlign={'middle'}>{spot.startTime}</Td>
                            <Td verticalAlign={'middle'}>{bookingMenu.name}</Td>
                            <Td verticalAlign={'middle'}>
                              {spot.displayAvailability}
                              <NavigationButton
                                size={'sm'}
                                variant="transparent-clickable"
                                color={'blue.600'}
                                onClick={closeDialog}
                                locationFrom={{ path: Path.home }}
                                to={{
                                  providerAccount: providerAccount,
                                  resourceName: 'spot',
                                  resourceId: spot.id,
                                  action: 'edit',
                                }}
                              >
                                <MdOutlineEdit />
                              </NavigationButton>
                            </Td>
                            <Td verticalAlign={'middle'} textAlign={'right'}>
                              <HStack>
                                <NavigationButton
                                  size={'sm'}
                                  variant={'blue-fill'}
                                  onClick={closeDialog}
                                  isDisabled={
                                    spot.bookingIds.length === spot.maxBookings
                                  }
                                  locationFrom={{ path: Path.home }}
                                  to={{
                                    resourceName: 'booking',
                                    action: 'create',
                                    providerAccount: providerAccount,
                                    urlParams: {
                                      spotId: spot.id,
                                    },
                                  }}
                                >
                                  予約を追加
                                </NavigationButton>
                              </HStack>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Text>予約枠なし</Text>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant={'white-blue'}
                onClick={() =>
                  spotCreateDialog.open(bookingMenu, {
                    date: date.format('YYYY-MM-DD'),
                    startTime: `${String(hour).padStart(2, '0')}:00`,
                  })
                }
              >
                予約枠を作成
              </Button>
              {/* <NavigationButton
                      size={'sm'}
                      variant="white-blue"
                      onClick={closeDialog}
                      locationFrom={{ path: Path.dashboard }}
                      to={{
                        resourceName: 'spot',
                        action: 'create',
                        providerAccount: providerAccount,
                        urlParams: {
                          date: date.format('YYYY-MM-DD'),
                          startTime: `${hour}:00`,
                          bookingMenuId: bookingMenu.id,
                        },
                      }}
                    >
                      予約枠を作成
                    </NavigationButton> */}
            </AlertDialogFooter>
          </AlertDialogContent>
        ),
      });
    },
    [bookingMenu, closeDialog, openDialog, providerAccount, spotCreateDialog]
  );

  return f;
};
