import { ThemeComponents } from '@chakra-ui/react';

export const tableStyle: ThemeComponents = {
  TableContainer: {
    baseStyle: {
      width: '100%',
    },
  },
  Table: {
    parts: ['thead', 'th', 'tr', 'td'],
    variants: {
      'pochico-striped': {
        thead: {
          verticalAlign: 'middle',
          borderColor: 'gray.120',
          borderWidth: '1px',
        },
        th: {
          backgroundColor: 'gray.50',
          color: 'black',
          verticalAlign: 'middle',
          fontWeight: 'bold',
          borderBottomWidth: '1px',
          borderColor: 'gray.120',
        },
        td: {
          verticalAlign: 'middle',
          minWidth: '100px',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderColor: 'gray.120',
        },
        tr: {
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderColor: 'gray.120',
          verticalAlign: 'middle',
          _even: {
            backgroundColor: 'gray.50',
          },
          _odd: {
            backgroundColor: 'white',
          },
        },
      },
    },
    defaultProps: {
      size: 'sm',
    },
  },
  // Thead: {
  //   baseStyle: {
  //     bg: 'gray.100',
  //   },
  //   defaultProps: {
  //     bg: 'red.500',
  //   },
  // },
  Th: {
    // baseStyle: {
    //   display: 'block',
    //   verticalAlign: 'middle',
    //   bg: 'red.500',
    //   minWidth: '100px',
    // },
    variants: {
      sortable: {
        cursor: 'pointer',
        _hover: {
          bg: 'gray.50',
        },
      },
    },
    // defaultProps: {
    //   bg: 'red.500',
    // },
  },
  // Tr: {
  //   baseStyle: {
  //     justifyContent: 'center',
  //     alignSelf: 'center',
  //   },
  // },
  // Td: {
  //   baseStyle: {
  //     verticalAlign: 'middle',
  //   },
  // },
};
