import {
  Box,
  HStack,
  Spacer,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  Booking,
  LineUser,
  ProviderAccount,
  dateStringWithWeekDay,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { atom, useAtom } from 'jotai';
import { DisplayLineUser } from '../../../firebase/types';
import { paginationAtom } from '../../../helpers/perPage';
import { useFetchBookingCount, useFetchBookings } from '../../../hooks/booking';
import { useFetchLineUser } from '../../../hooks/lineUser';
import { createLocationFrom } from '../../../hooks/locationState';
import { useFetchSharedBookingFormElements } from '../../../hooks/sharedBookingForms';
import { useIsPC } from '../../../hooks/useIsPC';
import { resourcePath } from '../../../hooks/useUrlPath';
import { Layout } from '../../ui/Layout';
import { Loading } from '../../ui/Loading';
import { NavigationButton } from '../../ui/NavigationButton';
import { PaginationIndicator } from '../../ui/PaginationIndicator';
import { SortableTableHeader } from '../../ui/SortableTableHeader';
import { TextWithNewLine } from '../../ui/TextWithNewLine';
import { BookingFilter } from '../Bookings';
import { LineUserCard } from './LineUserCard';

const includeHistoryAtom = atom<boolean>(false);
const bookingListPagingAtom = paginationAtom<Booking>({
  page: 1,
  sort: {
    field: 'dateTimeForSort',
    direction: 'asc',
  },
});

export const LineUserShow: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const query = useFetchLineUser({ providerAccount, id });
  const [includeHistory, setIncludeHistory] = useAtom(includeHistoryAtom);
  const [, setPaging] = useAtom(bookingListPagingAtom);
  const onChangeIncludeHistory = React.useCallback(() => {
    setIncludeHistory(!includeHistory);
    setPaging((paging) => {
      return {
        ...paging,
        page: 1,
        lastCursor: undefined,
      };
    });
  }, [includeHistory, setIncludeHistory, setPaging]);
  const isPC = useIsPC();

  return (
    <Layout
      hasBackButton={{
        providerAccount: providerAccount,
        resourceName: 'lineUser',
        action: 'list',
      }}
      pageTitle="LINEユーザー詳細"
    >
      {query.error && <Text>エラーが発生しました {String(query.error)}</Text>}
      <VStack alignItems={'flex-start'} width={'full'} padding={0} gap={0}>
        <Text color={'gray.500'} fontSize={'sm'}>
          ユーザーの名前を変更したり、メモを残したり、ユーザーを予約できないようにブロックすることができます。
        </Text>
        {!query.isLoading && query.data ? (
          <LineUserDetail
            providerAccount={providerAccount}
            lineUser={query.data}
          />
        ) : (
          <Spinner />
        )}
      </VStack>
      <Spacer pt={'32px'} />
      <VStack alignItems={'flex-start'} width={'full'} padding={0} gap={0}>
        <HStack w={'full'} justifyContent={'space-between'}>
          <Text fontSize={'md'} fontWeight={'bold'}>
            予約一覧
          </Text>
          <HStack>
            <Text fontSize={'sm'}>過去の予約を含める</Text>
            <Switch
              isChecked={includeHistory}
              onChange={onChangeIncludeHistory}
            />
          </HStack>
        </HStack>
        <Box
          w={'full'}
          borderRadius={'4px'}
          borderWidth={isPC ? '1px' : 0}
          borderColor={'gray.200'}
          p={isPC ? '16px' : 0}
        >
          {query.data ? (
            <LineUserBookings
              providerAccount={providerAccount}
              lineUser={query.data}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </VStack>
      <Spacer pt={'16px'} />
    </Layout>
  );
};

const LineUserDetail: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: DisplayLineUser;
}> = ({ providerAccount, lineUser }) => {
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      spacing={'16px'}
      // fontSize={'14px'}
    >
      {lineUser.archived && (
        <Box
          bg={'red.600'}
          color={'white'}
          w={'full'}
          textAlign={'center'}
          fontWeight={'bold'}
          borderRadius={'4px'}
          py={'4px'}
        >
          このユーザーは削除されています
        </Box>
      )}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={0}
        bg={'white'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        padding={'0'}
        alignItems={{ base: 'center', md: 'stretch' }}
        w={'full'}
        divider={<StackDivider borderColor={'gray.200'} />}
        bgColor={'gray.50'}
        flexGrow={0}
      >
        <VStack
          alignItems={'center'}
          spacing={'16px'}
          p={'8px'}
          w={'fit-content'}
        >
          <LineUserCard lineUser={lineUser} />
          <VStack w={'full'}>
            <NavigationButton
              size={'sm'}
              variant="blue-fill"
              to={{
                providerAccount: providerAccount,
                resourceName: 'lineUser',
                resourceId: lineUser.id,
                action: 'edit',
              }}
            >
              ユーザー情報を編集する
            </NavigationButton>
            <Text color={'gray.500'} fontSize={'14px'}>
              最終更新：{dateStringWithWeekDay(dayjs(lineUser.updateTime))}
            </Text>
          </VStack>
        </VStack>
        <VStack
          w={'full'}
          flexGrow={1}
          spacing={'16px'}
          alignItems={'flex-start'}
          p={'16px'}
        >
          <LineUserInformation lineUser={lineUser} />
          <React.Suspense fallback={<Spinner />}>
            <LineUserFormInputs
              providerAccount={providerAccount}
              lineUser={lineUser}
            />
          </React.Suspense>
        </VStack>
      </Stack>
    </VStack>
  );
};

const LineUserInformation: React.FC<{
  lineUser: LineUser;
}> = ({ lineUser }) => {
  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <Text fontSize={'md'} fontWeight={'bold'}>
        管理情報
      </Text>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        borderRadius={'4px'}
        borderColor={'gray.200'}
        borderWidth={'1px'}
        spacing={0}
        bgColor={'white'}
        divider={<StackDivider borderColor={'gray.200'} />}
      >
        <HStack w={'full'} alignItems={'stretch'} p={0}>
          <Text p={'12px'} bgColor={'gray.100'} color={'gray.500'} w={'140px'}>
            ポチコ上の別名
          </Text>
          <Text p={'12px'}>{lineUser.displayNameByProvider}</Text>
        </HStack>
        <HStack w={'full'} alignItems={'stretch'} p={0} minH={'4em'}>
          <Text
            p={'12px'}
            bgColor={'gray.100'}
            color={'gray.500'}
            w={'140px'}
            minW={'140px'}
          >
            ユーザーメモ
          </Text>

          <Box w={'full'} maxH={'10em'} bgColor={'white'} overflowY={'auto'}>
            <TextWithNewLine p={'12px'} w={'full'}>
              {lineUser.providerMemo || ''}
            </TextWithNewLine>
          </Box>
        </HStack>
      </VStack>
    </VStack>
  );
};

const LineUserFormInputs: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: LineUser;
}> = ({ providerAccount, lineUser }) => {
  const sharedBookingFormElementsQuery =
    useFetchSharedBookingFormElements(providerAccount);

  if ((sharedBookingFormElementsQuery.data || []).length === 0) {
    return null;
  }
  return (
    <VStack w={'full'} alignItems={'flex-start'}>
      <Text fontSize={'md'} fontWeight={'bold'}>
        個人情報（フォーム入力情報）
      </Text>
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        borderRadius={'4px'}
        borderColor={'gray.200'}
        borderWidth={'1px'}
        spacing={0}
        bgColor={'white'}
        divider={<StackDivider borderColor={'gray.200'} />}
      >
        {(sharedBookingFormElementsQuery.data || []).reduce((acc, element) => {
          const elementInput = lineUser.sharedFormInputs?.[element.id];
          const component = (
            <HStack key={element.id} w={'full'} alignItems={'stretch'} p={0}>
              <Text
                p={'12px'}
                bgColor={'gray.100'}
                color={'gray.500'}
                w={'140px'}
                minW={'140px'}
              >
                {element.name}
              </Text>
              <Box w={'full'} maxH={'10em'} overflowY={'auto'} p={'12px'}>
                {elementInput?.input}
              </Box>
            </HStack>
          );
          acc.push(component);
          return acc;
        }, [] as React.ReactElement[])}
      </VStack>
    </VStack>
  );
};

const LineUserBookings: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: LineUser;
}> = ({ providerAccount, lineUser }) => {
  const [includeHistory] = useAtom(includeHistoryAtom);
  const [paging, setPaging] = useAtom(bookingListPagingAtom);
  const onClickColumnHeader = React.useCallback(
    (column: keyof Booking) => {
      // 同じカラムを選択すると昇順降順を入れ替える
      // 別のカラムなら昇順に
      const direction =
        paging.sort.field === column
          ? paging.sort.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';
      setPaging((paging) => ({
        perPage: paging.perPage,
        page: 1,
        lastCursor: undefined,
        sort: {
          field: column,
          direction,
        },
      }));
    },
    [paging.sort.direction, paging.sort.field, setPaging]
  );
  const filter: BookingFilter = React.useMemo(() => {
    if (includeHistory) {
      return {
        lineUserId: lineUser.id,
      };
    } else {
      const now = dayjs();
      return {
        lineUserId: lineUser.id,
        displayDate: {
          start: now.format('YYYY-MM-DD'),
        },
      } as BookingFilter;
    }
  }, [includeHistory, lineUser.id]);
  const countQuery = useFetchBookingCount({
    providerAccountId: providerAccount.id,
    filter,
  });
  const query = useFetchBookings({
    providerAccount,
    filter,
    perPage: paging.perPage,
    sort: paging.sort,
    page: paging.page || 1,
  });
  const paginationIndicator = React.useMemo(
    () => (
      <PaginationIndicator
        perPage={paging.perPage}
        onChange={setPaging}
        page={paging.page || 1}
        total={countQuery.data || 0}
        isLoading={countQuery.isLoading || query.isLoading}
      />
    ),
    [
      countQuery.data,
      countQuery.isLoading,
      paging.page,
      paging.perPage,
      query.isLoading,
      setPaging,
    ]
  );
  const isPC = useIsPC();
  const locationFrom = React.useMemo(() => {
    return createLocationFrom(
      resourcePath({
        providerAccount,
        resourceName: 'lineUser',
        resourceId: lineUser.id,
        action: 'show',
      })
    );
  }, [lineUser.id, providerAccount]);
  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
      {isPC && paginationIndicator}
      <TableContainer w={'full'}>
        <Table variant="pochico-striped">
          <Thead>
            <Tr height={'3rem'} paddingY={'10px'}>
              {/* <Th textAlign={'center'} minWidth={'fit-content'}>
                <Checkbox bg={'white'} />
              </Th> */}
              <SortableTableHeader
                onClick={() => onClickColumnHeader('dateTimeForSort')}
                sortDirection={
                  paging.sort.field === 'dateTimeForSort'
                    ? paging.sort.direction
                    : undefined
                }
              >
                予約日
              </SortableTableHeader>
              <Th>開始時間</Th>
              <Th>予約メニュー</Th>
              <Th maxW={{ base: '200px', md: '400px' }}>予約メモ</Th>
              <Th w={'full'}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {countQuery.isFetching || query.isFetching ? (
              <Tr>
                <Td>
                  <Loading />
                </Td>
              </Tr>
            ) : (query.data || []).length === 0 ? (
              <Tr>
                <Td colSpan={6}>データがありません</Td>
              </Tr>
            ) : (
              (query.data || []).map((booking) => {
                return (
                  <Tr
                    key={booking.id}
                    justifyContent={'center'}
                    alignSelf={'center'}
                  >
                    {/* <Td textAlign={'center'} minWidth={'fit-content'}>
                      <Checkbox bg={'white'} />
                    </Td> */}
                    <Td verticalAlign={'middle'}>{booking.displayDate}</Td>
                    <Td verticalAlign={'middle'}>{booking.startTime}</Td>
                    <Td verticalAlign={'middle'}>{booking.bookingMenu.name}</Td>
                    <Td
                      maxW={{ base: '200px', md: '600px' }}
                      verticalAlign={'middle'}
                      // textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                      overflowX={'auto'}
                    >
                      <TextWithNewLine>
                        {booking.providerMemo || ''}
                      </TextWithNewLine>
                    </Td>
                    <Td verticalAlign={'middle'} textAlign={'right'}>
                      <NavigationButton
                        size={'sm'}
                        variant="white-blue"
                        locationFrom={locationFrom}
                        to={{
                          providerAccount: providerAccount,
                          resourceName: 'booking',
                          resourceId: booking.id,
                          action: 'show',
                        }}
                      >
                        詳細
                      </NavigationButton>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack w={'full'} justifyContent={'center'}>
        {paginationIndicator}
      </HStack>
    </VStack>
  );
};
