import { Divider, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { LineUser } from '@pochico/shared';
import React from 'react';
import { MdCheckCircle, MdDoDisturb } from 'react-icons/md';
import { DisplayLineUser } from '../../../firebase/types';

export const LineUserCard: React.FC<{ lineUser: DisplayLineUser }> = ({
  lineUser,
}) => {
  return (
    <VStack
      // w={'fit-content'}
      w={'326px'}
      minH={'200px'}
      alignItems={'center'}
      borderRadius={'4px'}
      spacing={'16px'}
      px={'20px'}
      py={'16px'}
    >
      <Image
        alignSelf={'center'}
        src={lineUser.pictureUrlLarge}
        boxSize={'126px'}
        borderRadius={'full'}
      />
      {lineUser.displayNameByProvider ? (
        <>
          <Text
            textAlign={'center'}
            w={'full'}
            fontWeight={'bold'}
            fontSize={'lg'}
            color={'gray.800'}
          >
            {lineUser.displayNameByProvider}
          </Text>
          <Text
            textAlign={'center'}
            w={'full'}
            fontWeight={'bold'}
            fontSize={'sm'}
            color={'gray.500'}
          >
            LINEの登録名：{lineUser.displayName}
          </Text>
        </>
      ) : (
        <Text
          textAlign={'center'}
          w={'full'}
          fontWeight={'bold'}
          fontSize={'lg'}
          color={'gray.800'}
        >
          {lineUser.displayName}
        </Text>
      )}
      <Divider w={'full'} />
      <LineUserStatus status={lineUser.status} />
    </VStack>
  );
};

const LineUserStatus: React.FC<{ status: LineUser['status'] }> = ({
  status,
}) => {
  const inner = React.useMemo(() => {
    if (status === 'blocked') {
      return (
        <HStack color={'red.500'}>
          <MdDoDisturb />
          <Text>ブロック</Text>;
        </HStack>
      );
    } else {
      return (
        <HStack color={'green.500'}>
          <MdCheckCircle />
          <Text>有効</Text>;
        </HStack>
      );
    }
  }, [status]);

  return (
    <HStack w={'full'} justifyContent={'space-between'} whiteSpace={'nowrap'}>
      <HStack>
        <Text>ステータス</Text>
        {/* <HintTooltip
          text={
            status === 'active'
              ? '現在、このLINEユーザーからの予約を受け付けています'
              : '現在、このLINEユーザーからの予約を受け付けません'
          }
        /> */}
      </HStack>
      {inner}
    </HStack>
  );
};
