import { HStack, Text, Th } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineArrowDownward, MdOutlineArrowUpward } from 'react-icons/md';
import { SortDirection } from '../../providers/dataProvider/type';

export const SortableTableHeader = ({
  onClick,
  sortDirection,
  children,
}: {
  onClick: () => void;
  sortDirection: SortDirection | undefined;
  children: React.ReactNode;
}) => {
  return (
    <Th cursor={'pointer'} onClick={onClick}>
      <HStack>
        <Text>{children}</Text>
        {sortDirection ? (
          sortDirection === 'asc' ? (
            <MdOutlineArrowUpward />
          ) : (
            <MdOutlineArrowDownward />
          )
        ) : (
          <></>
        )}
      </HStack>
    </Th>
  );
};
