import { Box } from '@chakra-ui/react';
import React from 'react';

export const AlertIcon = () => {
  return (
    <Box
      minWidth="16px"
      maxWidth="36px"
      w={{ base: '16px', md: '36px' }}
      h={'37px'}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="18" fill="#FA6E78" />
        <circle cx="18" cy="18" r="18" stroke="white" />
        <circle cx="18" cy="18" r="18" stroke="black" strokeOpacity="0.2" />
        <path
          d="M16.752 21.832L16.128 13.624L16.008 10.048H19.968L19.848 13.624L19.224 21.832H16.752ZM18 28.24C16.752 28.24 15.864 27.352 15.864 26.104C15.864 24.856 16.776 23.992 18 23.992C19.2 23.992 20.112 24.856 20.112 26.104C20.112 27.352 19.2 28.24 18 28.24Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
