import {
  Box,
  Modal,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';

const LoadingOverlayContext = React.createContext<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}>({
  isOpen: false,
  onOpen: () => {
    return;
  },
  onClose: () => {
    return;
  },
});
export const useLoadingOverlayContext = () =>
  React.useContext(LoadingOverlayContext);

export const LoadingOverlayContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const onClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <LoadingOverlayContext.Provider
      value={{
        isOpen,
        onOpen,
        onClose,
      }}
    >
      {children}
      <LoadingOverlay isOpen={isOpen} onClose={onClose} />
    </LoadingOverlayContext.Provider>
  );
};

const LoadingOverlay = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const isPc = useBreakpointValue({ base: false, md: true });
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      scrollBehavior={'inside'}
      size={'sm'}
    >
      <Box position={'relative'}>
        <ModalOverlay bgColor={'transparent.black'}>
          <Box
            display={'flex'}
            position={'absolute'}
            zIndex={10000} // ModalOverlayが 1300 程度
            top={'40%'}
            left={isPc ? '40%' : '40vw'}
            width={'60px'}
            height={'60px'}
          >
            <Spinner
              color="pochico.main"
              width={'full'}
              height={'full'}
              thickness="8px"
              size={'xl'}
            />
          </Box>
        </ModalOverlay>
      </Box>
    </Modal>
  );
};
