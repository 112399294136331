import dayjs from 'dayjs';
import React from 'react';

import { LineMessagePreview } from './LineMessagePreview';
import { TextWithMacro } from './TextWithMacro';

export const BookingSuccessMessagePreview: React.FC<{
  bookingSuccess: string;
}> = ({ bookingSuccess }) => {
  return (
    <LineMessagePreview>
      {/* 現在時刻をいれておく */}
      <TextWithMacro
        minHeight={'2rem'}
        text={bookingSuccess}
        date={dayjs()}
        // suffix={CONSTANTS.BOOKING_SUCCESS_SUFFIX}
      />
    </LineMessagePreview>
  );
};
