import { Button, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import Spacer from '../components/fragments/Spacer';
import { Path } from '../routers/Path';

const GcalAuthAlreadyCreated: FC = () => {
  setDocumentTitle('Googleカレンダー連携がすでに存在します');
  return (
    <VStack>
      <Spacer height={'40px'} />
      <Text fontSize={'xl'} fontWeight={'bold'}>
        既に他のGoogleカレンダーに紐ついているため、連携ができませんでした。
      </Text>
      <Spacer height={'40px'} />
      <Text>
        もし複数人でカレンダーを共有したい場合はGoogleカレンダーの共有機能を使ってみてください。
        <br />
        <a
          href="https://help.pochico.app/0ea4ead20c324e0ba3adb07c0fc56927"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'blue' }}
        >
          ヘルプページ〈連携したGoogleカレンダーを複数人で共有したい〉
        </a>
        <br />
        <br />
        <br />
        もし誤って連携してしまった等、一度Googleカレンダー連携を解除したい場合は、以下へご連絡ください。
        <br />
        {'サポート窓口: '}
        <a
          href="mailto:support-pochico@play-tech.jp"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'blue' }}
        >
          support-pochico@play-tech.jp
        </a>
      </Text>
      <Spacer height={'60px'} />
      <Link to={Path.configuration}>
        <Button colorScheme={'green'}>{'設定画面へ戻る'}</Button>
      </Link>
      <Spacer height={'40px'} />
    </VStack>
  );
};

export default GcalAuthAlreadyCreated;
