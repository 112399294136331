import {
  Button,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import { atom, useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { DisplayBooking } from '../../../firebase/types';
import { useFetchBookings } from '../../../hooks/booking';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useFetchLineUser } from '../../../hooks/lineUser';
import { createLocationFrom } from '../../../hooks/locationState';
import { Path } from '../../../routers/Path';
import { NavigationButton } from '../../ui/NavigationButton';
import { LineUserChip } from '../lineUsers/chip';

const selectedDateRangeAtom = atom<'today' | 'tomorrow'>('today');

export const DashboardBookingList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const bookingMenusQuery = useFetchBookingMenus({ providerAccount });
  const now = React.useMemo(() => dayjs(), []);
  const duration = React.useMemo(() => {
    return {
      start: now.startOf('day').format('YYYY-MM-DD'),
      end: now.add(2, 'day').startOf('day').format('YYYY-MM-DD'),
    };
  }, [now]);
  const bookingsQuery = useFetchBookings({
    providerAccount,
    filter: {
      displayDate: duration,
    },
    page: 1,
    perPage: 1000,
    sort: {
      field: 'dateTimeForSort',
      direction: 'asc',
    },
  });

  const bookingMenuList = bookingMenusQuery.data ?? [];
  const dateRange = useAtomValue(selectedDateRangeAtom);
  const date = React.useMemo(() => {
    const d = (() => {
      if (dateRange === 'today') {
        return now;
      } else {
        return now.add(1, 'day');
      }
    })();
    return d.format('YYYY-MM-DD');
  }, [dateRange, now]);

  const bookings = React.useMemo(() => {
    return (bookingsQuery.data || []).filter((b) => b.date === date);
  }, [bookingsQuery.data, date]);

  if (bookingMenusQuery.isLoading || bookingMenusQuery.isLoading) {
    return (
      <HStack>
        <Spinner />
        <Text>Loading...</Text>
      </HStack>
    );
  }
  return (
    <VStack
      alignItems={'flex-start'}
      w={'full'}
      padding={{ base: 0, md: '16px' }}
      borderRadius={'4px'}
      borderColor={'gray.300'}
      borderWidth={{ base: 0, md: '1px' }}
      bgColor={'white'}
      spacing={'16px'}
    >
      <HStack alignItems={'center'} justifyContent={'center'}>
        <DateRangeRadio dateRange={'today'}>
          {`今日 (${now.format('M/D')})`}
        </DateRangeRadio>
        <DateRangeRadio dateRange={'tomorrow'}>
          {`明日 (${now.add(1, 'd').format('M/D')})`}
        </DateRangeRadio>
      </HStack>
      <BookingListTable
        providerAccount={providerAccount}
        bookingMenuList={bookingMenuList}
        bookings={bookings}
      />
    </VStack>
  );
};

const DateRangeRadio: React.FC<{
  children: string;
  dateRange: 'today' | 'tomorrow';
}> = ({ children, dateRange }) => {
  const [selectedDateRange, setSelectedDateRange] = useAtom(
    selectedDateRangeAtom
  );
  return (
    <Button
      size={'md'}
      w={'140px'}
      whiteSpace={'nowrap'}
      variant={dateRange === selectedDateRange ? 'blue-fill' : 'white-blue'}
      onClick={() => setSelectedDateRange(dateRange)}
    >
      {children}
    </Button>
  );
};

const BookingListTable: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  bookings: DisplayBooking[];
}> = ({ providerAccount, bookingMenuList, bookings }) => {
  return (
    <VStack spacing={0} w={'full'}>
      <TableContainer w={'full'}>
        <Table variant="pochico-striped">
          <Thead>
            <Tr height={'3rem'} paddingY={'10px'}>
              {/* <Th>予約日</Th> */}
              <Th>開始時間</Th>
              <Th>予約メニュー</Th>
              <Th>予約ユーザー名</Th>
              <Th w={'full'}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {bookings.length > 0 ? (
              bookings.map((booking) => {
                return (
                  <BookingTableRow
                    booking={booking}
                    key={booking.id}
                    providerAccount={providerAccount}
                    bookingMenus={bookingMenuList}
                  />
                );
              })
            ) : (
              <Tr>
                <Td colSpan={8}>予約はありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

const BookingTableRow: React.FC<{
  providerAccount: ProviderAccount;
  booking: DisplayBooking;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, booking, bookingMenus }) => {
  const bookingMenu = React.useMemo(
    () => bookingMenus.find((menu) => menu.id === booking.bookingMenu.id),
    [bookingMenus, booking.bookingMenu.id]
  );
  const lineUser = useFetchLineUser({
    providerAccount,
    id: booking.lineUserId,
  });

  return (
    <Tr justifyContent={'center'} alignSelf={'center'}>
      {/* <Td verticalAlign={'middle'}>{booking.displayDate}</Td> */}
      <Td verticalAlign={'middle'}>{booking.startTime}</Td>
      <Td verticalAlign={'middle'}>{bookingMenu?.name}</Td>
      <Td verticalAlign={'middle'}>
        {booking.userName ? (
          booking.userName
        ) : booking.lineUserId ? (
          lineUser.data ? (
            <LineUserChip
              providerAccount={providerAccount}
              lineUser={lineUser.data}
            />
          ) : (
            <Spinner />
          )
        ) : (
          ''
        )}
      </Td>
      <Td verticalAlign={'middle'} textAlign={'right'}>
        <NavigationButton
          size={'sm'}
          variant="white-blue"
          locationFrom={createLocationFrom(Path.home)}
          to={{
            providerAccount: providerAccount,
            resourceName: 'booking',
            resourceId: booking.id,
            action: 'show',
          }}
        >
          詳細
        </NavigationButton>
      </Td>
    </Tr>
  );
};
