import { Box, BoxProps, Link, Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export const TextWithLinks: React.FC<
  {
    children: string;
  } & BoxProps
> = ({ children, ...props }) => {
  const body = React.useMemo(() => {
    const items = children.split(/(http[s]?:\/\/[^\s<]+)/g);
    return (
      <Box display={'inline-block'} width={'full'} gap={0} {...props}>
        {items.map((item, idx) => {
          const key = `text-with-link-${item}-${Math.random()}`;
          if (item.startsWith('http')) {
            return (
              <Link
                display={'inline'}
                key={key}
                textDecoration={'underline'}
                color={'blue'}
                href={item}
                target="_blank"
              >
                {item}
              </Link>
            );
          } else {
            return (
              <TextWithNewLine display={'inline'} key={key}>
                {item}
              </TextWithNewLine>
            );
          }
        })}
      </Box>
    );
  }, [children, props]);

  return body;
};

const TextWithNewLine: React.FC<TextProps & { children: string }> = ({
  children,
  ...props
}) => (
  <Text
    maxWidth={'90vw'}
    {...props}
    dangerouslySetInnerHTML={{
      __html: children.replace(new RegExp('\n', 'g'), '<br />'),
    }}
  ></Text>
);
