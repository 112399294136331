import { Spinner } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { DisplaySpot } from '../../../firebase/types';
import { useFetchBookings } from '../../../hooks/booking';
import { LocationFrom } from '../../../hooks/locationState';
import { BookingUserList } from '../../ui/BookingUser';

export const SpotBookingUsers: React.FC<{
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  locationFrom: LocationFrom;
}> = ({ providerAccount, spot, locationFrom }) => {
  const bookings = useFetchBookings({
    providerAccount,
    filter: {
      ids: spot.bookingIds,
    },
    perPage: spot.maxBookings,
    sort: { field: 'dateTimeForSort', direction: 'asc' },
    page: 1,
  });
  if (bookings.isLoading) {
    return <Spinner />;
  }

  return (
    <BookingUserList
      providerAccount={providerAccount}
      spot={spot}
      locationFrom={locationFrom}
      bookings={[
        ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
        // ...(bookings.data || []),
      ]}
    />
  );
};
