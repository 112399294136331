import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const inputLikeBaseStyle = {
  borderWidth: '1px',
  borderColor: 'border.normal',
  bgColor: 'white',
  color: 'gray.800',
  _focusVisible: {
    borderColor: 'border.focusVisible',
  },
  _hover: {
    borderColor: 'border.hover',
  },
  _readOnly: {
    cursor: 'default',
    _hover: {
      borderColor: 'border.normal',
    },
    _focus: {
      borderColor: 'border.normal',
    },
  },
};

const outline = definePartsStyle({
  // define the part you're going to style
  field: inputLikeBaseStyle,
});

export const Input = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    variant: 'outline',
  },
});
