import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Switch,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { MACRO, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CONSTANTS from '../../../commons/constants';
import { BookingMenuCreateParams } from '../../../firebase/types';
import { HintTooltip } from '../../ui/HintTooltip';

// 予約完了メッセージの入力フォーム
export const BookingSuccessMessageInput: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [cursorPosition, setCursorPosition] = React.useState<number>(0);
  const {
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<BookingMenuCreateParams>();
  const [bookingSuccessText, bookingSuccessEnabled] = useWatch({
    control,
    name: ['customText.bookingSuccess', 'bookingSuccessEnabled'],
  });
  const [customBookingSuccess, setCustomBookingSuccess] =
    React.useState<boolean>(() =>
      Boolean(
        bookingSuccessText &&
          bookingSuccessText !== CONSTANTS.DEFAULT_BOOKING_SUCCESS
      )
    );
  const [lastInput, setLastInput] = React.useState<string>('');

  const onChange = React.useCallback(
    (newValue: boolean) => {
      setCustomBookingSuccess(newValue);
      // チェックボックスがtrueの時だけ入力欄を有効化する
      if (newValue) {
        // editの際はすでにある値を使う or デフォルト値を使う
        setValue(
          'customText.bookingSuccess',
          lastInput ||
            bookingSuccessText ||
            providerAccount?.customText?.bookingSuccess ||
            CONSTANTS.DEFAULT_BOOKING_SUCCESS,
          {
            shouldDirty: true,
          }
        );
      } else {
        setLastInput(bookingSuccessText || '');
        setValue(
          'customText.bookingSuccess',
          CONSTANTS.DEFAULT_BOOKING_SUCCESS,
          { shouldDirty: true }
        );
      }
    },
    [
      bookingSuccessText,
      lastInput,
      providerAccount?.customText?.bookingSuccess,
      setValue,
    ]
  );

  const { ref, ..._register } = register('customText.bookingSuccess', {
    required: bookingSuccessEnabled && customBookingSuccess,

    onBlur: () => {
      if (textareaRef.current) {
        setCursorPosition(textareaRef.current.selectionStart);
      }
    },
  });

  const onClickInsertDateButton = React.useCallback(() => {
    const [left, right] = [
      bookingSuccessText?.slice(0, cursorPosition),
      bookingSuccessText?.slice(cursorPosition),
    ];
    setValue(
      'customText.bookingSuccess',
      `${left}${MACRO.fullDateJa}${right}`,
      { shouldDirty: true }
    );
  }, [bookingSuccessText, cursorPosition, setValue]);

  const hintText = React.useMemo(() => {
    const base = `[予約日時] と入力すると予約完了メッセージ送信時に置換されて予約日時が挿入されます。`;
    return base;
  }, []);
  const isOn = bookingSuccessEnabled && customBookingSuccess;

  return (
    <Flex direction={'column'} w={'full'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <Text fontWeight={'bold'}>予約完了メッセージのカスタマイズ</Text>

        <HStack spacing={2}>
          <Text>{isOn ? 'ON' : 'OFF'}</Text>
          <Switch
            key={`bookingSuccessCustomize-${isOn}`}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            defaultChecked={bookingSuccessEnabled && customBookingSuccess}
            isDisabled={!bookingSuccessEnabled}
          />
        </HStack>
      </HStack>
      {isOn && (
        <Flex
          mt={'8px'}
          direction={{ base: 'column', lg: 'row' }}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          // minHeight={'20rem'}
        >
          <Box
            marginRight={'16px'}
            w={{ base: 'full', lg: 'full' }}
            minWidth={{ base: 'full', lg: '20rem' }}
          >
            <FormControl
              isRequired={false}
              // isInvalid={!!errors.customText?.bookingSuccess}
            >
              <VStack spacing={'4px'} alignItems={'flex-start'}>
                <HStack alignItems={'center'} justifyContent={'flex-start'}>
                  <FormLabel margin={'4px'}>説明文</FormLabel>
                  <HintTooltip text={hintText} />
                </HStack>
                <Textarea
                  maxWidth={'full'}
                  minHeight={{ base: '8rem' }}
                  placeholder={
                    'ご予約ありがとうございます！\n当日は、どうぞお気をつけてお越しくださいませ。\nお会いできることを楽しみにしております！'
                  }
                  {..._register}
                  ref={(e) => {
                    ref(e);
                    textareaRef.current = e;
                  }}
                />
                <HStack
                  w={'full'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant={'white-blue'}
                    size={'sm'}
                    onClick={onClickInsertDateButton}
                  >
                    予約日時を挿入
                  </Button>
                </HStack>
                <FormErrorMessage>
                  {errors.customText?.bookingSuccess?.message}
                </FormErrorMessage>
              </VStack>
            </FormControl>
          </Box>
          {/* <Flex
            marginTop={'2rem'}
            w={'full'}
            minWidth={{ base: 'full', lg: '20rem' }}
            minHeight={{ base: '8rem', lg: '10rem' }}
          >
            <BookingSuccessMessagePreview
              bookingSuccess={bookingSuccess || ''}
            />
          </Flex> */}
        </Flex>
      )}
    </Flex>
  );
};
