import { ProviderAccount } from '@pochico/shared';
import React, { FC } from 'react';

import { LineOALinkMessagingApi } from './LineOaLinkMessagingApi';
import { LineOALinkModuleApi } from './LineOaLinkModuleApi';

type Props = {
  lineChannelType: ProviderAccount['lineChannelType'];
  isInitialFlow: boolean;
};

const _tag = '[line-oa-link]';
export const LineOALink: FC<Props> = ({ lineChannelType }) => {
  if (lineChannelType === 'messagingApi') {
    return <LineOALinkMessagingApi />;
  } else {
    return <LineOALinkModuleApi />;
  }
};
