import { createQueryKeys } from '@lukemorales/query-key-factory';
import { LineUser, ProviderAccount, waitAtLeast } from '@pochico/shared';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { UpdateData } from 'firebase/firestore';
import CONSTANTS from '../commons/constants';
import { LineUserFilter } from '../components/features/LineUsers';
import { DisplayLineUser, LineUserUpdateParams } from '../firebase/types';
import {
  bulkUpdateLineUsers,
  fetchAllLineUserUsingCache,
  getLineUser,
  getLineUserCount,
  getLineUserList,
  lineUserFilterOutsideFirestore,
  updateLineUser,
} from '../providers/dataProvider/lineUser';
import { Sort } from '../providers/dataProvider/type';
import { usePaginationQuery } from './usePaginationQuery';

export const lineUserQueryKey = createQueryKeys('lineUsers', {
  all: (providerAccountId: string) => [providerAccountId, 'all'],
  show: (providerAccountId: string, id: LineUser['id']) => [
    providerAccountId,
    id,
  ],
  count: (input: {
    providerAccountId: ProviderAccount['id'];
    filter: LineUserFilter;
  }) => [input.providerAccountId, input.filter],
  list: (input: {
    providerAccountId: ProviderAccount['id'];
    filter: LineUserFilter;
    perPage: number;
    sort: Sort<LineUser>;
    // page: number;
  }) => [
    input.providerAccountId,
    input.filter,
    input.perPage,
    // input.page,
    input.sort,
  ],
  listForAutoComplete: (input: {
    providerAccountId: ProviderAccount['id'];
    perPage: number;
    sort: Sort<LineUser>;
    input: string;
  }) => [
    input.providerAccountId,
    { input: input.input },
    input.perPage,
    input.sort,
  ],
});

export const useFetchLineUser = ({
  providerAccount,
  id,
}: {
  providerAccount: ProviderAccount;
  id: LineUser['id'] | undefined;
}) => {
  const query = useQuery({
    queryKey: lineUserQueryKey.show(providerAccount.id, id as string).queryKey,
    queryFn: () => getLineUser(providerAccount.id, id as string),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 1, // 1min
    enabled: !!id,
  });

  return query;
};

export const useFetchLineUsers = ({
  providerAccount,
  filter,
  perPage,
  sort,
  page,
  enabled,
}: {
  providerAccount: ProviderAccount;
  filter: LineUserFilter;
  perPage: number;
  sort: Sort<LineUser>;
  page: number;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const query = usePaginationQuery<DisplayLineUser>({
    queryKey: lineUserQueryKey.list({
      providerAccountId: providerAccount.id,
      perPage,
      filter,
      sort,
    }).queryKey,
    queryFn: (pagination) =>
      getLineUserList(queryClient, providerAccount.id, {
        filter,
        pagination,
      }).then((result) => {
        return result.data;
      }),
    pagination: {
      perPage,
      sort,
    },
    page,
    enabled: enabled ?? true,
  });
  return query;
};

export const useFetchLineUserCount = ({
  providerAccount,
  filter,
}: {
  providerAccount: ProviderAccount;
  filter: LineUserFilter;
}) => {
  const shouldFetchAll = Boolean(
    filter?.displayName || filter?.displayNameByProvider
  );
  const allUsersQuery = useFetchLineUsers({
    providerAccount,
    filter: {
      archived: false,
    },
    perPage: CONSTANTS.PAGINATION_MAX_LIMIT + 1,
    sort: { field: 'lastBookedAt', direction: 'desc' },
    page: 1,
    enabled: shouldFetchAll,
  });
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: lineUserQueryKey.count({
      providerAccountId: providerAccount.id,
      filter,
    }).queryKey,
    queryFn: () => {
      if (shouldFetchAll) {
        return (allUsersQuery.data || []).filter(
          lineUserFilterOutsideFirestore(filter)
        ).length;
      } else {
        return getLineUserCount(queryClient, providerAccount.id, filter);
      }
    },
    placeholderData: keepPreviousData,
    enabled: !shouldFetchAll || !allUsersQuery.isLoading,
  });
  return query;
};

export const useUpdateLineUserMutation = ({
  providerAccount,
}: {
  providerAccount: ProviderAccount;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (input: LineUserUpdateParams) => {
      return waitAtLeast(updateLineUser(providerAccount.id, input), 500);
    },
    onSuccess: async () => {
      return queryClient.invalidateQueries({
        queryKey: lineUserQueryKey._def,
      });
    },
  });
  return mutation;
};

export const useBulkUpdateLineUserMutation = (
  providerAccount: ProviderAccount
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: (UpdateData<LineUser> & { id: string })[]) =>
      waitAtLeast(bulkUpdateLineUsers(providerAccount.id, params), 500),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: lineUserQueryKey._def });
    },
  });
};
export const useFetchAllLineUsers = ({
  providerAccount,
  opts,
}: {
  providerAccount: ProviderAccount;
  opts?: { enabled?: boolean };
}) => {
  const client = useQueryClient();
  const query = useQuery({
    queryKey: lineUserQueryKey.all(providerAccount.id).queryKey,
    queryFn: async () => fetchAllLineUserUsingCache(client, providerAccount.id),
    enabled: opts?.enabled ?? true,
  });
  return query;
};
