import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { LineUserEdit } from '../../components/features/lineUsers/edit';
import { useUrlPath } from '../../hooks/useUrlPath';

export const LineUserEditPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('LINEユーザーの編集');

  const urlPath = useUrlPath();
  if (
    !urlPath ||
    urlPath.resourceName !== 'lineUser' ||
    urlPath.action !== 'edit' ||
    !urlPath.resourceId
  ) {
    console.log('invalid url path', urlPath);
    return (
      <Navigate
        replace={true}
        to={`/${providerAccount.id}/${providerAccount.botId}/lineUser`}
      />
    );
  }

  return (
    <LineUserEdit providerAccount={providerAccount} id={urlPath.resourceId} />
  );
};
