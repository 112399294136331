import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { SpotCreate } from '../../components/features/spots/create';
import { useUrlPath } from '../../hooks/useUrlPath';

export const SpotCreatePage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約枠の作成');

  const input = React.useMemo(() => {
    const p = new URLSearchParams(location.search);
    if (p.has('date') && p.has('bookingMenuId')) {
      return {
        date: p.get('date')!,
        startTime: p.get('startTime') || '10:00',
        bookingMenuId: p.get('bookingMenuId')!,
      };
    } else {
      return undefined;
    }
  }, []);
  const urlPath = useUrlPath();
  if (
    !urlPath ||
    urlPath.resourceName !== 'spot' ||
    urlPath.action !== 'create'
  ) {
    console.log('invalid url path', urlPath);
    return (
      <Navigate
        replace={true}
        to={`/${providerAccount.id}/${providerAccount.botId}/spot`}
      />
    );
  }

  return <SpotCreate providerAccount={providerAccount} input={input} />;
};
