import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  BookingForm,
  BookingMenu,
  ProviderAccount,
  dateTimeStringWithWeekDay,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdCheckCircle, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useFetchBookingForms } from '../../../../hooks/bookingForms';
import { useFetchBookingMenus } from '../../../../hooks/bookingMenus';
import { useIsPC } from '../../../../hooks/useIsPC';
import { resourcePath } from '../../../../hooks/useUrlPath';
import { Layout, LayoutBodyWithQuery } from '../../../ui/Layout';
import { Link } from '../../../ui/Link';
import { NavigationButton } from '../../../ui/NavigationButton';
import { PageTitle } from '../../../ui/PageTitle';
import { WebLink } from '../../../ui/WebLink';
import { DeleteBookingFormButton } from './DeleteBookingFormButton';

export const BookingFormList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const query = useFetchBookingForms({ providerAccount, enabled: true });
  const bookingMenusQuery = useFetchBookingMenus({ providerAccount });
  const pathToSharedBookingForms = React.useMemo(
    () =>
      resourcePath({
        action: 'list',
        resourceName: 'sharedBookingForm',
        providerAccount,
      }),
    [providerAccount]
  );
  const isPC = useIsPC();

  return (
    <Layout
      pageTitle={
        <HStack>
          <PageTitle color={'black'}>質問フォーム</PageTitle>
          <WebLink
            href={'https://help.pochico.app/11aeddbd00d78061a187c9f1586bd69f'}
            target={'_blank'}
            borderBottomWidth={'1px'}
            borderBottomColor={'blue.500'}
            textDecoration={'none'}
            _hover={{
              color: 'blue.400',
              textDecoration: 'none',
            }}
            _active={{
              color: 'blue.200',
              textDecoration: 'none',
            }}
          >
            <HStack>
              <Text>質問フォームの使い方</Text>
              <Icon as={FaExternalLinkAlt} fontSize={'sm'} />
            </HStack>
          </WebLink>
        </HStack>
      }
    >
      <Text color={'gray.700'} fontWeight={400}>
        予約メニューに紐付けを行うと、予約時にフォームを表示し、予約者の詳細な情報を収集することができます
      </Text>
      <Box h={'20px'} />
      <LayoutBodyWithQuery<BookingForm[]> query={query}>
        {(bookingForms) => {
          return (
            <LayoutBodyWithQuery<BookingMenu[]> query={bookingMenusQuery}>
              {(bookingMenus) => {
                return (
                  <BookingFormListView
                    providerAccount={providerAccount}
                    allBookingMenus={bookingMenus}
                    bookingForms={bookingForms}
                  />
                );
              }}
            </LayoutBodyWithQuery>
          );
        }}
      </LayoutBodyWithQuery>
    </Layout>
  );
};

const BookingFormListView: React.FC<{
  providerAccount: ProviderAccount;
  bookingForms: BookingForm[];
  allBookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingForms, allBookingMenus }) => {
  const isPC = useIsPC();
  const pathToSharedBookingForms = React.useMemo(
    () =>
      resourcePath({
        action: 'list',
        resourceName: 'sharedBookingForm',
        providerAccount,
      }),
    [providerAccount]
  );

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      spacing={'16px'}
      overflowX={'auto'}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        width={{ base: 'full', md: '600px' }}
        alignItems={{ base: 'center', md: 'center' }}
        spacing={{ base: 0, md: '20px' }}
      >
        <Tooltip
          label={'質問フォームは最大20個までです。'}
          pointerEvents={'fill'}
          hasArrow
        >
          <span
            style={{
              width: isPC ? 'fit-content' : '100%',
            }}
          >
            <NavigationButton
              size={'sm'}
              variant="blue-fill"
              w={{ base: 'full', md: 'fit-content' }}
              my={{ base: '16px', md: 0 }}
              isDisabled={bookingForms.length >= 20}
              to={{
                providerAccount: providerAccount,
                resourceName: 'bookingForm',
                action: 'create',
              }}
            >
              フォームを作成
            </NavigationButton>
          </span>
        </Tooltip>

        {
          <NavigationButton
            to={pathToSharedBookingForms}
            whiteSpace={'nowrap'}
            variant={'link'}
          >
            個人情報質問のカスタマイズ
          </NavigationButton>
        }
      </Stack>
      {bookingForms.length === 0 && (
        <EmptyBookingFormList providerAccount={providerAccount} />
      )}
      <Grid
        // columns={2}
        rowGap={'32px'}
        columnGap={'32px'}
        w={'full'}
        gridTemplateColumns={{
          base: '1fr',
          md: 'repeat(2, minmax(300px, 600px))',
        }}
        overflowX={'auto'}
      >
        {bookingForms.map((form, i) => (
          <BookingFormListItem
            key={`bookingForm-grid-${form.id}`}
            providerAccount={providerAccount}
            form={form}
            allBookingMenus={allBookingMenus}
          />
        ))}
      </Grid>
      {/* Stackの組合せだと2つ要素がある列と1つだけ要素がある列で要素の横幅が変わってしまう */}
      {/* <VStack
        spacing={'32px'}
        gap={'0px'}
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
      >
        {bookingForms.chunk(isPC ? 2 : 1).flatMap((forms, i) => (
          <HStack
            w={'full'}
            p={0}
            spacing={'32px'}
            key={`bookingFormRow-${i}`}
            alignItems={'flex-start'}
          >
            {forms.map((form, j) => (
              <BookingFormListItem
                key={`bookingForm-${form.id}`}
                providerAccount={providerAccount}
                form={form}
                allBookingMenus={allBookingMenus}
              />
            ))}
          </HStack>
        ))}
      </VStack> */}
    </VStack>
  );
};

const BookingFormListItem: React.FC<{
  providerAccount: ProviderAccount;
  allBookingMenus: BookingMenu[];
  form: BookingForm;
}> = ({ providerAccount, allBookingMenus, form }) => {
  const bookingMenus = React.useMemo(() => {
    return allBookingMenus.filter((menu) => {
      if (menu.bookingForm && menu.bookingForm.enabled) {
        return menu.bookingForm.formId === form.id;
      }
      return false;
    });
  }, [allBookingMenus, form.id]);

  return (
    <VStack
      // w={'full'}
      gap={0}
      // w={{ base: 'full', md: '600px' }}
      w={'full'}
      maxW={{ base: 'full', md: '600px' }}
      borderRadius={'4px'}
      borderColor={'gray.300'}
      borderWidth={'1px'}
      alignItems={'flex-start'}
      spacing={0}
      bg={'white'}
    >
      <Text p={'16px'} fontWeight={'bold'} color={'gray.900'}>
        {form.name}
      </Text>
      <Divider borderColor={'gray.300'} />

      <VStack
        p={'16px'}
        w={'full'}
        alignItems={'flex-start'}
        gap={'16px'}
        divider={<Divider borderColor={'gray.300'} />}
        spacing={0}
      >
        {bookingMenus.length > 0 && (
          <AssociatedBookingMenuList
            providerAccount={providerAccount}
            bookingMenus={bookingMenus}
          />
        )}
        <VStack w={'full'} p={0} gap={'16px'}>
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            gap={'4px'}
            spacing={'4px'}
            fontSize={'14px'}
            color={'gray.500'}
          >
            <Text>
              作成日時: {dateTimeStringWithWeekDay(dayjs(form.createTime))}
            </Text>
            <Text>
              最終更新: {dateTimeStringWithWeekDay(dayjs(form.updateTime))}
            </Text>
          </VStack>
          <HStack w={'full'} justifyContent={'flex-end'} spacing={'12px'}>
            <Tooltip
              label={'使用中のフォームは削除できません'}
              isDisabled={bookingMenus.length === 0}
              pointerEvents={'fill'}
              hasArrow
            >
              <span>
                <DeleteBookingFormButton
                  providerAccount={providerAccount}
                  bookingForm={form}
                  isDisabled={bookingMenus.length > 0}
                />
              </span>
            </Tooltip>
            <NavigationButton
              variant={'blue-fill'}
              to={{
                providerAccount,
                resourceName: 'bookingForm',
                action: 'edit',
                resourceId: form.id,
              }}
            >
              編集する
            </NavigationButton>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

const AssociatedBookingMenuList: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <VStack w={'full'} p={0} gap={expanded ? '0' : 0}>
      <HStack w={'full'} justifyContent={'space-between'}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: 0, md: '12px' }}
        >
          <HStack gap={'4px'}>
            <Icon
              as={MdCheckCircle}
              color={'green.500'}
              w={'24px'}
              h={'24px'}
            />
            <Text color={'green.500'} fontWeight={'bold'} whiteSpace={'nowrap'}>
              使用中
            </Text>
          </HStack>
          <Text color={'gray.600'} fontWeight={700}>
            {bookingMenus.length}件の予約メニュー
          </Text>
        </Stack>
        <Button variant={'transparent'} onClick={() => setExpanded(!expanded)}>
          <HStack spacing={'4px'} gap={'4px'} color={'blue.500'}>
            <Text textDecoration={'underline'}>
              {expanded ? '閉じる' : '詳細'}
            </Text>
            <Icon
              as={expanded ? MdExpandLess : MdExpandMore}
              w={'20px'}
              h={'20px'}
            />
          </HStack>
        </Button>
      </HStack>
      {expanded && (
        <HStack
          w={'full'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          flexWrap={'wrap'}
          gap={'10px'}
          spacing={0}
          p={0}
          m={0}
        >
          {bookingMenus.map((bookingMenu, i) => {
            return (
              <Link
                to={resourcePath({
                  providerAccount,
                  resourceName: 'bookingMenu',
                  action: 'show',
                  resourceId: bookingMenu.id,
                })}
                key={`bookingMenu-badge-${bookingMenu.id}-${i}`}
              >
                <Badge colorScheme={'gray'}>{bookingMenu.name}</Badge>
              </Link>
            );
          })}
        </HStack>
      )}
    </VStack>
  );
};

const EmptyBookingFormList: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  // return (
  //   <Button
  //     as={Link}
  //     to={resourcePath({
  //       providerAccount: providerAccount,
  //       resourceName: 'bookingForm',
  //       action: 'create',
  //     })}
  //     py={'20px'}
  //     h={'128px'}
  //     variant={'transparent-clickable'}
  //     w={'256px'}
  //     // onClick={() => onClickToAdd(0)}
  //     borderRadius={'4px'}
  //     borderWidth={'1px'}
  //     borderStyle={'dashed'}
  //     borderColor={'gray.500'}
  //   >
  //     <HStack color={'gray.500'} p={0} m={0} fontSize={'18px'}>
  //       <Icon as={MdAdd} m={0} />
  //       <Text>質問フォームを作成する</Text>
  //     </HStack>
  //   </Button>
  // );
  return <Text color={'black.700'}>作成済みの質問フォームはありません</Text>;
};
