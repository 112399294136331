import React, { createContext, FC, useContext } from 'react';

import { AuthState, useAuthentication } from '../hooks/useAuthentication';

const initialAuthState: AuthState = {
  status: 'not_initialized',
  initialized: false,
  firebaseUser: undefined,
  providerAccount: undefined,
  role: 'admin',
  refetch: Promise.resolve,
  clear: Promise.resolve,
};

const AuthStateContext: React.Context<AuthState> = createContext<AuthState>({
  ...initialAuthState,
});

export const useAuthState = () => useContext(AuthStateContext);

export const AuthProviderContainer: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const state = useAuthentication();

  return (
    <AuthStateContext.Provider value={state}>
      {children}
    </AuthStateContext.Provider>
  );
};
