import { Button, HStack, Link, Text, Tooltip, VStack } from '@chakra-ui/react';
import React from 'react';
import { SavedFilterContext } from './SavedFilter';
import { useListSavedFilterDialog } from './useListSavedFilterDialog';

// PC向けに保存した検索条件を表示・管理するコンポーネント
export const SavedFiltersForPC = <T,>({
  context,
}: {
  context: NonNullable<SavedFilterContext<T>>;
}) => {
  const {
    dispatcher: { remove, setSelectedFilter },
    state: { data, selectedFilter },
  } = context;
  const { open } = useListSavedFilterDialog({
    data,
    remove,
    toComponent: context.dispatcher.toComponent,
  });
  return (
    <VStack
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.100'}
      alignItems={'flex-start'}
      bgColor={'white'}
      p={'16px'}
    >
      <HStack spacing={'20px'}>
        <Text fontWeight={'bold'}>保存した検索条件</Text>
        <Link color="blue" textDecoration={'underline'} onClick={open}>
          詳細
        </Link>
      </HStack>
      <HStack gap={'8px'}>
        {data.length > 0 ? (
          data.map((savedFilter) => (
            <Tooltip
              key={`saved-filter-${savedFilter.name}`}
              hasArrow
              label={savedFilter.name}
            >
              <Button
                onClick={() => {
                  setSelectedFilter(savedFilter);
                }}
                size={'sm'}
                variant={
                  selectedFilter?.name === savedFilter.name
                    ? 'blue-fill'
                    : 'white-blue'
                }
              >
                {savedFilter.name.slice(0, 20)}
                {savedFilter.name.length > 20 ? '...' : ''}
              </Button>
            </Tooltip>
          ))
        ) : (
          <Text>なし</Text>
        )}
      </HStack>
    </VStack>
  );
};
