// export const DayOfWeek = [
//   'mon',
//   'tue',
//   'wed',
//   'thu',
//   'fri',
//   'sat',
//   'sun',
// ] as const;
// export type DayOfWeek = typeof DayOfWeek[number];

import dayjs from 'dayjs';

export const dayOfWeeks = [
  { id: 'mon', name: '月', day: 1, color: 'black' },
  { id: 'tue', name: '火', day: 2, color: 'black' },
  { id: 'wed', name: '水', day: 3, color: 'black' },
  { id: 'thu', name: '木', day: 4, color: 'black' },
  { id: 'fri', name: '金', day: 5, color: 'black' },
  { id: 'sat', name: '土', day: 6, color: 'blue.400' },
  { id: 'sun', name: '日', day: 0, color: 'red.400' },
] as const;
export type DayOfWeek = (typeof dayOfWeeks)[number];

export const dayOfWeekMap = new Map(dayOfWeeks.map((obj) => [obj.day, obj]));
export const dateStringWithWeekDay = (dateTime: dayjs.Dayjs) => {
  const weekday = dayOfWeekMap.get(dateTime.day() as any)!.name;
  return `${dateTime.format('YYYY/M/D')}(${weekday})`;
};
