import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { MdOutlineEmail } from 'react-icons/md';
import { WebLink } from '../components/ui/WebLink';
import authProvider from '../providers/authProvider';

type PasswordResetProp = {
  email: string;
};
export const PasswordReset: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PasswordResetProp>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (input: PasswordResetProp) => {
    if (submitted) {
      return;
    }

    const { email } = input;
    setSubmitting(true);
    console.log('sending email to: ', email);
    return authProvider
      .sendPasswordResetEmail({ email })
      .then(() => {
        toast({
          status: 'success',
          title: 'メールが送信されました',
        });
        setSubmitted(true);
        // return sleep(1000).then(() => navigate('/login'));
      })
      .catch((error) => {
        toast({
          status: 'error',
          title: `メールの送信に失敗しました エラー: ${error}`,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box paddingY={'16px'} paddingX={'16px'}>
      <Flex
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'580px'}
        marginX={'auto'}
        textAlign={'center'}
      >
        <Spacer mt={10} />
        <Heading>
          {GLOBAL_CONFIG.SERVICE_NAME.FULL_NAME_JP}
          <br />
          パスワード再設定
        </Heading>
        <Spacer mt={5} />

        <Flex direction="column" w="346px">
          {submitted ? (
            <Flex direction="column" alignItems="center">
              <Text>
                メールを送信しました。届いたメールに従ってパスワードを再設定してください。
              </Text>
              <Text fontSize={14} mt={10}>
                ※メールが届かない場合は..
                <br />
                ・noreply@m.pochico.appからのメールが迷惑メールフォルダに届いていないかご確認ください。
                <br />
                ・登録メールアドレスがご不明なお客様は、ポチコサポート(support-pochico@play-tech.jp)へメールにてご連絡ください。
              </Text>

              <Spacer mt={2} />

              <Text fontSize="small">
                <WebLink href="/login"> ログイン画面に戻る</WebLink>
              </Text>
            </Flex>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>メールアドレス</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon as={MdOutlineEmail} w={6} h={6} color="blue.600" />
                    }
                  />
                  <Input
                    {...register('email', {
                      required: 'メールアドレスの入力は必須です',
                    })}
                    type="email"
                    placeholder="example@example.co.jp"
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <Spacer mt={4} />

              <Button
                type="submit"
                isLoading={submitting}
                colorScheme="green"
                width={'full'}
              >
                パスワードリセットメールを送信
              </Button>

              <Spacer mt={2} />
              <Text fontSize="small">
                <WebLink href="/login"> ログイン画面に戻る</WebLink>
              </Text>
            </form>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
