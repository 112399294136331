import { HStack, Spinner, Text, VStack, useToast } from '@chakra-ui/react';
import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from '../../../context/providerAccount';
import { postBotApi } from '../../../dataStore/bot';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { Path } from '../../../routers/Path';
import Footer from '../../ui/Footer';
import { Layout } from '../../ui/Layout';

// 請求先メールアドレスを確認するためのページ
export const InvoiceEmailVerification = () => {
  const { initialized, providerAccount, firebaseUser } = useAuthState();
  if (!initialized) {
    return <Spinner />;
  }
  if (!providerAccount || !firebaseUser) {
    return <Navigate to={Path.login} />;
  }
  return (
    <InvoiceEmailVerificationInner
      providerAccount={providerAccount}
      firebaseUser={firebaseUser}
    />
  );
};

const InvoiceEmailVerificationInner: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const { refetch } = useAuthState();
  const mutation = useMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      return postBotApi<
        | { ok: false; error: string }
        | { ok: true; invoiceName: string; invoiceEmail: string }
      >(
        `/provider-accounts/${providerAccount.id}/verifyInvoiceEmail`,
        firebaseUser,
        { token }
      );
    },
    onSuccess: async (data) => {
      if (data?.ok) {
        if (data.body.ok) {
          // return data.body.invoiceEmail;
          return refetch();
        } else {
          return Promise.reject(data.body.error);
        }
      } else {
        return Promise.reject(data?.error);
      }
    },
  });
  React.useEffect(() => {
    if (
      token &&
      !mutation.isPending &&
      !mutation.isSuccess &&
      !mutation.isError
    ) {
      mutation.mutateAsync();
    }
  }, [mutation, token]);

  return (
    <Layout pageTitle={'メールアドレス認証'} hasBackButton={Path.configuration}>
      {!token ? (
        <Text>URLが不正です</Text>
      ) : mutation.isPending ? (
        <Spinner />
      ) : mutation.data?.ok ? (
        mutation.data.body.ok ? (
          <VStack alignItems={'flex-start'} spacing={'20px'}>
            <Text>メールアドレスの認証が完了しました</Text>
            <HStack>
              <Text>・請求先メールアドレス：</Text>
              <Text>{mutation.data.body.invoiceEmail}</Text>
            </HStack>
          </VStack>
        ) : (
          <Text>
            メールアドレスの認証に失敗しました：
            {mutation.data.body.error}
          </Text>
        )
      ) : (
        <Text>
          メールアドレスの認証に失敗しました：
          {String(mutation.error || mutation.data?.error)}
        </Text>
      )}

      <Footer />
    </Layout>
  );
};
