import {
  Button,
  Circle,
  HStack,
  Select,
  Spinner,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';
import { SetStateAction } from 'jotai';
import React from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import CONSTANTS from '../../commons/constants';
import { useIsPC } from '../../hooks/useIsPC';
import { Pagination } from '../../providers/dataProvider/type';

type PaginationIndicatorProps = {
  total: number;
  perPage: number;
  page: number;
  isLoading: boolean;
  onChange: (paging: SetStateAction<Pagination<any>>) => void;
};

export const PaginationIndicator: React.FC<PaginationIndicatorProps> = (
  props
) => {
  const isPC = useIsPC();
  if (isPC) {
    return <PaginationIndicatorPC {...props} />;
  } else {
    return <PaginationIndicatorSP {...props} />;
  }
};
const PaginationIndicatorPC: React.FC<PaginationIndicatorProps> = ({
  total,
  perPage,
  page,
  isLoading,
  onChange,
}) => {
  if (total === 0) {
    return (
      <></>
      // <Box padding={'30px'} textAlign={'center'}>
      //   <Text>データがありません</Text>
      // </Box>
    );
  }

  const pageCount = Math.ceil(
    Math.min(total, CONSTANTS.PAGINATION_MAX_LIMIT) / perPage
  );
  const maxPageNumber = Math.min(pageCount, page + 3);
  const minPageNumber = Math.max(1, page - 3);
  return (
    <HStack w={'full'} justifyContent={'space-between'} alignItems={'center'}>
      <HStack>
        <Text whiteSpace={'nowrap'} color={'gray.600'}>
          {perPage * (page - 1) + 1}
          {' - '}
          {Math.min(perPage * page, total, CONSTANTS.PAGINATION_MAX_LIMIT + 1)}
          件 / 全
          {total > CONSTANTS.PAGINATION_MAX_LIMIT
            ? `${CONSTANTS.PAGINATION_MAX_LIMIT + 1}+`
            : total}
          件
        </Text>
        <Select
          value={perPage}
          onChange={(e) => {
            onChange((prev) => {
              return {
                sort: prev.sort,
                lastCursor: undefined,
                page: 1,
                perPage: Number(e.target.value),
              };
            });
          }}
        >
          <option value={10}>10件</option>
          <option value={20}>20件</option>
          <option value={50}>50件</option>
          <option value={100}>100件</option>
        </Select>
      </HStack>
      <HStack alignItems={'center'} justifyContent={'flex-start'}>
        <Button
          isDisabled={page === 1}
          borderRadius={'full'}
          variant={'transparent-clickable'}
          borderColor={'gray.200'}
          borderWidth={'1px'}
          color={'gray.600'}
          onClick={() => {
            onChange((prev) => {
              return { ...prev, lastCursor: undefined, page: page - 1 };
            });
          }}
        >
          <HStack spacing={0} w={'3.5em'} justifyContent={'center'}>
            <MdOutlineKeyboardArrowLeft />
            <Text>前へ</Text>
          </HStack>
        </Button>
        {minPageNumber > 1 && <Text>...</Text>}
        {Array.from(Array(maxPageNumber - minPageNumber + 1).keys()).map(
          (i) => {
            const _page = i + minPageNumber;
            return (
              <PageNumber
                page={_page}
                isSelected={page === _page}
                isLoading={page === _page && isLoading}
                isDisabled={false}
                key={_page}
                onClick={() => {
                  onChange((prev) => {
                    return { ...prev, lastCursor: undefined, page: _page };
                  });
                }}
              />
            );
          }
        )}
        {pageCount > maxPageNumber && <Text>...</Text>}
        <Button
          isDisabled={page === pageCount}
          borderRadius={'full'}
          variant={'transparent-clickable'}
          borderColor={'gray.200'}
          borderWidth={'1px'}
          color={'gray.600'}
          onClick={() => {
            onChange((prev) => {
              return { ...prev, lastCursor: undefined, page: page + 1 };
            });
          }}
        >
          <HStack spacing={0} w={'3.5em'} justifyContent={'center'}>
            <Text>次へ</Text>
            <MdOutlineKeyboardArrowRight />
          </HStack>
        </Button>
      </HStack>
    </HStack>
  );
};

const PageNumber: React.FC<{
  page: number;
  element?: React.ReactElement;
  isSelected: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
}> = ({ page, element, isSelected, isLoading, isDisabled, onClick }) => {
  const styles = useStyleConfig('Box', {
    variant: isDisabled
      ? 'circle.disabled'
      : isSelected
      ? 'circle.selected'
      : 'circle.default',
  });
  return (
    <Circle minW={'26px'} size={'fit-content'}>
      <Button
        __css={styles}
        onClick={onClick}
        justifyContent={'center'}
        alignItems={'center'}
        isDisabled={isDisabled}
      >
        {isLoading ? <Spinner size={'xs'} /> : element || page}
      </Button>
    </Circle>
  );
};

const PaginationIndicatorSP: React.FC<PaginationIndicatorProps> = ({
  total,
  perPage,
  page,
  isLoading,
  onChange,
}) => {
  if (total === 0) {
    return (
      <></>
      // <Box padding={'30px'} textAlign={'center'}>
      //   <Text>データがありません</Text>
      // </Box>
    );
  }

  const pageCount = Math.ceil(
    Math.min(total, CONSTANTS.PAGINATION_MAX_LIMIT) / perPage
  );
  return (
    <HStack spacing={0} alignItems={'center'} justifyContent={'flex-start'}>
      <Button
        isDisabled={page === 1}
        borderTopLeftRadius={'full'}
        borderBottomLeftRadius={'full'}
        variant={'transparent-clickable'}
        borderColor={'gray.200'}
        borderWidth={'1px'}
        size={'md'}
        color={'gray.600'}
        onClick={() => {
          onChange((prev) => {
            return { ...prev, lastCursor: undefined, page: page - 1 };
          });
        }}
      >
        <HStack spacing={0} w={'3.5em'} justifyContent={'center'}>
          <MdOutlineKeyboardArrowLeft />
          <Text>前へ</Text>
        </HStack>
      </Button>
      <Select
        value={page}
        size={'md'}
        borderRadius={0}
        onChange={(e) =>
          onChange((prev) => {
            return {
              ...prev,
              lastCursor: undefined,
              page: Number(e.target.value),
            };
          })
        }
      >
        {Array.from(Array(pageCount).keys()).map((page) => {
          return (
            <option
              key={`paging-${total}-${perPage}-${page + 1}`}
              value={page + 1}
            >
              {perPage * page + 1} - {Math.min(perPage * (1 + page), total)}
            </option>
          );
        })}
      </Select>
      <Button
        isDisabled={page === pageCount}
        borderTopRightRadius={'full'}
        borderBottomRightRadius={'full'}
        size={'md'}
        variant={'transparent-clickable'}
        borderColor={'gray.200'}
        borderWidth={'1px'}
        color={'gray.600'}
        onClick={() => {
          onChange((prev) => {
            return { ...prev, lastCursor: undefined, page: page + 1 };
          });
        }}
      >
        <HStack spacing={0} w={'3.5em'} justifyContent={'center'}>
          <Text>次へ</Text>
          <MdOutlineKeyboardArrowRight />
        </HStack>
      </Button>
    </HStack>
  );
};
