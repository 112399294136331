import { createQueryKeys } from '@lukemorales/query-key-factory';
import { BookingForm, ProviderAccount, waitAtLeast } from '@pochico/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BookingFormCreateParams,
  BookingFormUpdateParams,
  createBookingForm,
  deleteBookingForm,
  fetchBookingForms,
  updateBookingForm,
} from '../providers/dataProvider/bookingForm';
import { fetchBookingMenus } from '../providers/dataProvider/bookingMenu';

export const bookingFormQueryKey = createQueryKeys('bookingForm', {
  list: (providerAccountId: string) => [providerAccountId],
  show: (providerAccountId: string, id: string) => [providerAccountId, id],
});

export const useFetchBookingForms = ({
  providerAccount,
  enabled,
}: {
  providerAccount: ProviderAccount;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: bookingFormQueryKey.list(providerAccount.id).queryKey,
    queryFn: async () => {
      return fetchBookingForms(providerAccount);
    },
    enabled: enabled ?? true,
  });
};

export const useFetchBookingForm = ({
  providerAccount,
  id,
  enabled,
}: {
  providerAccount: ProviderAccount;
  id: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: bookingFormQueryKey.show(providerAccount.id, id).queryKey,
    queryFn: () =>
      fetchBookingForms(providerAccount).then((forms) => {
        return forms.find((form) => form.id === id);
      }),
    enabled: Boolean(id) && (enabled ?? true),
  });
};

export const useCreateBookingForm = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: BookingFormCreateParams) => {
      return waitAtLeast(createBookingForm(providerAccount, data), 500);
    },
    onSuccess: async (data) => {
      return queryClient.invalidateQueries({
        queryKey: bookingFormQueryKey.list(providerAccount.id).queryKey,
      });
    },
  });
};

export const useUpdateBookingForm = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: BookingFormUpdateParams) => {
      return updateBookingForm(providerAccount, data);
    },
    onSuccess: async () => {
      return queryClient.invalidateQueries({
        queryKey: bookingFormQueryKey._def,
      });
    },
  });
};

export const useDeleteBookingForm = (providerAccount: ProviderAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: BookingForm['id']) => {
      const bookingMenus = await fetchBookingMenus(providerAccount);
      if (
        bookingMenus.some(
          (menu) =>
            menu.bookingForm?.enabled === true && menu.bookingForm.formId === id
        )
      ) {
        return Promise.reject('使用中のフォームは削除できません');
      } else {
        return deleteBookingForm(providerAccount, id);
      }
    },
    onSuccess: async () => {
      return queryClient.invalidateQueries({
        queryKey: bookingFormQueryKey._def,
      });
    },
  });
};
