import {
  Button,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { MdOutlinePerson, MdOutlineSettings } from 'react-icons/md';
import { useAuthState } from '../../context/providerAccount';
import { scrollToTop } from '../../helpers/scrollToTop';
import { useIsPC } from '../../hooks/useIsPC';
import { Link } from '../ui/Link';
import { LogoutButton } from '../ui/LogoutButton';
// import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';

export const UserMenu = () => {
  const isPC = useIsPC();
  const { providerAccount, firebaseUser } = useAuthState();
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      closeOnBlur={true}
      closeOnEsc={true}
      arrowSize={12}
    >
      <PopoverTrigger>
        <Button variant={'transparent-clickable'} onClick={onToggle}>
          <HStack>
            <MdOutlinePerson size={'24px'} />
            {isPC && <Text>{providerAccount?.displayName}</Text>}
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width={'fit-content'}
        color={'black'}
        borderWidth={'1px'}
        borderColor={'gray.500'}
        borderRadius={'4px'}
        mt={'4px'}
        mr={'4px'}
        boxShadow={'base'}
      >
        <PopoverArrow
          borderLeftWidth={'1px'}
          borderLeftColor={'gray.500'}
          borderTopWidth={'1px'}
          borderTopColor={'gray.500'}
          mt={'-2px'}
          ml={'2px'}
          // borderRadius={'4px'}
        />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            color={'black'}
            textAlign={'left'}
            w={'full'}
            // paddingRight={'12px'}
            marginRight={'18px'}
            spacing={'8px'}
            fontSize={'sm'}
            divider={<Divider borderColor={'gray.600'} />}
          >
            <VStack w={'full'} alignItems={'flex-start'} spacing={'8px'}>
              <Text fontWeight={'bold'}>{providerAccount?.displayName}</Text>
              <VStack w={'full'} alignItems={'flex-start'} spacing={'4px'}>
                <Text color={'gray.600'}>ログイン中のアカウント</Text>
                <VStack
                  w={'full'}
                  alignItems={'flex-start'}
                  pl={'8px'}
                  spacing={0}
                >
                  <Text>{firebaseUser?.displayName}</Text>
                  <Text>{firebaseUser?.email}</Text>
                </VStack>
              </VStack>
            </VStack>
            <VStack w={'full'} alignItems={'flex-start'} spacing={'0px'}>
              <Button
                to={'/configuration'}
                as={Link}
                w={'full'}
                variant={'transparent-clickable'}
                onClick={() => {
                  onToggle();
                  scrollToTop();
                }}
              >
                <HStack w={'full'} justifyContent={'flex-start'}>
                  <MdOutlineSettings />
                  <Text>各種設定</Text>
                </HStack>
              </Button>

              <LogoutButton />
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
