import { Text, TextProps } from '@chakra-ui/react';
import { MACRO, evaluateMacro } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

// text内にマクロが含まれている場合は、重複しないように制御する
// suffixとしてマクロを含むテキストを付与したり
export const TextWithMacro: React.FC<
  {
    text?: string;
    suffix?: string;
    date: dayjs.Dayjs;
  } & TextProps
> = ({ text, suffix, date, ...props }) => {
  const _suffix = suffix ? evaluateMacro(suffix, date) : '';
  if (!text) {
    return (
      <Text {...textStyle} {...props}>
        {buildTextWithBr(`\n${_suffix}`)}
      </Text>
    );
  }

  const remindText =
    text && hasMacro(text)
      ? evaluateMacro(text, date)
      : `${text}\n\n${_suffix}`;
  return (
    <Text {...textStyle} {...props}>
      {buildTextWithBr(remindText)}
    </Text>
  );
};

const textStyle: TextProps = { align: 'left', maxWidth: '100%' };

export const hasMacro = (text: string): boolean => {
  return !!Object.values(MACRO).find((macro) => text.includes(macro));
};

export const removeSuffix: (
  text: string | undefined,
  suffix: string
) => string = (text, suffix) => {
  if (!text) {
    return '';
  }

  return text.split(suffix)[0].trim();
};
export const addSuffix: (text: string, suffix: string) => string = (
  text,
  suffix
) => {
  if (hasMacro(text)) {
    return text;
  } else {
    return `${text.trim()}\n\n${suffix}`;
  }
};

const buildTextWithBr: (text: string) => JSX.Element[] = (text: string) => {
  return text.split(/(\n)/).map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : item}
      </React.Fragment>
    );
  });
};
