import React from 'react';

export const RightArrowWhite = () => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.817871"
        y="0.61084"
        width="10"
        height="1"
        transform="rotate(45 0.817871 0.61084)"
        fill="white"
      />
      <rect
        x="0.11084"
        y="14.071"
        width="10"
        height="1"
        transform="rotate(-45 0.11084 14.071)"
        fill="white"
      />
    </svg>
  );
};

export const RightArrowBlack = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.707031"
      y="0.964393"
      width="9.5"
      height="0.5"
      transform="rotate(45 0.707031 0.964393)"
      fill="white"
      stroke="black"
      strokeWidth="0.5"
    />
    <rect
      x="0.353553"
      y="14.071"
      width="9.5"
      height="0.5"
      transform="rotate(-45 0.353553 14.071)"
      fill="white"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);
