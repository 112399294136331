import {
  Box,
  Button,
  HStack,
  Icon,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BookingForm,
  ProviderAccount,
  SharedBookingFormElement,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  MdCallMade,
  MdExpandLess,
  MdExpandMore,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { useFetchSharedBookingFormElements } from '../../../hooks/sharedBookingForms';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookingFormCreateParams } from '../../../providers/dataProvider/bookingForm';
import { StyledSelect } from '../../ui/StyledSelect';
import { WebLink } from '../../ui/WebLink';
import { SharedBookingFormElementView } from './BookingFormElementView';

export const SharedBookingFormElementConfigurations: React.FC<{
  providerAccount: ProviderAccount;
  bookingFormId: BookingForm['id'] | undefined;
  onChange: (
    enabledSharedElements: BookingFormCreateParams['enabledSharedElements']
  ) => Promise<void>;
}> = ({ providerAccount, bookingFormId, onChange }) => {
  const [expand, setExpand] = React.useState(false);
  const locationFrom = React.useMemo(() => {
    return {
      path: resourcePath(
        bookingFormId
          ? {
              action: 'edit',
              resourceName: 'bookingForm',
              resourceId: bookingFormId,
              providerAccount,
            }
          : {
              action: 'list',
              resourceName: 'bookingForm',
              providerAccount,
            }
      ),
    };
  }, [bookingFormId, providerAccount]);
  const path = React.useMemo(
    () =>
      resourcePath({
        action: 'list',
        resourceName: 'sharedBookingForm',
        providerAccount,
      }),
    [providerAccount]
  );
  return (
    <VStack w={'full'} py={'20px'} alignItems={'flex-start'} gap={'12px'}>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        個人情報質問の表示設定
      </Text>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        spacing={'12px'}
        // px={'12px'}
      >
        <Text color={'gray.500'} fontSize={'14px'}>
          フォームを表示すると予約と同時に氏名などの個人情報を収集できるようになります。内容の編集は
          <WebLink
            href={path}
            // variant={'link'}
            locationFrom={locationFrom}
          >
            個人情報質問のカスタマイズ
          </WebLink>
          から行うことができます。
        </Text>
        <Box>
          <Button variant={'link'} onClick={() => setExpand((prev) => !prev)}>
            <HStack spacing={0}>
              <Icon
                as={expand ? MdExpandLess : MdExpandMore}
                boxSize={'24px'}
                w={'24px'}
                h={'24px'}
              />
              <Text w={'4em'}>{expand ? '閉じる' : '設定する'}</Text>
            </HStack>
          </Button>
        </Box>
      </HStack>
      {expand && (
        <React.Suspense fallback={<Spinner />}>
          <SharedBookingFormElementConfigurationsBody
            providerAccount={providerAccount}
            onChange={onChange}
          />
        </React.Suspense>
      )}
    </VStack>
  );
};
const SharedBookingFormElementConfigurationsBody: React.FC<{
  providerAccount: ProviderAccount;
  onChange: (
    enabledSharedElements: BookingFormCreateParams['enabledSharedElements']
  ) => Promise<void>;
}> = ({ providerAccount, onChange }) => {
  const query = useFetchSharedBookingFormElements(providerAccount);
  const sharedBookingFormElements = (query.data || []).filter(
    (element) => element.enabled
  );
  const { setValue, control } = useFormContext<BookingFormCreateParams>();
  const enabledSharedElements = useWatch({
    control,
    name: 'enabledSharedElements',
  });
  const _onChange = React.useCallback(
    async ({
      element,
      value,
    }: {
      element: SharedBookingFormElement;
      value: 'true' | 'true.required' | 'false';
    }) => {
      const { isEnabled, required } = (() => {
        switch (value) {
          case 'true':
            return { isEnabled: true, required: false };
          case 'true.required':
            return { isEnabled: true, required: true };
          case 'false':
            return { isEnabled: false, required: false };
          default:
            throw new Error('Invalid value');
        }
      })();
      const newValues = (() => {
        if (isEnabled) {
          const idx = enabledSharedElements.findIndex(
            ({ id }) => id === element.id
          );
          if (idx >= 0) {
            enabledSharedElements[idx].required = required;
            return enabledSharedElements;
          } else {
            return enabledSharedElements.concat({ id: element.id, required });
          }
        } else {
          return enabledSharedElements.filter(({ id }) => id !== element.id);
        }
      })();
      setValue('enabledSharedElements', newValues);
      return onChange(newValues);
    },
    [enabledSharedElements, onChange, setValue]
  );

  return (
    <VStack w={'full'} alignItems={'flex-start'} gap={'24px'}>
      {sharedBookingFormElements.length === 0 && (
        <Empty providerAccount={providerAccount} />
      )}
      {sharedBookingFormElements.map((element, i) => {
        const input = enabledSharedElements.find(({ id }) => id === element.id);
        return (
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            key={element.id}
            borderRadius={'12px'}
            borderWidth={'1px'}
            borderColor={'gray.100'}
            bgColor={input ? 'white' : 'gray.50'}
            boxShadow={'base'}
            // pb={'8px'}
            gap={0}
            p={0}
            spacing={0}
            divider={<StackDivider borderColor="gray.200" />}
          >
            <HStack
              w={'full'}
              justifyContent={'space-between'}
              px={'20px'}
              py={'16px'}
            >
              <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
                {element.name}
              </Text>
              <SharedBookingFormElementSwitcher
                element={element}
                isEnabled={Boolean(input)}
                isRequired={Boolean(input?.required)}
                onChange={_onChange}
              />
            </HStack>
            <SharedBookingFormElementView element={element} />
          </VStack>
        );
      })}
    </VStack>
  );
};

const SharedBookingFormElementSwitcher: React.FC<{
  element: SharedBookingFormElement;
  isEnabled: boolean;
  isRequired: boolean;
  onChange: (p: {
    element: SharedBookingFormElement;
    value: 'true' | 'true.required' | 'false';
  }) => Promise<void>;
}> = ({ element, isEnabled, isRequired, onChange }) => {
  const mutation = useMutation({
    mutationFn: onChange,
  });
  const value = isEnabled ? (isRequired ? 'true.required' : 'true') : 'false';
  return (
    <HStack>
      <StyledSelect
        type={'mustSelect'}
        defaultValue={value}
        options={[
          {
            id: 'false',
            element: (
              <HStack>
                <Icon color={'red.500'} as={MdVisibilityOff} />
                <Text>非表示</Text>
              </HStack>
            ),
          },
          {
            id: 'true',
            element: (
              <HStack>
                <Icon color={'green.500'} as={MdVisibility} />
                <Text>表示 - 任意入力</Text>
              </HStack>
            ),
          },
          {
            id: 'true.required',
            element: (
              <HStack>
                <Icon color={'green.500'} as={MdVisibility} />
                <Text>表示 - 必須入力</Text>
              </HStack>
            ),
          },
        ]}
        onSelect={(optionId) => {
          mutation.mutateAsync({
            element,
            value: optionId as 'true' | 'false' | 'true.required',
          });
        }}
        placeholder="選択してください"
        // value={value}
        isDisabled={mutation.isPending}
      />
      {mutation.isPending && <Spinner />}
    </HStack>
  );
};

const Empty: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  return (
    <VStack
      p={'20px'}
      h={'256px'}
      w={'full'}
      borderRadius={'4px'}
      borderWidth={'1px'}
      borderStyle={'dashed'}
      borderColor={'gray.500'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box color={'gray.500'} fontSize={'14px'}>
        個人情報を収集するには、個人情報質問を有効化してください。
        <br />
        有効化は
        <WebLink
          href={resourcePath({
            action: 'list',
            resourceName: 'sharedBookingForm',
            providerAccount,
          })}
          whiteSpace={'nowrap'}
        >
          <HStack>
            <Text>個人情報質問のカスタマイズ</Text>
            <Icon as={MdCallMade} />
          </HStack>
        </WebLink>
        から行うことができます。
      </Box>
    </VStack>
  );
};
