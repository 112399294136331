import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { Layout } from '../../../ui/Layout';
import { BookingFormCreateOrEditForm } from '../BookingFormCreateOrEdit';

export const BookingFormCreate: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  return (
    <Layout pageTitle={'質問フォームの作成'} hasBackButton>
      <BookingFormCreateOrEditForm
        providerAccount={providerAccount}
        bookingForm={undefined}
      />
    </Layout>
  );
};
