import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { useMutation } from '@tanstack/react-query';
import CONSTANTS from '../../../../commons/constants';
import { BookingMenuUpdateParams } from '../../../../firebase/types';
import {
  useFetchBookingMenu,
  useUpdateBookingMenu,
} from '../../../../hooks/bookingMenus';
import { useDialogDispatcher } from '../../../ui/Dialog';
import { FixedRight } from '../../../ui/Fixed';
import { RightArrowWhite } from '../../../ui/RightArrow';
import { useImageInputForm } from '../../../ui/useImageInputForm';
import { BookingMenuPreview } from '../../bookingMenus/BookingMenuPreview';
import {
  OnboardingDialogOptions,
  useOnboardingProgress,
} from './useOnboardingProgress';

export const useOnboardingBookingMenuDialog = ({
  providerAccount,
  onCompleted,
  options,
}: {
  providerAccount: ProviderAccount;
  onCompleted: () => void;
  options?: OnboardingDialogOptions;
}) => {
  const {
    loading: progressLoading,
    onboardingProgress: progress,
    updateProgress,
  } = useOnboardingProgress(options);

  const { isOpen, openDialog, closeDialog } = useDialogDispatcher();
  const toast = useToast();
  const query = useFetchBookingMenu({
    providerAccount,
    id: CONSTANTS.DEFAULT_BOOKING_MENU_ID,
  });
  const mutation = useUpdateBookingMenu(providerAccount);
  const onSubmit = React.useCallback(
    async (input: BookingMenuUpdateParams) => {
      return mutation
        .mutateAsync({ data: input })
        .then((res) => {
          return updateProgress('updateDefaultBookingMenu');
        })
        .then(() => {
          toast({
            title: '予約メニューの設定が完了しました',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          closeDialog();
          onCompleted();
        })
        .catch((e) => {
          toast({
            title: `予約メニューの更新に失敗しました. ${e}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    },
    [mutation, updateProgress, toast, closeDialog, onCompleted]
  );

  const openOnboardingBookingMenuDialog = React.useCallback(() => {
    if (isOpen || query.isLoading || progressLoading) {
      return;
    }
    if (!query.data || progress.updateDefaultBookingMenu) {
      onCompleted();
      return;
    }
    openDialog({
      size: '5xl',
      body: <DialogBody bookingMenu={query.data} onSubmit={onSubmit} />,
    });
  }, [
    query.data,
    query.isLoading,
    progressLoading,
    progress?.updateDefaultBookingMenu,
    isOpen,
    openDialog,
    onSubmit,
    onCompleted,
  ]);

  return {
    loading: query.isLoading,
    openOnboardingBookingMenuDialog,
  };
};

const DialogBody: React.FC<{
  bookingMenu: BookingMenu;
  onSubmit: (input: BookingMenuUpdateParams) => void;
}> = ({ bookingMenu, onSubmit }) => {
  const form = useForm<BookingMenuUpdateParams>({
    defaultValues: {
      ...bookingMenu,
      imageUrl: bookingMenu.imageUrl,
    },
  });
  const { component: ImageForm, file: imageFile } = useImageInputForm({
    form,
    formLabel: (
      <Text>
        画像 <small>(あとから変更できます)</small>
      </Text>
    ),
    imageUrl: bookingMenu.imageUrl,
    defaultImagePath: '/assets/booking-menu-default-image.png',
    validator: async (f) => {
      return undefined;
    },
  });

  const inputName = useWatch({
    control: form.control,
    name: 'name',
  });
  const inputDescription = useWatch({
    control: form.control,
    name: 'description',
  });
  const inputImageUrl = React.useMemo(() => {
    return imageFile ? URL.createObjectURL(imageFile) : bookingMenu.imageUrl;
  }, [bookingMenu.imageUrl, imageFile]);

  const mutation = useMutation({
    mutationFn: async (input: BookingMenuUpdateParams) => {
      const imageData = imageFile
        ? {
            imageData: {
              rawFile: imageFile,
              title: imageFile?.name,
            },
          }
        : {};
      const data = {
        ...input,
        ...imageData,
      };
      return onSubmit(data);
    },
  });

  return (
    <AlertDialogContent
      width={'fit-content'}
      // height={'90vh'}
      marginX={'16px'}
      maxH={'90vh'}
      overflowY={'auto'}
    >
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize="md" color={'blue.600'}>
            [チュートリアル STEP1/4]
          </Text>
          <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight="bold">
            予約メニューの設定をしてみよう
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((input) => mutation.mutateAsync(input))}
          style={{
            width: '100%',
          }}
        >
          <AlertDialogBody>
            <VStack
              w={'full'}
              justifyContent={'center'}
              alignItems={{ base: 'center', md: 'flex-start' }}
              spacing={4}
            >
              <VStack w={'full'} alignItems={'flex-start'}>
                <FormControl
                  isRequired={true}
                  isInvalid={!!form.formState.errors.name}
                >
                  <FormLabel>メニュー名</FormLabel>
                  <Input
                    maxWidth={'full'}
                    w={'full'}
                    type={'text'}
                    placeholder={'例）パーソナルトレーニング'}
                    {...form.register('name', {
                      required: true,
                    })}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.name?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired={false}
                  isInvalid={!!form.formState.errors.description}
                >
                  <FormLabel>説明文</FormLabel>
                  <Textarea
                    maxWidth={'full'}
                    w={'full'}
                    placeholder={
                      '例）専属トレーナーがお客様に最適なトレーニングプログラムを作成します。'
                    }
                    {...form.register('description', {
                      required: false,
                    })}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>

              <Stack direction={{ base: 'column', md: 'row' }}>
                <ImageForm />

                <VStack spacing={0}>
                  <Box
                    w={'full'}
                    bgColor={'gray.100'}
                    color={'gray.500'}
                    p={'8px'}
                    mt={'8px'}
                    textAlign={'center'}
                  >
                    プレビュー
                  </Box>
                  <Box px={'42px'} backgroundColor={'line.talk'}>
                    <BookingMenuPreview
                      bookingMenu={{
                        name: inputName || '',
                        description: inputDescription || '',
                        imageUrl: inputImageUrl,
                      }}
                      showImage={Boolean(inputImageUrl)}
                    />
                  </Box>
                </VStack>
              </Stack>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter justifyContent={'center'} mt={'24px'}>
            <VStack alignItems={'flex-end'} w={'full'}>
              <Button
                // w={'full'}
                w={{ base: 'full', md: '280px' }}
                variant={'blue-fill'}
                size={'md'}
                type={'submit'}
                isLoading={mutation.isPending}
                isDisabled={!form.formState.isValid}
                paddingRight={4}
              >
                次へ
                <FixedRight>
                  <RightArrowWhite />
                </FixedRight>
              </Button>
              <Text size={'sm'}>
                ※設定が完了してもLINE公式アカウントには表示されません
              </Text>
            </VStack>
          </AlertDialogFooter>
        </form>
      </FormProvider>
    </AlertDialogContent>
  );
};
