import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { getAdditionalUserInfo } from 'firebase/auth';
import { MdOutlineEmail, MdOutlineKey } from 'react-icons/md';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import Footer from '../components/ui/Footer';
import { Loading } from '../components/ui/Loading';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { useLineChannelTypeParameter } from '../hooks/useLineChannelTypeParameter';
import authProvider from '../providers/authProvider';
import { Path } from '../routers/Path';

type LogInProps = {
  email: string;
  password: string;
};

const LogIn: FC = () => {
  const navigate = useNavigate();
  const { initialized, providerAccount } = useAuthState();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const lineChannelType = useLineChannelTypeParameter();
  const {
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm<LogInProps>();
  const toast = useToast();

  const onSubmit = React.useCallback(
    async (data: LogInProps) => {
      setSubmitting(true);
      toast.closeAll();
      try {
        const user = await authProvider.login({
          email: data.email,
          password: data.password,
        });
        console.log(`succeeded to login`, { user, providerAccount });
        if (getAdditionalUserInfo(user)?.isNewUser) {
          if (lineChannelType === 'messagingApi') {
            navigate(Path.lineOaLinkMessagingApi);
          } else {
            navigate(Path.lineOaLink);
          }
        } else {
          navigate(Path.home);
        }
      } catch (e) {
        toast({
          title: `ログインに失敗しました エラー: ${e}`,
          status: 'error',
        });
        setSubmitting(false);
      }
    },
    [lineChannelType, navigate, providerAccount, toast]
  );

  setDocumentTitle('ポチコ新規登録');

  React.useEffect(() => {
    if (initialized && providerAccount) {
      console.log('already logged-in.', { providerAccount });
      navigate(Path.home);
    }
  }, [initialized, navigate, providerAccount]);

  console.log('LogIn', { initialized, providerAccount });
  if (!initialized) {
    return <Loading />;
  }

  return (
    <Box paddingY={'16px'} paddingX={'16px'}>
      <Flex
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'580px'}
        marginX={'auto'}
        textAlign={'center'}
      >
        <Spacer mt={10} />
        <Heading>
          {GLOBAL_CONFIG.SERVICE_NAME.FULL_NAME_JP}
          <br />
          ログイン
        </Heading>
        <Spacer mt={5} />

        <Flex direction="column" w="346px">
          <form>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>メールアドレス</FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Icon as={MdOutlineEmail} w={6} h={6} color="blue.600" />
                  }
                />
                <Input
                  {...register('email', {
                    required: 'メールアドレスの入力は必須です',
                  })}
                  type="email"
                  autoComplete={'email'}
                  placeholder="example@example.co.jp"
                />
              </InputGroup>
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <Spacer mt={6} />

            <FormControl isInvalid={!!errors.password}>
              <FormLabel>パスワード</FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Icon as={MdOutlineKey} w={6} h={6} color="blue.600" />
                  }
                />
                <Input
                  {...register('password', {
                    required: 'パスワードの入力は必須です',
                  })}
                  type="password"
                  autoComplete="current-password"
                />
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <Spacer mt={4} />

            <Button
              type="button"
              onClick={() => onSubmit(getValues())}
              isLoading={submitting}
              colorScheme="green"
              width={'full'}
            >
              ログイン
            </Button>

            <Spacer mt={2} />
            <Text>
              パスワードを忘れた場合は
              <WebLink href="/reset-password">こちら</WebLink>
            </Text>

            <Text>
              新規登録は
              <WebLink href="/signup">こちら</WebLink>
            </Text>
          </form>
        </Flex>
      </Flex>
      <Footer />
    </Box>
  );
};

export default LogIn;
