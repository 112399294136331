import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Stack,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MACRO, ProviderAccount } from '@pochico/shared';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { ChangeEvent } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '../../../context/providerAccount';
import { postBotApi } from '../../../dataStore/bot';
import { useIsPC } from '../../../hooks/useIsPC';
import { unregister } from '../../../registerServiceWorker';
import { Layout } from '../../ui/Layout';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { addSuffix, hasMacro, removeSuffix } from '../../ui/TextWithMacro';
import { ReminderPreview } from './preview';

type ReminderConfiguration = {
  remindText?: string;
  defaultLocation?: {
    address: string;
    latitude: number;
    longitude: number;
    title: string;
  };
};

// リマインダーに付与するデフォルトのsuffix
const MESSAGE_SUFFIX = `【予約日時】\n・${MACRO.fullDate}`;

export const ReminderConfigurationEditPage: React.FC = () => {
  const { initialized, providerAccount } = useAuthState();
  if (!initialized || !providerAccount) {
    return <>Loading...</>;
  }

  return (
    <Layout pageTitle={'リマインダー設定'} hasBackButton>
      <VStack w={'full'} spacing={'20px'} alignItems={'flex-start'}>
        <Text>
          予約をしたお客様向けに予約日の前日17時ごろにメッセージと地図を送ることができます。
        </Text>
        <ReminderConfiguration />
      </VStack>
    </Layout>
  );
};

const ReminderConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const { initialized, providerAccount, firebaseUser, refetch } =
    useAuthState();
  const toast = useToast();
  const mapRef = React.useRef<google.maps.Map | null>(null);

  const configuration: ProviderAccount['reminder'] = providerAccount?.reminder;
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch, // パフォーマンス観点ではuseWatchを使うように書き換えたい
    setError,
    clearErrors,
    setFocus,
    formState: { errors, isValid },
  } = useForm<ReminderConfiguration>({
    mode: 'onChange',
    defaultValues: {
      // 既存のリマインダーに入っているものを考慮して、予約時間についての情報をsuffixとしてつけたり外したりしてUI側で制御する
      remindText: removeSuffix(configuration?.remindText, MESSAGE_SUFFIX),
      defaultLocation: configuration?.defaultLocation,
    },
  });

  const [submitting, setSubmitting] = React.useState(false);
  const needReminder = true; // この画面に来ているということはreminderを使うということ
  const [needLocation, setNeedLocation] = React.useState(
    !!providerAccount?.reminder?.defaultLocation
  );

  const panToLatLng = React.useCallback(
    (latLng: google.maps.LatLngLiteral) => {
      const locs = getValues()['defaultLocation'];
      if (locs) {
        setValue('defaultLocation', {
          ...locs,
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
      }
      // mapRef.current?.panTo(latLng);
    },
    [getValues, setValue]
  );

  const onClickMap = React.useCallback(
    (latLng: google.maps.LatLng) => {
      // 以前のバリデーション結果を消しておく
      clearErrors(['defaultLocation.latitude', 'defaultLocation.longitude']);
      panToLatLng({ lat: latLng.lat(), lng: latLng.lng() });
    },
    [clearErrors, panToLatLng]
  );

  const _onSubmit = React.useCallback(
    async ({ remindText, defaultLocation }: ReminderConfiguration) => {
      console.log({ needReminder, remindText, needLocation, defaultLocation });
      if (!firebaseUser || !providerAccount) {
        return;
      }

      // 地図にピン置いてるかどうかのバリデーション
      if (needReminder && needLocation) {
        if (
          !defaultLocation ||
          (!defaultLocation.latitude && !defaultLocation.longitude)
        ) {
          // 片方だけにエラーを入れておく
          setError(`defaultLocation.latitude`, {
            type: 'required',
            message: 'ピンを設置して下さい',
          });
          setFocus('defaultLocation.latitude');
          return;
        }
      }
      if (!isValid || Object.keys(errors).length > 0) {
        console.error('validation failed', { isValid, errors });
        return;
      }

      // 必要な設定値だけを保存する
      const payload = {
        needReminder,
        ...(needReminder
          ? { remindText: addSuffix(remindText || '', MESSAGE_SUFFIX) }
          : {}),
        ...(needReminder && needLocation ? { defaultLocation } : {}),
      };
      setSubmitting(true);
      return postBotApi<{ message: string }>(
        `/remind/provider-account/${providerAccount.id}`,
        firebaseUser,
        payload
      )
        .then(async (response) => {
          if (response.ok) {
            toast({
              status: 'success',
              title: `リマインダーを保存しました`,
            });
            await refetch();
            navigate('/reminder');
          } else {
            throw new Error(`response: ${response.error}`);
          }
        })
        .catch((error) => {
          toast({
            status: 'error',
            title: `エラーが発生しました。error: ${error}`,
          });
          console.error(`failed to save reminder.`, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [
      needReminder,
      needLocation,
      firebaseUser,
      providerAccount,
      isValid,
      errors,
      setError,
      setFocus,
      toast,
      refetch,
      navigate,
    ]
  );

  React.useEffect(() => {
    // フォームがないので直接registerする
    register('defaultLocation.latitude');
    register('defaultLocation.longitude');

    if (configuration?.defaultLocation) {
      panToLatLng({
        lat: configuration.defaultLocation.latitude,
        lng: configuration.defaultLocation.longitude,
      });
    }
    return () => {
      unregister();
    };
  }, [register, panToLatLng, configuration]);

  const defaultLocation = useWatch({
    control,
    name: 'defaultLocation',
  });
  const latLng = React.useMemo(() => {
    // 検索結果があればそれを使い、なければ設置済みのピンの位置を使う
    console.log({
      defaultLocation,
    });
    if (
      defaultLocation &&
      defaultLocation.latitude &&
      defaultLocation.longitude
    ) {
      return {
        lat: defaultLocation.latitude,
        lng: defaultLocation.longitude,
      } as google.maps.LatLngLiteral;
    } else {
      return undefined;
    }
  }, [defaultLocation]);
  const handleRemindTextChange = React.useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
      setValue('remindText', e.target.value);
    },
    [setValue]
  );
  const isPC = useIsPC();
  const preview = (
    <PreviewContainer label={'プレビュー'}>
      <ReminderPreview
        configuration={Object.assign(
          {},
          watch(),
          needLocation ? {} : { defaultLocation: undefined }
        )}
      />
    </PreviewContainer>
  );

  return (
    <Box width={'full'}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          // divider={<StackDivider borderColor="gray.200" />}
          {...(needReminder ? {} : { display: 'none' })}
          justifyContent={'space-between'}
        >
          <VStack width={'full'} alignItems={'flex-start'} spacing={'12px'}>
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              borderWidth={isPC ? '1px' : 0}
              borderColor={'gray.200'}
              p={0}
              divider={<StackDivider borderColor="gray.200" />}
            >
              <FormControl
                isRequired={needReminder}
                isInvalid={!!errors.remindText}
              >
                <Flex
                  width={'full'}
                  direction={'column'}
                  alignItems={'flex-start'}
                  p={isPC ? '20px' : '8px'}
                >
                  <FormLabel>リマインダーのメッセージ</FormLabel>
                  <Textarea
                    {...register('remindText', {
                      maxLength: {
                        value: 5000,
                        message: '最大文字数は5000文字です',
                      }, // https://developers.line.biz/ja/reference/messaging-api/#text-message
                    })}
                    width={'100%'}
                    backgroundColor={'white'}
                    placeholder="お気をつけてお越し下さい"
                    resize={'vertical'}
                    onChange={handleRemindTextChange}
                    wrap={'soft'}
                  />
                  <FormErrorMessage>
                    {errors.remindText?.message}
                  </FormErrorMessage>
                  {hasMacro(getValues('remindText') || '') ? (
                    <></>
                  ) : (
                    <Text size={'sm'} textColor={'#7B7B7B'}>
                      ※予約日時はメッセージの後に自動的に挿入されます。
                    </Text>
                  )}
                </Flex>
              </FormControl>

              <VStack width={'full'} alignItems={'flex-start'} padding={'20px'}>
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    {'地図を送付する'}
                  </Text>
                  <Switch
                    name="needLocation"
                    defaultChecked={needLocation}
                    colorScheme={'blue'}
                    onChange={(e) => setNeedLocation(e.target.checked)}
                  />
                </HStack>
                <VStack
                  width={'full'}
                  marginTop={'4'}
                  alignItems={'flex-start'}
                  {...(needLocation ? {} : { display: 'none' })}
                >
                  <VStack width={'100%' /*{ base: '100%', md: '80%' }*/}>
                    <FormControl
                      isRequired={needReminder && needLocation}
                      isInvalid={
                        !!(
                          errors.defaultLocation?.latitude ||
                          errors.defaultLocation?.longitude
                        )
                      }
                    >
                      <FormLabel>
                        地図をタップまたはクリックして施設・店舗の上にピンを置いて下さい
                        {JSON.stringify(latLng)}
                      </FormLabel>
                      <Map
                        latLng={latLng}
                        onLoad={(map) => {
                          mapRef.current = map;
                        }}
                        onClick={onClickMap}
                      >
                        <SearchBox onSearch={panToLatLng} />
                        {mapRef.current && latLng && (
                          <>
                            <Marker position={latLng} />
                            <Box>hogehoge</Box>
                          </>
                        )}
                      </Map>
                      <FormErrorMessage>
                        {errors.defaultLocation?.latitude?.message}
                        {errors.defaultLocation?.longitude?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                  <VStack
                    width={'full'}
                    spacing={4}
                    paddingTop={4}
                    paddingBottom={4}
                  >
                    <FormControl
                      isRequired={needReminder && needLocation}
                      isInvalid={!!errors.defaultLocation?.title}
                    >
                      <FormLabel>施設・店舗 表示名</FormLabel>
                      <Input
                        maxWidth={'full'}
                        {...register('defaultLocation.title', {
                          maxLength: {
                            value: 300,
                            message: '最大文字数は300文字です',
                          },
                        })}
                        name="defaultLocation.title"
                        backgroundColor={'white'}
                        type="text"
                        placeholder="ヨガスタジオ『ポチコ』"
                      />
                      <FormErrorMessage>
                        {errors.defaultLocation?.title?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired={needReminder && needLocation}
                      isInvalid={!!errors.defaultLocation?.address}
                    >
                      <FormLabel>住所 表示名</FormLabel>
                      <Input
                        maxWidth={'full'}
                        {...register('defaultLocation.address', {
                          maxLength: {
                            value: 300,
                            message: '最大文字数は300文字です',
                          },
                        })}
                        backgroundColor={'white'}
                        type="text"
                        placeholder="東京都杉並区井草123-456 ポチコビル"
                      />
                      <FormErrorMessage>
                        {errors.defaultLocation?.address?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
            {!isPC ? preview : null}

            <HStack w={'full'} justifyContent={'flex-end'}>
              <Button
                type={'submit'}
                variant={'blue-fill'}
                w={isPC ? 'fit-content' : 'full'}
                isLoading={submitting}
              >
                この内容で保存する
              </Button>
            </HStack>
          </VStack>

          {isPC ? preview : null}
        </Stack>

        <Spacer mt={'3'} />
        <Text textColor={'red'}>
          ※保存すると、次のリマインダー送付タイミングから予約済みのユーザーにこのリマインダーが送付されます。
        </Text>
      </form>
    </Box>
  );
};

const useAddressSearch = ({
  map,
}: {
  map: google.maps.Map | null;
}): {
  search: (query: string) => Promise<void>;
  searching: boolean;
  resultLatLng: google.maps.LatLng | undefined;
} => {
  const [searching, setSearching] = React.useState<boolean>(false);
  const [resultLatLng, setResultLatLng] = React.useState<
    google.maps.LatLng | undefined
  >(undefined);
  if (!map) {
    return {
      search: () => Promise.resolve(undefined),
      searching,
      resultLatLng,
    };
  }
  const service = new google.maps.places.PlacesService(map);

  const search = async (query: string) => {
    const request: google.maps.places.FindPlaceFromQueryRequest = {
      query,
      fields: ['name', 'geometry'],
    };
    service.findPlaceFromQuery(request, (results, status) => {
      setSearching(false);
      if (results && status === google.maps.places.PlacesServiceStatus.OK) {
        if (results[0].geometry?.location) {
          setResultLatLng(results[0].geometry.location);
          return;
        }
      }
      setResultLatLng(undefined);
    });
  };
  return { search, searching, resultLatLng };
};

const DEFAULT_CENTER = { latitude: 35.6873666, longitude: 139.701167 };
type MapProps = {
  latLng?: google.maps.LatLngLiteral;
  onLoad: (map: google.maps.Map) => void;
  onClick: (map: google.maps.LatLng) => void;
};
const Map: React.FC<MapProps & { children: React.ReactNode }> = ({
  latLng,
  onLoad,
  onClick,
  children,
}) => {
  const options: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };
  const { isLoaded } = useGoogleMap();

  return (
    // ドキュメントメモ: https://react-google-maps-api-docs.netlify.app/
    <Box style={{ width: 'full' }}>
      {isLoaded && (
        <AspectRatio ratio={1}>
          <GoogleMap
            extraMapTypes={[]}
            options={options}
            zoom={16}
            center={{
              lat: latLng?.lat || DEFAULT_CENTER.latitude,
              lng: latLng?.lng || DEFAULT_CENTER.longitude,
            }}
            onLoad={onLoad}
            onClick={(event) => {
              if (event.latLng) {
                onClick(event.latLng);
              }
            }}
          >
            {children}
          </GoogleMap>
        </AspectRatio>
      )}
    </Box>
  );
};

const libraries: Parameters<typeof useJsApiLoader>[0]['libraries'] = ['places'];
const useGoogleMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GLOBAL_CONFIG.GOOGLE.MAP.API_KEY,
    libraries,
  });
  return { isLoaded };
};

const SearchBox = ({
  onSearch,
}: {
  onSearch: (latLng: google.maps.LatLngLiteral) => void;
}) => {
  const autoComplete = React.useRef<google.maps.places.Autocomplete | null>(
    null
  );
  const { isLoaded } = useGoogleMap();

  if (!isLoaded) {
    return <>Loading...</>;
  }
  return (
    <Autocomplete
      onLoad={(ref) => (autoComplete.current = ref)}
      onPlaceChanged={() => {
        if (!autoComplete.current) {
          console.log(`searchBox is empty. ${autoComplete}`);
          return;
        }
        const location = autoComplete.current.getPlace().geometry?.location;
        console.log({ location });
        if (location) {
          onSearch({
            lat: location.lat(),
            lng: location.lng(),
          });
        }
      }}
    >
      <Input
        isRequired={false}
        type="text"
        backgroundColor={'white'}
        placeholder="検索欄"
        width={'280px'}
        maxWidth={'85%'}
        position={'absolute'}
        left={0}
        top={0}
        marginTop={'2'}
        marginLeft={'2'}
      />
    </Autocomplete>
  );
};
