import {
  Box,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Contract, getPlan, ProviderAccount, sleep } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuthState } from '../../../context/providerAccount';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { Path } from '../../../routers/Path';
import Footer from '../../ui/Footer';
import { Layout } from '../../ui/Layout';
import { CardForm } from './CardForm';
import { PochicoPaymentMethod } from './createSubscription';
import { useCreateSubscription } from './useCreateSubscription';

export const CheckoutForm = () => {
  const { initialized, firebaseUser, providerAccount } = useAuthState();

  if (!initialized) {
    return <Spinner />;
  }
  if (!firebaseUser || !providerAccount) {
    // ちゃんとロードされた後でこれらがなかったら未ログイン
    alert(
      'ログインしていないか、ログインが時間切れになっています。再度ログインしてください。'
    );
    return <Navigate to={Path.login} />;
  }
  return (
    <CheckoutFormInner
      providerAccount={providerAccount}
      firebaseUser={firebaseUser}
    />
  );
};
const CheckoutFormInner: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const [searchParams] = useSearchParams();
  const { refetch, initialized } = useAuthState();
  const [paymentType, setPaymentType] = useState<'card' | 'invoice'>('card');
  const contract: Contract = React.useMemo(() => {
    const plan = (() => {
      if (providerAccount) {
        return getPlan(providerAccount);
      }
      return 'standard';
    })();
    return { plan };
  }, [providerAccount]);
  const toast = useToast();
  const firebaseUid = firebaseUser?.uid;
  const navigate = useNavigate();
  const createSubscription = useCreateSubscription();
  const mutation = useMutation({
    mutationFn: async (paymentMethod: PochicoPaymentMethod) => {
      return createSubscription(paymentMethod, contract);
    },
    onSuccess: async (result) => {
      console.log({ message: '支払い登録が完了', result });
      toast({
        title:
          '支払い登録が完了しました。メールアドレス認証のため、メールをご確認ください。',
        status: 'success',
      });
      await Promise.all([refetch(), sleep(2000)]);
      navigate('/');
    },
    onError: (e: Error) => {
      if (e.message === 'string' && e.message.includes('already registered')) {
        toast({
          title: 'すでに支払い登録済みです。管理画面へ戻ります。',
          status: 'info',
        });
        navigate('/');
        return;
      }
      console.error(e);
      toast({
        status: 'error',
        title: `エラーが発生しました。error: ${e}`,
      });
    },
  });

  useEffect(() => {
    if (!initialized || mutation.isSuccess || mutation.isPending) {
      return;
    }
    if (
      providerAccount.paymentStatus === 'registered' &&
      providerAccount.stripeCustomerId &&
      (providerAccount.stripeSubscriptionId ||
        providerAccount.stripeSubscriptionScheduleId)
    ) {
      if (providerAccount.status === 'attached') {
        toast({
          title: '支払い登録が完了しています。管理画面へ戻ります。',
        });
        navigate('/');
        // window.location.href = '/';
      } else {
        toast({
          title: 'LINE連携が切れてします。再度連携してください。',
          status: 'warning',
        });
        navigate('/line-oa-link');
      }
    }
  }, [
    firebaseUid,
    initialized,
    mutation.isPending,
    mutation.isSuccess,
    navigate,
    providerAccount,
    searchParams,
    toast,
  ]);

  return (
    <Layout
      pageTitle={
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text fontSize={'sm'}>かんたん予約ポチコ</Text>
          <Text fontWeight={'bold'}>支払い登録</Text>
        </VStack>
      }
      hasBackButton={true}
    >
      <VStack
        w={{ base: 'full', md: '840px' }}
        alignItems={'flex-start'}
        spacing={'32px'}
      >
        <VStack w={'full'} alignItems={'flex-start'} spacing={'20px'}>
          <Text fontWeight={'bold'}>ご注意事項</Text>
          <Box
            borderColor={'gray.300'}
            borderWidth={'1px'}
            w={'full'}
            padding={'10px'}
            borderRadius={'4px'}
            p={'20px'}
            fontSize={'sm'}
            color={'gray.500'}
            whiteSpace={{ base: undefined, md: 'nowrap' }}
          >
            ※月額3,000円（税抜）です。
            <br />
            ※無料期間中に退会した場合、請求は発生しません。また、無料期間から有料期間に移行した月のみ日割り請求となります。
            <br />
            ※ポチコへ再連携した場合、無料期間の再付与はありません。
          </Box>
        </VStack>

        <VStack w={'full'} alignItems={'flex-start'} spacing={'20px'}>
          <Text fontWeight={'bold'}>お支払い方法</Text>
          <VStack
            w={'full'}
            padding={'10px'}
            borderRadius={'4px'}
            bgColor={'gray.50'}
            p={'20px'}
            spacing={'20px'}
            fontSize={'sm'}
            color={'gray.500'}
            alignItems={'center'}
          >
            <RadioGroup
              w={'full'}
              onChange={(s) => setPaymentType(s as 'card' | 'invoice')}
              value={paymentType}
            >
              <HStack>
                <Radio value="card">クレジットカード払い</Radio>
                {/* <Radio value="invoice">請求書払い</Radio> 請求書払いは受付停止 */}
              </HStack>
            </RadioGroup>
            <CardForm
              visible={paymentType === 'card'}
              onSubmit={(paymentMethod) => {
                return mutation.mutateAsync(paymentMethod);
              }}
            />
          </VStack>
        </VStack>

        {/* <InvoiceForm 請求書払いは未払いが多すぎてサポートが大変だったので受付停止（google formで年払いのみ受付している）
              visible={paymentType === 'invoice'}
              providerAccount={providerAccount!}
              firebaseUser={firebaseUser!}
              onSubmit={onSubmit}
            /> */}
      </VStack>
      <Footer />
    </Layout>
  );
};
