import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
// import { Link } from 'react-router-dom';

import { scrollToTop } from '../../helpers/scrollToTop';
import { LocationFrom } from '../../hooks/locationState';
import { ResourceProp, resourcePath } from '../../hooks/useUrlPath';
import { Link } from './Link';

export const NavigationButton: React.FC<
  ButtonProps & {
    to: ResourceProp | string;
    locationFrom?: LocationFrom;
    target?: '_blank';
    rel?: 'noopener noreferrer';
  }
> = ({ children, locationFrom, ...props }) => {
  const { to, target, rel, ...rest } = props;
  const href = React.useMemo(() => {
    if (typeof to === 'string') {
      return to;
    } else {
      return resourcePath(to);
    }
  }, [to]);

  return (
    <Button
      as={Link}
      to={href}
      target={target}
      rel={rel}
      state={locationFrom}
      w={rest.w || rest.width}
      onClick={(e) => {
        if (rest.isDisabled) {
          e.preventDefault();
          return;
        }
        scrollToTop();
      }}
      size={'sm'}
      {...rest}
    >
      {children}
    </Button>
  );
};
