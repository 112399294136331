import { ProviderAccount } from '@pochico/shared';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { postBotApi } from '../dataStore/bot';
import { FirebaseUser } from '../firebase/firebaseInit';

export const useSendEmailVerificationMutation = (
  providerAccount: ProviderAccount,
  firebaseUser: FirebaseUser,
  callbacks: {
    onSuccess: (email: string) => void;
    onError: (error: string) => void;
  }
) => {
  const f = React.useCallback(
    async (
      input:
        | {
            action: 'emailVerification';
          }
        | {
            action: 'changeInvoiceConfiguration';
            payload: {
              invoiceName: string;
              invoiceEmail: string;
            }; // tokenに含む情報
          }
    ) => {
      return postBotApi<
        { ok: false; error: string } | { ok: true; email: string }
      >(
        `/provider-accounts/${providerAccount.id}/sendEmailVerification`,
        firebaseUser,
        input
      ).then((res) => {
        if (res.ok) {
          if (res.body.ok) {
            return res.body.email;
          }
          return Promise.reject({ error: res.body.error });
        } else {
          return Promise.reject({ error: res.error });
        }
      });
    },
    [firebaseUser, providerAccount.id]
  );

  return useMutation({
    mutationFn: f,
    onSuccess: callbacks.onSuccess,
    onError: callbacks.onError,
  });
};

export const useEmailTokenVerificationQuery = (
  providerAccount: ProviderAccount,
  firebaseUser: FirebaseUser,
  token: string
) => {
  return useQuery({
    queryKey: ['emailTokenVerification', providerAccount.id, token],
    queryFn: async () => {
      return postBotApi<
        { ok: false; error: string } | { ok: true; payload: any }
      >(
        `/provider-accounts/${providerAccount.id}/verifyEmailToken`,
        firebaseUser,
        { token }
      ).then((res) => {
        if (res.ok) {
          if (res.body.ok) {
            return { payload: res.body.payload };
          } else {
            return Promise.reject(res.body.error);
          }
        } else {
          return Promise.reject(res.error);
        }
      });
    },
    enabled: Boolean(token),
  });
};
