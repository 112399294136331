// 8HLrvZH6Ro9fD4DDPE3s

import React from 'react';

import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { ReminderConfigurationPage } from '../../components/features/reminder';

const ReminderConfiguration = () => {
  setDocumentTitle('リマインダー設定');
  return <ReminderConfigurationPage />;
};

export default ReminderConfiguration;
