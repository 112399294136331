import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { myStorage } from './firebaseInit';

export const uploadThenGetUrlBookingMenuImage = async (
  providerAccountId: string,
  bookingMenuId: string,
  imageFile: File
): Promise<string> => {
  return uploadThenGetUrl(providerAccountId, bookingMenuId + '.jpg', imageFile);
};

const uploadThenGetUrl = async (
  providerAccountId: string,
  imageName: string,
  imageFile: File
): Promise<string> => {
  const storageRef = ref(
    myStorage,
    `images/bookingMenuImage/${providerAccountId}/${imageName}`
  );

  return uploadBytes(storageRef, imageFile).then(async (snapshot) => {
    const url = await getDownloadURL(snapshot.ref);
    console.log(`Uploaded a blob or file! ${JSON.stringify(imageFile)}`, {
      url,
      imageFile,
      a: imageFile.name,
      size: imageFile.size,
    });
    return url;
  });
};
