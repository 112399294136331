import { ProviderAccount } from '@pochico/shared';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getBotApi } from '../dataStore/bot';
import { FirebaseUser } from '../firebase/firebaseInit';

export const useInvoiceConfigurationQuery = ({
  providerAccount,
  firebaseUser,
}: {
  providerAccount: ProviderAccount | undefined;
  firebaseUser: FirebaseUser | undefined;
}) => {
  return useQuery({
    queryKey: ['invoiceConfiguration', providerAccount?.id],
    queryFn: React.useCallback(async () => {
      if (!providerAccount || !firebaseUser) {
        return;
      }
      return getBotApi<
        | { ok: false; error: string }
        | {
            ok: true;
            invoiceConfiguration: {
              invoiceName: string;
              invoiceEmail: string | null;
            } | null;
          }
      >(
        `/provider-accounts/${providerAccount.id}/invoiceConfiguration`,
        firebaseUser
      ).then((res) => {
        if (res.ok) {
          if (res.body.ok) {
            return { invoiceConfiguration: res.body.invoiceConfiguration };
          } else {
            return Promise.reject(new Error(res.body.error));
          }
        } else {
          return Promise.reject(res.error);
        }
      });
    }, [firebaseUser, providerAccount]),
    enabled: !!providerAccount && !!firebaseUser,
  });
};
