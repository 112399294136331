import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ProviderAccount, SharedBookingFormElement } from '@pochico/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  SharedBookingFormElementUpsertParams,
  fetchSharedBookingFormElements,
  upsertSharedBookingFormElement,
} from '../providers/dataProvider/sharedBookingForm';

export const sharedBookingFormQueryKey = createQueryKeys('sharedBookingForm', {
  list: (providerAccountId: string) => [providerAccountId],
  show: (providerAccountId: string, id: SharedBookingFormElement['id']) => [
    providerAccountId,
    id,
  ],
});

export const useFetchSharedBookingFormElements = (
  providerAccount: ProviderAccount
) => {
  return useQuery({
    queryKey: sharedBookingFormQueryKey.list(providerAccount.id).queryKey,
    queryFn: async () => {
      return fetchSharedBookingFormElements(providerAccount.id);
    },
  });
};

export const useUpsertSharedBookingFormElement = (
  providerAccount: ProviderAccount
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: SharedBookingFormElementUpsertParams) => {
      return upsertSharedBookingFormElement(providerAccount, params);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: sharedBookingFormQueryKey._def,
      });
    },
  });
};
