import { ProviderAccount } from '@pochico/shared';
import { updateDoc } from 'firebase/firestore';
import React from 'react';

import { useAuthState } from '../../../../context/providerAccount';
import { getProviderAccountRef } from '../../../../providers/dataProvider/providerAccount';

type OnboardingProgress = {
  updateProgress: (
    completed: keyof NonNullable<ProviderAccount['onboardingProgress']>
  ) => Promise<void>;
} & (
  | { loading: true; onboardingProgress: undefined }
  | {
      loading: false;
      onboardingProgress: NonNullable<ProviderAccount['onboardingProgress']>;
    }
);
export type OnboardingDialogOptions = {
  forceOpen?: boolean;
};

const onboardingDialogReleaseDate = new Date('2023-09-07T00:00+09:00');

export const useOnboardingProgress = (
  options?: OnboardingDialogOptions
): OnboardingProgress => {
  const { status, refetch, providerAccount } = useAuthState();
  const updateProgress = React.useCallback(
    async (
      completed: keyof NonNullable<ProviderAccount['onboardingProgress']>
    ) => {
      if (!providerAccount) {
        return Promise.resolve();
      }
      return updateDoc(
        getProviderAccountRef(providerAccount.id),
        `onboardingProgress.${completed}`,
        true,
        'updateTime',
        new Date()
      ).then(() => {
        return refetch();
      });
    },
    [providerAccount, refetch]
  );

  if (!providerAccount || status === 'refetching') {
    return { loading: true, onboardingProgress: undefined, updateProgress };
  }

  if (options?.forceOpen === true) {
    return {
      loading: false,
      onboardingProgress: {
        updateDefaultBookingMenu: false,
        createSpot: false,
        createBooking: false,
      },
      updateProgress,
    };
  }

  if (
    !providerAccount.onboardingProgress &&
    providerAccount.createTime < onboardingDialogReleaseDate
  ) {
    // リリース日以前に作られたアカウントは新しいオンボーディングを使わない
    return {
      loading: false,
      onboardingProgress: {
        updateDefaultBookingMenu: true,
        createSpot: true,
        createBooking: true,
      },
      updateProgress,
    };
  } else {
    // リリース日以降は新しいオンボーディングを使う
    return {
      loading: false,
      onboardingProgress: {
        updateDefaultBookingMenu:
          providerAccount.onboardingProgress?.updateDefaultBookingMenu || false,
        createSpot: providerAccount.onboardingProgress?.createSpot || false,
        createBooking:
          providerAccount.onboardingProgress?.createBooking || false,
      },
      updateProgress,
    };
  }
};
