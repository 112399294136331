import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { SpotList } from '../../components/features/spots/list';

export const SpotListPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約枠を管理');
  return <SpotList providerAccount={providerAccount} />;
};
