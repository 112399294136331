import { Button, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { Link } from 'react-router-dom';
import { useSetDocumentTitle } from '../commons/components/setDocumentTitle';
import Spacer from '../components/fragments/Spacer';
import { Path } from '../routers/Path';

const GcalAuthComplete: React.FC = () => {
  useSetDocumentTitle('Googleカレンダー連携の完了');
  return (
    <VStack>
      <Spacer height={'40px'} />
      <Text fontSize={'xl'} fontWeight={'bold'} textAlign={'center'}>
        {GLOBAL_CONFIG.SERVICE_NAME.FULL_NAME_JP + 'の'}
        <br />
        Googleカレンダー連携が完了しました！🎉
      </Text>
      <Spacer height={'40px'} />
      <Text>
        あなたのGoogleカレンダーに、ポチコ予約専用のカレンダーが追加されました。今後の予約/キャンセルがリアルタイムに反映されます！
      </Text>
      <Spacer height={'60px'} />
      <Link to={Path.home}>
        <Button colorScheme={'green'}>{'管理者ツールへ戻る'}</Button>
      </Link>
      <Spacer height={'40px'} />
    </VStack>
  );
};
export default GcalAuthComplete;
