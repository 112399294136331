import {
  BookingForm,
  BookingFormElement,
  getRandomString,
  ProviderAccount,
  SharedBookingFormElement,
} from '@pochico/shared';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  UpdateData,
  updateDoc,
} from 'firebase/firestore';
import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';
import { BookingFormFromFS } from '../../firebase/types';

export const getBookingFormRef = (providerId: string) => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    CONSTANTS.COLLECTION.SUB_BOOKING_FORMS
  ).withConverter({
    toFirestore: (doc: BookingForm) => {
      return {
        ...doc,
      };
    },
    fromFirestore: (
      snap: QueryDocumentSnapshot<BookingFormFromFS>
    ): BookingForm => {
      const doc = snap.data();
      const createTime: Date = (doc.createTime as Timestamp).toDate();
      const updateTime: Date = (doc.updateTime as Timestamp).toDate();
      return {
        ...doc,
        createTime,
        updateTime,
      };
    },
  });
};

export const fetchBookingForms = async (
  providerAccount: ProviderAccount
): Promise<BookingForm[]> => {
  return getDocs(getBookingFormRef(providerAccount.id)).then((snap) => {
    return snap.docs
      .map((doc) => doc.data())
      .sort((a, b) => b.updateTime.getTime() - a.updateTime.getTime());
  });
};

export const fetchBookingForm = async (
  providerAccount: ProviderAccount,
  id: string
): Promise<BookingForm | undefined> => {
  return getDoc(doc(getBookingFormRef(providerAccount.id), id)).then((snap) => {
    return snap.exists() ? snap.data() : undefined;
  });
};

export type BookingFormCreateParams = {
  name: string;
  formElements: BookingFormElement[];
  enabledSharedElements: {
    id: SharedBookingFormElement['id'];
    required: boolean;
  }[];
};
export const createBookingForm = async (
  providerAccount: ProviderAccount,
  param: BookingFormCreateParams
): Promise<BookingForm> => {
  const id = getRandomString(20);
  const now = new Date();
  const bookingForm: BookingForm = {
    id,
    providerAccountId: providerAccount.id,
    name: param.name,
    formElements: param.formElements,
    enabledSharedElements: param.enabledSharedElements,
    createTime: now,
    updateTime: now,
  };
  return setDoc(
    doc(getBookingFormRef(providerAccount.id), id),
    bookingForm
  ).then(() => bookingForm);
};

export type BookingFormUpdateParams = {
  id: string;
} & BookingFormCreateParams;

export const updateBookingForm = async (
  providerAccount: ProviderAccount,
  param: BookingFormUpdateParams
): Promise<void> => {
  const now = new Date();
  return updateDoc(doc(getBookingFormRef(providerAccount.id), param.id), {
    ...param,
    updateTime: now,
  } as UpdateData<BookingForm>);
};

export const deleteBookingForm = async (
  providerAccount: ProviderAccount,
  id: BookingForm['id']
): Promise<void> => {
  return deleteDoc(doc(getBookingFormRef(providerAccount.id), id));
};
