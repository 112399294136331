import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  StackDivider,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  arraySwap,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { useBulkUpdateBookingMenus } from '../../../../hooks/bookingMenus';
import {
  LocationFrom,
  createLocationFrom,
} from '../../../../hooks/locationState';
import { resourcePath } from '../../../../hooks/useUrlPath';
import { LineBackground } from '../../../ui/LineBackground';
import { useLoadingOverlayContext } from '../../../ui/LoadingOverlay';
import { BookingMenuPreviewWithoutBackground } from '../BookingMenuPreview';
import { BookingMenuActionButtons } from './BookingMenuActionButtons';

export const PublishedBookingMenusViewPC = ({
  providerAccount,
  bookingMenus,
}: {
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}) => {
  const updateDisplayPriorityMutation =
    useBulkUpdateBookingMenus(providerAccount);

  const overlayContext = useLoadingOverlayContext();
  const [orderedBookingMenus, setOrderedBookingMenus] = React.useState(() =>
    bookingMenus.sort((a, b) => b.displayPriority - a.displayPriority)
  );
  React.useEffect(() => {
    // 公開と下書きの切り替えがstateまで反映されないのでここで反映させる
    if (bookingMenus.length !== orderedBookingMenus.length) {
      setOrderedBookingMenus(
        bookingMenus.sort((a, b) => b.displayPriority - a.displayPriority)
      );
    }
  }, [bookingMenus, orderedBookingMenus.length]);
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          resourceName: 'bookingMenu',
          action: 'list',
          providerAccount,
        })
      ),
    [providerAccount]
  );

  const toast = useToast();

  const onChangeDisplayPriority = React.useCallback(
    (orderedBookingMenus: BookingMenu[]) => {
      const newOrderedBookingMenus = orderedBookingMenus.map((menu, index) => ({
        ...menu,
        displayPriority: orderedBookingMenus.length - index + 1, // 下書き→公開にしたときにdisplayPriorityを1にしているので2始まりにしておく
      }));
      overlayContext.onOpen();
      return updateDisplayPriorityMutation.mutateAsync(
        { bookingMenus: newOrderedBookingMenus },
        {
          onSuccess: () => {
            setOrderedBookingMenus(newOrderedBookingMenus);
          },
          onError: (e) => {
            toast({
              title: `エラーが発生しました ${e}`,
              status: 'error',
            });
          },
          onSettled: () => overlayContext.onClose(),
        }
      );
    },
    [overlayContext, toast, updateDisplayPriorityMutation]
  );
  const handleDragEnd = React.useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over) {
        return;
      }
      if (active.id !== over.id) {
        const oldIndex = orderedBookingMenus.findIndex(
          (menu) => menu.id === active.id
        );
        const newIndex = orderedBookingMenus.findIndex(
          (menu) => menu.id === over.id
        );
        const newOrderedBookingMenus = arrayMove(
          orderedBookingMenus,
          oldIndex,
          newIndex
        );
        setOrderedBookingMenus(newOrderedBookingMenus);
        onChangeDisplayPriority(newOrderedBookingMenus);
      }
    },
    [onChangeDisplayPriority, orderedBookingMenus]
  );

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });
  const sensors = useSensors(pointerSensor);
  const menus = React.useMemo(() => {
    return orderedBookingMenus.map((bookingMenu, index) => {
      return (
        <BookingMenuLinePreviewItem
          key={`preview-${bookingMenu.id}-${bookingMenu.displayPriority}-${index}`}
          providerAccount={providerAccount}
          orderedBookingMenus={orderedBookingMenus}
          bookingMenu={bookingMenu}
          index={index}
          locationFrom={locationFrom}
          onChangeDisplayPriority={onChangeDisplayPriority}
        />
      );
    });
  }, [
    locationFrom,
    onChangeDisplayPriority,
    orderedBookingMenus,
    providerAccount,
  ]);
  if (orderedBookingMenus.length === 0) {
    return (
      <LineBackground>
        <Text p={'100px'} color={'white'} fontWeight={'bold'}>
          公開中の予約メニューがありません
        </Text>
      </LineBackground>
    );
  }

  return (
    <VStack w={{ base: 'full', md: 'fit-content' }} alignItems={'flex-start'}>
      <LineBackground>
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          // collisionDetection={closestCenter}
        >
          <SortableContext items={bookingMenus} strategy={rectSortingStrategy}>
            <SimpleGrid
              w={'full'}
              columns={{ base: 2, lg: 3, xl: 4, '2xl': 5 }}
              // minChildWidth={'250px'}
              borderRadius={'4px'}
              p={'20px'}
              gap={'20px'}
              spacing={'32px'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              {menus}
            </SimpleGrid>
          </SortableContext>
        </DndContext>
      </LineBackground>
    </VStack>
  );
};

const BookingMenuLinePreviewItem: React.FC<{
  providerAccount: ProviderAccount;
  orderedBookingMenus: BookingMenu[];
  bookingMenu: BookingMenu;
  index: number;
  locationFrom: LocationFrom;
  onChangeDisplayPriority: (orderedBookingMenus: BookingMenu[]) => void;
}> = ({
  providerAccount,
  orderedBookingMenus,
  bookingMenu,
  index,
  locationFrom,
  onChangeDisplayPriority,
}) => {
  const sortable = useSortable({
    id: bookingMenu.id,
  });
  const style: React.CSSProperties = {
    transform: sortable.transform
      ? CSS.Transform.toString({
          ...sortable.transform,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition: sortable.transition,
    touchAction: 'none',
  };
  return (
    <VStack
      w={'fit-content'}
      ref={sortable.setNodeRef}
      style={style}
      gap={'8px'}
      alignItems={'center'}
      spacing={0}
      // {...sortable.attributes}
      // {...sortable.listeners}
    >
      <HStack
        spacing={0}
        w={'full'}
        fontSize={'xs'}
        p={'12px'}
        gap={0}
        justifyContent={'space-between'}
        bgColor={'gray.50'}
        color={'gray.600'}
        h={'40px'}
        borderRadius={'4px'}
      >
        <HStack>
          <Tooltip label={'長押しでつかめます'} hasArrow>
            <IconButton
              aria-label="menu"
              icon={HamburgerIcon}
              variant={'transparent-clickable'}
              cursor={'grab'}
              {...sortable.attributes}
              {...sortable.listeners}
            />
          </Tooltip>
          <Text whiteSpace={'nowrap'}>{index + 1}番目</Text>
        </HStack>
        <HStack>
          <Button
            width={'24px'}
            height={'24px'}
            bgColor={'transparent'}
            borderRadius={'2px'}
            isDisabled={index === 0}
            p={0}
            onClick={() => {
              const reordered = arraySwap(
                orderedBookingMenus,
                index,
                index - 1
              );
              return onChangeDisplayPriority(reordered);
            }}
          >
            <Icon
              as={MdOutlineKeyboardArrowLeft}
              // color={'gray.500'}
              boxSize={'24px'}
            />
          </Button>
          <Button
            width={'24px'}
            height={'24px'}
            p={0}
            bgColor={'transparent'}
            borderRadius={'2px'}
            isDisabled={index === orderedBookingMenus.length - 1}
            onClick={() => {
              const reordered = arraySwap(
                orderedBookingMenus,
                index,
                index + 1
              );
              return onChangeDisplayPriority(reordered);
            }}
          >
            <Icon
              as={MdOutlineKeyboardArrowRight}
              // color={'gray.500'}
              boxSize={'24px'}
            />
          </Button>
        </HStack>
        {/* <BookingMenuStatusToggleButton
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
        /> */}
      </HStack>
      <Box
        // w={'full'}
        position={'relative'}
      >
        <Box cursor={'grab'} {...sortable.attributes} {...sortable.listeners}>
          <BookingMenuPreviewWithoutBackground
            bookingMenu={bookingMenu}
            showImage={Boolean(bookingMenu.imageUrl)}
          >
            <VStack spacing={0} gap={0} w={'full'}>
              <StackDivider borderWidth={'1px'} borderColor={'gray.200'} />
              <BookingMenuActionButtons
                providerAccount={providerAccount}
                bookingMenu={bookingMenu}
                locationFrom={locationFrom}
              />
            </VStack>
          </BookingMenuPreviewWithoutBackground>
        </Box>
      </Box>
    </VStack>
  );
};

export const HamburgerIcon = <GiHamburgerMenu size={'16px'} />;
