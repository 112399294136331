import { Checkbox } from './Checkbox';
import { linkStyle } from './Link';
import { Radio } from './Radio';
import { Select } from './Select';
import { Box } from './box';
import { buttonStyle } from './button';
import { Input } from './input';
import { Menu } from './menu';
import { tableStyle } from './table';
import { Textarea } from './textarea';

export const components = {
  Button: buttonStyle,
  ...tableStyle,
  Box,
  Select,
  Radio,
  Input,
  Textarea,
  Checkbox,
  Link: linkStyle,
  Menu,
};
