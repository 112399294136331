import { Button, useToast } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingMenuCreateParams } from '../../../firebase/types';
import { useCreateBookingMenu } from '../../../hooks/bookingMenus';
import { RESOURCE_NAME } from '../../../providers/dataProvider';
import { Layout } from '../../ui/Layout';
import { BookingMenuEditForm } from './BookingMenuEditForm';

export const BookingMenuCreate: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const toast = useToast();
  const resource: string = React.useMemo(() => {
    if (providerAccount) {
      return `${providerAccount.id}/${providerAccount.botId}/${RESOURCE_NAME.BOOKING_MENU}`;
    } else {
      return '';
    }
  }, [providerAccount]);

  const defaultValues = React.useMemo(() => {
    return {
      status: 'suspended',
      displayPriority: 1,
      customText: {
        bookingSuccess: providerAccount?.customText?.bookingSuccess, // providerAccount単位で設定されているものがあればそちらを表示する
      },
      bookingSuccessEnabled: true,
      bookingForm: undefined,
      useImage: true,
    } as BookingMenuCreateParams;
  }, [providerAccount?.customText?.bookingSuccess]);

  const form = useForm<BookingMenuCreateParams & { useImage: boolean }>({
    defaultValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isValid },
  } = form;
  const navigate = useNavigate();
  const mutation = useCreateBookingMenu(providerAccount);

  const onSubmit = React.useCallback(
    async (data: BookingMenuCreateParams) => {
      return mutation
        .mutateAsync(data)
        .then(async ({ data }) => {
          toast({
            title: '予約メニューを作成しました。',
            status: 'success',
          });
          navigate(`/${resource}/${data.id}/show`);
        })
        .catch((e) => {
          toast({
            title: `予約メニューの作成に失敗しました error: ${e}`,
            status: 'error',
          });
        });
    },
    [mutation, navigate, resource, toast]
  );

  return (
    <Layout
      pageTitle="予約メニューの作成"
      hasBackButton={{
        action: 'list',
        resourceName: 'bookingMenu',
        providerAccount,
      }}
      headerAction={
        <Button
          type={'submit'}
          variant={'blue-fill'}
          form={'booking-menu-edit-form'}
          isLoading={mutation.isPending}
          // isDisabled={!isValid || !isDirty}
          isDisabled={!isDirty}
        >
          保存する
        </Button>
      }
    >
      <FormProvider {...form}>
        <BookingMenuEditForm
          onSubmit={onSubmit}
          providerAccount={providerAccount}
          bookingMenu={undefined}
        />
      </FormProvider>
    </Layout>
  );
};
