import {
  Box,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';

export type Choice = {
  id: string | number;
  element: React.ReactElement;
  text: string;
};
export type AutoCompleteInputProp =
  | {
      type: 'input';
      onChange: (value: Choice) => void;
      choices: Choice[] | ((input: string) => Promise<Choice[]>);
      placeholder?: string;
      emptyText: string;
      defaultValue: Choice | undefined;
    }
  | {
      type: 'select';
      onChange: (value: Choice | null) => void;
      choices: Choice[] | ((input: string) => Promise<Choice[]>);
      placeholder?: string;
      emptyText: string;
      defaultValue: Choice | undefined;
    }
  | {
      type: 'mustSelect';
      onChange: (value: Choice) => void;
      choices: Choice[] | ((input: string) => Promise<Choice[]>);
      placeholder?: string;
      emptyText: string;
      defaultValue: Choice | undefined;
    };

// TODO: InputとSelectで別のコンポーネントにしたい
// Input時は入力で候補を絞り込み、選択するとInput欄は空白になる
// Select時は入力で候補を絞り込み、選択するとInput欄に選択済みのものが表示される
// `mustSelect`を指定した場合は未選択状態に戻すことができない
export const AutoCompleteInput: React.FC<AutoCompleteInputProp> = ({
  type,
  onChange,
  choices,
  placeholder,
  emptyText,
  defaultValue,
}) => {
  const stateAtom = React.useMemo(
    () =>
      atom<{
        selectedChoice: Choice | undefined;
        text: string;
        input: string;
        isFocus: boolean;
      }>({
        // ページをまたいだらおかしくなる
        selectedChoice: defaultValue,
        text: '',
        input: '',
        isFocus: false,
      }),
    [defaultValue]
  );
  const [state, setState] = useAtom(stateAtom);
  const [filteredChoices, setFilteredChoices] = React.useState<Choice[]>([]);
  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      setState((prev) => ({
        ...prev,
        text: input,
        input: input,
      }));
      const _choices = await (async () => {
        if (typeof choices === 'function') {
          return choices(input);
        } else {
          return input.length > 0
            ? choices
            : choices.filter((choice) => choice.text.includes(state.input));
        }
      })();
      setFilteredChoices(_choices);
    },
    [choices, setState, state.input]
  );
  React.useEffect(() => {
    handleChange({ target: { value: '' } } as any);
  }, []);
  const onMouseDown = useMutation({
    mutationFn: async (choice: Choice | null) => {
      setState((prev) => ({
        ...prev,
        text: '',
        input: '',
        isFocus: false,
        selectedChoice: choice || undefined,
      }));
      onChange(choice as any);
    },
  });
  // const filteredChoices = React.useMemo(() => {
  //   const _choices = await(async () => {
  //     if (typeof choices === 'function') {
  //       return choices(state.input);
  //     } else {
  //       return choices;
  //     }
  //   });
  //   !state.input?.length
  //     ? choices
  //     : choices.filter((choice) => choice.text.includes(state.input)),
  //     [state.input, choices];
  // });
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    // type=='select'時にonClickでInputにフォーカスを当てるために必要
    if (state.isFocus) {
      ref.current.focus();
    }
  }, [state.isFocus]);

  const Choices = React.useMemo(() => {
    if (!filteredChoices || filteredChoices.length === 0) {
      return (
        <Text
          bgColor={'white'}
          px={'8px'}
          py={'4px'}
          w={'full'}
          textAlign={'left'}
        >
          {emptyText}
        </Text>
      );
    }

    const choiceElements = filteredChoices.map((choice, i) => (
      <Box
        cursor="pointer"
        bgColor={'white'}
        _hover={{ bgColor: 'gray.100' }}
        textAlign={'left'}
        w={'full'}
        key={i}
        px="8px"
        py="4px"
        onMouseDown={() => {
          onMouseDown.mutate(choice);
        }}
      >
        {choice.element}
      </Box>
    ));
    if (type === 'select') {
      return [
        <Box
          cursor="pointer"
          bgColor={'white'}
          color={'gray.500'}
          _hover={{ bgColor: 'gray.100' }}
          textAlign={'left'}
          w={'full'}
          key={'select-placeholder'}
          px="8px"
          py="4px"
          onMouseDown={() => {
            onMouseDown.mutate(null);
          }}
        >
          {placeholder}
        </Box>,
      ].concat(choiceElements);
    } else {
      return choiceElements;
    }
  }, [emptyText, filteredChoices, onMouseDown, placeholder, type]);

  return (
    <Box
      w="full"
      ref={ref}
      onBlur={() => setState((prev) => ({ ...prev, isFocus: false }))}
    >
      {type === 'input' ? (
        <InputGroup>
          <Input
            onFocus={() => setState((prev) => ({ ...prev, isFocus: true }))}
            onBlur={() => setState((prev) => ({ ...prev, isFocus: false }))}
            isDisabled={onMouseDown.isPending}
            type="text"
            size={'sm'}
            value={state.text}
            onChange={handleChange}
            placeholder={placeholder}
            ref={ref}
          />
          {onMouseDown.isPending && (
            <InputRightElement>
              <Spinner />
            </InputRightElement>
          )}
        </InputGroup>
      ) : !state.selectedChoice || state.isFocus ? (
        // Selectで選択する候補を絞り込むためのinput
        <InputGroup minW={'fit-content'} w={'300px'}>
          <Input
            onFocus={() => setState((prev) => ({ ...prev, isFocus: true }))}
            onBlur={() => setState((prev) => ({ ...prev, isFocus: false }))}
            isDisabled={onMouseDown.isPending}
            type="text"
            size={'md'}
            value={state.text}
            onChange={handleChange}
            // placeholder={type === 'mustSelect' ? placeholder : undefined}
            placeholder={placeholder}
            ref={ref}
          />
          <InputRightElement>
            {onMouseDown.isPending ? (
              <Spinner />
            ) : (
              <Box w={'16px'} style={{ rotate: '90deg' }}>
                <MdArrowForwardIos />
              </Box>
            )}
          </InputRightElement>
        </InputGroup>
      ) : (
        // Selectで何かしら選択済みの状態
        <HStack
          justifyContent={'space-between'}
          bgColor={'white'}
          borderRadius={'8px'}
          borderColor={'gray.200'}
          borderWidth={'1px'}
          minW={'fit-content'}
          w={'300px'}
          px={'12px'}
          py={'8px'}
          onClick={() => setState((prev) => ({ ...prev, isFocus: true }))}
        >
          {state.selectedChoice.element}
          <Box w={'16px'} style={{ rotate: '90deg' }}>
            <MdArrowForwardIos />
          </Box>
        </HStack>
      )}
      {state.isFocus && (
        <VStack
          zIndex={100}
          position={'absolute'}
          w="20em"
          boxShadow="base"
          // bg="white"
          mt="4px"
          py={'8px'}
          px={'8px'}
          justifyContent={'flex-start'}
          borderRadius={'md'}
          borderColor={'gray.200'}
          borderWidth={'1px'}
          bgColor={'white'}
          divider={<Divider margin={0} borderColor={'gray.120'} />}
          maxH={'400px'}
          overflowY={'auto'}
        >
          {Choices}
        </VStack>
      )}
    </Box>
  );
};
