// 8HLrvZH6Ro9fD4DDPE3s

import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { BookingConfigurationPage } from '../components/features/configuration/BookingConfiguration';
import { useAuthState } from '../context/providerAccount';

const BookingLimitation = () => {
  setDocumentTitle('予約回数を制限する');
  const { initialized, providerAccount } = useAuthState();
  if (!initialized || !providerAccount) {
    return <Spinner />;
  }
  return <BookingConfigurationPage providerAccount={providerAccount} />;
};

export default BookingLimitation;
