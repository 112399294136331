import { ProviderAccount, ProviderStaff, waitUntil } from '@pochico/shared';
import React, { useState } from 'react';

import { collection, doc, getDoc } from 'firebase/firestore';
import CONSTANTS from '../commons/constants';
import { fetchBotInfo } from '../dataStore/bot';
import { FirebaseUser, db, myAuth } from '../firebase/firebaseInit';
import { fetchProviderAccount } from '../providers/dataProvider/providerAccount';

export const useFirebaseAuth = (): {
  isWaiting: boolean;
  firebaseUser: FirebaseUser | undefined;
} => {
  const [user, setUser] = useState<FirebaseUser | undefined>(undefined);
  const [isWaiting, setIsWaiting] = useState<boolean>(true);

  React.useEffect(() => {
    return myAuth.onAuthStateChanged((user: FirebaseUser | null) => {
      setUser(user ?? undefined);
      setIsWaiting(false);
    });
  }, []);

  return {
    isWaiting: isWaiting,
    firebaseUser: user,
  };
};

export type AuthState = {
  status:
    | 'not_initialized'
    | 'loading'
    | 'authenticated'
    | 'not_authenticated'
    | 'refetching';
  initialized: boolean; // statusから計算できる
  firebaseUser: FirebaseUser | undefined;
  providerAccount: ProviderAccount | undefined;
  role: 'admin' | 'support';
  refetch: () => Promise<void>;
  clear: () => Promise<void>;
};

const _tag = '[useAuthentication]';

// not_initialized: 初期化前
// loading: firebaseUserからproviderStaffを取得中 or providerStaffからproviderAccountを取得中
// authenticated: 認証成功
// not_authenticated: 認証失敗(ログインしていない or 紐づけされていない)
export const useAuthentication = (): AuthState => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | undefined>();

  const [providerAccountId, setProviderAccountId] = useState<
    string | undefined
  >();
  const [providerAccount, setProviderAccount] = useState<ProviderAccount>();
  const [status, setStatus] = useState<AuthState['status']>('not_initialized');
  const [role, setRole] = useState<AuthState['role']>('admin');
  const fetchingProviderAccount = React.useRef<boolean | undefined>(undefined); // 内部的な状態

  const fetchProviderAccountByFirebaseUser = React.useCallback(
    async (firebaseUser: FirebaseUser) => {
      return fetchProviderStaffByFirebaseUserInternal({ user: firebaseUser })
        .then((providerStaff) => {
          // TODO: 複数アカウントを管理できるようにするにはここを修正する必要がある
          const providerAccount = providerStaff?.providerAccounts?.[0];
          if (providerAccount) {
            setProviderAccountId(providerAccount.id);
            setRole(providerAccount.role || 'admin'); // デフォルトではadmin
            // loadingのままにしてuseEffectが走らせる
          } else {
            // 紐づけできていないのでおかしい
            setStatus('not_authenticated');
            console.error(
              `${_tag}providerAccount not found. userId: ${firebaseUser.uid}`
            );
          }
        })
        .catch((error) => {
          console.log(
            `${_tag}failed to fetch providerAccounts due to ${error}`,
            {
              error,
              status,
              firebaseUser,
            }
          );
          if (status !== 'not_initialized') {
            // 'not_initialized'のときは、clearが呼ばれたときになりうる
            console.error(
              `${_tag}failed to fetch providerAccounts. error: ${error}`,
              {
                error,
                firebaseUser,
                status,
              }
            );
            setStatus('not_authenticated');
          }
        });
    },
    [status]
  );

  // ProviderAccountを再取得するための関数
  const refetch = React.useCallback(async () => {
    // console.log(
    //   `${_tag}refetch called. providerAccount: ${providerAccount?.id}`
    // );
    setStatus('refetching');
    if (!firebaseUser) {
      // console.log(`${_tag}refetch called but firebaseUser is not defined`);
      return;
    }
    return fetchProviderAccountByFirebaseUser(firebaseUser).then(() =>
      waitUntil(() => {
        // console.log(`[waitUntil]status: ${status}`);
        return status === 'authenticated';
      }, 5000)
        .then(() => {
          return;
        })
        .catch(() => {
          setStatus('not_authenticated');
          console.log('catch timeout', {
            status,
            providerAccountId,
            providerAccount,
            firebaseUser,
          });
          return;
        })
    );
    // setRefetchCalled(true);
  }, [
    fetchProviderAccountByFirebaseUser,
    firebaseUser,
    providerAccount,
    providerAccountId,
    status,
  ]);

  const clear = React.useCallback(async () => {
    // console.log(`${_tag}clear called. providerAccount: ${providerAccount?.id}`);
    await myAuth.signOut();
    setFirebaseUser(undefined);
    setProviderAccountId(undefined);
    setProviderAccount(undefined);
    setStatus('not_initialized'); // myAuth.onAuthStateChangedが走るように
  }, []);

  React.useEffect(() => {
    const triggerProviderAccountFetch =
      providerAccountId &&
      (status === 'refetching' || // 再取得は問答無用でfetchする
        (!fetchingProviderAccount.current &&
          (status === 'loading' || status === 'not_initialized') &&
          !providerAccount));

    if (!triggerProviderAccountFetch) {
      return;
    }
    fetchingProviderAccount.current = true;
    fetchProviderAccount(providerAccountId)
      .then(async (providerAccount) => {
        setProviderAccount(providerAccount);
        setStatus('authenticated');

        fetchBotInfo(providerAccount, firebaseUser)
          .then((botInfo) => {
            if (
              status !== 'refetching' &&
              botInfo?.displayName !== providerAccount?.displayName
            ) {
              return refetch();
            }
            return;
          })
          .catch((e) => {
            console.error(`${_tag}failed to fetch botInfo. error: ${e}`, e);
          });
      })
      .catch((e) => {
        console.error(
          `${_tag}failed to fetch providerAccount ${providerAccountId}. error: ${e}`,
          e
        );
        setProviderAccount(undefined);
        setStatus('not_authenticated');
      })
      .finally(() => {
        fetchingProviderAccount.current = false;
        // setRefetchCalled(false);
      });
  }, [firebaseUser, providerAccount, providerAccountId, refetch, status]);

  React.useEffect(() => {
    return myAuth.onAuthStateChanged((user) => {
      // console.log(`${_tag}onAuthStateChanged`, {
      //   user,
      //   status,
      //   providerAccountId,
      //   providerAccount,
      // });
      if (
        firebaseUser &&
        user &&
        firebaseUser.uid === user.uid &&
        status !== 'not_initialized'
      ) {
        return;
      }

      setFirebaseUser(user ?? undefined);

      if (user) {
        setStatus('loading');
        fetchProviderAccountByFirebaseUser(user);
      } else {
        setStatus('not_authenticated');
        setProviderAccountId(undefined);
        setProviderAccount(undefined);
      }
    });
  }, [
    fetchProviderAccountByFirebaseUser,
    firebaseUser,
    providerAccount,
    providerAccountId,
    status,
  ]);

  return {
    status,
    initialized:
      status === 'not_authenticated' ||
      status === 'authenticated' ||
      status === 'refetching', // 再取得を待つ必要がある場合はstatus === 'refetching'で判定すること
    firebaseUser,
    providerAccount,
    role,
    refetch,
    clear,
  };
};

const fetchProviderStaffByFirebaseUserInternal = async ({
  user,
}: {
  user: FirebaseUser;
}): Promise<ProviderStaff | undefined> => {
  return getDoc(
    doc(collection(db, CONSTANTS.COLLECTION.PROVIDER_STAFFS), user.uid)
  )
    .then((doc) => {
      if (doc.exists()) {
        const data = doc.data() as ProviderStaff;
        // console.log(`${_tag}fetched providerStaff`, {
        //   user,
        //   providerStaff: data,
        // });
        return data;
      } else {
        // 紐づけできていないのでおかしい
        console.error(`${_tag}providerAccount not found. userId: ${user.uid}`, {
          firebaseUser: user,
        });
        return undefined;
      }
    })
    .catch((e) => {
      if ('code' in e && e.code === 'permission-denied') {
        // アカウント登録途中などでproviderStaffが存在しない場合はsecurity ruleによってpermission-deniedが返却される
        return Promise.resolve(undefined);
      }
      const error = new Error(
        `${_tag}failed to fetch providerAccount: user: '${user?.uid}', error: '${e}'`
      );
      return Promise.reject(error);
    });
};
