import { ProviderAccount } from '@pochico/shared';
import { FirebaseUser } from '../firebase/firebaseInit';

type BotResponse<T> =
  | {
      ok: true;
      body: T;
    }
  | {
      ok: false;
      error: string;
    };

export const deleteBotApi = async <T>(
  path: string,
  firebaseUser: FirebaseUser,
  options: Partial<RequestInit> = {}
): Promise<BotResponse<T>> => {
  return firebaseUser.getIdToken().then((idToken) => {
    return fetch(GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN + path, {
      method: 'delete',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      ...options,
    }).then(processResponse<T>);
  });
};

export const postBotApi = async <T>(
  path: string,
  firebaseUser: FirebaseUser,
  body: any,
  options: Partial<RequestInit> = {}
): Promise<BotResponse<T>> => {
  return firebaseUser.getIdToken().then((idToken) => {
    return fetch(GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN + path, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(body),
      ...options,
    }).then(processResponse<T>);
  });
};

export const getBotApi = async <T>(
  path: string,
  firebaseUser: FirebaseUser,
  options: Partial<RequestInit> = {}
): Promise<BotResponse<T>> => {
  return firebaseUser.getIdToken().then((idToken) => {
    return fetch(GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN + path, {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      ...options,
    }).then(processResponse<T>);
  });
};

const processResponse = async <T>(
  response: Response
): Promise<{ ok: true; body: T } | { ok: false; error: string }> => {
  const bodyText = await response.text();
  try {
    // console.log(
    //   `[processResponse]url: ${response.url}, status: ${response.status}`
    // );
    const body = JSON.parse(bodyText);

    if (response.status < 400) {
      return { ok: true, body: body as T };
    }

    if (response.status === 401) {
      // Unauthorizedだけはここで処理する
      if ('error' in body && body.error === 'Unauthorized') {
        console.warn(
          `[processResponse]url: ${response.url}, error: ${body.error}`,
          body.error
        );
        return { ok: false, error: 'Unauthorized' };
      }
    }
  } catch (e) {
    console.error(
      `failed to process response.url: ${response.url}, error: ${e}`,
      e
    );
  }

  console.warn(
    `[processResponse]url: ${response.url}, status: ${response.status}, error: ${bodyText}`,
    bodyText
  );
  return { ok: false, error: bodyText };
};

export const fetchBotInfo = async (
  providerAccount: ProviderAccount | undefined,
  firebaseUser: FirebaseUser | undefined
) => {
  if (!firebaseUser || !providerAccount) {
    return;
  }
  type BotInfo = { displayName: string };
  return getBotApi<{ botInfo: BotInfo }>(
    `/provider-accounts/${providerAccount.id}/botInfo`,
    firebaseUser
  ).then((response) => {
    if (response.ok) {
      return { displayName: response.body.botInfo.displayName };
    } else {
      console.error(`failed to fetch botInfo. ${response.error}`);
      return undefined;
    }
  });
};
