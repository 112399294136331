import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Radio,
  Stack,
  StackDivider,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  Color,
  ProviderAccount,
  ProviderAccountTheme,
  defaultTheme,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { MdCheckCircle } from 'react-icons/md';
import { useAuthState } from '../../../context/providerAccount';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useIsPC } from '../../../hooks/useIsPC';
import { updateProviderAccount } from '../../../providers/dataProvider/providerAccount';
import { Path } from '../../../routers/Path';
import { Layout } from '../../ui/Layout';
import { WebLink } from '../../ui/WebLink';
import { ThemeConfigurationPreview } from './ThemePreview';

const presetButtonBackgroundColors: Color[] = [
  '#7F0019',
  '#C9162B',
  '#FA4A4D',
  '#EC5F47',
  '#FFA42E',
  '#F7C11E',
  '#1FC46E',
  '#00A683',
  '#45C4B7',
  '#2EBDDB',
  '#168ACE',
  '#3971C0',
  '#5572D9',
  '#AB83E6',
  '#8F63CF',
  '#AA52E0',
  '#E95BBB',
  '#FF9CCD',
  '#FF8CA7',
  '#799D55',
  '#56714C',
  '#01713F',
  '#005F5D',
  '#CA9660',
  '#877059',
  '#57453A',
  '#322A25',
  '#4E6A79',
  '#4A5D69',
  '#35435E',
  '#7E6C86',
  '#876D78',
  '#8D586A',
  '#673B4D',
  '#562E66',
  '#979797',
  '#454545',
  '#262626',
];

export const ThemeConfigurationPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { refetch } = useAuthState();
  const bookingMenuQuery = useFetchBookingMenus({
    providerAccount,
  });
  const bookingMenu = React.useMemo(() => {
    const menus = bookingMenuQuery.data || [];
    if (!menus) {
      return null;
    }
    const active = menus
      .filter((menu) => menu.status === 'active')
      .sort((a, b) => b.displayPriority - a.displayPriority);
    if (active.length > 0) {
      return active[0];
    } else {
      return menus[0];
    }
  }, [bookingMenuQuery.data]);
  const form = useForm<ProviderAccountTheme>({
    defaultValues: providerAccount.theme || defaultTheme,
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = form;
  const theme = useWatch({ control });
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (theme: ProviderAccountTheme) => {
        return updateProviderAccount(providerAccount, { theme });
      },
      [providerAccount]
    ),
    onSuccess: async () => {
      toast.closeAll();
      toast({
        title: 'テーマを保存しました',
        status: 'success',
      });
      await refetch();
    },
    onError: (error) => {
      toast({
        title: `エラーが発生しました ${error}`,
        description: error.message,
        status: 'error',
      });
    },
  });

  const isPC = useIsPC();

  return (
    <Layout
      pageTitle={'トーク画面のカラーテーマ'}
      hasBackButton={Path.configuration}
    >
      <VStack w={'full'} alignItems={'flex-start'} spacing={'20px'}>
        <Box color={'gray.700'} fontWeight={400}>
          トーク画面に送信される、候補日時や予約メニューのボタンカラーを変更できます。
          （ポチコ用リッチメニューの色は連動しません。
          <WebLink
            target="_blank"
            href={'https://help.pochico.app/da1842a40fd3407c837aec83364893fa'}
          >
            リッチメニューの変更はこちらから
          </WebLink>
          ）
        </Box>
        {!isPC && <Divider borderColor="gray.200" />}
        <FormProvider {...form}>
          <form
            style={{ width: '100%' }}
            onSubmit={handleSubmit((theme) => mutation.mutateAsync(theme))}
          >
            <Stack
              direction={{ base: 'column-reverse', md: 'row' }}
              w={'full'}
              spacing={'16px'}
            >
              <VStack spacing={'16px'} w={'full'}>
                <VStack
                  w={'full'}
                  spacing={'16px'}
                  borderWidth={'1px'}
                  borderColor={'gray.200'}
                  borderRadius={'4px'}
                  py={'16px'}
                  divider={<StackDivider borderColor={'gray.200'} />}
                >
                  <Box p={'16px'} w={'full'}>
                    <FormControl
                      isInvalid={!!errors?.submitButton?.backgroundColor}
                    >
                      <SubmitButtonBackgroundColorPicker />
                    </FormControl>
                  </Box>
                  <Box w={'full'} p={'16px'}>
                    <FormControl isInvalid={!!errors?.submitButton?.textColor}>
                      <SubmitTextColorPicker />
                    </FormControl>
                  </Box>
                </VStack>
                <Button
                  type={'submit'}
                  isLoading={mutation.isPending}
                  w={{ base: 'full', md: 'fit-content' }}
                  alignSelf={'flex-end'}
                  variant={'blue-fill'}
                  isDisabled={!isValid || !isDirty}
                  size={'md'}
                  h={'44px'}
                >
                  保存する
                </Button>
              </VStack>
              <VStack
                w={'full'}
                spacing={'16px'}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                borderRadius={'4px'}
                p={{ base: 0, md: '12px' }}
                pt={{ base: '12px', md: 0 }}
              >
                <Text fontWeight={'bold'} fontSize={'md'}>
                  プレビュー
                </Text>
                {bookingMenu && (
                  <ThemeConfigurationPreview
                    bookingMenu={bookingMenu}
                    theme={theme as ProviderAccountTheme} // 型エラーが出るのでキャスト
                  />
                )}
              </VStack>
            </Stack>
          </form>
        </FormProvider>
      </VStack>
    </Layout>
  );
};

const SubmitButtonBackgroundColorPicker: React.FC = () => {
  const isPC = useIsPC();
  const form = useFormContext<ProviderAccountTheme>();
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = form;
  const { ref, ...buttonColorInputRegister } = register(
    'submitButton.backgroundColor',
    {
      setValueAs: (value: string) => value.trim(),
      onChange: (e) => {
        const value = e.target.value as string;
        setCustomColor(value);
      },
    }
  );
  const colorInputRef = React.useRef<HTMLInputElement | null>(null);
  const theme = useWatch({ control });
  const { backgroundColor: selectedButtonColor, textColor: selectedTextColor } =
    theme.submitButton || defaultTheme.submitButton;
  const [customColor, setCustomColor] = React.useState<string>(
    selectedButtonColor || defaultTheme.submitButton.backgroundColor
  );

  const onClickColorPickerButton = React.useCallback(() => {
    colorInputRef.current?.click();
  }, []);
  const onClickResetButtonColor = React.useCallback(() => {
    setValue(
      'submitButton.backgroundColor',
      defaultTheme.submitButton.backgroundColor,
      { shouldDirty: true }
    );
    setCustomColor(defaultTheme.submitButton.backgroundColor);
  }, [setValue]);
  return (
    <VStack w={'full'} spacing={'32px'} alignItems={'flex-start'}>
      <HStack>
        <Text fontWeight={'bold'}>ボタンの背景色</Text>
        <Button variant={'link'} onClick={onClickResetButtonColor}>
          初期値に戻す
        </Button>
      </HStack>
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text fontWeight={'bold'}>プリセットから選ぶ</Text>

        <HStack
          gap={'4px'}
          spacing={0}
          w={'full'}
          maxW={isPC ? '500px' : 'full'}
          flexWrap={isPC ? 'wrap' : 'wrap'}
          minH={{ base: '60px', md: 'fit-content' }}
        >
          {presetButtonBackgroundColors.map((color) => (
            <Box key={color} w={'48px'} h={'48px'} position={'relative'}>
              <Input
                key={color}
                type={'radio'}
                bgColor={color}
                borderWidth={'1px'}
                borderColor={'gray.200'}
                borderRadius={'4px'}
                onClick={() => {
                  setValue('submitButton.backgroundColor', color, {
                    shouldDirty: true,
                  });
                }}
                minW={'48px'}
                maxW={'48px'}
                minH={'48px'}
                maxH={'48px'}
              />
              {selectedButtonColor === color && (
                <Icon
                  position={'absolute'}
                  as={MdCheckCircle}
                  borderRadius={'full'}
                  w={'20px'}
                  h={'20px'}
                  bgColor={color}
                  color={'white'}
                  top={'14px'}
                  left={'14px'}
                />
              )}
            </Box>
          ))}
        </HStack>
      </VStack>
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text fontWeight={'bold'}>カスタムカラー</Text>
        <HStack>
          <Radio
            isChecked={
              selectedButtonColor &&
              !presetButtonBackgroundColors.includes(selectedButtonColor)
            }
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (isChecked) {
                setValue('submitButton.backgroundColor', customColor as Color, {
                  shouldDirty: true,
                });
              }
            }}
          />
          <Box
            minW={'48px'}
            minH={'48px'}
            maxW={'48px'}
            maxH={'48px'}
            borderRadius={'4px'}
            bgColor={customColor}
            borderWidth={'1px'}
            borderColor={'gray.200'}
          />
          <Input
            type={'text'}
            value={customColor}
            w={'120px'}
            placeholder={'#123456'}
            onChange={(e) => {
              const value = e.target.value as Color;
              setCustomColor(value);
              setValue('submitButton.backgroundColor', value, {
                shouldDirty: true,
              });
            }}
          />

          <Box position={'relative'}>
            <Button onClick={onClickColorPickerButton}>色を選択</Button>
            <Input
              position={'absolute'}
              top={'24px'}
              left={'24px'}
              bgColor={'transparent'}
              // display={showColorPicker ? 'block' : 'none'}
              w={0}
              p={0}
              h={0}
              type={'color'}
              {...buttonColorInputRegister}
              ref={(e) => {
                ref(e);
                colorInputRef.current = e;
              }}
            />
          </Box>
        </HStack>
      </VStack>
      <FormErrorMessage>
        {errors?.submitButton?.backgroundColor?.message}
      </FormErrorMessage>
    </VStack>
  );
};

const SubmitTextColorPicker: React.FC = () => {
  const form = useFormContext<ProviderAccountTheme>();
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const onClickResetTextColor = React.useCallback(() => {
    setValue('submitButton.textColor', defaultTheme.submitButton.textColor, {
      shouldDirty: true,
    });
  }, [setValue]);
  return (
    <VStack w={'full'} spacing={'32px'} alignItems={'flex-start'}>
      <HStack>
        <Text fontWeight={'bold'}>ボタンテキストカラー</Text>
        <Button variant={'link'} onClick={onClickResetTextColor}>
          初期値に戻す
        </Button>
      </HStack>
      <Controller
        control={control}
        name={'submitButton.textColor'}
        render={({ field: { ref: _, ...field } }) => {
          return (
            <HStack w={'full'} alignItems={'flex-start'}>
              {(['white', 'black'] as const).map((color) => (
                <TextColorRadioButton
                  key={`textColorRadio-${color}`}
                  color={color}
                  field={field}
                />
              ))}
            </HStack>
          );
        }}
      />

      <FormErrorMessage>
        {errors?.submitButton?.textColor?.message}
      </FormErrorMessage>
    </VStack>
  );
};

const TextColorRadioButton: React.FC<{
  color: 'black' | 'white';
  field: { value: string } & any;
}> = ({ color, field: { value, ...field } }) => {
  return (
    <Box w={'48px'} h={'48px'} position={'relative'}>
      <Input
        value={color}
        // isChecked={value === 'white'}
        {...field}
        type={'radio'}
        w={'full'}
        h={'full'}
        p={'4px'}
        bgColor={color}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        borderRadius={'4px'}
      />
      {color === value && (
        <Icon
          position={'absolute'}
          as={MdCheckCircle}
          w={'20px'}
          h={'20px'}
          bgColor={color}
          color={color === 'black' ? 'white' : 'black'}
          top={'14px'}
          left={'14px'}
        />
      )}
    </Box>
  );
};
