import { BookingFormElement } from '@pochico/shared';

export const typeLabel: {
  [key in BookingFormElement['type']]: string;
} = {
  string: '自由入力',
  number: '数字入力',
  'single-select': 'ラジオボタン(単一選択)',
  'multi-select': 'チェックボックス(複数選択)',
  agreement: '規約確認・注意事項',
} as const;
