import React from 'react';

export const setDocumentTitle = (title?: string) => {
  document.title = `${GLOBAL_CONFIG.SERVICE_NAME.UNIQ_NAME_JP} 管理者ツール${
    title ? `- ${title}` : ''
  }`;
};
export const useSetDocumentTitle = (title: string) => {
  React.useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
};
