import React from 'react';

export const Checkmark = () => (
  <svg
    width="86"
    height="78"
    viewBox="0 0 86 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.5877" cy="38.5877" r="38.5877" fill="#92C9B2" />
    <circle cx="38.5879" cy="38.5877" r="32.7632" fill="white" />
    <path
      d="M20.9604 32.0489L20.2482 31.3342L19.5399 32.0528L15.3054 36.3487L14.6194 37.0446L15.2993 37.7465L37.2419 60.3975L37.9508 61.1294L38.6693 60.4068L83.7092 15.1047L84.2908 14.5196L83.827 13.8374L79.9775 8.17464L79.293 7.16776L78.4383 8.03484L37.9562 49.1036L20.9604 32.0489Z"
      fill="#92C9B2"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
