import { atom } from 'jotai';
import CONSTANTS from '../commons/constants';
import { Pagination } from '../providers/dataProvider/type';

const perPageAtomValue = atom(
  localStorage.getItem('perPage')
    ? Number.parseInt(localStorage.getItem('perPage') as string)
    : CONSTANTS.PAGINATION_PER_PAGE_DEFAULT
);
export const perPageAtom = atom(
  (get) => get(perPageAtomValue),
  (get, set, newValue: number) => {
    set(perPageAtomValue, newValue);
    localStorage.setItem('perPage', newValue.toString());
  }
);

// pagination用の状態を保存するatomを作成する
// perPageは別のstate(localStorageに書き込む)
// それ以外はatom内のstateに保存する
export const paginationAtom = <T>(
  initialValue: Omit<Pagination<T>, 'perPage'>
) => {
  const internalState = atom<Omit<Pagination<T>, 'perPage'>>(initialValue);
  const state = atom(
    (get) => {
      const perPage = get(perPageAtom);
      const state = get(internalState);
      return { ...state, perPage };
    },
    (
      get,
      set,
      newValue: Pagination<T> | ((prev: Pagination<T>) => Pagination<T>)
    ) => {
      const _newValue = (() => {
        if (typeof newValue === 'function') {
          const current = {
            perPage: get(perPageAtom),
            ...get(internalState),
          } as Pagination<T>;
          return newValue(current);
        } else {
          return newValue;
        }
      })();
      const { perPage, ...rest } = _newValue;
      set(perPageAtom, perPage);
      set(internalState, rest);
    }
  );
  // 再度ページを表示したときにはページを1ページ目に戻す
  state.onMount = (set) => {
    set((prev) => {
      return {
        ...prev,
        page: 1,
        lastCursor: undefined,
      };
    });
  };

  return state;
};
