import React from 'react';
import { useLocation } from 'react-router-dom';

export type LocationFrom = {
  path: string;
};
export const createLocationFrom = (path: string) => {
  return {
    path,
  } as LocationFrom;
};

export const useLocationFrom = (({
  fallbackPath,
}: {
  fallbackPath: string | undefined;
}): LocationFrom | undefined => {
  const location = useLocation();
  const from = React.useMemo(() => {
    const from = location.state as LocationFrom | undefined;
    if (from) {
      return from;
    } else if (fallbackPath) {
      return createLocationFrom(fallbackPath);
    }
    return;
  }, [fallbackPath, location.state]);
  return from;
}) as {
  (arg: { fallbackPath: string }): LocationFrom;
  (arg: { fallbackPath: undefined }): LocationFrom | undefined;
};
