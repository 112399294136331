import { Text, VStack } from '@chakra-ui/react';
import { BookingMenu } from '@pochico/shared';
import * as React from 'react';

type LimitDateTimeProps = {
  bookingMenu: BookingMenu;
  source: 'bookingStart' | 'bookingEnd' | 'cancelEnd';
  label: string;
  description: (input: { dayBefore: number; time?: string }) => string;
};
export const LimitDateTime: React.FC<{
  bookingMenu: LimitDateTimeProps['bookingMenu'];
  source: LimitDateTimeProps['source'];
}> = ({ bookingMenu, source }) => {
  switch (source) {
    case 'bookingStart':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="bookingStart"
          label={'予約受付の『開始』時間'}
          description={({ dayBefore, time }) => {
            return `${dayBefore} 日前${
              time ? `の ${time} ` : ''
            }から予約受付を開始する`;
          }}
        />
      );
    case 'bookingEnd':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="bookingEnd"
          label={'予約受付の『終了』時間'}
          description={({ dayBefore, time }) => {
            return `${dayBefore} 日前${
              time ? `の ${time} ` : ''
            }に予約受付を終了する`;
          }}
        />
      );
    case 'cancelEnd':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="cancelEnd"
          label={'『キャンセル』受付の終了時間'}
          description={({ dayBefore, time }) => {
            return `${dayBefore} 日前${
              time ? `の ${time} ` : ''
            }までキャンセルを受け付ける`;
          }}
        />
      );
  }
};

const LimitDateTimeShared = (props: LimitDateTimeProps) => {
  const { source, bookingMenu, label, description } = props;
  const value = bookingMenu[source];

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      width={'full'}
    >
      <Text fontWeight={'bold'}>{label}</Text>
      <Text>{value ? description(value) : '設定無し'}</Text>
    </VStack>
  );
};
