import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { SpotShow } from '../../components/features/spots/show';
import { useUrlPath } from '../../hooks/useUrlPath';

export const SpotShowPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約枠の詳細');
  const urlPath = useUrlPath();

  if (
    !urlPath ||
    urlPath.resourceName !== 'spot' ||
    urlPath.action !== 'show' ||
    !urlPath.resourceId
  ) {
    console.log('invalid url path', urlPath);
    return (
      <Navigate
        replace={true}
        to={`/${providerAccount.id}/${providerAccount.botId}/spot`}
      />
    );
  }

  return <SpotShow providerAccount={providerAccount} id={urlPath.resourceId} />;
};
