import {
  Divider,
  HStack,
  Image,
  ListItem,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { useDeleteBookingMenu } from '../../../../hooks/bookingMenus';
import { createLocationFrom } from '../../../../hooks/locationState';
import { useIsPC } from '../../../../hooks/useIsPC';
import { resourcePath } from '../../../../hooks/useUrlPath';
import { useConfirmationDialog } from '../../../ui/ConfirmationDialog';
import { BookingMenuPreviewWithoutBackground } from '../BookingMenuPreview';
import { BookingMenuActionButtons } from './BookingMenuActionButtons';

export const BookingMenuDraftListView = ({
  providerAccount,
  bookingMenus,
}: {
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}) => {
  const isPC = useIsPC();
  if (isPC) {
    return (
      <VStack
        spacing={'8px'}
        w={'full'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        borderRadius={'3px'}
        px={'20px'}
        py={'20px'}
      >
        <HStack
          gap={'20px'}
          // p={bookingMenus.length > 0 ? '32px' : 0}
          spacing={0}
          w={'full'}
          flexWrap={'wrap'}
          alignItems={'flex-start'}
        >
          {bookingMenus.length > 0 ? (
            bookingMenus.map((bookingMenu, index, arr) => {
              return (
                <BookingMenuDraftItem
                  key={`booking-menu-${index}`}
                  providerAccount={providerAccount}
                  bookingMenu={bookingMenu}
                />
              );
            })
          ) : (
            <Text>{'予約メニューがありません'}</Text>
          )}
        </HStack>
      </VStack>
    );
  } else {
    return (
      <VStack
        spacing={'0'}
        w={'full'}
        borderWidth={'1px'}
        borderColor={'gray.200'}
        divider={<Divider borderColor={'gray.200'} />}
      >
        {bookingMenus.length > 0 ? (
          bookingMenus.map((bookingMenu, index, arr) => {
            return (
              <BookingMenuDraftItem
                key={`booking-menu-${index}`}
                providerAccount={providerAccount}
                bookingMenu={bookingMenu}
              />
            );
          })
        ) : (
          <Text py={'16px'}>{'予約メニューがありません'}</Text>
        )}
      </VStack>
    );
  }
};

const BookingMenuDraftItem: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  const isPC = useIsPC();
  const deleteMutation = useDeleteBookingMenu(providerAccount);
  const locationFrom = createLocationFrom(
    resourcePath({
      resourceName: 'bookingMenu',
      providerAccount,
      action: 'list',
    })
  );
  const { openConfirmationDialog } = useConfirmationDialog();
  const toast = useToast();
  const onClickDelete = React.useCallback(async () => {
    if (bookingMenu.isDefault) {
      return;
    }
    openConfirmationDialog({
      title: `予約メニュー「${bookingMenu.name}」を削除しますか？`,
      body: (
        <>
          <Text>
            元に戻すことはできません。
            <br />
            また、予約メニューを削除しても以下は削除されません
          </Text>
          <UnorderedList>
            <ListItem>予約枠</ListItem>
            <ListItem>すでに取られた予約</ListItem>
          </UnorderedList>
        </>
      ),
      cancelText: 'キャンセル',
      submitText: '削除する',
      onSubmit: async () => {
        return deleteMutation
          .mutateAsync(bookingMenu.id)
          .then(() => {
            toast({
              title: `${bookingMenu.name} を削除しました`,
              status: 'success',
            });
          })
          .catch((e) => {
            toast({
              title: `${bookingMenu.name} の削除に失敗しました error: ${e}`,
              status: 'error',
            });
          });
      },
    });
  }, [
    bookingMenu.id,
    bookingMenu.isDefault,
    bookingMenu.name,
    deleteMutation,
    openConfirmationDialog,
    toast,
  ]);

  if (isPC) {
    return (
      <VStack spacing={0} gap={0} alignItems={'center'}>
        <BookingMenuPreviewWithoutBackground
          bookingMenu={bookingMenu}
          showImage={Boolean(bookingMenu.imageUrl)}
        >
          <VStack spacing={0} gap={0} w={'full'}>
            <StackDivider borderWidth={'1px'} borderColor={'gray.200'} />
            <BookingMenuActionButtons
              providerAccount={providerAccount}
              bookingMenu={bookingMenu}
              locationFrom={locationFrom}
            />
          </VStack>
        </BookingMenuPreviewWithoutBackground>
      </VStack>
    );
  } else {
    return (
      <HStack
        spacing={0}
        w={'full'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        bgColor={'gray.100'}
      >
        {bookingMenu.imageUrl && (
          <Image
            src={bookingMenu.imageUrl}
            alt={bookingMenu.name}
            width={'96px'}
            minWidth={'96px'}
            height={'64px'}
          />
        )}
        <VStack
          w={'full'}
          bgColor={'white'}
          px={'20px'}
          py={'20px'}
          alignItems={'flex-start'}
          fontSize={'sm'}
          // overflowX={'clip'}
        >
          <Text fontWeight={'bold'} fontSize={20}>
            {bookingMenu.name}
          </Text>
          <Text fontSize={13} textOverflow={'ellipsis'}>
            {bookingMenu.description}
          </Text>
          <BookingMenuActionButtons
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
            locationFrom={locationFrom}
          />
        </VStack>
      </HStack>
    );
  }
};
