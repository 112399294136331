import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {
  MdDelete,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { SavedFilterContext } from './SavedFilter';

// SP向けに保存した検索条件を表示・管理するコンポーネント(Drawer)
export const SavedFilterDrawerForSP = <T,>({
  disclosure,
  filter,
  applyFilter,
  context,
  children,
}: {
  disclosure: ReturnType<typeof useDisclosure>;
  filter: T;
  applyFilter: (filter: T) => void;
  context: SavedFilterContext<T>;
  children: React.ReactNode;
}) => {
  const { isOpen, onClose } = disclosure;

  const [page, setPage] = React.useState<'top' | 'upsert' | 'list' | 'delete'>(
    'top'
  );
  const [_filter, setFilter] = React.useState(filter);
  const [name, setName] = React.useState('');
  if (!context) {
    return (
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          setPage('top');
          setName('');
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              絞り込み
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <VStack alignItems={'flex-start'} spacing={'12px'}>
              {children}
              <Divider borderColor={'gray.300'} />
              <Button
                w={'full'}
                variant={'blue-fill'}
                onClick={() => {
                  applyFilter(filter);
                  onClose();
                }}
              >
                検索する
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  const {
    dispatcher: { upsert, remove, setSelectedFilter },
    state: { data, selectedFilter },
  } = context;

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onClose();
        setPage('top');
        setName('');
      }}
    >
      <DrawerOverlay />

      {page === 'top' ? (
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              絞り込み
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <VStack alignItems={'flex-start'} spacing={'12px'}>
              {selectedFilter && (
                <Box
                  p={'12px'}
                  borderRadius={'4px'}
                  w={'full'}
                  bgColor={'blue.50'}
                  color={'blue.500'}
                  fontSize={'sm'}
                >
                  {selectedFilter.name}を適用中
                </Box>
              )}
              {children}
              <Button
                w={'full'}
                variant={'transparent-clickable'}
                onClick={() => setPage('list')}
                py={'16px'}
              >
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text>保存した検索条件</Text>
                  <MdOutlineKeyboardArrowRight />
                </HStack>
              </Button>
              <Divider borderColor={'gray.300'} />
              <Button
                w={'full'}
                variant={'blue-fill'}
                onClick={() => {
                  applyFilter(filter);
                  setSelectedFilter(null);
                  onClose();
                }}
              >
                検索する
              </Button>
              <Button
                w={'full'}
                variant={'white-blue'}
                onClick={() => setPage('upsert')}
              >
                現在の検索条件を保存
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      ) : page === 'upsert' ? (
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack fontSize={'lg'} fontWeight={'bold'}>
              <MdOutlineKeyboardArrowLeft
                onClick={() => {
                  setName('');
                  setPage('top');
                }}
              />
              <Text fontWeight={'bold'} fontSize={'lg'}>
                絞り込み
              </Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <VStack alignItems={'flex-start'} spacing={'16px'}>
              <FormControl isRequired>
                <FormLabel>検索条件名</FormLabel>
                <Input
                  placeholder="名前"
                  // defaultValue={selectedSavedFilter?.name}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <Button
                w={'full'}
                variant={'blue-fill'}
                isDisabled={!name}
                onClick={async () => {
                  const filter = { name, filter: _filter };
                  await upsert(filter);
                  setName('');
                  setSelectedFilter(filter);
                  setPage('top');
                }}
              >
                保存する
              </Button>
              <Button
                w={'full'}
                colorScheme={'gray'}
                onClick={() => {
                  setName('');
                  setPage('top');
                }}
              >
                キャンセルする
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      ) : page === 'list' ? (
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack fontSize={'lg'} fontWeight={'bold'}>
              <MdOutlineKeyboardArrowLeft
                onClick={() => {
                  setName('');
                  setPage('top');
                }}
              />
              <Text fontWeight={'bold'} fontSize={'lg'}>
                保存した検索条件
              </Text>
              <Button
                variant={'white-red'}
                onClick={() => {
                  setPage('delete');
                }}
              >
                削除
              </Button>
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <VStack
              alignItems={'flex-start'}
              divider={<Divider borderColor={'gray.300'} />}
              overflowY={'auto'}
            >
              {data.map((savedFilter) => (
                <Button
                  w={'full'}
                  key={savedFilter.name}
                  variant={'transparent-clickable'}
                  onClick={() => {
                    setFilter(savedFilter.filter);
                    applyFilter(savedFilter.filter);
                    setSelectedFilter(savedFilter); // applyFilterでclearSelectionしているためそれよりあとでsetSelectedFilterを呼ぶ
                    setPage('top');
                    onClose();
                  }}
                >
                  <HStack w={'full'} justifyContent={'flex-start'}>
                    <Text>{savedFilter.name}</Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      ) : page === 'delete' ? (
        // ここ実装する！！！
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack fontSize={'lg'} fontWeight={'bold'}>
              <MdOutlineKeyboardArrowLeft
                onClick={() => {
                  setPage('list');
                }}
              />
              <Text fontWeight={'bold'} fontSize={'lg'}>
                削除する検索条件を選択
              </Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <VStack
              alignItems={'flex-start'}
              divider={<Divider borderColor={'gray.300'} />}
              overflowY={'auto'}
            >
              {data.map((savedFilter) => (
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>{savedFilter.name}</Text>
                  <Button
                    variant={'transparent-clickable'}
                    onClick={() => {
                      if (selectedFilter?.name === savedFilter.name) {
                        setSelectedFilter(null);
                      }
                      remove(savedFilter.name);
                    }}
                  >
                    <MdDelete color={'red'} />
                  </Button>
                </HStack>
              ))}
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      ) : (
        <>画面を再読込してください</>
      )}
    </Drawer>
  );
};
