import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

// 登録動線やログイン時に、URLパラメータをみてLINE連携方式を取得する
export const useLineChannelTypeParameter = () => {
  const [urlParams] = useSearchParams();
  const lineChannelType: Required<ProviderAccount['lineChannelType']> =
    React.useMemo(() => {
      const param = urlParams.get('lineChannelType');
      if ((param && param === 'messagingApi') || param === 'moduleApi') {
        return param;
      } else {
        return 'moduleApi';
      }
    }, [urlParams]);
  return lineChannelType;
};
