import {
  AspectRatio,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { MdError } from 'react-icons/md';
import { AlertIcon } from '../../components/ui/AlertIcon';
import { FixedRight } from '../../components/ui/Fixed';
import { RightArrowBlack } from '../../components/ui/RightArrow';

export const LineOfficialAccountIntro = () => {
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');
  return (
    <VStack
      w={'full'}
      spacing={4}
      padding={6}
      direction={'column'}
      textAlign={'left'}
      borderRadius={10}
      border={'1px solid #CCCCCC'}
    >
      {isLargerThan480 ? (
        <HStack alignItems={'flex-start'}>
          <Icon as={MdError} color={'red.400'} boxSize={'32px'} />
          <VStack alignItems={'left'}>
            <Title />
            <Description />
          </VStack>
          <Flex>
            <Flex justifyContent={'center'} marginTop={'2em'}>
              <CreateAccountButton />
            </Flex>
          </Flex>
        </HStack>
      ) : (
        <HStack alignItems={'flex-start'}>
          <AlertIcon />
          <VStack alignItems={'flex-start'}>
            <Title />
            <Description />
            <CreateAccountButton />
          </VStack>
        </HStack>
      )}

      <VStack
        w={'full'}
        backgroundColor={'gray.100'}
        alignItems={'flex-start'}
        padding={8}
      >
        <Text fontSize={'md'} fontWeight={'bold'}>
          LINE公式アカウントとは？
        </Text>
        <Text fontSize={'sm'}>
          「LINE」上で企業や店舗がアカウントをつくり、友だち追加してくれたユーザーに直接情報を届けられるサービスです。
          <br />
          {'>'}
          <Link
            textDecoration={'underline'}
            target={'_blank'}
            rel={'noopener noreferrer'}
            href={'https://www.linebiz.com/jp/service/line-official-account/'}
          >
            LINE公式アカウントについて
          </Link>
        </Text>
      </VStack>
    </VStack>
  );
};

export const Title = () => (
  <Text fontSize={'lg'} fontWeight={'bold'}>
    LINE公式アカウントをお持ちでない方
  </Text>
);
export const Description = () => (
  <Text fontSize={'md'} alignItems={'left'}>
    LINE公式アカウントをお持ちでない場合は連携前に無料で利用できるLINE公式アカウントの開設をお願いいたします
  </Text>
);
export const CreateAccountButton = () => (
  <Box h={'full'} w={{ base: 'full', md: '280px' }}>
    <Link
      href={'https://manager.line.biz'}
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      <Button
        // colorScheme={'white'}
        color={'black'}
        backgroundColor={'white'}
        w={{ base: 'full', md: '280px' }}
        height={'50px'}
        borderRadius={40}
        border={'1px solid #000'}
        fontSize={'16px'}
      >
        アカウントを作成する
        <FixedRight>
          <RightArrowBlack />
        </FixedRight>
      </Button>
    </Link>
  </Box>
);

export const StepTitle: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Text
    fontSize={{ base: 16, md: 17 }}
    fontWeight={'bold'}
    maxWidth={'15rem'}
    textAlign={'left'}
  >
    {children}
  </Text>
);
export const Number1 = () => (
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.7143" cy="10.7143" r="10.7143" fill="#07B53B" />
    <circle cx="10.7143" cy="10.7143" r="10.7143" stroke="white" />
    <circle
      cx="10.7143"
      cy="10.7143"
      r="10.7143"
      stroke="black"
      strokeOpacity="0.2"
    />
    <path
      d="M12.0514 15.5714H9.76943V9.78941C9.76943 9.63074 9.76943 9.43474 9.76943 9.20141C9.77876 8.96808 9.78343 8.72541 9.78343 8.47341C9.79276 8.22141 9.8021 7.99741 9.81143 7.80141C9.73676 7.85741 9.63876 7.96008 9.51743 8.10941C9.40543 8.25874 9.28876 8.38474 9.16743 8.48741L8.11743 9.41141L6.96943 8.13741L10.0914 5.57541H12.0514V15.5714Z"
      fill="white"
    />
  </svg>
);
export const Number2 = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.7143" cy="10.7143" r="10.7143" fill="#07B53B" />
    <circle cx="10.7143" cy="10.7143" r="10.7143" stroke="white" />
    <circle
      cx="10.7143"
      cy="10.7143"
      r="10.7143"
      stroke="black"
      strokeOpacity="0.2"
    />
    <path
      d="M14.7045 15.5714H7.53446V13.9964L10.0245 11.2814C10.5345 10.7214 10.9345 10.2564 11.2245 9.88641C11.5245 9.50641 11.7345 9.16641 11.8545 8.86641C11.9845 8.55641 12.0495 8.22641 12.0495 7.87641C12.0495 7.44641 11.9445 7.12641 11.7345 6.91641C11.5345 6.70641 11.2745 6.60141 10.9545 6.60141C10.6045 6.60141 10.2545 6.69641 9.90446 6.88641C9.56446 7.07641 9.18946 7.34641 8.77946 7.69641L7.45946 6.24141C7.75946 5.97141 8.07446 5.72141 8.40446 5.49141C8.73446 5.26141 9.12446 5.07641 9.57446 4.93641C10.0245 4.78641 10.5645 4.71141 11.1945 4.71141C11.8645 4.71141 12.4445 4.84141 12.9345 5.10141C13.4245 5.35141 13.7995 5.69641 14.0595 6.13641C14.3295 6.56641 14.4645 7.05641 14.4645 7.60641C14.4645 8.05641 14.3995 8.46641 14.2695 8.83641C14.1395 9.20641 13.9495 9.57141 13.6995 9.93141C13.4595 10.2914 13.1695 10.6664 12.8295 11.0564C12.4895 11.4364 12.1095 11.8664 11.6895 12.3464L10.6095 13.5614L10.5945 13.6664H14.7045V15.5714Z"
      fill="white"
    />
  </svg>
);
export const Number3 = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.7143" cy="10.7143" r="10.7143" fill="#07B53B" />
    <circle cx="10.7143" cy="10.7143" r="10.7143" stroke="white" />
    <circle
      cx="10.7143"
      cy="10.7143"
      r="10.7143"
      stroke="black"
      strokeOpacity="0.2"
    />
    <path
      d="M14.3445 7.26141C14.3445 7.75141 14.2445 8.17641 14.0445 8.53641C13.8445 8.89641 13.5795 9.19141 13.2495 9.42141C12.9195 9.65141 12.5495 9.82141 12.1395 9.93141V9.97641C12.9395 10.0764 13.5545 10.3364 13.9845 10.7564C14.4145 11.1764 14.6295 11.7414 14.6295 12.4514C14.6295 13.0714 14.4845 13.6314 14.1945 14.1314C13.9045 14.6214 13.4545 15.0114 12.8445 15.3014C12.2445 15.5814 11.4645 15.7214 10.5045 15.7214C9.90446 15.7214 9.36946 15.6714 8.89946 15.5714C8.42946 15.4714 7.97946 15.3264 7.54946 15.1364V13.2164C7.98946 13.4464 8.43946 13.6214 8.89946 13.7414C9.36946 13.8614 9.79946 13.9214 10.1895 13.9214C10.8995 13.9214 11.3895 13.7814 11.6595 13.5014C11.9395 13.2214 12.0795 12.8264 12.0795 12.3164C12.0795 12.0164 12.0145 11.7664 11.8845 11.5664C11.7545 11.3564 11.5245 11.2014 11.1945 11.1014C10.8745 10.9914 10.4095 10.9364 9.79946 10.9364H9.10946V9.19641H9.81446C10.3845 9.19641 10.8245 9.13641 11.1345 9.01641C11.4445 8.88641 11.6595 8.71641 11.7795 8.50641C11.9095 8.28641 11.9745 8.03641 11.9745 7.75641C11.9745 7.37641 11.8695 7.08141 11.6595 6.87141C11.4495 6.65141 11.1295 6.54141 10.6995 6.54141C10.3895 6.54141 10.0995 6.58641 9.82946 6.67641C9.55946 6.75641 9.31946 6.85641 9.10946 6.97641C8.90946 7.09641 8.74446 7.21141 8.61446 7.32141L7.56446 5.76141C7.83446 5.56141 8.12946 5.38141 8.44946 5.22141C8.77946 5.06141 9.14946 4.93641 9.55946 4.84641C9.97946 4.75641 10.4545 4.71141 10.9845 4.71141C12.0245 4.71141 12.8445 4.93641 13.4445 5.38641C14.0445 5.82641 14.3445 6.45141 14.3445 7.26141Z"
      fill="white"
    />
  </svg>
);
export const Number4 = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.7143" cy="10.7143" r="10.7143" fill="#07B53B" />
    <circle cx="10.7143" cy="10.7143" r="10.7143" stroke="white" />
    <circle
      cx="10.7143"
      cy="10.7143"
      r="10.7143"
      stroke="black"
      strokeOpacity="0.2"
    />
    <path
      d="M14.9745 13.3514H13.8195V15.5714H11.4345V13.3514H7.27946V11.7764L11.5845 4.86141H13.8195V11.5964H14.9745V13.3514ZM11.4345 9.78141C11.4345 9.61141 11.4345 9.41141 11.4345 9.18141C11.4445 8.94141 11.4545 8.70641 11.4645 8.47641C11.4745 8.23641 11.4845 8.02641 11.4945 7.84641C11.5045 7.65641 11.5095 7.52641 11.5095 7.45641H11.4495C11.3595 7.65641 11.2695 7.85141 11.1795 8.04141C11.0995 8.22141 11.0045 8.41641 10.8945 8.62641L9.37946 11.5964H11.4345V9.78141Z"
      fill="white"
    />
  </svg>
);

export const TutorialVideo = () => {
  return (
    <AspectRatio ratio={720 / 900}>
      <video
        controls
        preload={'metadata'}
        poster="/assets/pochico_tutorial_thumbnail.png"
      >
        <source src="/assets/pochico_tutorial_2.mov#t=0" type="video/mp4" />
      </video>
    </AspectRatio>
  );
};
