import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

export const LineMessagePreview: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex
      padding={'24px'}
      alignItems={'center'}
      justifyContent={'center'}
      backgroundColor={'line.talk'}
      w={'full'}
      h={'full'}
    >
      <Box
        w={'full'}
        h={'full'}
        borderRadius={'24px'}
        padding={'12px'}
        backgroundColor={'white'}
      >
        {children}
        {/* <Text
          dangerouslySetInnerHTML={{
            __html: message.replace(new RegExp('\n', 'g'), '<br />'),
          }}
        ></Text> */}
      </Box>
    </Flex>
  );
};
