import * as Sentry from '@sentry/react';

export const initializeSentry = () => {
  if (!GLOBAL_CONFIG.SENTRY) {
    return;
  }
  Sentry.init({
    dsn: GLOBAL_CONFIG.SENTRY.DSN,
    environment: GLOBAL_CONFIG.ENV,
    release: GLOBAL_CONFIG.GIT_HASH,
    // Adjust this value in production, or use tracesSampler for greater control
    // integrations: [new BrowserTracing()],
    // tracesSampleRate: 1.0,
  });
};
