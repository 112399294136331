import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { Dashboard } from '../components/features/dashboard';

// ReactAdminのDashboardComponentとして使えるようにproviderAccountを受け取らない
export const DashboardPage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  setDocumentTitle('ホーム');
  return <Dashboard providerAccount={providerAccount} />;
};
