import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AttachErrorCode, ProviderAccount, sleep } from '@pochico/shared';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { MdOutlineLightbulb } from 'react-icons/md';
import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { needPayment } from '../../commons/paymentUtil';
import { ErrorCode } from '../../components/ui/ErrorCode';
import { FixedRight } from '../../components/ui/Fixed';
import { Header } from '../../components/ui/Header';
import { LineOaLinkHelpImage } from '../../components/ui/LineOaLinkHelpImage';
import { PageTitle } from '../../components/ui/PageTitle';
import { RegistrationStepLineAttach } from '../../components/ui/RegistrationSteps';
import { RightArrowWhite } from '../../components/ui/RightArrow';
import { WebLink } from '../../components/ui/WebLink';
import { useAuthState } from '../../context/providerAccount';
import { postBotApi } from '../../dataStore/bot';
import { Path } from '../../routers/Path';
import {
  LineOfficialAccountIntro,
  Number1,
  Number2,
  Number3,
  Number4,
  StepTitle,
} from './components';

type LineMessagingApiParam = {
  channelId: number;
  channelSecret: string;
};
export const LineOALinkMessagingApi = () => {
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = React.useState<AttachErrorCode>();
  const { firebaseUser, providerAccount, initialized, refetch } =
    useAuthState();
  const toast = useToast();

  const isModuleApiConnected = React.useMemo(
    () => providerAccount?.lineChannelType !== 'messagingApi',
    [providerAccount]
  );
  const alreadyConfigured = React.useMemo(
    () =>
      providerAccount &&
      providerAccount.lineChannelType === 'messagingApi' &&
      providerAccount.status === 'attached',
    [providerAccount]
  );

  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (param: LineMessagingApiParam) => {
        if (!firebaseUser) {
          return;
        }
        return postBotApi<
          | { ok: true; providerAccount: ProviderAccount }
          | { ok: false; errorCode: AttachErrorCode }
        >('/registration', firebaseUser, {
          ...param,
        });
      },
      [firebaseUser]
    ),
    onSuccess: async (response) => {
      console.log(`response: ${JSON.stringify(response)}`);
      if (!response) {
        toast({
          title: `LINE連携に失敗しました。`,
          status: 'error',
        });
        return;
      } else {
        if (response.ok) {
          if (response.body.ok) {
            await refetch();
            if (isModuleApiConnected) {
              toast({
                title: 'LINE連携が完了しました',
                status: 'success',
              });
              await sleep(3000);
              // すでに連携済みなのでトップへ
              navigate(Path.home);
            } else {
              // LIFF IDの登録画面へ進まず/attach-completeへ。LIFF ID登録は設定画面から行う
              navigate(Path.attachComplete);
            }
          } else {
            setErrorCode(response.body.errorCode);
          }
        } else {
          setErrorCode(JSON.parse(response.error).errorCode);
          toast({
            title: `LINE連携に失敗しました。エラー: ${response.error}`,
            status: 'error',
          });
        }
      }
    },
  });

  setDocumentTitle('ポチコLINE連携');

  React.useEffect(() => {
    if (initialized && !firebaseUser) {
      toast({
        title:
          'ログインに失敗しました。セッション切れの可能性があるので、次のページから再度ログインしてください。',
        status: 'error',
      });
      navigate(Path.login);
    } else if (initialized && providerAccount) {
      if (needPayment(providerAccount)) {
        navigate(Path.unpaid);
      } else if (!mutation.isSuccess && alreadyConfigured) {
        toast({
          title: 'すでにLINE連携が完了しています',
          status: 'info',
        });
        // navigate(Path.home);
      }
    }
  }, [
    alreadyConfigured,
    firebaseUser,
    initialized,
    mutation.isSuccess,
    navigate,
    providerAccount,
    toast,
  ]);

  return (
    <Flex
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      maxWidth={'990px'}
      paddingX={4}
      paddingBottom={'48px'}
      marginX={'auto'}
    >
      {providerAccount && isModuleApiConnected ? (
        <>
          <Header />
          <PageTitle>Messaging APIでのLINE連携に切り替えます</PageTitle>
        </>
      ) : alreadyConfigured ? (
        <>
          <Header />
          <PageTitle>Messaging API連携情報の変更を行います</PageTitle>
        </>
      ) : (
        <>
          <Spacer mt={10} />
          <RegistrationStepLineAttach />
          <Spacer mt={10} />
          <PageTitle>つづいてLINE連携を行います</PageTitle>
        </>
      )}
      <Spacer mt={8} />
      <Text whiteSpace={'pre-wrap'}>
        Channel IDおよびChannel Secretを入力してください。
        {(!providerAccount ||
          (!isModuleApiConnected && !alreadyConfigured)) && (
          <>
            <br />
            LINE公式アカウントがない方は事前に開設が必要です。
          </>
        )}
      </Text>
      <Spacer mt={8} />

      {/* <Instruction /> */}

      <HStack
        borderRadius={'xl'}
        borderWidth={'1px'}
        borderColor={'gray.500'}
        bgColor={'gray.50'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={'16px'}
        paddingY={'32px'}
      >
        <Icon as={MdOutlineLightbulb} color={'yellow.500'} boxSize={'24px'} />
        <VStack alignItems={'flex-start'}>
          <Text>
            Channel IDおよびChannel Secretの取得方法については、
            <WebLink
              href="https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1"
              target="_blank"
              rel="noopener noreferrer"
              color={'black'}
            >
              こちらのページ
            </WebLink>
            をご参照ください。
          </Text>
          {alreadyConfigured && (
            <Text fontWeight={'bold'}>
              セキュリティのため、設定済みのChannel IDおよびChannel
              Secretは非表示となっています。
            </Text>
          )}
        </VStack>
      </HStack>

      <Spacer mt={12} />

      <InputForm
        providerAccount={providerAccount}
        onSubmit={async (param) => {
          await mutation.mutateAsync(param);
        }}
      />

      {errorCode && (
        <Box w={['full', '452px']} color={'red'}>
          <ErrorCode errorCode={errorCode} />
        </Box>
      )}

      {alreadyConfigured || isModuleApiConnected ? (
        <WebLink
          width={['full', '452px']}
          href={Path.configuration}
          marginTop={8}
        >
          戻る
        </WebLink>
      ) : (
        <>
          <WebLink
            width={['full', '452px']}
            href={Path.lineOaLink}
            marginTop={8}
          >
            {'LINE Market Placeを使った連携をする'}
          </WebLink>

          <Spacer mt={12} />

          <LineOfficialAccountIntro />
        </>
      )}
    </Flex>
  );
};

const InputForm: React.FC<{
  providerAccount: ProviderAccount | undefined;
  onSubmit: (param: LineMessagingApiParam) => Promise<void>;
}> = ({ providerAccount, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = useForm<LineMessagingApiParam>({
    mode: 'onChange',
  });
  const _onSubmit = React.useCallback(
    (param: LineMessagingApiParam) => {
      setSubmitting(true);
      onSubmit(param).finally(() => {
        setSubmitting(false);
      });
    },
    [onSubmit]
  );
  const [submitting, setSubmitting] = React.useState(false);
  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <VStack alignItems={'flex-start'} spacing={8}>
        <Text fontWeight={'bold'}>
          アカウント名：{providerAccount?.displayName}
        </Text>
        <FormControl isInvalid={!!errors.channelId}>
          <FormLabel>Channel ID</FormLabel>
          <Input
            maxWidth={'full'}
            {...register('channelId', {
              required: true,
              maxLength: {
                value: 300,
                message: '最大文字数は300文字です',
              },
              valueAsNumber: true,
            })}
            name="channelId"
            backgroundColor={'white'}
            type="text"
            placeholder="1234567890"
          />
          <FormErrorMessage>{errors.channelId?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.channelSecret}>
          <FormLabel>Channel Secret</FormLabel>
          <Input
            maxWidth={'full'}
            {...register('channelSecret', {
              required: true,
              maxLength: {
                value: 300,
                message: '最大文字数は300文字です',
              },
            })}
            name="channelSecret"
            backgroundColor={'white'}
            type="text"
            placeholder="43d43cbd877f5d1af56ddec5fbbfbe56"
          />
          <FormErrorMessage>{errors.channelSecret?.message}</FormErrorMessage>
        </FormControl>
        <Button
          type={'submit'}
          colorScheme={'pochico.green'}
          width={['full', '452px']}
          borderRadius={20}
          isDisabled={!isValidating && !isValid}
          isLoading={submitting}
        >
          {'LINE連携をする'}
          <FixedRight>
            <RightArrowWhite />
          </FixedRight>
        </Button>
      </VStack>
    </form>
  );
};

const Instruction = () => {
  return (
    <VStack alignItems={'center'} spacing={8}>
      <PageTitle>LINE連携の流れ</PageTitle>
      <HStack
        backgroundColor={'gray.100'}
        overflowX={'scroll'}
        w={{ base: 'fit-content', md: '80vw' }}
        padding={8}
        spacing={4}
        alignItems={'flex-start'}
      >
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number1 />
            <StepTitle>LINEアカウントにログイン</StepTitle>
          </HStack>
          <Box>
            <LineOaLinkHelpImage
              src={'/assets/line_attach_step1.png'}
              alt={'LINEアカウントにログイン'}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number2 />
            <StepTitle>
              連携したいLINE公式アカウントを選択してMessaging APIを有効化する
            </StepTitle>
          </HStack>
          <Box>
            <LineOaLinkHelpImage
              src={'/assets/line_attach_ma_step2-3.png'}
              alt={
                '連携したいLINE公式アカウントを選択してMessaging APIを有効化する'
              }
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number3 />
            <StepTitle>プロバイダーを選択する</StepTitle>
          </HStack>
          <Box>
            <LineOaLinkHelpImage
              src={'/assets/line_attach_ma_step4-5.png'}
              alt={'プロバイダーを選択する'}
            />
          </Box>
        </VStack>
        <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
          <HStack alignItems={'center'}>
            <Number4 />
            <StepTitle>Channel IDとChannel Secretを控える</StepTitle>
          </HStack>
          <Box>
            <LineOaLinkHelpImage
              src={'/assets/line_attach_ma_step6.png'}
              alt={'Channel IDとChannel Secretを控える'}
            />
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};
