import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const FixedRight: React.FC<BoxProps & { children: React.ReactNode }> = ({
  children,
  ...props
}) => {
  return (
    <Box
      position={'absolute'}
      top={'50%'}
      right={'3%'}
      transform={'translateY(-50%)'}
      {...props}
    >
      {children}
    </Box>
  );
};
