import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount, Spot } from '@pochico/shared';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import {
  useDeleteSpots,
  useFetchSpot,
  useUpdateSpot,
} from '../../../hooks/spots';
import { resourcePath } from '../../../hooks/useUrlPath';
import { useConfirmationDialog } from '../../ui/ConfirmationDialog';
import { Layout } from '../../ui/Layout';

export const SpotEdit: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const navigate = useNavigate();
  const { openConfirmationDialog } = useConfirmationDialog();
  const query = useFetchSpot({ providerAccount, id });
  const [updatedSpot, setUpdatedSpot] = React.useState<Spot>();
  const updateMutation = useUpdateSpot(providerAccount);
  const deleteMutation = useDeleteSpots(providerAccount);
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });
  const bookingMenu = React.useMemo(
    () =>
      (bookingMenuQuery.data || [])?.find(
        (menu) => menu.id === query.data?.bookingMenuId
      ),
    [bookingMenuQuery.data, query.data?.bookingMenuId]
  );
  const toast = useToast();
  const onDelete = React.useCallback(async () => {
    openConfirmationDialog({
      title: '予約枠を削除しますか？',
      body: (
        <>
          <Text>
            元に戻すことはできません。
            <br />
            また、すでに予約が取られている場合は削除できません
          </Text>
        </>
      ),
      cancelText: 'キャンセル',
      submitText: '削除する',
      onSubmit: async () => {
        return deleteMutation
          .mutateAsync([id])
          .then(() => {
            toast({
              title: `予約枠を削除しました`,
              status: 'success',
            });
            return navigate(
              resourcePath({
                providerAccount,
                resourceName: 'spot',
                action: 'list',
              })
            );
          })
          .catch((e) => {
            toast({
              title: `予約枠の削除に失敗しました error: ${e}`,
              status: 'error',
            });
          });
      },
    });
  }, [
    deleteMutation,
    id,
    navigate,
    openConfirmationDialog,
    providerAccount,
    toast,
  ]);

  const onSubmit = React.useCallback(async () => {
    if (updatedSpot) {
      return updateMutation.mutateAsync(updatedSpot).then(() => {
        navigate(
          resourcePath({
            providerAccount,
            resourceName: 'spot',
            action: 'show',
            resourceId: id,
          })
        );
      });
    }
  }, [id, navigate, providerAccount, updateMutation, updatedSpot]);

  const readyToUpdate = !!(
    !query.isLoading &&
    query.data &&
    !bookingMenuQuery.isLoading &&
    bookingMenu
  );
  const readyToDelete = !!(
    !query.isLoading &&
    query.data &&
    query.data.bookingIds.length === 0
  );

  return (
    <Layout hasBackButton pageTitle="予約枠の編集">
      {query.error && <Text>エラーが発生しました {String(query.error)}</Text>}
      {query.data && readyToUpdate ? (
        <VStack
          alignItems={'flex-start'}
          width={'full'}
          padding={0}
          spacing={'20px'}
        >
          <SpotEditForm
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
            spot={query.data}
            onChange={setUpdatedSpot}
          />
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              colorScheme={'red'}
              size={'sm'}
              onClick={onDelete}
              isLoading={!readyToUpdate || deleteMutation.isPending}
              disabled={!readyToDelete}
            >
              <Tooltip label={'予約が既に入っている場合は削除できません'}>
                削除する
              </Tooltip>
            </Button>
            <Button
              variant={'blue-fill'}
              size={'sm'}
              onClick={onSubmit}
              isLoading={!readyToUpdate || updateMutation.isPending}
              isDisabled={!readyToUpdate}
            >
              保存する
            </Button>
          </HStack>
        </VStack>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
};

const SpotEditForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  spot: Spot;
  onChange: (spot: Spot) => void;
}> = ({ providerAccount, bookingMenu, spot, onChange }) => {
  const form = useForm<Spot>({
    mode: 'onChange',
    defaultValues: spot,
  });
  React.useEffect(() => {
    const { unsubscribe } = form.watch((values, { name }) => {
      if (values && name && name in values && values[name as keyof Spot]) {
        onChange({ ...spot, [name]: values[name as keyof Spot], id: spot.id });
      }
    });
    return unsubscribe;
  }, [form, onChange, spot]);

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      borderWidth={'1px'}
      borderColor={'gray.100'}
      fontSize={'xs'}
      p={'16px'}
    >
      <FormControl isRequired>
        <FormLabel>予約日</FormLabel>

        <Input
          type={'text'}
          // placeholder={'パーソナルトレーニング'}
          value={spot.date}
          isDisabled={true}
          bg={'white'}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>開始時間</FormLabel>

        <Input
          type={'text'}
          value={spot.startTime}
          isDisabled={true}
          bg={'white'}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>予約メニュー</FormLabel>
        <Input
          type={'text'}
          // placeholder={'パーソナルトレーニング'}
          value={bookingMenu.name}
          isDisabled={true}
          bg={'white'}
        />
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors.maxBookings}>
        <FormLabel htmlFor={'maxBookings'}>予約枠数</FormLabel>
        <HStack>
          <Input
            type={'number'}
            w={'fit-content'}
            placeholder={'この時間に予約できる枠数'}
            bg={'white'}
            {...form.register('maxBookings', {
              valueAsNumber: true,
              validate: (value) => {
                if (value < 1) {
                  return '1以上の数値を入力してください';
                } else if (value < spot.bookingIds.length) {
                  return 'すでに入っている予約の数より小さい数値を入力することはできません';
                }
                return undefined;
              },
            })}
          />
          <Text fontSize={'sm'} color={'gray.500'}>
            / 現在の予約数：{spot.bookingIds.length}件
          </Text>
        </HStack>
        <FormErrorMessage>
          {form.formState.errors?.maxBookings?.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );
};
