import { Text, VStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { Path } from '../../../../routers/Path';
import { Link } from '../../../ui/Link';
import { BookingMenuDetail } from '../show';

export const DefaultBookingMenuShow: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  return (
    <VStack w={'full'}>
      <VStack
        w={'full'}
        px={'16px'}
        py={'12px'}
        bgColor={'blue.50'}
        color={'gray.700'}
        alignItems={'flex-start'}
      >
        <Text fontWeight={'bold'}>予約メニュー機能は現在OFFになっています</Text>
        <Text>
          複数の予約メニューを作成する場合は、
          <Link to={Path.configuration} textDecoration={'underline'} px={'4px'}>
            各種設定
          </Link>
          より機能をONにしてください。
        </Text>
      </VStack>
      <BookingMenuDetail
        providerAccount={providerAccount}
        bookingMenu={bookingMenu}
      />
    </VStack>
  );
  // return (
  //   <Flex
  //     direction={{ base: 'column', lg: 'row' }}
  //     alignItems={'flex-start'}
  //     // padding={{ base: '4px', lg: '24px' }}
  //     minWidth={'full'}
  //     backgroundColor={'white'}
  //   >
  //     <VStack
  //       alignItems={'flex-start'}
  //       spacing={'24px'}
  //       backgroundColor={'white'}
  //     >
  //       <HStack
  //         w={'full'}
  //         alignItems={'center'}
  //         justifyContent={'space-between'}
  //       >
  //         <Text size={'3xl'} fontWeight={'bold'}>
  //           予約できる条件の設定
  //         </Text>
  //         <NavigationButton
  //           variant={'blue-navigation'}
  //           to={{
  //             action: 'edit',
  //             resourceId: bookingMenu.id,
  //             resourceName: 'bookingMenu',
  //             providerAccount,
  //           }}
  //         >
  //           編集
  //         </NavigationButton>
  //       </HStack>
  //       <LimitDateTime bookingMenu={bookingMenu} source="bookingStart" />
  //       <LimitDateTime bookingMenu={bookingMenu} source="bookingEnd" />
  //       <LimitDateTime bookingMenu={bookingMenu} source="cancelEnd" />
  //     </VStack>
  //   </Flex>
  // );
};
