import React from 'react';
import { useAuthState } from '../context/providerAccount';

export const useInquiryUrl = () => {
  const { providerAccount, firebaseUser } = useAuthState();
  const inquiryFormUrl = React.useMemo(() => {
    if (!providerAccount) {
      // 未ログイン状態ならヘルプページを返す
      return 'https://help.pochico.app/e4c3aec031a648d3b4786ab54de9222d';
    }
    // ログイン済みならIDなどのデータを埋めた状態の問い合わせフォームURLを返す
    const email = firebaseUser?.email;
    return `https://docs.google.com/forms/d/e/1FAIpQLSeUYrhdLoGeQWYBw-vDqEcsNRKuL25LghcX12EG-rouMYOHWg/viewform?usp=pp_url&entry.101049160=${
      providerAccount?.id || ''
    }&entry.1033174478=${encodeURIComponent(email || '')}&entry.803886051=${
      email ? encodeURIComponent(email) : 'なし'
    }&entry.1652389447=${providerAccount?.basicId}`;
  }, [firebaseUser?.email, providerAccount]);

  return inquiryFormUrl;
};
