import {
  Button,
  FormControl,
  HStack,
  Input,
  Switch,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { deleteField } from 'firebase/firestore';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAuthState } from '../../../context/providerAccount';
import { useIsPC } from '../../../hooks/useIsPC';
import { updateProviderAccount } from '../../../providers/dataProvider/providerAccount';
import { Path } from '../../../routers/Path';
import { Layout } from '../../ui/Layout';

// 設定対象の項目だけを抽出
type InputProps = NonNullable<Pick<ProviderAccount, 'bookingLimitation'>>;
export const BookingConfigurationPage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { refetch } = useAuthState();
  const form = useForm<InputProps>({
    defaultValues: {
      bookingLimitation: providerAccount.bookingLimitation || {
        limit: undefined,
        period: 'all',
      },
    },
  });
  const {
    register,
    setFocus,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = form;
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (input: InputProps) => {
        return updateProviderAccount(providerAccount, {
          bookingLimitation: input.bookingLimitation
            ? {
                limit: Number(input.bookingLimitation.limit),
                period: 'all',
              }
            : deleteField(),
        });
      },
      [providerAccount]
    ),
    onSuccess: async () => {
      toast.closeAll();
      toast({
        title: '保存しました',
        status: 'success',
      });
      await refetch();
    },
    onError: (error) => {
      toast({
        title: `エラーが発生しました ${error}`,
        description: error.message,
        status: 'error',
      });
    },
  });

  const isPC = useIsPC();

  return (
    <Layout
      pageTitle={'同時に予約できる数を制限する'}
      hasBackButton={Path.configuration}
    >
      <FormProvider {...form}>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit((bookingLimitation) =>
            mutation.mutateAsync(bookingLimitation)
          )}
        >
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            maxW={'600px'}
            spacing={{ base: '12px', md: '20px' }}
            // divider={
            //   isPC ? undefined : <StackDivider borderColor={'gray.300'} />
            // }
          >
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              spacing={'16px'}
              px={{ base: 0, md: '16px' }}
              py={{ base: '32px', md: '16px' }}
              borderRadius={'4px'}
              borderColor={'gray.300'}
              borderWidth={{ base: 0, md: '1px' }}
              borderTopWidth={'1px'}
              borderTopColor={'gray.300'}
              borderBottomWidth={'1px'}
              borderBottomColor={'gray.300'}
            >
              <ConfigurationItem
                providerAccount={providerAccount}
                label={'上限を設定する'}
                propertyKey={'bookingLimitation'}
                onSwitchChange={(visible) => {
                  if (visible) {
                    setTimeout(() => setFocus('bookingLimitation.limit'));
                  }
                }}
              >
                <FormControl
                  w={'full'}
                  isRequired
                  isInvalid={!!errors.bookingLimitation?.limit?.message}
                >
                  <VStack w={'full'} alignItems={'flex-start'}>
                    <HStack
                      w={'full'}
                      alignItems={'center'}
                      whiteSpace={'nowrap'}
                    >
                      <Text>1人が同時に予約できるのは</Text>
                      <Input
                        {...register('bookingLimitation.limit', {
                          required: '必須項目です',
                        })}
                        w={'4em'}
                        type={'number'}
                        inputMode={'numeric'}
                      />
                      <Text>枠まで</Text>
                    </HStack>
                    <Text>
                      ※当日の予約時間を過ぎるとまた新たに予約できる枠が増えます。
                    </Text>
                  </VStack>
                </FormControl>
              </ConfigurationItem>
            </VStack>
            <Button
              type={'submit'}
              isLoading={mutation.isPending}
              w={{ base: 'full', md: 'fit-content' }}
              alignSelf={'flex-end'}
              variant={'blue-fill'}
              isDisabled={!isValid || !isDirty}
              size={'md'}
              h={'44px'}
            >
              保存する
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </Layout>
  );
};

const ConfigurationItem: React.FC<{
  providerAccount: ProviderAccount;
  label: string;
  propertyKey: keyof ProviderAccount;
  onSwitchChange?: (visible: boolean) => void;
  children: React.ReactNode;
}> = ({ providerAccount, label, propertyKey, onSwitchChange, children }) => {
  const { setValue } = useFormContext<ProviderAccount>();
  const [visible, setVisible] = React.useState<boolean>(() => {
    return Boolean(
      providerAccount[propertyKey] &&
        Object.keys(providerAccount[propertyKey]).length > 0
    );
  });
  const onChange = React.useCallback(() => {
    const newVisible = !visible;
    // チェックボックスがtrueの時だけ入力欄を有効化する
    if (newVisible) {
      // editの際はすでにある値を使う
      const defaultValue = providerAccount[propertyKey];
      setValue(propertyKey, defaultValue, { shouldDirty: true });
    } else {
      setValue(propertyKey, undefined, { shouldDirty: true });
    }
    setVisible(newVisible);
    onSwitchChange?.(newVisible);
  }, [onSwitchChange, propertyKey, providerAccount, setValue, visible]);

  return (
    <VStack w={'full'} spacing={'12px'}>
      <HStack width={'full'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Text fontWeight={'bold'}>{label}</Text>
        </HStack>
        <HStack spacing={2}>
          <Text>{visible ? 'ON' : 'OFF'}</Text>
          <Switch
            defaultChecked={visible}
            isChecked={visible}
            onChange={onChange}
          />
        </HStack>
      </HStack>
      {visible ? children : null}
    </VStack>
  );
};
