import { ProviderAccount, waitAtLeast } from '@pochico/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthState } from '../context/providerAccount';
import { updateProviderAccount } from '../providers/dataProvider/providerAccount';
import { bookingMenusQueryKeys } from './bookingMenus';

export const useUpdateProviderAccount = (providerAccount: ProviderAccount) => {
  const { refetch } = useAuthState();
  return useMutation({
    mutationFn: async (
      updateData: FirebaseFirestore.UpdateData<ProviderAccount>
    ) => {
      return waitAtLeast(
        updateProviderAccount(providerAccount, updateData),
        1000
      );
    },
    onSuccess: async () => {
      return refetch();
    },
  });
};

export const useSetBookingMenuEnablePerProviderAccount = (
  providerAccount: ProviderAccount
) => {
  const { refetch } = useAuthState();
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ enabled }: { enabled: boolean }) => {
      const needMenu = providerAccount.needBookingMenu === true;
      if (needMenu === enabled) {
        return;
      }
      return waitAtLeast(
        updateProviderAccount(providerAccount, {
          needBookingMenu: enabled,
        }),
        1000
      );
    },
    onSuccess: async () => {
      return Promise.all([
        refetch(),
        client.invalidateQueries({
          queryKey: bookingMenusQueryKeys._def,
        }),
      ]);
    },
  });
};
