import { Flex, IconButton } from '@chakra-ui/react';

import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccountTheme } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useIsPC } from '../../../hooks/useIsPC';
import { LineBackground } from '../../ui/LineBackground';
import { BookingMenuPreviewWithoutBackground } from '../bookingMenus/BookingMenuPreview';

export const ThemeConfigurationPreview: React.FC<{
  bookingMenu: BookingMenu;
  theme: ProviderAccountTheme;
}> = ({ bookingMenu, theme }) => {
  const isPC = useIsPC();

  return (
    <LineBackground px={'16px'} py={'32px'}>
      {bookingMenu &&
        (isPC ? (
          <HStack
            w={'full'}
            spacing={'16px'}
            alignItems={'flex-start'}
            justifyContent={'center'}
          >
            <BookingMenuThemePreview bookingMenu={bookingMenu} theme={theme} />
            <LineDatePickerPreview bookingMenu={bookingMenu} theme={theme} />
          </HStack>
        ) : (
          <Carousel
            size={400}
            cards={[
              <BookingMenuThemePreview
                bookingMenu={bookingMenu}
                theme={theme}
              />,
              <LineDatePickerPreview bookingMenu={bookingMenu} theme={theme} />,
            ]}
          />
        ))}
    </LineBackground>
  );
};

const BookingMenuThemePreview: React.FC<{
  bookingMenu: BookingMenu;
  theme: ProviderAccountTheme;
}> = ({ bookingMenu, theme }) => {
  const { backgroundColor: selectedButtonColor, textColor: selectedTextColor } =
    theme.submitButton;
  return (
    <BookingMenuPreviewWithoutBackground
      bookingMenu={bookingMenu}
      showImage={Boolean(bookingMenu.imageUrl)}
    >
      <Box w={'full'} px={'12px'} pb={'12px'} key={JSON.stringify(theme)}>
        <Button
          // key={`bookingMenuButton-${previewKey}`}
          bgColor={selectedButtonColor}
          _hover={{ bgColor: selectedButtonColor }}
          _active={{ bgColor: selectedButtonColor }}
          color={selectedTextColor}
          w={'full'}
          size={'lg'}
          fontWeight={'normal'}
        >
          予約する
        </Button>
      </Box>
    </BookingMenuPreviewWithoutBackground>
  );
};

const LineDatePickerPreview: React.FC<{
  bookingMenu: BookingMenu;
  theme: ProviderAccountTheme;
}> = ({ bookingMenu, theme }) => {
  const month = React.useMemo(() => dayjs().month() + 1, []);
  return (
    <VStack
      borderRadius={'17px'}
      bgColor={'white'}
      // maxW={'full'}
      w={{ base: 'full', md: '300px' }}
      maxW={{ base: '300px', md: '300px' }}
      p={'20px'}
      alignItems={'flex-start'}
      zIndex={3}
      spacing={'12px'}
    >
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text fontWeight={'bold'} color={theme.primaryColor} fontSize={'sm'}>
          予約日程を選ぶ
        </Text>
        <Text
          fontWeight={'bold'}
          color={theme.secondaryTextColor}
          fontSize={'sm'}
        >
          {`〈${bookingMenu.name}〉`}
        </Text>
        <Text color={theme.mainTextColor} fontSize={'xl'} fontWeight={'bold'}>
          {month}月13日〜{month}月22日
        </Text>
      </VStack>
      <Divider borderColor={'gray.200'} />
      <HStack w={'full'} gap={'4px'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          {['13日(月)', '14日(火)', '15日(水)'].map((day) => (
            <Center
              key={`day-${day}`}
              w={'full'}
              h={'48px'}
              borderRadius={'8px'}
              bgColor={theme.disabledTextColor}
              color={'white'}
            >
              <Box>{day}</Box>
            </Center>
          ))}
        </VStack>
        <VStack w={'full'} alignItems={'flex-start'}>
          {['20日(月)', '21日(火)', '22日(水)'].map((day) => (
            <Center
              key={`day-${day}`}
              w={'full'}
              h={'48px'}
              borderRadius={'8px'}
              bgColor={theme.submitButton.backgroundColor}
              color={theme.submitButton.textColor}
            >
              <Box>{day}</Box>
            </Center>
          ))}
        </VStack>
      </HStack>
    </VStack>
  );
};

const Carousel: React.FC<{ size: number; cards: React.ReactNode[] }> = ({
  size,
  cards,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const onClick = React.useCallback(
    (direction: 'next' | 'prev') => {
      ref.current?.scrollTo({
        left: ref.current.scrollLeft + (direction === 'next' ? size : -size),
        behavior: 'smooth',
      });
    },
    [size]
  );
  const pageCount = cards.length;
  const onScrollChange = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const scrollLeft = e.currentTarget.scrollLeft;
      setPage(Math.max(1, Math.ceil(scrollLeft / (size / pageCount))));
    },
    [pageCount, size]
  );
  const [page, setPage] = React.useState(1);

  return (
    <HStack
      py={'16px'}
      w={'full'}
      alignItems={'center'}
      spacing={0}
      position={'relative'}
    >
      <Box
        bgColor={'black'}
        color={'white'}
        zIndex={1}
        fontSize={'sm'}
        position={'absolute'}
        px={'8px'}
        py={'4px'}
        borderRadius={'6px'}
        top={'-16px'}
        left={'0'}
      >
        {page} / {pageCount}
      </Box>
      <IconButton
        aria-label="left-arrow"
        color={'white'}
        visibility={page === 1 ? 'hidden' : 'visible'}
        bgColor={'transparent'}
        _hover={{ bgColor: 'transparent' }}
        _active={{ bgColor: 'transparent' }}
        icon={<MdArrowBackIos />}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => onClick('prev')}
      />
      <HStack
        w={size}
        onScroll={onScrollChange}
        spacing={'12px'}
        alignItems={'flex-start'}
        overflowX={'scroll'}
        position={'relative'}
        ref={ref}
        sx={{
          scrollSnapType: 'x mandatory',
        }}
      >
        {cards.map((card, i) => {
          return (
            <Flex
              flex={'0 0 100%'}
              justifyContent={'center'}
              alignItems={'center'}
              key={`card-${i}`}
              sx={{
                scrollSnapAlign: 'start',
                scrollSnapStop: 'always',
              }}
            >
              {card}
            </Flex>
          );
        })}
      </HStack>
      <IconButton
        aria-label="right-arrow"
        color={'white'}
        bgColor={'transparent'}
        visibility={page === pageCount ? 'hidden' : 'visible'}
        _hover={{ bgColor: 'transparent' }}
        _active={{ bgColor: 'transparent' }}
        icon={<MdArrowForwardIos />}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => onClick('next')}
      />
    </HStack>
  );
};
