import { Stripe } from '@stripe/stripe-js';
import StripeModule from 'stripe';

import { Contract } from '@pochico/shared';
import { postBotApi } from '../../../dataStore/bot';
import { FirebaseUser } from '../../../firebase/firebaseInit';

export const PRICE_ID = GLOBAL_CONFIG.STRIPE.PRICE_ID.LIGHT_PLAN;

// StripeのPaymentMethodと区別するためにprefixつけた
export type PochicoPaymentMethod = { invoiceEmail: string } & (
  | { type: 'card'; paymentMethodId: string }
  | { type: 'invoice'; name: string }
);

// botと共通で揃える型
export type CreateSubscriptionRequest = {
  firebaseUid: string;
  providerAccountId: string;
  paymentMethod: PochicoPaymentMethod;
  contract: Contract;
};

export const createSubscription = async (
  stripe: Stripe,
  providerAccountId: string,
  firebaseUser: FirebaseUser,
  paymentMethod: PochicoPaymentMethod,
  contract: Contract
) => {
  const request: CreateSubscriptionRequest = {
    firebaseUid: firebaseUser.uid,
    providerAccountId,
    paymentMethod,
    contract,
  };
  return (
    postBotApi<
      | {
          subscription: StripeModule.Response<StripeModule.Subscription>;
        }
      | {
          subscriptionSchedule: StripeModule.Response<StripeModule.SubscriptionSchedule>;
        }
    >('/payment/create-subscription', firebaseUser, request)
      .then(async (response) => {
        if (response.ok) {
          return response.body;
        } else {
          throw response.error;
        }
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then(async (result) => {
        if ('subscription' in result) {
          const { subscription } = result;
          console.log(JSON.stringify(subscription));
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          await handlePaymentThatRequiresCustomerAction(
            stripe,
            subscription,
            undefined,
            paymentMethod,
            undefined
          );
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          handleRequiresPaymentMethod(subscription);

          return {
            subscription,
            invoice: undefined,
            contract,
            paymentMethod,
            isRetry: undefined,
          };
        } else {
          return {
            subscriptionSchedule: result.subscriptionSchedule,
          };
        }
      })
  );
};

const handleRequiresPaymentMethod = (
  subscription: StripeModule.Response<StripeModule.Subscription>
): void => {
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return;
  } else if (
    typeof subscription.latest_invoice !== 'string' &&
    typeof subscription.latest_invoice?.payment_intent !== 'string' &&
    subscription.latest_invoice?.payment_intent?.status ===
      'requires_payment_method'
  ) {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    localStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status
    );
    throw { error: { message: 'Your card was declined.' } };
  } else {
    return;
  }
};

const handlePaymentThatRequiresCustomerAction = async (
  stripe: Stripe,
  subscription: StripeModule.Response<StripeModule.Subscription> | undefined,
  invoice: StripeModule.Response<StripeModule.Invoice> | undefined,
  paymentMethod: PochicoPaymentMethod,
  isRetry?: boolean
): Promise<void> => {
  if (subscription && subscription.status === 'active') {
    // Subscription is active, no customer actions required.
    return;
  }

  const paymentIntent: StripeModule.PaymentIntent | undefined =
    createPaymentIntent(subscription, invoice);

  if (!paymentIntent) {
    console.info('No PaymentIntent, so finish.');
    return;
  }

  const clientSecret = paymentIntent.client_secret;
  const status = paymentIntent.status;

  if (!clientSecret) {
    console.log('[handlePaymentThatRequiresCustomerAction] no clientSecret.');
    return;
  }

  if (
    status === 'requires_action' ||
    (isRetry === true && status === 'requires_payment_method')
  ) {
    if (paymentMethod.type === 'invoice') {
      throw new Error(
        `unknown status error. paymentType is invoice but customer action required. subscription: ${JSON.stringify(
          { subscription, paymentIntent }
        )}`
      );
    }
    // 3D Secure対応っぽい
    console.log(
      '[handlePaymentThatRequiresCustomerAction] status === requires_action or (isRetry === true && status === requires_payment_method'
    );
    return stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          throw result;
        } else {
          if (result.paymentIntent?.status === 'succeeded') {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return;
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  } else {
    // No customer action needed.
    return;
  }
};

export const retryInvoiceWithNewPaymentMethod = (
  stripe: Stripe,
  params: {
    providerAccountId: string;
    firebaseUid: string;
    invoiceEmail: string;
    paymentMethodId: string;
    invoiceId: string;
    priceId: string;
  }
) => {
  const {
    providerAccountId,
    firebaseUid,
    invoiceEmail,
    paymentMethodId,
    invoiceId,
    priceId,
  } = params;
  return (
    fetch(GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN + '/payment/retry-invoice', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        firebaseUid: firebaseUid,
        providerAccountId: providerAccountId,
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result as StripeModule.Response<StripeModule.Invoice>;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then(async (invoice) => {
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        await handlePaymentThatRequiresCustomerAction(
          stripe,
          undefined,
          invoice,
          { type: 'card', paymentMethodId, invoiceEmail },
          true
        );

        return {
          priceId: priceId,
          subscription: undefined,
          invoice: invoice,
          paymentMethodId: paymentMethodId,
        };
      })
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        throw error;
      })
  );
};

const onSubscriptionComplete = (result: {
  subscription?: StripeModule.Response<StripeModule.Subscription>;
}) => {
  // Payment was successful.
  window.location.href = '/payment/registration/complete';
  if (result.subscription?.status === 'active') {
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.
  }
};

const createPaymentIntent = (
  subscription: StripeModule.Response<StripeModule.Subscription> | undefined,
  invoice: StripeModule.Response<StripeModule.Invoice> | undefined
): StripeModule.PaymentIntent | undefined => {
  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  const paymentIntent: string | StripeModule.PaymentIntent | undefined =
    (() => {
      if (invoice) {
        return invoice.payment_intent ?? undefined;
      }
      if (
        subscription?.latest_invoice &&
        typeof subscription?.latest_invoice !== 'string' &&
        'payment_intent' in subscription.latest_invoice
      ) {
        return subscription.latest_invoice.payment_intent ?? undefined;
      }
      return;
    })();

  if (!paymentIntent || typeof paymentIntent === 'string') {
    console.warn('No paymentIntent.');
    return undefined;
  }

  return paymentIntent;
};
