import { getRandomString, getRandomStrings, Spot } from '@pochico/shared';
import { doc, setDoc, writeBatch } from 'firebase/firestore';

import {
  BulkAddSpotsInput,
  convertBulkAddSpotsToSpots,
} from '../../components/features/bulkAddSpots/types';
import { db } from '../../firebase/firebaseInit';
import { getFullSpotsRef } from './spot';
import { getBulkSpotAddHistoryRef } from './spotBulkAddHistory';

export const createBulkSpots = async (
  providerAccountId: string,
  botId: string,
  params: BulkAddSpotsInput
): Promise<{ data: { id: string; count: number } }> => {
  const now = new Date();
  const bulkSpotAddHistoryId = getRandomString(20);
  const spots: Spot[] = (() => {
    const spotLikes = convertBulkAddSpotsToSpots(params);
    const ids = getRandomStrings(20, spotLikes.length);
    return spotLikes.map<Spot>((spotLike) => {
      const id = ids.shift();
      if (!id) {
        throw Error('id is undefined');
      }
      return {
        ...spotLike,
        id,
        bulkSpotAddHistoryId,
        botId,
        status: 'active',
        bookingIds: [],
        bookingMenuId: params.bookingMenuId,
        createTime: now,
        updateTime: now,
      };
    });
  })();
  if (spots.length === 0) {
    throw new Error('作成可能な予約日時がありません');
  }
  const spotRef = getFullSpotsRef(providerAccountId);

  console.time(`saving ${spots.length} spots`);
  console.log({ spots });

  // firebaseは一度に500レコードまで
  const spotsList = spots.chunk(500);
  const batchList = spotsList.map((spots) => {
    const batch = writeBatch(db);
    spots.forEach((spot: Spot) => {
      batch.set(doc(spotRef, spot.id), spot);
    });
    return batch.commit();
  });
  const storeOperation = setDoc(
    doc(getBulkSpotAddHistoryRef(providerAccountId), bulkSpotAddHistoryId),
    {
      id: bulkSpotAddHistoryId,
      providerAccountId,
      createTime: now,
      count: spots.length,
      ...params,
    }
  ).then(() => {
    // 型合わせ
    return;
  });

  return Promise.all(batchList.concat([storeOperation]))
    .then(() => {
      return {
        data: {
          id: bulkSpotAddHistoryId,
          count: spots.length,
        },
      };
    })
    .catch((error) => {
      throw new Error(`予約枠の一括登録に失敗しました。${error}`);
    })
    .finally(() => {
      console.timeEnd(`saving ${spots.length} spots`);
    });
};
