import { Box, HStack, Icon, Spacer, Text, VStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { MdCheckCircle } from 'react-icons/md';
import CONSTANTS from '../../../../commons/constants';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { Path } from '../../../../routers/Path';
import { Layout } from '../../../ui/Layout';
import { Link } from '../../../ui/Link';
import { NavigationButton } from '../../../ui/NavigationButton';
import { PageTitle } from '../../../ui/PageTitle';
import { BookingMenuDraftListView } from './BookingMenuDraftListView';
import { DefaultBookingMenuShow } from './DefaultBookingMenuShow';
import { PublishedBookingMenusViewPC } from './PublishedBookingMenusViewPC';

export const BookingMenuListPC: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const publishedBookingMenus = React.useMemo(
    () => bookingMenus.filter((menu) => menu.status === 'active'),
    [bookingMenus]
  );
  const draftBookingMenus = React.useMemo(
    () => bookingMenus.filter((menu) => menu.status === 'suspended'),
    [bookingMenus]
  );

  if (!providerAccount.needBookingMenu) {
    const defaultMenu = bookingMenus.find((menu) => menu.isDefault);
    if (defaultMenu) {
      // デフォルトメニューの詳細のみを表示する
      return (
        <Box
          width={{ base: '342px', lg: '891px' }}
          // alignSelf={'normal'}
          justifyContent={'center'}
          padding={{ base: '4px', lg: '24px' }}
          backgroundColor={'white'}
          marginBottom={'24px'}
        >
          <HStack
            w={'full'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <PageTitle>予約メニューの設定</PageTitle>
          </HStack>
          <VStack
            spacing={{ base: '24px', lg: '48px' }}
            alignItems={'flex-start'}
          >
            {/* <NeedBookingMenuSwitcher providerAccount={providerAccount} /> */}
            <DefaultBookingMenuShow
              providerAccount={providerAccount}
              bookingMenu={defaultMenu}
            />
          </VStack>
        </Box>
      );
    }
  }

  return (
    <Layout
      pageTitle={
        <HStack w={'full'} justifyContent={'space-between'}>
          <PageTitle>予約メニュー</PageTitle>
          <Text fontSize={'sm'} color={'gray.500'}>
            予約メニュー機能をオフにしたい場合は
            <Link
              to={Path.configuration}
              textDecoration={'underline'}
              px={'4px'}
            >
              各種設定
            </Link>
            へ
          </Text>
        </HStack>
      }
      headerAction={null}
    >
      <VStack
        // marginX={'auto'}
        alignItems={'flex-start'}
      >
        {/* <NeedBookingMenuSwitcher providerAccount={providerAccount} /> */}
        <VStack spacing={'4px'} alignItems={'flex-start'}>
          <HStack>
            <Icon as={MdCheckCircle} color={'green.500'} boxSize={'24px'} />
            <HStack>
              <Text fontSize={'xl'} fontWeight={'bold'}>
                公開中 {publishedBookingMenus.length}件
              </Text>
              <Text fontSize={'xs'}>
                / 最大{CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS}件
              </Text>
            </HStack>
            {/* <HStack fontSize={'sm'} spacing={'2px'} color={'gray.500'}>
            {HamburgerIcon}
            <Text>をつかんで並び替えることができます</Text>
          </HStack> */}
          </HStack>
          <Text fontSize={'sm'} color={'gray.500'}>
            公開中の予約メニューは表示順を入れ替えることができます。入れ替えたい予約メニューをマウスでクリックを押したまま、離さずに入れ替えたい場所にずらしてください。（タブレット端末の場合は指で押したまま指をスライドさせてください）
          </Text>
        </VStack>
        <PublishedBookingMenusViewPC
          providerAccount={providerAccount}
          bookingMenus={publishedBookingMenus}
        />
        <Spacer pt={'24px'} />
        <VStack alignItems={'flex-start'}>
          <VStack w={'full'} alignItems={'flex-start'} spacing={'4px'}>
            <HStack>
              <Text fontSize={'xl'} fontWeight={'bold'}>
                下書き中 {draftBookingMenus.length}件
              </Text>
              <Text fontSize={'xs'}>
                / 最大{CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}件
              </Text>
            </HStack>
            <Text fontSize={'sm'} color={'gray.500'}>
              下書き中の予約メニューは{CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}
              件まで作成可能です
            </Text>
          </VStack>
          <NavigationButton
            variant={'blue-fill'}
            isDisabled={
              draftBookingMenus.length >=
              CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS
            }
            to={{
              resourceName: 'bookingMenu',
              action: 'create',
              providerAccount,
            }}
          >
            予約メニューを作成する
          </NavigationButton>
          <BookingMenuDraftListView
            providerAccount={providerAccount}
            bookingMenus={draftBookingMenus}
          />
        </VStack>
      </VStack>
    </Layout>
  );
};
