import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { Link } from './Link';

export const LogoutButton = () => {
  return (
    <Button
      to={'/logout'}
      as={Link}
      w={'full'}
      variant={'transparent-clickable'}
    >
      <HStack w={'full'} justifyContent={'flex-start'}>
        <MdOutlineLogout />
        <Text>ログアウト</Text>
      </HStack>
    </Button>
  );
};
