import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { initializeApp, type FirebaseApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, type User } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
export type FirebaseUser = User;

const app: FirebaseApp = initializeApp(
  GLOBAL_CONFIG.FIREBASE_CONFIG,
  GLOBAL_CONFIG.FIREBASE_CONFIG.projectId
);

console.info(
  '■firebase project (Client): ' + GLOBAL_CONFIG.FIREBASE_CONFIG.projectId
);

const _db = getFirestore(app);

// undefinedのときにエラーを吐かない設定。型を完璧にできているのなら、nullを入れずにやれるのでめちゃくちゃ良いが、ゆるく使ってるなら、意図しないundefinedが通ってしまうのでやめる
// _db.settings({ ignoreUndefinedProperties: true })

export const db = _db;
export const myAuth = getAuth(app);
export const myStorage = getStorage(app);

export const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

// const firebaseAuthOption: RAFirebaseOptions = {
//   app, // 二重に初期化しないように渡さないとgoogleのapi.jsでエラーが出る
// };
// export const firebaseAuthProvider = FirebaseAuthProvider(
//   GLOBAL_CONFIG.FIREBASE_CONFIG,
//   firebaseAuthOption
// );

// let _analyticsProviderAccountId: string | undefined;
// export const setAnalyticsProviderAccountId = (providerAccountId: string) => {
//   if (_analyticsProviderAccountId || !providerAccountId) {
//     return;
//   } else {
//     _analyticsProviderAccountId = providerAccountId;
//     analytics.setUserProperties({
//       providerAccountId,
//     });
//   }
// };

// ローカルで実行中の場合は、エミュレータを使う
if (GLOBAL_CONFIG.USE_EMULATOR) {
  console.log(`*** Use Firebase emulator ***`);
  connectFirestoreEmulator(db, 'localhost', 8888);
  connectAuthEmulator(myAuth, 'http://localhost:8887');
  connectStorageEmulator(myStorage, 'localhost', 9199);
}
