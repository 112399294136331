import {
  Button,
  HStack,
  Icon,
  TextProps,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { MdOutlineHelp } from 'react-icons/md';

export const HintTooltip: React.FC<
  {
    text: React.ReactNode;
    children?: React.ReactNode;
  } & TextProps
> = ({ text, children, ...rest }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  return (
    <Tooltip alignItems={'center'} label={text} isOpen={isOpen}>
      {/* <Box width={'24px'} height={'24px'}> */}
      <Button
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
        borderRadius={'full'}
        backgroundColor={'transparent'}
        _hover={{
          base: {
            bg: 'gray.100',
            textDecoration: 'underline',
          },
          md: {
            bg: 'gray.50',
          },
        }}
      >
        <HStack gap={0} spacing={'4px'} {...rest}>
          <Icon width={'20px'} height={'20px'} as={MdOutlineHelp} />
          {children}
        </HStack>
      </Button>
      {/* </Box> */}
    </Tooltip>
  );
};
