import React from 'react';
export const StripeLinkButton = () => {
  return (
    <svg
      aria-hidden="true"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: 'inline',
      }}
      // class="⚙   asv asw asx asy asz as10 as11 ⚙15g0rl8"
    >
      <path
        d="M2 4v10h10v-3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2zm5.707 5.707a1 1 0 1 1-1.414-1.414l6.3-6.298H9.017a.998.998 0 1 1 0-1.995h5.986A.995.995 0 0 1 16 .998v5.986a.998.998 0 1 1-1.995 0V3.406z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};
