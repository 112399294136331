import { LineUser, ProviderAccount } from '@pochico/shared';
import React from 'react';

import { NavigationButton } from '../../ui/NavigationButton';

export const LineUserChip: React.FC<{
  providerAccount: ProviderAccount;
  lineUser: LineUser;
}> = ({ providerAccount, lineUser }) => {
  return (
    <NavigationButton
      borderRadius={'full'}
      to={{
        action: 'show',
        resourceName: 'lineUser',
        resourceId: lineUser.id,
        providerAccount,
      }}
    >
      {lineUser.displayNameByProvider || lineUser.displayName}
    </NavigationButton>
  );
};
