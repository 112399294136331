import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useConfirmationDialog } from '../components/ui/ConfirmationDialog';

export const GoogleCalendarConfigurationButton: React.FC<{
  providerAccount: ProviderAccount;
  // onClick: (isCurrentChecked: boolean) => Promise<void>;
}> = ({ providerAccount }) => {
  const { openConfirmationDialog } = useConfirmationDialog();

  const onClick = React.useCallback(
    async (_: React.MouseEvent<HTMLButtonElement>) => {
      const alreadyConnected = providerAccount.calendar?.id !== undefined;
      const to = `${GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN}/auth/google/gcal?account=${providerAccount.id}`;
      if (!alreadyConnected) {
        window.open(to, '_blank', 'noopenere,noreferrer');
        return;
      }
      openConfirmationDialog({
        title: `Googleカレンダー連携`,
        size: '3xl',
        submitButtonDisabledDurationSec: 3,
        body: (
          <VStack w={'full'} alignItems={'flex-start'}>
            {providerAccount.calendar?.email && (
              <VStack w={'full'} alignItems={'flex-start'}>
                <Box fontWeight={'bold'}>
                  【現在連携されているGoogleアカウント名】
                </Box>
                <Box>{providerAccount.calendar.email}</Box>
              </VStack>
            )}
            <VStack
              w={'full'}
              alignItems={'flex-start'}
              bgColor={'gray.50'}
              p={'20px'}
              borderRadius={'8px'}
            >
              <Box color={'red.500'} fontWeight={'bold'}>
                ⚠再連携する前によくご確認ください✋️
              </Box>
              <Box>
                ・再連携すると、現在連携されているカレンダーには予約が反映されなくなります。
                <br />
                ・すでに取得された予約は移行されません（現在連携されているカレンダーに残ります）。
                <br />
              </Box>
            </VStack>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              上記をご理解の上で、Googleカレンダーを再連携しますか？
            </Text>
          </VStack>
        ),
        cancelText: 'キャンセル',
        submitText: `再連携する`,
        onSubmit: async () => {
          window.open(to, '_blank', 'noopenere,noreferrer');
        },
      });
    },
    [
      openConfirmationDialog,
      providerAccount.calendar?.email,
      providerAccount.calendar?.id,
      providerAccount.id,
    ]
  );

  return (
    <Button onClick={onClick} variant={'white-blue'}>
      {providerAccount.calendar ? '再連携する' : '連携する'}
    </Button>
  );
};
