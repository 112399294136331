import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import {
  BookingMenuCreateParams,
  BookingMenuUpdateParams,
} from '../../../firebase/types';
import { useFetchBookingForms } from '../../../hooks/bookingForms';
import { useIsPC } from '../../../hooks/useIsPC';
import { LimitDateTimeInput } from '../../ui/LimitDateTimeInput';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { WebLink } from '../../ui/WebLink';
import { useImageInputForm } from '../../ui/useImageInputForm';
import { BookingMenuPreview } from './BookingMenuPreview';
import { BookingSuccessPreview } from './BookingSuccess';
import { BookingSuccessMessageInput } from './BookingSuccessMessageInput';

export type BookingMenuEditFormInputs =
  | BookingMenuCreateParams
  | (BookingMenuUpdateParams & { useImage: boolean });

export const BookingMenuEditForm: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu | undefined;
  onSubmit: (inputs: BookingMenuEditFormInputs) => Promise<void>;
}> = ({ providerAccount, bookingMenu, onSubmit }) => {
  const form = useFormContext<BookingMenuEditFormInputs>();

  // サイズに対するバリデーションは一旦なし
  // https://developers.line.biz/flex-simulator/
  const { component: ImageForm, file: imageFile } = useImageInputForm({
    form,
    formLabel: '画像(横:縦 = 2:1)',
    imageUrl: bookingMenu?.imageUrl,
    defaultImagePath: '/assets/booking-menu-default-image.png',
    onChange: React.useCallback(
      (file: File | undefined) => {
        form.setValue(
          'imageData',
          file
            ? {
                rawFile: file,
                title: file?.name,
              }
            : undefined,
          { shouldDirty: true }
        );
      },
      [form]
    ),
    // validator: async (f) => {
    //   return undefined;
    // },
  });
  const { control, trigger } = form;

  const [
    inputName,
    inputDescription,
    inputBookingSuccessMessage,
    useImage,
    bookingSuccessEnabled,
  ] = useWatch({
    control,
    name: [
      'name',
      'description',
      'customText.bookingSuccess',
      'useImage',
      'bookingSuccessEnabled',
    ],
  });
  const inputImageUrl = React.useMemo(() => {
    return imageFile ? URL.createObjectURL(imageFile) : bookingMenu?.imageUrl;
  }, [bookingMenu?.imageUrl, imageFile]);

  const navigate = useNavigate();
  const isPC = useIsPC();
  const showNotification = React.useMemo(() => {
    return new Date() < new Date('2024-11-01 00:00:00');
  }, []);
  const mutation = useMutation({
    mutationFn: onSubmit,
  });
  React.useEffect(() => {
    if (bookingMenu) {
      trigger();
    }
  }, [bookingMenu, trigger]);

  return (
    <form
      onSubmit={form.handleSubmit((input) => {
        return mutation.mutateAsync({
          ...input,
          ...(useImage && imageFile
            ? {
                imageData: {
                  rawFile: imageFile,
                  title: imageFile?.name,
                },
              }
            : {}),
          shouldNoImage: !useImage,
        });
      })}
      id={'booking-menu-edit-form'}
      style={{
        width: '100%',
      }}
    >
      <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
        {showNotification && (
          <Box
            bgColor={'pink.50'}
            w={'full'}
            px={'16px'}
            fontSize={'sm'}
            py={'12px'}
            color={'gray.700'}
          >
            【お知らせ】予約メニューにフォーム機能が追加されました！また、機能が増えたことで、今まで利用していた機能はタブで分けられています。
          </Box>
        )}
        <BookingFormEditTabs
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
          ImageForm={ImageForm}
          imageUrl={inputImageUrl}
        />
      </VStack>
    </form>
  );
};

const BookingMenuBasicInformationEdit: React.FC<{
  providerAccount: ProviderAccount;
  ImageForm: React.FC;
}> = ({ providerAccount, ImageForm }) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BookingMenuEditFormInputs>();
  const useImage = useWatch({ control, name: 'useImage' });

  return (
    <VStack w={'full'} alignItems={'flex-start'} p={'16px'} spacing={'16px'}>
      <FormControl isRequired={true} isInvalid={!!errors.name}>
        <FormLabel fontWeight={'bold'}>メニュー名</FormLabel>
        <Input
          maxWidth={'full'}
          type={'text'}
          placeholder={'パーソナルトレーニング'}
          {...register('name', {
            required: true,
            maxLength: {
              value: 200,
              message: '200文字以内で入力してください。',
            },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={false} isInvalid={!!errors.description}>
        <FormLabel fontWeight={'bold'}>説明文</FormLabel>
        <Textarea
          maxWidth={'full'}
          placeholder={
            '専属トレーナーがお客様に最適なトレーニングプログラムを作成します。'
          }
          {...register('description', {
            required: false,
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <VStack w={'full'} alignItems={'flex-start'}>
        <HStack
          w={'full'}
          justifyContent={{
            base: 'space-between',
            md: 'space-between',
          }}
          alignItems={'center'}
          spacing={{ base: undefined, md: '36px' }}
        >
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              画像エリアの表示
            </Text>
            <Text fontSize={'sm'}>メニューエリア内に画像を表示します。</Text>
          </VStack>
          <Switch
            isChecked={useImage}
            onChange={() =>
              setValue('useImage', !useImage, { shouldDirty: true })
            }
          />
        </HStack>
        {useImage && (
          <Box maxW={'full'}>
            <ImageForm />
          </Box>
        )}
        {/* <Icon as={HighlightOff} color={'red'} /> */}
      </VStack>
    </VStack>
  );
};

const BookingFormEditTabs: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu | undefined;
  ImageForm: React.FC;
  imageUrl: string | undefined;
}> = ({ providerAccount, bookingMenu, ImageForm, imageUrl }) => {
  const { control } = useFormContext<BookingMenuEditFormInputs>();
  const [
    inputName,
    inputDescription,
    inputBookingSuccessMessage,
    useImage,
    bookingSuccessEnabled,
  ] = useWatch({
    control,
    name: [
      'name',
      'description',
      'customText.bookingSuccess',
      'useImage',
      'bookingSuccessEnabled',
    ],
  });
  return (
    <Tabs w={'full'} defaultIndex={0} borderColor={'gray.200'}>
      <TabList w={'full'} justifyContent={'flex-start'}>
        {providerAccount.needBookingMenu && (
          <Tab fontSize={'lg'} fontWeight={'bold'}>
            基本
          </Tab>
        )}
        <Tab fontSize={'lg'} fontWeight={'bold'}>
          受付期間
        </Tab>
        <Tab fontSize={'lg'} fontWeight={'bold'}>
          フォーム・その他
        </Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />

      <TabPanels w={'full'} pt={'16px'}>
        {providerAccount.needBookingMenu && (
          <TabPanel w={'full'} justifyContent={'center'} p={0}>
            <Stack
              w={'full'}
              direction={{ base: 'column', md: 'row' }}
              borderWidth={'1px'}
              borderColor={'gray.200'}
              divider={<StackDivider borderColor="gray.200" />}
              spacing={0}
            >
              <Box w={'full'} maxW={{ md: '600px' }}>
                <BookingMenuBasicInformationEdit
                  providerAccount={providerAccount}
                  ImageForm={ImageForm}
                />
              </Box>
              <VStack
                // flexGrow={1}
                p={'20px'}
                alignItems={'flex-start'}
                spacing={'24px'}
                width={'full'}
                maxW={{ base: 'full', md: '600px' }}
                backgroundColor={'white'}
              >
                <Text fontSize={'lg'} fontWeight={'bold'}>
                  プレビュー
                </Text>
                <PreviewContainer label={'予約メニュー'}>
                  <BookingMenuPreview
                    bookingMenu={{
                      ...bookingMenu,
                      name: inputName || bookingMenu?.name || '',
                      description:
                        inputDescription || bookingMenu?.description || '',
                      imageUrl: useImage
                        ? imageUrl || bookingMenu?.imageUrl
                        : undefined,
                    }}
                    showImage={useImage}
                  />
                </PreviewContainer>
              </VStack>
            </Stack>
          </TabPanel>
        )}
        <TabPanel w={'full'} justifyContent={'center'} p={0}>
          <Box
            w={'full'}
            maxW={{ md: '600px' }}
            borderWidth={'1px'}
            borderColor={'gray.200'}
          >
            <BookingMenuConfigurationEdit bookingMenu={bookingMenu} />
          </Box>
        </TabPanel>
        <TabPanel w={'full'} justifyContent={'center'} p={0}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            w={bookingSuccessEnabled ? 'full' : { base: 'full', md: '600px' }}
            spacing={0}
            alignItems={'flex-start'}
            borderWidth={'1px'}
            borderColor={'gray.200'}
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Box w={'full'} maxW={{ base: 'full', md: '600px' }}>
              <BookingMenuFormAndOthersEdit
                providerAccount={providerAccount}
                bookingMenu={bookingMenu}
              />
            </Box>
            {bookingSuccessEnabled && (
              <VStack
                flexGrow={1}
                w={'full'}
                p={'20px'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                spacing={'24px'}
                width={'full'}
                maxW={{ base: 'full', md: '600px' }}
                backgroundColor={'white'}
              >
                <Text fontSize={'lg'} fontWeight={'bold'}>
                  プレビュー
                </Text>
                <BookingSuccessPreview
                  providerAccount={providerAccount}
                  bookingSuccessMessage={inputBookingSuccessMessage}
                />
              </VStack>
            )}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const BookingMenuConfigurationEdit: React.FC<{
  bookingMenu: BookingMenu | undefined;
}> = ({ bookingMenu }) => {
  return (
    <VStack
      w={'full'}
      p={0}
      divider={<StackDivider borderColor="gray.200" />}
      spacing={'16px'}
      py={'16px'}
    >
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="bookingStart" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="bookingEnd" />
      </Box>
      <Box p={'16px'} w={'full'}>
        <LimitDateTimeInput bookingMenu={bookingMenu} source="cancelEnd" />
      </Box>
    </VStack>
  );
};

const BookingMenuFormAndOthersEdit: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu | undefined;
}> = ({ providerAccount, bookingMenu }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<BookingMenuEditFormInputs>();
  const [bookingSuccessEnabled, bookingFormEnabled] = useWatch({
    control,
    name: ['bookingSuccessEnabled', 'bookingForm.enabled'],
  });
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      divider={<StackDivider borderColor={'gray.200'} />}
      spacing={'16px'}
      py={'16px'}
    >
      <BookingFormForBookingMenuEdit
        providerAccount={providerAccount}
        bookingMenu={bookingMenu}
      />
      <VStack w={'full'} alignItems={'flex-start'} p={'16px'}>
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text fontWeight={'bold'}>予約完了メッセージを送付する</Text>
          <FormControl
            isDisabled={!bookingFormEnabled}
            isInvalid={false}
            w={'fit-content'}
          >
            <HStack spacing={2} alignItems={'center'}>
              <Text>{bookingSuccessEnabled ? 'ON' : 'OFF'}</Text>
              <Switch
                key={`bookingSuccessEnabled-${bookingSuccessEnabled}`}
                {...register('bookingSuccessEnabled')}
                // defaultChecked={bookingSuccessEnabled}
              />
            </HStack>
          </FormControl>
        </HStack>
        {bookingFormEnabled ? (
          <Box color={'gray.800'}>
            質問フォームを利用する場合は、予約完了メッセージを送信すると
            <Box color={'red.500'} fontWeight={'bold'} display={'inline'}>
              LINE公式アカウントのメッセージ通数を消費します。
            </Box>
            詳しくは{' '}
            <WebLink
              href={'https://help.pochico.app/11aeddbd00d780329e6dc4e096a4f4f1'}
              target={'_blank'}
            >
              こちら
            </WebLink>
          </Box>
        ) : (
          <Box color={'gray.800'}>
            質問フォームを利用しない場合、予約完了メッセージは必ず送付されます。
            LINEのメッセージ通数は消費しません。
          </Box>
        )}
      </VStack>
      <Box p={'16px'} w={'full'}>
        <BookingSuccessMessageInput providerAccount={providerAccount} />
      </Box>
    </VStack>
  );
};

const BookingFormForBookingMenuEdit: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu | undefined;
}> = ({ providerAccount, bookingMenu }) => {
  const {
    setValue,
    register,
    control,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext<BookingMenuEditFormInputs>();
  const bookingFormInput = useWatch({
    control,
    name: 'bookingForm',
  });
  const enabled = bookingFormInput?.enabled === true;
  const query = useFetchBookingForms({ providerAccount, enabled: true });

  return (
    <VStack w={'full'} alignItems={'flex-start'} p={'16px'} spacing={'16px'}>
      <HStack w={'full'} justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={'bold'}>質問フォーム</Text>

        <FormControl
          isInvalid={!!errors.bookingForm?.enabled?.message}
          w={'fit-content'}
        >
          <HStack alignItems={'center'} spacing={2}>
            <Text>{enabled ? 'ON' : 'OFF'}</Text>

            <Switch
              {...register('bookingForm.enabled', {
                onChange: (e) => {
                  if (!e.target.checked) {
                    setValue('bookingSuccessEnabled', true, {
                      shouldDirty: true,
                    });
                  }
                },
              })}
            />
          </HStack>
        </FormControl>
      </HStack>
      {enabled &&
        (query.isFetching ? (
          <Spinner />
        ) : (
          <FormControl
            isRequired={enabled}
            isInvalid={!!errors.bookingForm?.formId?.message}
          >
            <Select
              {...register('bookingForm.formId', {
                required: '選択されていません',
              })}
              placeholder={'選択してください'}
              w={'full'}
            >
              {query.data?.map((form) => (
                <option key={form.id} value={form.id}>
                  {form.name}
                </option>
              ))}
            </Select>
          </FormControl>
        ))}
      {enabled && (
        <Text>
          質問フォームをONにした際の管理者へのチャット通知については{' '}
          <WebLink
            target={'_blank'}
            href={'https://help.pochico.app/121eddbd00d780148fe0c17550f4c885'}
          >
            こちら
          </WebLink>
        </Text>
      )}
    </VStack>
  );
};
