import { Button, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { BookingForm, ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useFetchBookingForm } from '../../../../hooks/bookingForms';
import { useIsPC } from '../../../../hooks/useIsPC';
import { bookingFormPreviewUrl } from '../../../../models/bookingForm';
import { Layout, LayoutBodyWithQuery } from '../../../ui/Layout';
import { PageTitle } from '../../../ui/PageTitle';
import { BookingFormCreateOrEditForm } from '../BookingFormCreateOrEdit';

export const BookingFormEdit: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
  bookingForm?: BookingForm;
}> = ({ providerAccount, id, bookingForm }) => {
  const query = useFetchBookingForm({
    providerAccount,
    id,
    enabled: !bookingForm,
  });
  const previewUrl = React.useMemo(
    () => bookingFormPreviewUrl(providerAccount, id),
    [id, providerAccount]
  );
  const isPC = useIsPC();
  return (
    <Layout
      pageTitle={
        isPC ? (
          <HStack w={'600px'} justifyContent={'space-between'}>
            <PageTitle>質問フォームの編集</PageTitle>
            <Button
              as={Link}
              target="_blank"
              href={previewUrl}
              colorScheme="gray"
              isExternal={true}
            >
              <HStack>
                <Icon as={FaExternalLinkAlt} />
                <Text>プレビュー</Text>
              </HStack>
            </Button>
          </HStack>
        ) : (
          '質問フォームの編集'
        )
      }
      hasBackButton={{
        providerAccount,
        resourceName: 'bookingForm',
        action: 'list',
      }}
      headerAction={
        !isPC && (
          <Button
            as={Link}
            target="_blank"
            href={previewUrl}
            colorScheme="gray"
            isExternal={true}
          >
            プレビュー
          </Button>
        )
      }
    >
      {bookingForm ? (
        <BookingFormCreateOrEditForm
          providerAccount={providerAccount}
          bookingForm={bookingForm}
        />
      ) : (
        <LayoutBodyWithQuery<BookingForm | undefined> query={query}>
          {(bookingForm) => {
            if (!bookingForm) {
              return <Text>見つかりません</Text>;
            }
            return (
              <BookingFormCreateOrEditForm
                providerAccount={providerAccount}
                bookingForm={bookingForm}
              />
            );
          }}
        </LayoutBodyWithQuery>
      )}
    </Layout>
  );
};
