import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { MdError } from 'react-icons/md';
import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { FixedRight } from '../../components/ui/Fixed';
import { PageTitle } from '../../components/ui/PageTitle';
import { RegistrationLayout } from '../../components/ui/RegistrationLayout';
import { RegistrationStepLineAttach } from '../../components/ui/RegistrationSteps';
import {
  RightArrowBlack,
  RightArrowWhite,
} from '../../components/ui/RightArrow';
import { useAuthState } from '../../context/providerAccount';
import { FirebaseUser } from '../../firebase/firebaseInit';
import {
  LineOfficialAccountIntro,
  Number1,
  Number2,
  Number3,
  Number4,
  StepTitle,
} from './components';

const _tag = '[line-oa-link]';

export const LineOALinkModuleApi: FC = () => {
  const { firebaseUser, providerAccount, initialized, status } = useAuthState();
  const toast = useToast();

  console.info(`${_tag}`, {
    providerAccountId: providerAccount?.id,
    firebaseUserId: firebaseUser?.uid,
    initialized,
    status,
  });

  setDocumentTitle('ポチコLINE連携');

  // if (initialized && !firebaseUser) {
  //   toast({
  //     title:
  //       'ログインに失敗しました。セッション切れの可能性があるので、次のページから再度ログインしてください。',
  //     status: 'error',
  //   });
  //   return <Navigate to={Path.login} />;
  // } else if (initialized && providerAccount) {
  //   if (needPayment(providerAccount)) {
  //     return <Navigate to={Path.unpaid} />;
  //   } else if (
  //     providerAccount.lineChannelType === 'moduleApi' &&
  //     providerAccount.status === 'attached'
  //   ) {
  //     toast({
  //       title: 'すでにLINE連携が完了しています',
  //       status: 'info',
  //     });
  //     return <Navigate to={Path.home} />;
  //   } else if (providerAccount.lineChannelType === 'messagingApi') {
  //     // Messaging API -> Module APIは連携方式を変更できない
  //     toast({
  //       title: 'Messaging APIで連携済みです',
  //       status: 'info',
  //     });
  //     return <Navigate to={Path.home} />;
  //   }
  // }

  return (
    <RegistrationLayout showLogoutButton={true}>
      <VStack alignItems={'center'} paddingX={'16px'}>
        {!initialized ? (
          <HStack
            alignItems={'center'}
            justifyContent={'center'}
            height={'30vh'}
            fontSize={'2xl'}
          >
            <Spinner />
            <Text>Loading... ステータス: {status}</Text>
          </HStack>
        ) : (
          <LineAttach initialized={initialized} firebaseUser={firebaseUser} />
        )}
      </VStack>
    </RegistrationLayout>
  );
};

const LineAttach: React.FC<{
  initialized: boolean;
  firebaseUser: FirebaseUser | undefined;
}> = ({ initialized, firebaseUser }) => {
  const disclosure = useDisclosure();
  return (
    <VStack
      justifyContent={'center'}
      maxWidth={{ base: 'full', md: '990px' }}
      alignItems={'center'}
      textAlign={'center'}
      marginX={'auto'}
      spacing={'32px'}
      py={'16px'}
    >
      <RegistrationStepLineAttach />
      <PageTitle whiteSpace={'nowrap'}>つづいてLINE連携を行います</PageTitle>
      <Text whiteSpace={'pre-wrap'}>
        {/* 手順をご確認の上、「LINE連携をする」ボタンを押し、移動した画面で設定をお願いいたします。
        <br /> */}
        LINE公式アカウントがない方は事前に開設が必要です。
      </Text>

      <VStack
        alignItems={'center'}
        w={'full'}
        paddingX={'16px'}
        spacing={'24px'}
      >
        <Button
          // disabled={isDisabled}
          bgColor={'green.500'}
          color={'white'}
          _hover={{
            bgColor: 'green.400',
            color: 'white',
          }}
          _active={{
            bgColor: 'green.300',
            color: 'white',
          }}
          width={{ base: 'full', md: '452px' }}
          h={'48px'}
          isLoading={!initialized}
          fontSize={{ base: 'md', md: 'xl' }}
          px={'24px'}
          borderRadius={20}
          onClick={disclosure.onOpen}
        >
          LINEのページに移動してポチコと連携する
          <FixedRight>
            <RightArrowWhite />
          </FixedRight>
        </Button>

        {/* <Link
          to={
            'https://docs.google.com/forms/d/e/1FAIpQLSdXxA91PSNGvQx5K079MMPOzwb_vrD7h79z23bYOOfmRBXB7w/viewform'
          }
          target="_blank"
        >
          <Button
            isLoading={!initialized}
            // disabled={isDisabled}
            colorScheme={'green'}
            width={['full', '452px']}
            borderRadius={20}
          >
            {'LINE連携を代行依頼する（無料）'}
            <FixedRight>
              <RightArrowWhite />
            </FixedRight>
          </Button>
        </Link> */}
      </VStack>

      {/* 
      Messaging APIをセルフサインアップ出来るようにするときに使う
      <WebLink
        fontSize={'sm'}
        href={Path.lineOaLinkMessagingApi}
        marginTop={8}
        width={['full', '452px']}
      >
        {'LINE Messaging APIを使った連携をする'}
      </WebLink> */}
      <HStack
        alignItems={'flex-start'}
        w={'full'}
        p={'16px'}
        borderWidth={'1px'}
        borderColor={'#CCCCCC'}
        borderRadius={'10px'}
      >
        <Icon as={MdError} color={'yellow.400'} boxSize={'24px'} />
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems={'flex-start'}
          w={'full'}
          justifyContent={'space-between'}
          gap={'8px'}
        >
          <VStack alignItems={'flex-start'} w={'full'}>
            <Text fontWeight={'bold'}>
              LINE連携が不安な方・うまくいかない方
            </Text>

            <Text textAlign={'left'}>
              ポチコスタッフが連携をサポートします。用語がわからない、初めてなので不安がある、変なページになってしまった等ありましたらお気軽にご依頼ください。
            </Text>
          </VStack>
          <Stack
            w={{ base: 'full', md: 'fit-content' }}
            direction={{ base: 'row', md: 'column' }}
          >
            <Box h={'full'} w={{ base: 'full', md: '280px' }}>
              <Button
                w={{ base: 'full', md: '280px' }}
                // colorScheme={'white'}
                to={'https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1'}
                target={'_blank'}
                as={Link}
                color={'black'}
                backgroundColor={'white'}
                width={'full'}
                height={'40px'}
                borderRadius={40}
                border={'1px solid #000'}
                fontSize={'16px'}
              >
                ヘルプを見る
                <FixedRight>
                  <RightArrowBlack />
                </FixedRight>
              </Button>
            </Box>
            <Box h={'full'} w={{ base: 'full', md: '280px' }}>
              <Button
                w={{ base: 'full', md: '280px' }}
                // colorScheme={'white'}
                to={
                  'https://docs.google.com/forms/d/e/1FAIpQLSdXxA91PSNGvQx5K079MMPOzwb_vrD7h79z23bYOOfmRBXB7w/viewform'
                }
                target={'_blank'}
                as={Link}
                color={'black'}
                backgroundColor={'white'}
                width={'full'}
                height={'40px'}
                borderRadius={40}
                border={'1px solid #000'}
                fontSize={'16px'}
              >
                連携代行を依頼
                <FixedRight>
                  <RightArrowBlack />
                </FixedRight>
              </Button>
            </Box>
          </Stack>
        </Stack>
      </HStack>

      <LineOfficialAccountIntro />
      <DialogBody disclosure={disclosure} firebaseUser={firebaseUser} />
    </VStack>
  );
};

const DialogBody: React.FC<{
  disclosure: ReturnType<typeof useDisclosure>;
  firebaseUser: FirebaseUser | undefined;
}> = ({ disclosure, firebaseUser }) => {
  const ref = React.useRef(null);
  return (
    <AlertDialog
      size={'3xl'}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      leastDestructiveRef={ref}
      isCentered={true}
      autoFocus={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent p={{ base: '12px', md: '24px' }}>
          <AlertDialogHeader>
            <Text fontSize={'xl'} fontWeight={'bold'}>
              LINE公式アカントと連携する
            </Text>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <VStack w={'fit-content'} spacing={'24px'} alignItems={'center'}>
              <HStack
                backgroundColor={'gray.100'}
                overflowX={'scroll'}
                w={'full'}
                padding={'16px'}
                spacing={'24px'}
              >
                <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
                  <HStack alignItems={'center'}>
                    <Number1 />
                    <StepTitle>LINEアカウントにログイン</StepTitle>
                  </HStack>
                  <Box>
                    <Image
                      src={'/assets/line_attach_step1.png'}
                      alt={'LINEアカウントにログイン'}
                      maxHeight={{ base: '60vh', md: '477px' }}
                    />
                  </Box>
                </VStack>
                <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
                  <HStack alignItems={'center'}>
                    <Number2 />
                    <StepTitle>紐付けるアカウントを選択</StepTitle>
                  </HStack>
                  <Box>
                    <Image
                      src={'/assets/line_attach_step2.png'}
                      alt={'紐付けるアカウントを選択'}
                      maxHeight={{ base: '60vh', md: '477px' }}
                    />
                  </Box>
                </VStack>
                <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
                  <HStack alignItems={'center'}>
                    <Number3 />
                    <StepTitle>「連携」をタップ</StepTitle>
                  </HStack>
                  <Box>
                    <Image
                      src={'/assets/line_attach_step3.png'}
                      alt={'「連携」をタップ'}
                      maxHeight={{ base: '60vh', md: '477px' }}
                    />
                  </Box>
                </VStack>
                <VStack minWidth={'fit-content'} alignItems={'flex-start'}>
                  <HStack alignItems={'center'}>
                    <Number4 />
                    <StepTitle>連携完了</StepTitle>
                  </HStack>
                  <Box>
                    <Image
                      src={'/assets/line_attach_step4.png'}
                      alt={'連携完了'}
                      maxHeight={{ base: '60vh', md: '477px' }}
                    />
                  </Box>
                </VStack>
              </HStack>
              <Button
                as={Link}
                to={`${GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN}/attach/${GLOBAL_CONFIG.SERVICE_NAME.UNIQ_NAME_EN}/auth?uid=${firebaseUser?.uid}`}
                target={'_blank'}
                // isLoading={!initialized}
                // disabled={isDisabled}
                bgColor={'green.500'}
                color={'white'}
                _hover={{
                  bgColor: 'green.400',
                  color: 'white',
                }}
                _active={{
                  bgColor: 'green.300',
                  color: 'white',
                }}
                width={{ base: 'full', md: '452px' }}
                h={'48px'}
                px={'24px'}
                fontSize={{ base: 'md', md: 'xl' }}
                borderRadius={20}
              >
                LINEのページに移動してポチコと連携する
                <FixedRight>
                  <RightArrowWhite />
                </FixedRight>
              </Button>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
