import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingMenuCreate } from '../../components/features/bookingMenus/create';

const BookingMenuCreatePage: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  useSetDocumentTitle('予約メニューの作成');

  return <BookingMenuCreate providerAccount={providerAccount} />;
};

export default BookingMenuCreatePage;
