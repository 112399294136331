import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingFormCreate } from '../../components/features/bookingForms/create';

const BookingFormCreatePage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  useSetDocumentTitle('新しい質問フォームを作成する');
  return <BookingFormCreate providerAccount={providerAccount} />;
};

export default BookingFormCreatePage;
