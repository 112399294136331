import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BookingCreateParams } from '../../../firebase/types';
import { useFetchSpot, useFetchSpots } from '../../../hooks/spots';
import { NavigationButton } from '../../ui/NavigationButton';
// import timezone from 'dayjs/plugin/timezone';
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Asia/Tokyo');

export const SpotInput: React.FC<{
  providerAccount: ProviderAccount;
  defaultValues?: {
    id?: string;
    date: string;
  };
}> = ({ providerAccount, defaultValues }) => {
  const { register, control, setValue, resetField, formState } = useFormContext<
    BookingCreateParams & { spot_date: string }
  >();
  const [spotDate, setSpotDate] = React.useState<string>(
    defaultValues?.date || ''
  );
  const spotQuery = useFetchSpot({
    providerAccount,
    id: defaultValues?.id,
  });
  const bookingMenuId = useWatch({
    name: 'bookingMenuId',
    control,
  });
  const spotsQuery = useFetchSpots({
    providerAccount,
    perPage: 999,
    page: 1,
    direction: 'asc',
    filter: {
      displayDate: {
        start: dayjs(spotDate).startOf('day').format('YYYY-MM-DD'),
        end: dayjs(spotDate).endOf('day').format('YYYY-MM-DD'),
      },
      bookingMenuId: bookingMenuId,
    },
    enabled: Boolean(bookingMenuId || !defaultValues?.id),
  });
  const onSpotDateChanged: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSpotDate(e?.target.value);
        resetField('spotId');
      },
      [resetField]
    );

  React.useEffect(() => {
    if (bookingMenuId) {
      setSpotDate('');
      resetField('spotId');
      resetField('spot_date');
    }
  }, [bookingMenuId, resetField]);

  React.useEffect(() => {
    if (defaultValues) {
      if (defaultValues.id) {
        setValue('spotId', defaultValues.id);
      }
      setValue('spot_date', defaultValues.date);
      setSpotDate(defaultValues.date);
    }
  }, [setValue, defaultValues]);

  return (
    <VStack alignItems={'flex-end'} spacing={'16px'}>
      <FormControl
        isRequired
        isInvalid={!!formState.errors?.spot_date}
        isDisabled={!defaultValues && !bookingMenuId}
      >
        <FormLabel w={{ base: '7rem', lg: 'fit-content' }}>
          予約枠の日付
        </FormLabel>
        <VStack>
          <Input
            {...register('spot_date', {
              required: true,
              onChange: onSpotDateChanged,
            })}
            type={'date'}
            placeholder="予約枠の日付"
            isDisabled={Boolean(defaultValues?.id)}
          />
          <FormErrorMessage>
            {formState.errors?.spotId?.message}
          </FormErrorMessage>
        </VStack>
      </FormControl>

      <FormControl
        isRequired
        isDisabled={!(defaultValues || (bookingMenuId && spotDate))}
        isInvalid={!!formState.errors?.spotId}
      >
        <FormLabel w={{ base: '7rem', lg: 'fit-content' }}>開始時間</FormLabel>
        {defaultValues?.id ? (
          spotQuery.isFetching ? (
            <Spinner />
          ) : (
            <Select
              {...register('spotId', {
                required: true,
              })}
              value={defaultValues.id}
              placeholder={'選択してください'}
              isDisabled={true}
              w={'max-content'}
            >
              <option
                id={defaultValues.id}
                key={defaultValues.id}
                value={defaultValues.id}
              >
                {` ${spotQuery.data?.startTime} (${spotQuery.data?.bookingIds.length}/${spotQuery.data?.maxBookings}枠)`}
              </option>
            </Select>
          )
        ) : spotsQuery.isFetching ? (
          <Spinner />
        ) : spotsQuery.data &&
          spotsQuery.data?.length &&
          spotsQuery.data.length > 0 ? (
          <Select
            {...register('spotId', {
              required: true,
            })}
            defaultValue={defaultValues?.id}
            placeholder={'選択してください'}
            w={'max-content'}
          >
            {spotsQuery.data.map((spot) => (
              <option id={spot.id} key={spot.id} value={spot.id}>
                {` ${spot.startTime} (${spot.bookingIds.length}/${spot.maxBookings}枠)`}
              </option>
            ))}
          </Select>
        ) : spotDate ? (
          <HStack justifyContent={'flex-start'} alignItems={'center'}>
            <Select
              placeholder={'予約枠がありません'}
              w={'max-content'}
              isDisabled
            />
            <NavigationButton
              // locationFrom={locationFrom}
              to={{
                providerAccount,
                resourceName: 'spot',
                action: 'create',
                urlParams: {
                  date: spotDate,
                  bookingMenuId: bookingMenuId,
                },
              }}
              variant={'white-blue'}
            >
              予約枠を作成
            </NavigationButton>
          </HStack>
        ) : (
          <Select
            placeholder={'日付を選択してください'}
            w={'max-content'}
            isDisabled
          />
        )}

        <FormErrorMessage>{formState.errors?.spotId?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};
