import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const PreviewContainer: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => (
  <VStack spacing={0} w={'full'}>
    <Box w={'full'} p={'8px'} bgColor={'gray.100'}>
      <Text
        color={'gray.500'}
        textAlign={'center'}
        w={'full'}
        fontWeight={'bold'}
      >
        {label}
      </Text>
    </Box>
    {children}
  </VStack>
);
