import { Location } from 'react-router-dom';

export const redirectBack = {
  write: (location: Location) => {
    const to = `${location.pathname}${location.search}${location.hash}`;
    localStorage.setItem('redirectBack', to);
  },
  readAndPurge: () => {
    const to = localStorage.getItem('redirectBack');
    localStorage.removeItem('redirectBack');
    return to;
  },
};
