import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { PathDictionary } from '../routers/Path';

export type UrlPath = {
  resourceName:
    | 'spot'
    | 'bookingMenu'
    | 'booking'
    | 'lineUser'
    | 'bookingForm'
    | 'sharedBookingForm'
    | string;
} & (
  | {
      action: 'list' | 'create';
    }
  | {
      action: 'show' | 'edit';
      resourceId: string;
    }
);

export const useUrlPath = (): UrlPath | undefined => {
  const location = useLocation();
  const urlPath: UrlPath | undefined = React.useMemo(() => {
    if (PathDictionary[location.pathname]) {
      return {
        resourceName: PathDictionary[location.pathname],
        action: 'list',
      };
    }
    const segments = location.pathname.split('/');
    // /<providerAccountId>/<botId>/<resourceName>/<resourceId>/<action>
    switch (segments.length) {
      case 2:
        if (segments[1] === '') {
          return { resourceName: 'home', action: 'list' };
        } else {
          return { resourceName: 'configuration', action: 'list' };
        }
      case 4:
        // /<providerAccountId>/<botId>/<resourceName>
        return {
          resourceName: segments[3] as UrlPath['resourceName'],
          action: 'list',
        };

      case 5:
        // /<providerAccountId>/<botId>/<resourceName>/create
        return {
          resourceName: segments[3] as UrlPath['resourceName'],
          action: 'create',
        };

      case 6:
        // /<providerAccountId>/<botId>/<resourceName>/<resourceId>/show|edit
        return {
          resourceName: segments[3] as UrlPath['resourceName'],
          resourceId: segments[4] as string,
          action: segments[5] as 'show' | 'edit',
        };

      default:
        throw new Error(`[useUrlPath] invalid path: ${location.pathname}`);
    }
  }, [location]);

  return urlPath;
};

export type ResourceProp = UrlPath & {
  providerAccount: ProviderAccount;
  urlParams?: Record<string, string>;
};

export const resourcePath = (props: ResourceProp) => {
  const { providerAccount, urlParams } = props;
  const prefix = `/${providerAccount.id}/${providerAccount.botId}`;
  const param = urlParams
    ? `?${new URLSearchParams(props.urlParams).toString()}`
    : '';

  const { resourceName, action } = props;
  switch (action) {
    case 'list':
      return `${prefix}/${resourceName}${param}`;

    case 'create':
      return `${prefix}/${resourceName}/create${param}`;

    case 'edit':
      return `${prefix}/${resourceName}/${props.resourceId}/${action}${param}`;

    case 'show':
      return `${prefix}/${resourceName}/${props.resourceId}/${action}${param}`;
  }
};
