import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { scrollToTop } from '../../helpers/scrollToTop';
import { LocationFrom } from '../../hooks/locationState';

type Props = LinkProps & {
  locationFrom?: LocationFrom;
};
export const WebLink: React.FC<Props> = React.forwardRef(
  ({ locationFrom, ...props }, ref) => {
    return (
      <ChakraLink
        as={RouterLink}
        to={props.href}
        cursor={'pointer'}
        onClick={scrollToTop}
        state={locationFrom?.path}
        color={'blue.500'}
        textDecoration={'underline'}
        _hover={{
          color: 'blue.400',
          textDecoration: 'underline',
        }}
        _active={{
          color: 'blue.200',
          textDecoration: 'underline',
        }}
        {...props}
      />
    );
  }
);
