import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const LineBackground: React.FC<
  FlexProps & { children: React.ReactNode }
> = ({ children, ...props }) => {
  return (
    <Flex
      padding={'6'}
      backgroundColor={'line.talk'}
      borderRadius={'base'}
      width={'full'}
      justifyContent={'center'}
      {...props}
    >
      {children}
    </Flex>
  );
};
