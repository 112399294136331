import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { useAuthState } from '../../../context/providerAccount';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { useInvoiceConfigurationQuery } from '../../../hooks/providerAccount';
import { useSendEmailVerificationMutation } from '../../../hooks/useEmailVerification';
import { Path } from '../../../routers/Path';
import Footer from '../../ui/Footer';
import { Layout } from '../../ui/Layout';

// 請求先メールアドレスを登録するためのフォーム
// 入力されたメールアドレスに対して、支払い情報登録ページへのリンクを送る
export const ChangeInvoiceConfigurationForm = () => {
  const { initialized, providerAccount, firebaseUser } = useAuthState();
  if (!initialized) {
    return <Spinner />;
  }
  if (!providerAccount || !firebaseUser) {
    return <Navigate to={Path.login} />;
  }
  return (
    <ChangeInvoiceConfigurationFormInner
      providerAccount={providerAccount}
      firebaseUser={firebaseUser}
    />
  );
};
const ChangeInvoiceConfigurationFormInner: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const invoiceConfiguration = useInvoiceConfigurationQuery({
    providerAccount,
    firebaseUser,
  });
  if (invoiceConfiguration.isFetching) {
    return <Spinner />;
  }
  if (!invoiceConfiguration.data?.invoiceConfiguration) {
    return <Text>請求先情報が見つかりません</Text>;
  }
  return (
    <Body
      providerAccount={providerAccount}
      firebaseUser={firebaseUser}
      invoiceConfiguration={invoiceConfiguration.data.invoiceConfiguration}
    />
  );
};

const Body: React.FC<{
  providerAccount: ProviderAccount;
  firebaseUser: FirebaseUser;
  invoiceConfiguration: { invoiceName: string; invoiceEmail: string | null };
}> = ({ providerAccount, firebaseUser, invoiceConfiguration }) => {
  const toast = useToast();
  const mutation = useSendEmailVerificationMutation(
    providerAccount,
    firebaseUser,
    {
      onSuccess: () => {
        toast({
          title: `請求先メールアドレス「${invoiceConfiguration.invoiceEmail}」に届いているメールをご確認の上、本文内のURLリンクをクリックして認証を完了してください。`,
          status: 'warning',
        });
      },
      onError: (error) => {
        toast({
          title: `エラーが発生しました ${error}`,
          status: 'error',
        });
      },
    }
  );
  const currentInvoiceName = invoiceConfiguration.invoiceName;
  const currentInvoiceEmail = invoiceConfiguration.invoiceEmail || undefined;

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm<{ invoiceName: string; invoiceEmail: string }>({
    defaultValues: {
      invoiceName: currentInvoiceName,
      invoiceEmail: currentInvoiceEmail,
    },
  });

  const onSubmit = React.useCallback(
    ({
      invoiceName,
      invoiceEmail,
    }: {
      invoiceName: string;
      invoiceEmail: string;
    }) => {
      // 現在のメールアドレスと同じものでもsubmitはできるようにしておく
      return mutation.mutateAsync({
        action: 'changeInvoiceConfiguration',
        payload: {
          invoiceName,
          invoiceEmail,
        },
      });
    },
    [mutation]
  );
  const isDisabled =
    // !inputInvoiceEmail ||
    // inputInvoiceEmail === currentInvoiceEmail ||
    mutation.isSuccess || !isValid;
  if (
    providerAccount.paymentStatus === 'unregistered' ||
    !currentInvoiceEmail
  ) {
    return (
      <Layout pageTitle={'請求先情報の変更'} hasBackButton={Path.configuration}>
        <Text>請求先情報が未設定です</Text>
      </Layout>
    );
  }

  return (
    <Layout pageTitle={'請求先情報の変更'} hasBackButton={Path.configuration}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          w={{ base: 'full', md: '840px' }}
          alignItems={'flex-start'}
          spacing={'32px'}
        >
          {/* <Text>
            現在の請求先メールアドレス：{currentInvoiceEmail || '未設定です'}
          </Text> */}
          <VStack w={'full'} spacing={'20px'} alignItems={'center'}>
            <FormControl isRequired isInvalid={!!errors.invoiceName}>
              <FormLabel>請求先</FormLabel>
              <InputGroup>
                <Input
                  type={'text'}
                  {...register('invoiceName', {
                    required: '入力は必須です',
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors.invoiceName?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
          <VStack w={'full'} spacing={'20px'} alignItems={'center'}>
            <FormControl isRequired isInvalid={!!errors.invoiceEmail}>
              <FormLabel>請求先メールアドレス</FormLabel>
              <InputGroup>
                <Input
                  type={'email'}
                  {...register('invoiceEmail', {
                    required: '入力は必須です',
                  })}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.invoiceEmail?.message}
              </FormErrorMessage>
            </FormControl>
            <HStack w={'full'} justifyContent={'flex-end'}>
              <Button
                type={'submit'}
                variant="blue-fill"
                isLoading={mutation.isPending}
                isDisabled={isDisabled}
              >
                認証メールを送信する
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </form>
      <Footer />
    </Layout>
  );
};
