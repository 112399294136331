import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Radio,
  Select,
  StackDivider,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  PredefinedSharedBookingFormElement,
  SharedBookingFormElement,
  sleep,
} from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { useIsPC } from '../../../../hooks/useIsPC';
import { SharedBookingFormElementUpsertParams } from '../../../../providers/dataProvider/sharedBookingForm';

type FormElement =
  | SharedBookingFormElement
  | PredefinedSharedBookingFormElement;
const targetAtom = atom<
  { isOpen: true; element: FormElement } | { isOpen: false; element?: never }
>({ isOpen: false });
targetAtom.onMount = (setAtom) => {
  setAtom({ isOpen: false });
};
export const useSharedBookingFormElementEditor = (
  formElement: FormElement,
  onChange: (formElement: SharedBookingFormElementUpsertParams) => Promise<void>
) => {
  const form = useForm<SharedBookingFormElementUpsertParams>({
    defaultValues: formElement,
  });
  const {
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
    trigger,
  } = form;
  const [target, setTarget] = useAtom(targetAtom);
  const disclosure = useDisclosure();
  const { onOpen, onClose: _onClose } = disclosure;
  const onClose = React.useCallback(async () => {
    _onClose();
    await sleep(100);
    setTarget({ isOpen: false });
  }, [_onClose, setTarget]);

  const toast = useToast();
  const onSubmit = useMutation({
    mutationFn: React.useCallback(async () => {
      const ok = await trigger();
      if (ok) {
        const formElement = getValues();
        return onChange(formElement);
      }
    }, [getValues, onChange, trigger]),
    onSuccess: () => {
      toast.closeAll();
      toast({
        title: '保存しました',
        status: 'success',
      });
      onClose();
    },
    onError: (e) => {
      toast({
        title: `エラーが発生しました ${e}`,
        status: 'error',
      });
    },
  });
  const isPC = useIsPC();
  const onClickToEdit = React.useCallback(
    async (formElement: FormElement) => {
      setTarget({ isOpen: true, element: formElement });
      onOpen();
    },
    [onOpen, setTarget]
  );

  const renderEditor = React.useCallback(() => {
    if (!target.isOpen) {
      return <></>;
    }
    const _disclosure = {
      ...disclosure,
      // isOpen: target.isOpen,
      onClose: onClose,
    };
    return (
      <FormProvider {...form}>
        {isPC ? (
          <SharedBookingFormElementEditorDialog
            key={target.element.id}
            disclosure={_disclosure}
            formElement={formElement}
            onSubmit={onSubmit.mutateAsync}
          />
        ) : (
          <SharedBookingFormElementEditorDrawer
            key={target.element.id}
            disclosure={_disclosure}
            formElement={formElement}
            onSubmit={onSubmit.mutateAsync}
          />
        )}
      </FormProvider>
    );
  }, [
    disclosure,
    form,
    formElement,
    isPC,
    onClose,
    onSubmit.mutateAsync,
    target,
  ]);
  return {
    onClickToEdit,
    renderEditor,
  };
};

const SharedBookingFormElementEditorDialog: React.FC<{
  disclosure: ReturnType<typeof useDisclosure>;
  formElement: FormElement;
  onSubmit: () => Promise<void>;
}> = ({ disclosure: { isOpen, onClose }, formElement, onSubmit }) => {
  const { isValid } = useFormState();
  const cancelRef = React.useRef(null);
  const mutation = useMutation({
    mutationFn: onSubmit,
  });
  return (
    <AlertDialog
      size={'2xl'}
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      autoFocus={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent marginX={'16px'}>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            alignItems={'center'}
          >
            <Text w={'full'}>質問内容の編集</Text>
            <AlertDialogCloseButton />
          </AlertDialogHeader>
          <AlertDialogBody>
            <SharedBookingFormElementEditorBody formElement={formElement} />
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack w={'full'} justifyContent={'flex-end'} gap={'12px'}>
              <Button colorScheme="gray" onClick={onClose}>
                キャンセル
              </Button>
              <Button
                isDisabled={!isValid}
                isLoading={mutation.isPending}
                variant={'blue-fill'}
                onClick={() => mutation.mutateAsync()}
              >
                保存
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const SharedBookingFormElementEditorDrawer: React.FC<{
  disclosure: ReturnType<typeof useDisclosure>;
  formElement: FormElement;
  onSubmit: () => Promise<void>;
}> = ({ disclosure: { isOpen, onClose }, formElement, onSubmit }) => {
  const { isValid } = useFormState();
  const mutation = useMutation({
    mutationFn: onSubmit,
  });
  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      size={'xl'}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isFullHeight={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Text w={'full'}>質問内容の編集</Text>
        </DrawerHeader>

        <DrawerBody
          borderTopWidth={'1px'}
          borderTopColor={'gray.200'}
          borderBottomWidth={'1px'}
          borderBottomColor={'gray.200'}
        >
          <SharedBookingFormElementEditorBody formElement={formElement} />
        </DrawerBody>

        <DrawerFooter>
          <HStack w={'full'} justifyContent={'flex-end'} gap={'12px'}>
            <Button colorScheme="gray" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              isDisabled={!isValid}
              isLoading={mutation.isPending}
              variant={'blue-fill'}
              onClick={() => mutation.mutateAsync()}
            >
              保存
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const SharedBookingFormElementEditorBody: React.FC<{
  formElement: FormElement;
}> = ({ formElement }) => {
  const form = useFormContext<SharedBookingFormElementUpsertParams>();
  const {
    formState: { errors },
    register,
  } = form;
  const type = formElement.type;
  const handleTextareaChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    []
  );
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      gap={'20px'}
      p={0}
      divider={<StackDivider borderColor={'gray.200'} />}
      // borderLeftWidth={'4px'}
      // borderLeftColor={'gray.500'}
      // px={'12px'}
    >
      <VStack w={'full'} alignItems={'flex-start'} gap={'20px'}>
        <FormControl isReadOnly isRequired isInvalid={!!errors.type}>
          <FormLabel>タイプ</FormLabel>
          <Box w={'full'}>
            <Select
              {...register(`type`)}
              value={formElement.type}
              isReadOnly
              isDisabled
            >
              <option value={formElement.type}>{formElement.name}</option>
              {/* <option value="name">氏名</option>
            <option value="furigana">カナ入力</option>
            <option value="tel">電話番号</option>
            <option value="email">メールアドレス</option>
            <option value="memberId">会員番号</option>
            <option value="sex">性別</option> */}
            </Select>
          </Box>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.title}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <FormLabel>質問文</FormLabel>
            <FormHelperText color={'gray.500'}>100文字まで</FormHelperText>
          </HStack>
          <Box w={'full'} h={'fit-content'}>
            <Textarea
              minW={'full'}
              maxW={'full'}
              // boxSizing={'content-box'}
              minH={'1em'}
              h={'fit-content'}
              pb={0}
              maxH={'10em'}
              fontSize={'md'}
              bgColor={'white'}
              placeholder={'質問文'}
              {...register(`title`, {
                onChange: handleTextareaChange,
                required: true,
                maxLength: 100,
              })}
            />
          </Box>
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.description}>
          <HStack w={'full'} justifyContent={'space-between'}>
            <FormLabel>説明文</FormLabel>
            <FormHelperText color={'gray.500'}>400文字まで</FormHelperText>
          </HStack>
          <Box w={'full'} h={'fit-content'}>
            <Textarea
              minW={'full'}
              maxW={'full'}
              // boxSizing={'content-box'}
              minH={'3em'}
              h={'fit-content'}
              maxH={'10em'}
              fontSize={'md'}
              bgColor={'white'}
              placeholder={'説明文'}
              {...register(`description`, {
                onChange: handleTextareaChange,
                required: false,
                maxLength: 400,
              })}
            />
          </Box>
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      {type === 'sex' && (
        <VStack w={'full'} gap={'8px'} alignItems={'flex-start'}>
          <Text color={'gray.900'} fontSize={'16px'} fontWeight={'bold'}>
            選択肢設定
          </Text>
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            spacing={'8px'}
            mt={'12px'}
            pl={'12px'}
            borderLeftWidth={'2px'}
            borderLeftColor={'gray.500'}
          >
            {formElement.candidates.map((candidate, i) => (
              <HStack key={`sex-${i}`} justifyContent={'flex-start'}>
                <Radio readOnly isChecked={false} />
                <Text>{candidate}</Text>
              </HStack>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
